import { useState } from 'react';
import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import { DeleteForever, RestoreFromTrash } from '@mui/icons-material';
import { IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';

import * as RiskCatalogueApi from 'api/riskCatalogue';
import PropertyDisplay from './PropertyDisplay';
import ConfirmDialog from './ConfirmDialog';
import useAlerts from 'hooks/useAlerts';

interface IDeletedCatalogueItemCardProps {
	catalogueItem: IApiRiskCatalogueItem;
	onChange: () => void;
}

export default function DeletedCatalogueItemCard({ catalogueItem, onChange }: IDeletedCatalogueItemCardProps) {
	const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();

	const restoreCatalogueItem = async () => {
		setIsSaving(true);

		try {
			await RiskCatalogueApi.restoreDeletedCatalogueItem(catalogueItem.risk_catalogue_id);
			addAlert('success', `Catalogue item '${catalogueItem.name}' has been restored`);
			onChange();
		} catch {
			addAlert('error', `Failed to restore catalogue item '${catalogueItem.name}'`);
		} finally {
			setIsSaving(false);
		}
	};

	const deleteCatalogueItem = async () => {
		setIsSaving(true);

		try {
			await RiskCatalogueApi.permanentlyDeleteCatalogueItem(catalogueItem.risk_catalogue_id);
			addAlert('success', `Catalogue item '${catalogueItem.name}' has been deleted`);
			onChange();
		} catch {
			addAlert('error', `Failed to delete catalogue item '${catalogueItem.name}'`);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Card
			sx={{
				margin: (theme) => theme.spacing(1),
				width: '400px',
			}}
		>
			<Box sx={{ margin: (theme) => theme.spacing(1) }}>
				<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: (theme) => theme.spacing(1) }}>
					<Typography
						component="div"
						variant="h6"
						sx={{ flexGrow: 1, marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(1) }}
					>
						{catalogueItem.name}
					</Typography>
				</Box>
				<Box sx={{ marginLeft: (theme) => theme.spacing(1) }}>
					<PropertyDisplay name="Register type" compact width="120px">
						<Typography>{catalogueItem.risk_register_type.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Lifecycle status" compact width="120px">
						<Typography>{catalogueItem.risk_register_lifecycle_status.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Risk type" compact width="120px">
						<Typography>{catalogueItem.risk_type.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Risk category" compact width="120px">
						<Typography>{catalogueItem.risk_category.name}</Typography>
					</PropertyDisplay>
				</Box>
			</Box>
			<CardActions>
				<Box sx={{ flexGrow: 1 }} />
				<Button startIcon={<RestoreFromTrash />} onClick={() => setIsRestoreDialogOpen(true)} disabled={isSaving}>
					Restore
				</Button>
				<Button startIcon={<DeleteForever />} onClick={() => setIsDeleteDialogOpen(true)} disabled={isSaving}>
					Delete
				</Button>
			</CardActions>
			{isRestoreDialogOpen && (
				<ConfirmDialog
					title="Restore catalogue item?"
					message={`The catalogue item '${catalogueItem.name}' will be restored.`}
					cancelLabel="Cancel"
					confirmLabel="Confirm and restore"
					onClose={() => setIsRestoreDialogOpen(false)}
					onConfirm={() => restoreCatalogueItem()}
				/>
			)}
			{isDeleteDialogOpen && (
				<ConfirmDialog
					title="Permently delete catalogue item?"
					message={`The catalogue item '${catalogueItem.name}' will be deleted from Risk Safe. This action cannot be reverted.`}
					cancelLabel="Cancel"
					confirmLabel="Confirm and permanently delete"
					onClose={() => setIsDeleteDialogOpen(false)}
					onConfirm={() => deleteCatalogueItem()}
				/>
			)}
		</Card>
	);
}
