import { useContext, useMemo } from 'react';
import {
	createTheme,
	ThemeProvider,
	Theme,
	StyledEngineProvider,
	PaletteOptions,
	ThemeOptions,
} from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { ColorModeContext } from 'providers/ColorModeProvider';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const mitiePurple = '#5b1f69';
const mitiePurpleLight = '#974892';
const mitiePurpleDark = '#421757';
const mitieRed = '#d71920';
const mitieRedLight = '#ea6c69';
const mitieRedDark = '#bc0007';

const lightPalette: PaletteOptions = {
	primary: {
		light: mitiePurpleLight,
		main: mitiePurple,
		dark: mitiePurpleDark,
		contrastText: '#ffffff',
	},
	secondary: {
		light: mitieRedLight,
		main: mitieRed,
		dark: mitieRedDark,
		contrastText: '#ffffff',
	},
	background: {
		default: '#f5f5f5',
	},
	mode: 'light',
};

const darkPalette: PaletteOptions = {
	secondary: {
		light: mitieRedLight,
		main: mitieRed,
		dark: mitieRedDark,
		contrastText: '#000000',
	},
	background: {
		default: '#242424',
	},
	mode: 'dark',
};

const theme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					whiteSpace: 'nowrap',
				},
			},
		},
	},
	typography: {
		body1: {
			fontSize: 14,
		},
		subtitle1: {
			fontSize: 14,
		},
		h5: {
			fontSize: 22,
		},
		h6: {
			fontSize: 18,
		},
	},
};

const lightTheme = createTheme({
	...theme,
	palette: lightPalette,
});

const darkTheme = createTheme({
	...theme,
	palette: darkPalette,
});

interface IThemeProps {
	children: any;
}

export default function CustomTheme(props: IThemeProps) {
	const { colorMode } = useContext(ColorModeContext);
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const theme = useMemo(() => {
		if (colorMode === 'auto') {
			return prefersDarkMode ? darkTheme : lightTheme;
		} else if (colorMode === 'dark') {
			return darkTheme;
		} else {
			return lightTheme;
		}
	}, [prefersDarkMode, colorMode]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</StyledEngineProvider>
	);
}
