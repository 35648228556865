import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import PowerBiReport from 'components/PowerBiReport';

export default function ManagementInformation() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'ManagementInformationPage');

	componentTracking();

	return <PowerBiReport />;
}
