import { Box, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import { useNavigate, useParams } from 'routing/routing';
import CompaniesAdmin from 'components/CompaniesAdmin';
import AuthenticationProvidersAdmin from 'components/AuthenticationProvidersAdmin';
import UsersAdmin from 'components/UsersAdmin';

interface IDataset {
	key: string;
	name: string;
	description: string;
}

const datasets: IDataset[] = [
	{ key: 'companies', name: 'Customers', description: 'Configure customers and licenses' },
	{
		key: 'auth-providers',
		name: 'Authentication providers',
		description: 'Configure Single Sign-On options',
	},
	{
		key: 'users',
		name: 'Users',
		description: 'View and manage Risk Safe users',
	},
];

export default function Administration() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'AdministrationPage');
	const navigate = useNavigate();
	const { page } = useParams();

	componentTracking();

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto', flexShrink: 0 }}>
				{datasets.map(({ key, name, description }) => (
					<ListItemButton key={key} selected={key === page} onClick={() => navigate(null, { page: key })}>
						<ListItemText primary={name} secondary={description} />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{page === 'companies' && <CompaniesAdmin />}
			{page === 'auth-providers' && <AuthenticationProvidersAdmin />}
			{page === 'users' && <UsersAdmin />}
		</Box>
	);
}
