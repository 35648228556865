import React, { useState } from 'react';
import { IApiRisk, IApiRiskRegister } from '@mitie/risk-register-api-types';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

import RiskRegisterTree from './RiskRegisterTree';
import * as RiskRegistersApi from 'api/riskRegisters';

interface IMoveRiskDialogProps {
	registersList: IApiRiskRegister[];
	register: IApiRiskRegister[];
	risk: IApiRisk;
	onChange: (selected: IApiRiskRegister[]) => void;
	onClose: () => void;
}

export default function MoveRiskDialog({ registersList, register, risk, onChange, onClose }: IMoveRiskDialogProps) {
	const [selected, setSelected] = useState(register);
	const [isSaving, setIsSaving] = useState(false);

	const moveRisk = async () => {
		setIsSaving(true);

		try {
			const existingRegisterId = register[register.length - 1].risk_register_id;
			const newRegisterId = selected[selected.length - 1].risk_register_id;
			await RiskRegistersApi.moveRisk(existingRegisterId, newRegisterId, risk.risk_id);
			onChange(selected);
			onClose();
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Risk register to move this risk to</DialogTitle>
			<DialogContent sx={{ height: '400px' }}>
				<RiskRegisterTree
					list={registersList}
					selected={selected}
					onSelect={(newSelected: IApiRiskRegister[]) => setSelected(newSelected)}
				/>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					onClick={async () => {
						moveRisk();
					}}
					disabled={!selected || isSaving}
					variant="contained"
				>
					Move risk
				</Button>
			</DialogActions>
		</Dialog>
	);
}
