import {
	IApiRiskCatalogueItem,
	IApiRiskCatalogueItemCreate,
	IApiRiskCatalogueItemUpdate,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAll() {
	return Api.get<IApiRiskCatalogueItem[]>('risk-catalogue');
}

export function updateRiskCatalogueItem(riskCatalogueId: number, data: IApiRiskCatalogueItemUpdate) {
	return Api.patch<void>(`risk-catalogue/${riskCatalogueId}`, data);
}

export function deleteRiskCatalogueItem(riskCatalogueId: number) {
	return Api.delete_(`risk-catalogue/${riskCatalogueId}`);
}

export function createRiskCatalogueItem(data: IApiRiskCatalogueItemCreate) {
	return Api.post<void>(`risk-catalogue`, data);
}

export function getDeletedCatalogueItems() {
	return Api.get<IApiRiskCatalogueItem[]>('deleted-catalogue-items');
}

export function restoreDeletedCatalogueItem(riskCatalogueId: number) {
	return Api.post<void>(`deleted-catalogue-items/${riskCatalogueId}/restore`, {});
}

export function permanentlyDeleteCatalogueItem(riskCatalogueId: number) {
	return Api.delete_<void>(`deleted-catalogue-items/${riskCatalogueId}`);
}
