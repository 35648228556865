import { IApiUserRoleAssignment, IApiUserRoleAssignmentCreate } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAssignmentsForUser(userId: string) {
	return Api.get<IApiUserRoleAssignment[]>(`users/${userId}/role-assignments`);
}

export function create(userId: string, data: IApiUserRoleAssignmentCreate) {
	return Api.post(`users/${userId}/role-assignments`, data);
}

export function remove(userId: string, data: IApiUserRoleAssignmentCreate) {
	return Api.delete_(`users/${userId}/role-assignments`, data);
}
