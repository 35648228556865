import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Close, Save } from '@mui/icons-material';

interface IFormData {
	description: string;
	owner: string;
	start_date: string;
	end_date: string;
	completed: boolean;
}

interface IAddOrUpdatedRiskTreatmentActionDialogProps {
	data?: IFormData;
	onClose: (data?: IFormData) => void;
}

const validationSchema = yup.object({
	description: yup.string().required('Description is required').max(1024),
	owner: yup.string().required('Owner is required').max(255),
	start_date: yup.date().required('Start date is required'),
	end_date: yup
		.date()
		.required('End date is required')
		.min(yup.ref('start_date'), 'End date cannot be before start date'),
	completed: yup.boolean().required().default(false),
});

export default function AddOrUpdateRiskTreatmentActionDialog({
	data,
	onClose,
}: IAddOrUpdatedRiskTreatmentActionDialogProps) {
	const formik = useFormik<IFormData>({
		initialValues: data || { description: '', owner: '', start_date: '', end_date: '', completed: false },
		validationSchema,
		validateOnMount: true,
		onSubmit: (data) => onClose(data),
	});

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>{`${data ? 'Update' : 'Add'} action`}</DialogTitle>
			<DialogContent>
				<TextField
					label="Description"
					name="description"
					value={formik.values.description}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.description)}
					helperText={formik.touched.description && formik.errors.description}
					fullWidth
					multiline
					rows={3}
					sx={{ marginTop: (theme) => theme.spacing(1) }}
				/>
				<TextField
					label="Owners"
					name="owner"
					value={formik.values.owner}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.owner)}
					helperText={formik.touched.owner && formik.errors.owner}
					sx={{ marginTop: (theme) => theme.spacing(2) }}
					fullWidth
				/>
				<Box sx={{ display: 'flex' }}>
					<DatePicker
						label="Start date"
						value={new Date(formik.values.start_date)}
						onChange={(newValue) => {
							if (newValue) {
								formik.setFieldValue('start_date', format(newValue, 'yyyy-MM-dd'));
							} else {
								formik.setFieldValue('start_date', '');
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={Boolean(formik.errors.start_date)}
								helperText={formik.touched.start_date && formik.errors.start_date}
								sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2) }}
								fullWidth
							/>
						)}
					/>
					<DatePicker
						label="End date"
						value={new Date(formik.values.end_date)}
						onChange={(newValue) => {
							if (newValue) {
								formik.setFieldValue('end_date', format(newValue, 'yyyy-MM-dd'));
							} else {
								formik.setFieldValue('end_date', '');
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								error={Boolean(formik.errors.end_date)}
								helperText={formik.touched.end_date && formik.errors.end_date}
								sx={{ marginTop: (theme) => theme.spacing(2) }}
								fullWidth
							/>
						)}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={formik.values.completed}
								onChange={(e) => formik.setFieldValue('completed', e.target.checked)}
							/>
						}
						label="Action completed"
						sx={{ marginTop: (theme) => theme.spacing(2), marginLeft: (theme) => theme.spacing(2) }}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					onClick={() => formik.submitForm()}
					color="primary"
					variant="contained"
					disabled={!formik.isValid}
				>
					{data ? 'Update' : 'Add'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
