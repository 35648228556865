import React, { useState } from 'react';
import {
	AppBar,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Divider,
	Badge,
	LinearProgress,
	useMediaQuery,
	Link,
	Box,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import { EmailOutlined, Help, Launch, Menu as MenuIcon, Notifications } from '@mui/icons-material';

import { useNavigate } from 'routing/routing';
import { Page } from 'routing/routes';
import useRecursiveTimeout from 'hooks/useRecursiveTimeout';
import useNotifications from 'hooks/useNotifications';
import logo from 'images/logo-small-dark.png';
import smallLogo from 'images/logo-small-text-dark.png';
import { formatDateTime } from 'utils';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginLeft: -12,
			marginRight: 20,
		},
		notificationButtonProgress: {
			color: theme.palette.secondary.main,
			position: 'relative',
			left: -48,
			zIndex: -1,
		},
		notificationText: {
			whiteSpace: 'initial',
			maxWidth: '500px',
		},
		headerDivider: {
			borderColor: theme.palette.primary.contrastText,
		},
	}),
);

interface IHeaderBarProps {
	onNavBarToggle: () => void;
	pageName: string;
}

export default function HeaderBar({ onNavBarToggle, pageName }: IHeaderBarProps) {
	const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] = useState<HTMLElement | null>(null);
	const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLElement | null>(null);
	const classes = useStyles();
	const navigate = useNavigate();
	const { notifications, notificationsFetching, fetchNotifications } = useNotifications();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

	useRecursiveTimeout(fetchNotifications, 60000);

	return (
		<AppBar>
			<Toolbar>
				<IconButton color="inherit" aria-label="Open menu" onClick={onNavBarToggle}>
					<MenuIcon />
				</IconButton>
				<a href="/">
					<img
						src={isSmall ? smallLogo : logo}
						alt="logo"
						style={{ height: '56px', marginTop: '4px', marginLeft: '16px', marginRight: '32px' }}
					/>
				</a>
				{!isSmall && (
					<>
						<Divider orientation="vertical" flexItem variant="middle" className={classes.headerDivider} />
						<Typography
							variant="h6"
							color="inherit"
							noWrap={true}
							sx={{ marginLeft: (theme) => theme.spacing(4), textTransform: 'uppercase' }}
						>
							{pageName}
						</Typography>
					</>
				)}
				<div className={classes.grow} />
				<IconButton onClick={(e) => setHelpMenuAnchorEl(e.currentTarget)} color="inherit">
					<Help />
				</IconButton>
				<IconButton onClick={(e) => setNotificationsMenuAnchorEl(e.currentTarget)} color="inherit">
					<Badge badgeContent={notifications?.length} color="secondary">
						<Notifications />
					</Badge>
				</IconButton>
				<Menu
					anchorEl={helpMenuAnchorEl}
					keepMounted={true}
					open={Boolean(helpMenuAnchorEl)}
					onClose={() => setHelpMenuAnchorEl(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Box style={{ padding: '0.5rem 1rem' }}>
						{process.env.REACT_APP_HELP_URL && (
							<Link
								href={process.env.REACT_APP_HELP_URL}
								variant="body1"
								underline="hover"
								target="_blank"
								rel="noopener"
								sx={{ display: 'flex', alignItems: 'center', marginBottom: (theme) => theme.spacing(1) }}
							>
								Risk Safe help <Launch fontSize="small" sx={{ marginLeft: (theme) => theme.spacing(1) }} />
							</Link>
						)}
						{process.env.REACT_APP_SUPPORT_EMAIL && (
							<Link
								href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
								variant="body1"
								underline="hover"
								rel="noopener"
								sx={{ display: 'flex', alignItems: 'center', marginBottom: (theme) => theme.spacing(1) }}
							>
								<EmailOutlined fontSize="small" sx={{ marginRight: (theme) => theme.spacing(1) }} /> Email support
							</Link>
						)}
						<Typography>Risk Safe release: {process.env.REACT_APP_RELEASE_ID}</Typography>
						{process.env.REACT_APP_RELEASE_NOTES_URL && (
							<Link
								href={process.env.REACT_APP_RELEASE_NOTES_URL}
								variant="body1"
								underline="hover"
								target="_blank"
								rel="noopener"
								sx={{ display: 'flex', alignItems: 'center', marginTop: (theme) => theme.spacing(1) }}
							>
								What&apos;s new? <Launch fontSize="small" sx={{ marginLeft: (theme) => theme.spacing(1) }} />
							</Link>
						)}
					</Box>
				</Menu>
				<Menu
					anchorEl={notificationsMenuAnchorEl}
					keepMounted={true}
					open={Boolean(notificationsMenuAnchorEl)}
					onClose={() => setNotificationsMenuAnchorEl(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					PaperProps={{
						style: {
							maxHeight: '50%',
							width: '400px',
						},
					}}
				>
					{notificationsFetching && <LinearProgress />}
					{notifications.map((notification, index) => (
						<MenuItem
							key={index}
							dense={true}
							divider={index !== notifications.length - 1}
							onClick={() => {
								setNotificationsMenuAnchorEl(null);
								navigate(notification.link.page as Page, notification.link.params);
							}}
							sx={{ flexDirection: 'column', alignItems: 'start' }}
						>
							<Typography variant="body2" className={classes.notificationText}>
								{notification.message}
							</Typography>
							<Typography variant="caption" className={classes.notificationText}>
								{notification.scope}
							</Typography>
							<Typography variant="caption" className={classes.notificationText}>
								{'Generated ' +
									formatDateTime(notification.time) +
									(notification.author ? ` by ${notification.author}` : '')}
							</Typography>
						</MenuItem>
					))}
				</Menu>
				<UserMenu />
			</Toolbar>
		</AppBar>
	);
}
