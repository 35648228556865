import { useState } from 'react';
import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import { DeleteForever, RestoreFromTrash } from '@mui/icons-material';
import { IApiRiskWithRegister } from '@mitie/risk-register-api-types';

import RiskScore from './RiskScore';
import PropertyDisplay from './PropertyDisplay';
import * as RiskRegistersApi from '../api/riskRegisters';
import ConfirmDialog from './ConfirmDialog';
import useAlerts from 'hooks/useAlerts';

interface IRetiredRiskCardProps {
	risk: IApiRiskWithRegister;
	onChange: () => void;
}

export default function RetiredRiskCard({ risk, onChange }: IRetiredRiskCardProps) {
	const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();

	const restoreRisk = async () => {
		setIsSaving(true);

		try {
			await RiskRegistersApi.restoreRetiredRisk(risk.risk_id);
			addAlert('success', `Risk '${risk.name}' has been restored to risk register '${risk.risk_register.name}'`);
			onChange();
		} catch {
			addAlert('error', `Failed to restore risk '${risk.name}' to risk register '${risk.risk_register.name}'`);
		} finally {
			setIsSaving(false);
		}
	};

	const deleteRisk = async () => {
		setIsSaving(true);

		try {
			await RiskRegistersApi.deleteRetiredRisk(risk.risk_id);
			addAlert('success', `Risk '${risk.name}' has been deleted from risk register '${risk.risk_register.name}'`);
			onChange();
		} catch {
			addAlert('error', `Failed to delete risk '${risk.name}' from risk register '${risk.risk_register.name}'`);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Card
			key={risk.risk_id}
			sx={{
				margin: (theme) => theme.spacing(1),
				width: '400px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box sx={{ margin: (theme) => theme.spacing(1), flexGrow: 1 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: (theme) => theme.spacing(1) }}>
					<Typography
						component="div"
						variant="h6"
						sx={{ flexGrow: 1, marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(1) }}
					>
						{risk.name}
					</Typography>
					<RiskScore value={risk.inherent_score} label="Inherent risk rating" size="48px" />
					<RiskScore value={risk.residual_score} label="Residual risk rating" size="48px" />
				</Box>
				<Box sx={{ marginLeft: (theme) => theme.spacing(1) }}>
					<PropertyDisplay name="Register" compact>
						<Typography>{risk.risk_register?.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Type" compact>
						<Typography>{risk.risk_type?.name || 'Not set'}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Category" compact>
						<Typography>{risk.risk_category?.name || 'Not set'}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Appetite" compact>
						<Typography>{risk.appetite || 'Not set'}</Typography>
						<Typography sx={{ color: risk.appetite_status === 'Above appetite' ? 'red' : 'default' }}>
							{risk.appetite_status ? risk.appetite_status : ''}
						</Typography>
					</PropertyDisplay>
				</Box>
			</Box>
			<CardActions>
				<Box sx={{ flexGrow: 1 }} />
				<Button startIcon={<RestoreFromTrash />} onClick={() => setIsRestoreDialogOpen(true)} disabled={isSaving}>
					Restore
				</Button>
				<Button startIcon={<DeleteForever />} onClick={() => setIsDeleteDialogOpen(true)} disabled={isSaving}>
					Delete
				</Button>
			</CardActions>
			{isRestoreDialogOpen && (
				<ConfirmDialog
					title="Restore risk?"
					message={`This risk will be restored in register '${risk.risk_register.name}', and put in a pending state. An owner of the register will need to publish it to a live state.\n\nRisk: ${risk.name}\nRegister: ${risk.risk_register.name}`}
					cancelLabel="Cancel"
					confirmLabel="Confirm and restore risk"
					onClose={() => setIsRestoreDialogOpen(false)}
					onConfirm={() => restoreRisk()}
				/>
			)}
			{isDeleteDialogOpen && (
				<ConfirmDialog
					title="Permently delete risk?"
					message={`This risk will be deleted from Risk Safe. This action cannot be reverted.\n\nRisk: ${risk.name}\nRegister: ${risk.risk_register.name}`}
					cancelLabel="Cancel"
					confirmLabel="Confirm and permanently delete risk"
					onClose={() => setIsDeleteDialogOpen(false)}
					onConfirm={() => deleteRisk()}
				/>
			)}
		</Card>
	);
}
