import * as React from 'react';
import { stringify } from 'qs';
import { AccountMultiple, Bookshelf, PlaylistEdit, ViewDashboard, ApplicationCog } from 'mdi-material-ui';
import { FolderDelete, List } from '@mui/icons-material';
import { Params } from 'react-router-dom';

import RiskRegisters from 'pages/RiskRegisters';
import RiskRegistersEdit from 'pages/RiskRegistersEdit';
import RiskCatalogue from 'pages/RiskCatalogue';
import NotFound from 'pages/NotFound';
import Users from 'pages/Users';
import ManagementInformation from 'pages/ManagementInformation';
import Unauthenticated from 'pages/Unauthenticated';
import Configuration from 'pages/Configuration';
import { encodeStringForUrl } from 'utils';
import DeletedData from 'pages/DeletedData';
import Administration from 'pages/Administration';

export enum Page {
	RiskRegisters = '/risk-registers',
	RiskRegistersEdit = '/manage-risk-registers',
	RiskCatalogue = '/manage-risk-catalogue',
	Users = '/users',
	ManagementInformation = '/mi',
	Configuration = '/configuration',
	DeletedData = '/deleted-data',
	Administration = '/administration',
	Unauthenticated = '/unauthorised',
	NotFound = '*',
}

export type ApplicationParams = {
	registerId?: number;
	registerName?: string;
	riskId?: number;
	riskName?: string;
	definition?: string;
	user?: string;
	page?: string;
	selected?: string;
};

export interface IRoute {
	path: Page;
	component: React.ReactNode;
	icon?: React.ReactNode;
	label: string;
	secured: boolean;
	getUrl?: (params: Partial<ApplicationParams>) => string;
	getParams?: (urlParams: Readonly<Params<string>>) => Partial<ApplicationParams>;
}

function qs(obj: unknown): string {
	return stringify(obj, { addQueryPrefix: true });
}

export const routes: IRoute[] = [
	{
		label: 'Risk registers',
		path: Page.RiskRegisters,
		component: <RiskRegisters />,
		icon: <List />,
		secured: true,
		getUrl: ({ registerId, registerName, riskId, riskName }: ApplicationParams) =>
			Page.RiskRegisters +
			qs({
				register: registerId && registerName ? `${registerId}-${encodeStringForUrl(registerName)}` : undefined,
				risk: riskId && riskName ? `${riskId}-${encodeStringForUrl(riskName)}` : undefined,
			}),
		getParams: ({ register, risk }: Readonly<Params<string>>) => {
			const params: Partial<ApplicationParams> = {};
			if (register) {
				const split = register.split('-');
				params.registerId = Number(split[0]);
				params.registerName = split[1];
			}

			if (risk) {
				const split = risk.split('-');
				params.riskId = Number(split[0]);
				params.riskName = split[1];
			}

			return params;
		},
	},
	{
		label: 'Manage risk registers',
		path: Page.RiskRegistersEdit,
		component: <RiskRegistersEdit />,
		icon: <PlaylistEdit />,
		secured: true,
		getUrl: ({ registerId, registerName }: ApplicationParams) =>
			Page.RiskRegistersEdit +
			qs({
				register: registerId && registerName ? `${registerId}-${encodeStringForUrl(registerName)}` : undefined,
			}),
		getParams: ({ register }: Readonly<Params<string>>) => {
			const params: Partial<ApplicationParams> = {};
			if (register) {
				const split = register.split('-');
				params.registerId = Number(split[0]);
				params.registerName = split[1];
			}

			return params;
		},
	},
	{
		label: 'Manage risk catalogue',
		path: Page.RiskCatalogue,
		component: <RiskCatalogue />,
		icon: <Bookshelf />,
		secured: true,
	},
	{
		label: 'Manage users',
		path: Page.Users,
		component: <Users />,
		icon: <AccountMultiple />,
		secured: true,
		getUrl: ({ user }: ApplicationParams) =>
			Page.Users +
			qs({
				user,
			}),
		getParams: ({ user }: Readonly<Params<string>>) => ({
			user,
		}),
	},
	{
		label: 'Management information',
		path: Page.ManagementInformation,
		component: <ManagementInformation />,
		icon: <ViewDashboard />,
		secured: true,
	},
	{
		label: 'Global configuration',
		path: Page.Configuration,
		component: <Configuration />,
		icon: <ApplicationCog />,
		secured: true,
		getUrl: ({ page, selected }: ApplicationParams) =>
			Page.Configuration +
			qs({
				page,
				selected,
			}),
		getParams: ({ page, selected }: Readonly<Params<string>>) => ({
			page,
			selected,
		}),
	},
	{
		label: 'Deleted data',
		path: Page.DeletedData,
		component: <DeletedData />,
		icon: <FolderDelete />,
		secured: true,
		getUrl: ({ page }: ApplicationParams) =>
			Page.DeletedData +
			qs({
				page,
			}),
		getParams: ({ page }: Readonly<Params<string>>) => ({
			page,
		}),
	},
	{
		label: 'Administration panel',
		path: Page.Administration,
		component: <Administration />,
		secured: true,
		getUrl: ({ page, selected }: ApplicationParams) =>
			Page.Administration +
			qs({
				page,
				selected,
			}),
		getParams: ({ page, selected }: Readonly<Params<string>>) => ({
			page,
			selected,
		}),
	},
	{
		label: 'Page not found',
		path: Page.NotFound,
		component: <NotFound />,
		secured: true,
	},
	{
		label: 'Unauthorised',
		path: Page.Unauthenticated,
		component: <Unauthenticated />,
		secured: false,
	},
];

export const routesByName = routes.reduce((acc, cur) => {
	acc[cur.path] = cur;
	return acc;
}, {} as Record<Page, IRoute>);
