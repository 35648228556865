import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type AlertType = 'success' | 'error' | 'warning';

export interface IAlert {
	id: string;
	message: string;
	type: AlertType;
	timeout: number | null;
}

interface IAlertStore {
	alerts: IAlert[];
	addAlert: (type: AlertType, message: string) => void;
	removeAlertWithId: (alertId: string) => void;
}

export const AlertsContext = React.createContext<IAlertStore>({
	alerts: [],
	addAlert: () => undefined,
	removeAlertWithId: () => undefined,
});

export default function AlertsProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [alerts, setAlerts] = useState<IAlert[]>([]);

	const addAlert = (type: AlertType, message: string) => {
		const existingAlert = alerts.find((existingAlert) => existingAlert.message === message);

		if (!existingAlert) {
			const timeout = type === 'error' ? null : 6000;
			const id = uuid();
			const newAlert = {
				id,
				message,
				type,
				timeout,
			};

			setAlerts((current) => [...current, newAlert]);
		}
	};

	const removeAlertWithId = (alertId: string) => {
		if (!alerts.length) {
			return;
		}

		setAlerts((current) => current.filter(({ id }) => id !== alertId));
	};

	const contextValue = {
		alerts: alerts,
		addAlert: useCallback(addAlert, [alerts]),
		removeAlertWithId: useCallback(removeAlertWithId, [alerts]),
	};

	return <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>;
}
