import { IApiUserFromDirectory, IApiUserPhoto } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getByIds(ids: string[]) {
	return Api.post<IApiUserFromDirectory[]>('user-directory/by-ids', { user_ids: ids });
}

export function getPhotosByIds(ids: string[]) {
	return Api.post<IApiUserPhoto[]>('user-directory/by-ids/photo', { user_ids: ids });
}

export function search(search: string) {
	return Api.post<IApiUserFromDirectory[]>('user-directory/search', { search });
}
