import React, { useEffect, useState } from 'react';
import { Box, Button, Drawer, TextField, Typography } from '@mui/material';
import { IApiRiskRegister, IApiRisk, IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';
import { Add, Check, FilterList } from '@mui/icons-material';
import { Bookshelf, FilterRemove } from 'mdi-material-ui';

import CreateOrUpdateRiskDialog from './CreateOrUpdateRiskDialog';
import RiskSummary from './RiskSummary';
import useNotifications from 'hooks/useNotifications';
import RiskDetails from './RiskDetails';
import CatalogueItemPickerDialog from './CatalogueItemPickerDialog';
import ActionButtons, { IActionButtonProps } from './ActionButtons';
import TextFilterInputMobile from './TextFilterInputMobile';

interface IRisksMobileProps {
	register: IApiRiskRegister;
	registerParents: IApiRiskRegister[];
	childrenRegisters: IApiRiskRegister[];
	allRegisters: IApiRiskRegister[];
	risks: IApiRisk[];
	selectedRisk?: IApiRisk | null;
	onChange: () => void;
}

type FilterOption = 'residualRisk' | 'state' | 'owner' | 'principalRisk' | 'appetiteStatus';
type FilterOptions = Record<FilterOption, string[]>;

const generateFiltersAndOptionsForRisks = (risks: IApiRisk[]) => {
	const filterOptions: Record<FilterOption, Record<string, { order: number; filterValue: string }>> = {
		residualRisk: {},
		state: {},
		owner: {},
		principalRisk: {},
		appetiteStatus: {},
	};

	const risksWithFilters: { filterValues: Record<FilterOption, string>; risk: IApiRisk }[] = risks.map((risk) => {
		const { residual_score, state, owner, principal_risk, linked_principal_risks, appetite_status } = risk;
		const severityFilterValue =
			residual_score === null
				? { order: 5, filterValue: 'Residual score not defined' }
				: residual_score.score < 5
				? { order: 4, filterValue: 'Manageable risks' }
				: residual_score.score < 11
				? { order: 3, filterValue: 'Material risks' }
				: residual_score.score < 16
				? { order: 2, filterValue: 'Significant risks' }
				: { order: 1, filterValue: 'Severe risks' };
		const principalRiskFilterValue = principal_risk
			? { order: 1, filterValue: 'Principal risks' }
			: linked_principal_risks.length
			? { order: 2, filterValue: 'Linked with principal risk' }
			: { order: 3, filterValue: 'Other risks' };
		const stateFilterValue =
			state === 'Pending acceptance'
				? { order: 1, filterValue: 'Added from another registry and requires a review' }
				: state === 'Pending treatment'
				? { order: 2, filterValue: 'Risk treatment not completed' }
				: state === 'Live'
				? { order: 3, filterValue: 'Live risks' }
				: { order: 4, filterValue: 'Retired risks' };
		const ownerFilterValue = owner
			? { order: 1, filterValue: `Owner: ${owner}` }
			: { order: 0, filterValue: 'No owner' };
		const appetiteStatusFilterValue =
			appetite_status === 'Above appetite'
				? { order: 1, filterValue: 'Above appetite' }
				: appetite_status === 'In line with appetite'
				? { order: 2, filterValue: 'In line with appetite' }
				: appetite_status === 'Below appetite'
				? { order: 3, filterValue: 'Below appetite' }
				: { order: 4, filterValue: 'Appetite status not defined' };

		filterOptions.residualRisk[severityFilterValue.filterValue] = severityFilterValue;
		filterOptions.principalRisk[principalRiskFilterValue.filterValue] = principalRiskFilterValue;
		filterOptions.state[stateFilterValue.filterValue] = stateFilterValue;
		filterOptions.owner[ownerFilterValue.filterValue] = ownerFilterValue;
		filterOptions.appetiteStatus[appetiteStatusFilterValue.filterValue] = appetiteStatusFilterValue;

		return {
			filterValues: {
				residualRisk: severityFilterValue.filterValue,
				state: stateFilterValue.filterValue,
				owner: ownerFilterValue.filterValue,
				principalRisk: principalRiskFilterValue.filterValue,
				appetiteStatus: appetiteStatusFilterValue.filterValue,
			},
			risk,
		};
	});

	return {
		filterOptions: Object.entries(filterOptions).reduce((acc, [filterOption, filterValue]) => {
			const a = Object.values(filterValue)
				.sort((a, b) => a.order - b.order)
				.map(({ filterValue }) => filterValue);
			acc[filterOption as FilterOption] = a;

			return acc;
		}, {} as FilterOptions),
		risksWithFilters,
	};
};

export default function RisksMobile({
	register,
	registerParents,
	childrenRegisters,
	allRegisters,
	risks,
	selectedRisk,
	onChange,
}: IRisksMobileProps) {
	const [risksInRegister, setRisksInRegister] = useState<
		{
			risk: IApiRisk;
			filterValues: Record<FilterOption, string>;
		}[]
	>();
	const [filterOptions, setFilterOptions] = useState<FilterOptions>();
	const [isAddRiskPopupOpen, setIsAddRiskPopupOpen] = useState(false);
	const [isAddRiskFromCataloguePopupOpen, setIsAddRiskFromCataloguePopupOpen] = useState(false);
	const [selectedCatalogueItem, setSelectedCatalogueItem] = useState<IApiRiskCatalogueItem | undefined>();
	const [filterBy, setFilterBy] = useState<FilterOption>('residualRisk');
	const [filter, setFilter] = useState<string | null>(null);
	const [displayedRisks, setDisplayedRisks] = useState<IApiRisk[]>([]);
	const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
	const { fetchNotifications } = useNotifications();
	const [textFilter, setTextFilter] = useState<string>('');

	useEffect(() => {
		const { filterOptions, risksWithFilters } = generateFiltersAndOptionsForRisks(risks);
		setFilterOptions(filterOptions);
		setRisksInRegister(risksWithFilters);
	}, [risks]);

	useEffect(() => {
		// Client side sorting

		if (!risksInRegister) {
			setDisplayedRisks([]);
		} else {
			// Filter according to the Filter term
			let filteredRisks: {
				risk: IApiRisk;
				filterValues: Record<FilterOption, string>;
			}[];

			if (textFilter) {
				const searchTerm = textFilter.toLowerCase();

				filteredRisks = risksInRegister.filter(({ risk }) => {
					const toSearchList = [risk.name, risk.description, risk.owner, risk.sponsor];

					if (risk.risk_category) {
						toSearchList.push(risk.risk_category.name);
					}

					if (risk.risk_type) {
						toSearchList.push(risk.risk_type.name);
					}

					const toSearch = toSearchList.join('~').toLowerCase();

					return toSearch.includes(searchTerm);
				});
			} else {
				filteredRisks = risksInRegister;
			}

			if (filter) {
				filteredRisks = filteredRisks.filter(({ filterValues }) => filterValues[filterBy] === filter);
			}

			// Always sort risks descending residual score inside each bucket
			setDisplayedRisks(
				filteredRisks
					.map(({ risk }) => risk)
					.sort((a, b) => (b.residual_score?.score || 0) - (a.residual_score?.score || 0)),
			);
		}
	}, [risksInRegister, filterBy, filter, textFilter]);

	const actions: IActionButtonProps[] = [
		{ label: 'Add risk...', icon: <Add />, onClick: () => setIsAddRiskPopupOpen(true) },
	];

	if (register?.risk_register_lifecycle_status) {
		actions.push({
			label: 'Add risk from catalogue...',
			icon: <Bookshelf />,
			onClick: () => setIsAddRiskFromCataloguePopupOpen(true),
		});
	}

	return selectedRisk ? (
		<RiskDetails
			register={register}
			registerParents={registerParents}
			childrenRegisters={childrenRegisters}
			allRegisters={allRegisters}
			risk={selectedRisk}
			onChange={() => {
				onChange();
				fetchNotifications();
			}}
		/>
	) : (
		<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
			<Box
				sx={{
					display: 'flex',
					borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
					flex: 'none',
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					{register.permissions.includes('create-risk') && <ActionButtons items={actions} />}
				</Box>
				<Box sx={{ display: 'flex', marginRight: (theme) => theme.spacing(1) }}>
					<Button startIcon={<FilterList />} onClick={() => setIsFilterDrawerOpen(true)} disabled={!filterOptions}>
						Filter
					</Button>
					<Drawer open={isFilterDrawerOpen} anchor="bottom" onClose={() => setIsFilterDrawerOpen(false)}>
						<Box sx={{ display: 'flex', flexDirection: 'column', margin: (theme) => theme.spacing(1) }}>
							<TextField
								label="Filter by"
								value={filterBy}
								onChange={(event) => {
									setFilterBy(event.target.value as FilterOption);
									setFilter(null);
								}}
								variant="standard"
								select
								SelectProps={{
									native: true,
								}}
								InputLabelProps={{
									shrink: true,
								}}
							>
								<option value="residualRisk">Residual score</option>
								<option value="appetiteStatus">Appetite status</option>
								<option value="principalRisk">Principal risk</option>
								<option value="owner">Risk owner</option>
								<option value="state">State</option>
							</TextField>
							<TextField
								label="Filter risks"
								value={filter || ''}
								onChange={(event) => setFilter(event.target.value || null)}
								variant="standard"
								select
								SelectProps={{
									native: true,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								sx={{ marginTop: (theme) => theme.spacing(1) }}
							>
								<option value="">All risks</option>
								{filterOptions &&
									filterOptions[filterBy].map((filterOption) => (
										<option key={filterOption} value={filterOption}>
											{filterOption}
										</option>
									))}
							</TextField>
							<TextFilterInputMobile
								label="Text filter"
								value={textFilter}
								onChange={(newTextFilter) => setTextFilter(newTextFilter)}
							/>
						</Box>
						<Box sx={{ margin: (theme) => theme.spacing(1), display: 'flex', flexDirection: 'column' }}>
							<Button
								onClick={() => {
									setFilter(null);
									setTextFilter('');
								}}
								startIcon={<FilterRemove />}
							>
								Clear filters
							</Button>
							<Button
								startIcon={<Check />}
								variant="contained"
								sx={{ marginTop: (theme) => theme.spacing(1) }}
								onClick={() => setIsFilterDrawerOpen(false)}
							>{`View ${displayedRisks.length} risks`}</Button>
						</Box>
					</Drawer>
				</Box>
			</Box>
			{displayedRisks.length > 0 ? (
				<Box sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', minHeight: 0, flexGrow: 1 }}>
					{displayedRisks.map((risk) => (
						<RiskSummary key={risk.risk_id} risk={risk} sx={{ margin: (theme) => theme.spacing(1), flexShrink: 0 }} />
					))}
				</Box>
			) : (
				<Box sx={{ margin: (theme) => theme.spacing(1) }}>
					<Typography sx={{ textAlign: 'center' }}>No risk to display</Typography>
				</Box>
			)}
			{isAddRiskPopupOpen && (
				<CreateOrUpdateRiskDialog
					register={register}
					mode="create"
					catalogueItem={selectedCatalogueItem}
					onClose={async (result: boolean) => {
						if (result) {
							onChange();
							fetchNotifications();
						}

						setIsAddRiskPopupOpen(false);
					}}
				/>
			)}
			{isAddRiskFromCataloguePopupOpen && register.risk_register_lifecycle_status && (
				<CatalogueItemPickerDialog
					lifecycleStatusId={register.risk_register_lifecycle_status.risk_register_lifecycle_status_id}
					onSelect={(catalogueItem) => {
						setSelectedCatalogueItem(catalogueItem);
						setIsAddRiskPopupOpen(true);
					}}
					onClose={() => {
						setIsAddRiskFromCataloguePopupOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
