import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';

import RegistersPickerDialog from './RegistersPickerDialog';

interface IRegistersPickerProps {
	registers: IApiRiskRegister[];
	value: IApiRiskRegister[][];
	onChange: (value: IApiRiskRegister[][]) => void;
}

export default function RegistersPicker({ registers, value, onChange }: IRegistersPickerProps) {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	return (
		<TextField
			variant="outlined"
			sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2) }}
			label="Cascade to"
			value={value.length > 0 ? `${value.length} registers selected` : 'None selected'}
			InputProps={{
				endAdornment: (
					<InputAdornment
						position="end"
						sx={{
							height: 'auto',
							margin: '12px 8px',
						}}
					>
						<>
							<Button onClick={() => setIsPickerOpen(true)}>Pick...</Button>
							{isPickerOpen && (
								<RegistersPickerDialog
									registers={registers}
									value={value}
									onClose={(value) => {
										if (value) {
											onChange(value);
										}

										setIsPickerOpen(false);
									}}
								/>
							)}
						</>
					</InputAdornment>
				),
				inputProps: {
					disabled: true,
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}
