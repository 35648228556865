import { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, ListItem, ListItemText, TextField } from '@mui/material';
import { IApiUserFromDirectory } from '@mitie/risk-register-api-types';

import useUsers from 'hooks/useUsers';
import * as UserDirectoryApi from '../api/userDirectory';
import useDebounce from 'hooks/useDebounce';

interface IDirectoryPersonInputProps {
	value: string[];
	label: string;
	disabled?: boolean;
	onChange: (value: string[]) => void;
}

export default function DirectoryPersonInput({ value, label, disabled, onChange }: IDirectoryPersonInputProps) {
	const [usersInDropDown, setUsersInDropDown] = useState<
		{ userId: string; isLoading?: boolean; data?: IApiUserFromDirectory | undefined }[]
	>([]);
	const [selectedUsers, setSelectedUsers] = useState<
		{ userId: string; isLoading?: boolean; data?: IApiUserFromDirectory | undefined }[]
	>([]);
	const { usersCache, fetchUserFromDirectory, saveUsersInCache } = useUsers();
	const [isLoading, setIsLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const debouncedInput = useDebounce(inputValue, 500);

	useEffect(() => {
		if (debouncedInput.length > 2) {
			loadOptions();
		}
	}, [debouncedInput]);

	useEffect(() => {
		for (const userId of value) {
			fetchUserFromDirectory(userId);
		}

		const mappedSelectedUsers = value.map((userId) => {
			const userFromStore = usersCache[userId.toLowerCase()];

			if (userFromStore) {
				return { userId, ...userFromStore };
			} else {
				return { userId };
			}
		});

		setSelectedUsers(mappedSelectedUsers);
	}, [value]);

	const loadOptions = async () => {
		setIsLoading(true);

		try {
			const data = await UserDirectoryApi.search(debouncedInput);

			saveUsersInCache(data);
			setUsersInDropDown(data.map((userData) => ({ userId: userData.user_id, data: userData })));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Autocomplete
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.data?.name || option.userId)}
			filterOptions={(x) => x}
			options={usersInDropDown}
			multiple
			// filterSelectedOptions
			value={selectedUsers}
			onChange={(event, newValues) => {
				const newUserIds = newValues.map((user) => (typeof user === 'string' ? user : user.userId));
				onChange(newUserIds);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					fullWidth
					InputProps={{
						...params.InputProps,
						placeholder: 'Search...',
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<ListItem {...props}>
						<ListItemText primary={option.data?.name || 'Loading...'} secondary={option.data?.job_title || ''} />
					</ListItem>
				);
			}}
			isOptionEqualToValue={(option, value) => option.userId === value.userId}
			noOptionsText="No match found"
			disabled={disabled}
			loading={isLoading}
			disableClearable={true}
			freeSolo
			sx={{ margin: (theme) => theme.spacing(1) }}
		/>
	);
}
