import { useEffect, useState } from 'react';
import { Button, Chip, InputAdornment, TextField } from '@mui/material';
import { IApiRisk } from '@mitie/risk-register-api-types';

import * as PrincipalRisksApi from '../api/principalRisks';

interface ILinkedPrincipalRisksPickerProps {
	value: number[];
	onChange: (value: number[]) => void;
	disabled: boolean;
	error?: boolean;
	helperText?: React.ReactNode;
}

export default function LinkedPrincipalRisksPicker({
	value,
	onChange,
	disabled,
	error,
	helperText,
}: ILinkedPrincipalRisksPickerProps) {
	const [principalRisks, setPrincipalRisks] = useState<{ [id: number]: IApiRisk }>({});
	const [arePrincipalRisksLoading, setArePrincipalRisksLoading] = useState(false);
	const [selectedPrincipalRisk, setSelectedPrincipalRisk] = useState<number | null>(null);

	useEffect(() => {
		loadPrincipalRisks();
	}, []);

	const loadPrincipalRisks = async () => {
		setArePrincipalRisksLoading(true);

		try {
			const data = await PrincipalRisksApi.getAll();

			setPrincipalRisks(
				data.reduce((acc: { [id: number]: IApiRisk }, cur) => {
					acc[cur.risk_id] = cur;
					return acc;
				}, {}),
			);
		} finally {
			setArePrincipalRisksLoading(false);
		}
	};

	const addLinkedPrincipalRisk = () => {
		if (!selectedPrincipalRisk) {
			return;
		}

		if (!value.find((riskId) => riskId === selectedPrincipalRisk)) {
			const newValue = [...value, selectedPrincipalRisk];
			onChange(newValue);
		}

		setSelectedPrincipalRisk(null);
	};

	const removeLinkedPrincipalRisk = (riskIdToRemove: number) => {
		const newValue = value.filter((riskId) => riskId !== riskIdToRemove);
		onChange(newValue);
	};

	return (
		<TextField
			variant="outlined"
			sx={{
				margin: (theme) => theme.spacing(1),
				'& select': {
					width: 'auto',
				},
				'& .MuiNativeSelect-icon': {
					marginRight: '72px',
				},
			}}
			label="Linked principal risks"
			select
			value={selectedPrincipalRisk || ''}
			onChange={(event) => setSelectedPrincipalRisk(event.target.value ? Number(event.target.value) : null)}
			error={error}
			helperText={helperText}
			disabled={disabled}
			SelectProps={{
				native: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment
						position="start"
						sx={{
							flexGrow: 1,
							flexWrap: 'wrap',
							gap: (theme) => theme.spacing(1),
							height: 'auto',
							maxHeight: 'inherit',
							margin: (theme) => theme.spacing(1),
						}}
					>
						{value.length === 0
							? 'None'
							: value.map((riskId) => {
									const riskName = !arePrincipalRisksLoading
										? principalRisks[riskId]?.name || 'Unknown principal risk'
										: 'Loading...';
									return (
										<Chip
											key={riskId}
											label={riskName}
											onDelete={!disabled ? () => removeLinkedPrincipalRisk(riskId) : undefined}
										/>
									);
							  })}
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<Button onClick={() => addLinkedPrincipalRisk()} disabled={!selectedPrincipalRisk || disabled}>
							Add
						</Button>
					</InputAdornment>
				),
				sx: {
					color: error ? 'red' : 'default',
				},
			}}
		>
			<option value="">-- Select principal risk --</option>
			{Object.values(principalRisks).map((risk) => (
				<option key={risk.risk_id} value={risk.risk_id}>
					{risk.name}
				</option>
			))}
		</TextField>
	);
}
