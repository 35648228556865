import { useContext } from 'react';
import { Typography, ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import { Brightness4, Brightness7, SettingsBrightness } from '@mui/icons-material';

import { ColorModeContext } from 'providers/ColorModeProvider';

export default function ColourModePicker() {
	const { colorMode, setColorMode } = useContext(ColorModeContext);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Typography sx={{ marginLeft: (theme) => theme.spacing(2) }}>Colour mode:</Typography>
			<ToggleButtonGroup
				exclusive
				size="small"
				value={colorMode}
				onChange={(event, mode) => {
					if (mode) {
						setColorMode(mode);
					}
				}}
				sx={{ margin: (theme) => theme.spacing(1) }}
			>
				<ToggleButton value="light">
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<Brightness7 sx={{ marginRight: (theme) => theme.spacing(1) }} />
						Light
					</Box>
				</ToggleButton>
				<ToggleButton value="auto">
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<SettingsBrightness sx={{ marginRight: (theme) => theme.spacing(1) }} />
						Auto
					</Box>
				</ToggleButton>
				<ToggleButton value="dark">
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<Brightness4 sx={{ marginRight: (theme) => theme.spacing(1) }} />
						Dark
					</Box>
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	);
}
