import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Step,
	StepLabel,
	Stepper,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	IApiRiskUpdate,
	IApiRiskReviewCreate,
	RiskScoreMovement,
	IApiRiskControlUpdate,
	IApiRiskControl,
	IApiRiskTreatmentActionUpdate,
	ReviewInterval,
	IApiRiskTreatmentAction,
	IApiRiskFull,
	IApiRiskScoreUpsert,
	IApiRiskRegister,
	IApiRiskImpactCategory,
} from '@mitie/risk-register-api-types';
import { Archive, ArrowBack, ArrowForward, Check, Close } from '@mui/icons-material';

import * as RisksRegisterApi from '../api/riskRegisters';
import { createRiskAction } from '../api/riskActions';
import { getRiskControlsForRisk } from '../api/riskControls';
import * as RiskImpactCategoriesApi from '../api/riskImpactCategories';
import RetireRiskDialog from './RetireRiskDialog';
import RiskControlsInput from './RiskControlsInput';
import RiskScoreFull from './RiskScoreFull';
import RiskScoreInput from './RiskScoreInput';
import { calculateAppetiteStatus } from 'utils';
import RiskScoreDirectInput from './RiskScoreDirectInput';
import RiskTreatmentActionsInput from './RiskTreatmentActionsInput';
import { getRiskTreatmentActionsForRisk } from 'api/riskTreatmentActions';

interface IFormData {
	controls: (IApiRiskControl | IApiRiskControlUpdate)[];
	mitigations: (IApiRiskControl | IApiRiskControlUpdate)[];
	controlsAssuranceSources: string;
	residual_score: IApiRiskScoreUpsert | null;
	desired_score: IApiRiskScoreUpsert | null;
	treatmentRequiredResources: string;
	reviewInterval: ReviewInterval | '';
}

const validationSchemasByStep = [
	yup.object({
		controls: yup.array().of(yup.object()).min(1, 'You need to enter at least one control measure'),
	}),
	yup.object({}),
	yup.object({
		controlsAssuranceSources: yup.string().required('Enter sources of assurance'),
		residual_score: yup
			.object({
				likelihood: yup.number().required(),
				impact: yup.number().required(),
				score: yup.number().required(),
				risk_score_impacts: yup.array().required(),
			})
			.default(null)
			.required('Enter the residual risk rating'),
	}),
	yup.object({
		desired_score: yup
			.object({
				likelihood: yup.number().required(),
				impact: yup.number().required(),
				score: yup.number().required(),
				risk_score_impacts: yup.array().required(),
			})
			.default(null)
			.required('Enter the desired residual risk rating'),
		treatmentRequiredResources: yup
			.string()
			.required('Enter the resources required to carry out for the treatment plan'),
	}),
	yup.object({
		reviewInterval: yup.string().required('Enter the interval for reviews'),
	}),
];

const getInitialFormData = (): IFormData => {
	return {
		controls: [],
		mitigations: [],
		controlsAssuranceSources: '',
		residual_score: null,
		desired_score: null,
		treatmentRequiredResources: '',
		reviewInterval: '',
	};
};

const getFormDataFromRisk = (risk: IApiRiskFull, riskControls: IApiRiskControl[]): IFormData => {
	return {
		controls: riskControls.filter((rc) => rc.type === 'Control'),
		mitigations: riskControls.filter((rc) => rc.type === 'Mitigation'),
		controlsAssuranceSources: risk.controls_assurance_sources || '',
		residual_score: risk.residual_score,
		desired_score: risk.desired_score,
		treatmentRequiredResources: risk.treatment_required_resources || '',
		reviewInterval: risk.review_interval || '',
	};
};

interface IRiskReviewDialogProps {
	register: IApiRiskRegister;
	riskId: number;
	onClose: (result: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		field: {
			margin: theme.spacing(1),
			minWidth: '250px',
		},
	}),
);

export default function RiskReviewDialog({ register, riskId, onClose }: IRiskReviewDialogProps) {
	const classes = useStyles();
	const [isRiskDataLoading, setIsRiskDataLoading] = useState(false);
	const [areRiskTreatmentActionsLoading, setAreRiskTreatmentActionsLoading] = useState(false);
	const [areRiskImpactCategoriesLoading, setAreRiskImpactCategoriesLoading] = useState(false);
	const [savedRiskData, setSavedRiskData] = useState<IApiRiskFull>();
	const [riskImpactCategories, setRiskImpactCategories] = useState<IApiRiskImpactCategory[]>([]);
	const [isSaving, setIsSaving] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [completedSteps, setCompletedSteps] = useState<(boolean | undefined)[]>([]);
	const [optionalSteps, setOptionalSteps] = useState<(boolean | undefined)[]>([]);
	const [treatmentPlanRequired, setTreatmentPlanRequired] = useState(false);
	const [treatmentPlanEnabled, setTreatmentPlanEnabled] = useState(false);
	const formik = useFormik<IFormData>({
		initialValues: getInitialFormData(),
		validationSchema: validationSchemasByStep[activeStep],
		onSubmit: async ({
			controls,
			mitigations,
			controlsAssuranceSources,
			residual_score,
			desired_score,
			treatmentRequiredResources,
			reviewInterval,
		}) => {
			if (!savedRiskData || !savedRiskData.residual_score) {
				return;
			}

			setIsSaving(true);

			try {
				const scoreBefore = savedRiskData.residual_score.score;
				const scoreAfter = residual_score!.score;
				const scoreMovement: RiskScoreMovement =
					!scoreBefore || scoreAfter === scoreBefore
						? 'No change'
						: scoreAfter < scoreBefore
						? 'Improving'
						: 'Deteriorating';

				const riskReviewData: IApiRiskReviewCreate = {
					risk_id: riskId,
					score_before: scoreBefore,
					score_after: scoreAfter,
					score_movement: scoreMovement,
				};

				const treatment_actions: IApiRiskTreatmentActionUpdate[] = riskTreatmentActions.flatMap(({ updated }) =>
					updated ? [updated] : [],
				);

				const appetite_status =
					residual_score && savedRiskData.appetite
						? calculateAppetiteStatus(residual_score.score, savedRiskData.appetite)
						: null;

				const riskUpdateData: IApiRiskUpdate = {
					controls_assurance_sources: controlsAssuranceSources,
					residual_score,
					controls: [...controls, ...mitigations],
					appetite_status,
					desired_score,
					treatment_required_resources: treatmentRequiredResources || null,
					treatment_actions,
					review_interval: reviewInterval || null,
				};

				await RisksRegisterApi.completeReview(register.risk_register_id, riskId, {
					review: riskReviewData,
					risk: riskUpdateData,
				});

				onClose(true);
			} finally {
				setIsSaving(false);
			}
		},
	});
	const [retireRiskDialogOpen, setRetireRiskDialogOpen] = useState(false);
	const [riskTreatmentActions, setRiskTreatmentActions] = useState<
		{ saved?: IApiRiskTreatmentAction; updated: IApiRiskTreatmentActionUpdate | null }[]
	>([]);

	useEffect(() => {
		loadRiskImpactCategories();
		loadRiskData();
		loadTreatmentActions();
	}, [riskId]);

	useEffect(() => {
		const isStepCompleted = validationSchemasByStep[activeStep].isValidSync(formik.values);
		setCompletedSteps((current) => {
			const updated = [...current];
			updated[activeStep] = isStepCompleted;

			return updated;
		});
	}, [activeStep]);

	useEffect(() => {
		// Make risk treatment plan step optional depending on the risk score and appetite status
		if (!savedRiskData) {
			return;
		}

		const treatmentRequired =
			formik.values.residual_score && savedRiskData.appetite
				? formik.values.residual_score.score >= 16 ||
				  calculateAppetiteStatus(formik.values.residual_score.score, savedRiskData.appetite) === 'Above appetite'
				: false;

		setOptionalSteps((current) => {
			const updated = [...current];
			updated[3] = !treatmentRequired && !treatmentPlanEnabled;

			return updated;
		});
		setTreatmentPlanRequired(treatmentRequired);
	}, [formik.values.residual_score, savedRiskData, treatmentPlanEnabled]);

	useEffect(() => {
		// Show risk treatment plan if relevant
		const treatmentCompleted = formik.values.desired_score !== null;

		if (treatmentPlanRequired || treatmentCompleted) {
			setTreatmentPlanEnabled(true);
		}
	}, [treatmentPlanRequired, formik.values.desired_score]);

	const handleFieldChange = (fieldName: string, value: any) => {
		// Update field in formik
		formik.setFieldValue(fieldName, value);

		// Update whether the step is completed
		const isStepCompleted = validationSchemasByStep[activeStep].isValidSync({
			...formik.values,
			[fieldName]: value,
		});
		setCompletedSteps((current) => {
			const updated = [...current];
			updated[activeStep] = isStepCompleted;

			return updated;
		});
	};

	const handleChange = ({ target }: React.ChangeEvent<any> | any) => {
		handleFieldChange(target.name, target.value);
	};

	const loadRiskImpactCategories = async () => {
		setAreRiskImpactCategoriesLoading(true);

		try {
			const data = await RiskImpactCategoriesApi.getLiveImpactCategories();
			setRiskImpactCategories(data);
		} finally {
			setAreRiskImpactCategoriesLoading(false);
		}
	};

	const loadRiskData = async () => {
		if (!riskId) {
			return;
		}

		setIsRiskDataLoading(true);

		try {
			const riskData = await RisksRegisterApi.getRiskById(register.risk_register_id, riskId);
			const riskControlsData = await getRiskControlsForRisk(register.risk_register_id, riskId);
			const formData = getFormDataFromRisk(riskData, riskControlsData);
			formik.resetForm({ values: formData });
			setSavedRiskData(riskData);
		} finally {
			setIsRiskDataLoading(false);
		}
	};

	const loadTreatmentActions = async () => {
		if (!riskId) {
			return;
		}

		setAreRiskTreatmentActionsLoading(true);

		try {
			const data = await getRiskTreatmentActionsForRisk(register.risk_register_id, riskId);
			setRiskTreatmentActions(
				data.map((item) => ({
					saved: item,
					updated: {
						risk_treatment_action_id: item.risk_treatment_action_id,
						description: item.description,
						owner: item.owner,
						start_date: item.start_date,
						end_date: item.end_date,
						completed: item.completed,
					},
				})),
			);
		} finally {
			setAreRiskTreatmentActionsLoading(false);
		}
	};

	const goToNextStep = () => {
		const newStep = activeStep + 1;

		if (newStep < steps.length) {
			setActiveStep(newStep);
		}
	};

	const toGoPreviousStep = () => {
		const newStep = activeStep - 1;

		if (newStep >= 0) {
			setActiveStep(newStep);
		}
	};

	const steps = [
		'Review control measures',
		'Review mitigation measures',
		'Residual score',
		'Risk treatment plan',
		'Risk reviews',
	];
	const optionalStepsCount = optionalSteps.filter((s) => s).length;
	const dataEntryCompleted =
		completedSteps.filter((s, i) => !optionalSteps[i] && s).length === steps.length - optionalStepsCount;

	return (
		<Dialog open={true} fullWidth maxWidth="lg">
			<DialogTitle>{savedRiskData ? `Risk review: ${savedRiskData.name}` : ''}</DialogTitle>
			<DialogContent sx={{ display: 'flex', minHeight: '500px' }}>
				{isRiskDataLoading ? (
					<CircularProgress />
				) : (
					<>
						<Box>
							<Stepper sx={{ marginBottom: (theme) => theme.spacing(2) }} nonLinear orientation="vertical">
								{steps.map((label, step) => {
									const completed = Boolean(completedSteps[step]);
									const optional = Boolean(optionalSteps[step]);
									const active = activeStep === step;
									return (
										<Step key={label} completed={completed} active={active}>
											<StepLabel
												optional={
													completed ? undefined : (
														<Typography variant="caption">{optional ? 'Optional' : 'Not completed'}</Typography>
													)
												}
												componentsProps={{
													label: { style: { fontWeight: active ? 500 : 'normal', whiteSpace: 'nowrap' } },
												}}
												StepIconProps={{
													sx:
														!active && completed
															? { color: (theme) => `${theme.palette.success.main} !important` }
															: {},
												}}
											>
												{label}
											</StepLabel>
										</Step>
									);
								})}
							</Stepper>
						</Box>
						<Box
							sx={{ marginLeft: (theme) => theme.spacing(2), flexGrow: 1, display: 'flex', flexDirection: 'column' }}
						>
							<form onSubmit={formik.handleSubmit}>
								{activeStep === 0 && (
									<RiskControlsInput
										type="Control"
										initialData={formik.initialValues.controls as IApiRiskControl[]}
										data={formik.values.controls}
										onChange={(data) => handleFieldChange('controls', data)}
										isLoading={isRiskDataLoading}
									/>
								)}
								{activeStep === 1 && (
									<RiskControlsInput
										type="Mitigation"
										initialData={formik.initialValues.mitigations as IApiRiskControl[]}
										data={formik.values.mitigations}
										onChange={(data) => handleFieldChange('mitigations', data)}
										isLoading={isRiskDataLoading}
									/>
								)}
								{activeStep === 2 && (
									<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
										<TextField
											label="Sources of assurance"
											name="controlsAssuranceSources"
											multiline
											fullWidth
											rows={4}
											value={formik.values.controlsAssuranceSources}
											onChange={handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.controlsAssuranceSources && Boolean(formik.errors.controlsAssuranceSources)}
											helperText={formik.touched.controlsAssuranceSources && formik.errors.controlsAssuranceSources}
											className={classes.field}
										/>
										<Box style={{ display: 'flex', flexWrap: 'wrap' }}>
											<RiskScoreFull value={savedRiskData?.residual_score} label="Current residual rating" />
											<RiskScoreInput
												value={formik.values.residual_score}
												impactCategories={riskImpactCategories}
												loading={areRiskImpactCategoriesLoading}
												label="Residual risk rating"
												onChange={(value) => handleFieldChange('residual_score', value)}
											/>
										</Box>
									</Box>
								)}
								{activeStep === 3 && (
									<Box>
										{treatmentPlanRequired && (
											<Alert severity="info" sx={{ margin: (theme) => theme.spacing(1) }}>
												{formik.values.residual_score && formik.values.residual_score.score >= 16
													? 'Because the residual risk rating is 16 or above, you need to complete a risk treatment plan'
													: 'Because the risk exposure is above appetite, you need to complete a risk treatment plan'}
											</Alert>
										)}
										<FormControlLabel
											control={
												<Switch
													disabled={treatmentPlanRequired}
													checked={treatmentPlanEnabled}
													onChange={(event) => {
														setTreatmentPlanEnabled(event.target.checked);
													}}
												/>
											}
											label="Carry out risk treatment plan"
										/>
										{treatmentPlanEnabled && (
											<>
												<Box sx={{ display: 'flex', alignItems: 'stretch' }}>
													<RiskScoreFull value={formik.values.residual_score} label="Current residual risk rating" />
													<RiskScoreDirectInput
														value={formik.values.desired_score}
														label="Desired residual risk rating"
														onChange={(value) => handleFieldChange('desired_score', value)}
													/>
													<TextField
														label="Resources required"
														name="treatmentRequiredResources"
														sx={{ flexGrow: 1 }}
														multiline
														rows={3}
														value={formik.values.treatmentRequiredResources}
														onChange={handleChange}
														onBlur={formik.handleBlur}
														error={
															formik.touched.treatmentRequiredResources &&
															Boolean(formik.errors.treatmentRequiredResources)
														}
														helperText={
															formik.touched.treatmentRequiredResources && formik.errors.treatmentRequiredResources
														}
														className={classes.field}
													/>
												</Box>
												<RiskTreatmentActionsInput
													data={riskTreatmentActions}
													onChange={(data) => setRiskTreatmentActions(data)}
													isLoading={areRiskTreatmentActionsLoading}
												/>
											</>
										)}
									</Box>
								)}
								{activeStep === 4 && (
									<Box>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<TextField
												label="Required frequency for reviews"
												name="reviewInterval"
												value={formik.values.reviewInterval}
												select={true}
												SelectProps={{
													native: true,
												}}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) => {
													const value = event.target.value;
													handleFieldChange('reviewInterval', value);
												}}
												onBlur={formik.handleBlur}
												error={formik.touched.reviewInterval && Boolean(formik.errors.reviewInterval)}
												helperText={formik.touched.reviewInterval && formik.errors.reviewInterval}
												className={classes.field}
											>
												<option value="">-- Select from list --</option>
												<option value="quarterly">Quarterly</option>
												<option value="bimonthly">Every 2 months</option>
												<option value="monthly">Monthly</option>
												<option value="biweekly">Every 2 weeks</option>
												<option value="weekly">Weekly</option>
												<option value="daily">Daily</option>
											</TextField>
											<Typography variant="caption">
												The risk owner(s) will will notified by email and in app when this risk is due for review
											</Typography>
										</Box>
									</Box>
								)}
							</form>
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose(false)} variant="outlined">
					Cancel
				</Button>
				{activeStep > 0 && (
					<Button startIcon={<ArrowBack />} onClick={() => toGoPreviousStep()} variant="outlined">
						Back
					</Button>
				)}
				<Box sx={{ flex: '1 1 auto' }} />
				{activeStep < steps.length - 1 ? (
					<Button
						endIcon={<ArrowForward />}
						onClick={() => {
							const isStepCompleted = validationSchemasByStep[activeStep].isValidSync(formik.values);
							setCompletedSteps((current) => {
								const updated = [...current];
								updated[activeStep] = isStepCompleted;

								return updated;
							});

							goToNextStep();
						}}
						variant="contained"
					>
						Next
					</Button>
				) : (
					<Button
						startIcon={<Check />}
						onClick={() => formik.submitForm()}
						autoFocus
						variant="contained"
						disabled={isSaving || !dataEntryCompleted}
					>
						Complete review
					</Button>
				)}
				<Button startIcon={<Archive />} onClick={() => setRetireRiskDialogOpen(true)} autoFocus variant="outlined">
					Retire risk...
				</Button>
			</DialogActions>
			{retireRiskDialogOpen && (
				<RetireRiskDialog
					onClose={async (comment) => {
						if (comment) {
							await createRiskAction(register.risk_register_id, riskId, {
								risk_id: riskId,
								action_type: 'retire-risk',
								description: 'Review request to retire risk',
								user_comment: comment,
							});
							onClose(true);
						}

						setRetireRiskDialogOpen(false);
					}}
				/>
			)}
		</Dialog>
	);
}
