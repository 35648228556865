import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import {
	IApiRiskCatalogueItem,
	IApiRiskRegister,
	IApiRiskRegisterLifecycleStatus,
	IApiRiskRegisterTypeWithLifecycleStatuses,
} from '@mitie/risk-register-api-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Close, Save } from '@mui/icons-material';

import useAlerts from 'hooks/useAlerts';
import * as RiskRegistersApi from '../api/riskRegisters';
import * as RiskRegisterTypesApi from '../api/riskRegisterTypes';
import PropertyDisplay from './PropertyDisplay';
import CatalogueItemsPicker from './CatalogueItemsPicker';

interface IFormData {
	name: string;
	risk_register_type_id?: number;
	risk_register_lifecycle_status_id?: number;
	catalogue_items?: IApiRiskCatalogueItem[];
}

const validationSchema = yup.object({
	name: yup.string().required('Name is required').max(255),
	risk_register_type_id: yup.number().required('Register type is required'),
});

interface IRiskRegisterEditDialogProps {
	register?: IApiRiskRegister;
	parent: IApiRiskRegister | null;
	onClose: (result: boolean) => void;
}

const getInitialValues = (register?: IApiRiskRegister): IFormData => ({
	name: register?.name || '',
	risk_register_type_id: register?.risk_register_type.risk_register_type_id || undefined,
	risk_register_lifecycle_status_id:
		register?.risk_register_lifecycle_status?.risk_register_lifecycle_status_id || undefined,
});

export default function RiskRegisterEditDialog({ register, parent, onClose }: IRiskRegisterEditDialogProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [existingLifecycleStatus, setExistingLifecycleStatus] = useState<IApiRiskRegisterLifecycleStatus | null>(null);
	const [riskRegisterTypes, setRiskRegisterTypes] = useState<IApiRiskRegisterTypeWithLifecycleStatuses[]>([]);
	const [areTypesLoading, setAreTypesLoading] = useState(false);
	const [selectedType, setSelectedType] = useState<IApiRiskRegisterTypeWithLifecycleStatuses>();
	const formik = useFormik<IFormData>({
		initialValues: getInitialValues(register),
		validationSchema,
		validateOnMount: true,
		onSubmit: async ({ name, risk_register_type_id, risk_register_lifecycle_status_id, catalogue_items }) => {
			if (!risk_register_type_id) {
				return;
			}

			setIsSaving(true);

			try {
				if (register) {
					await RiskRegistersApi.update(register.risk_register_id, {
						name,
						risk_register_type_id,
						risk_register_lifecycle_status_id: risk_register_lifecycle_status_id || null,
						catalogue_items,
					});
				} else {
					await RiskRegistersApi.create({
						name,
						risk_register_type_id,
						risk_register_lifecycle_status_id: risk_register_lifecycle_status_id || null,
						parent_risk_register_id: parent?.risk_register_id || null,
						catalogue_items,
					});
				}

				onClose(true);
			} finally {
				setIsSaving(false);
			}
		},
	});
	const { addAlert } = useAlerts();

	useEffect(() => {
		loadRegisterTypes();
	}, []);

	useEffect(() => {
		if (register?.risk_register_lifecycle_status) {
			setExistingLifecycleStatus(register.risk_register_lifecycle_status);
		}
	}, [register?.risk_register_id]);

	useEffect(() => {
		if (!formik.values.risk_register_type_id || !riskRegisterTypes) {
			setSelectedType(undefined);
		}

		setSelectedType(riskRegisterTypes.find((t) => t.risk_register_type_id === formik.values.risk_register_type_id));
	}, [riskRegisterTypes, formik.values.risk_register_type_id]);

	const loadRegisterTypes = async () => {
		setAreTypesLoading(true);

		try {
			const data = await RiskRegisterTypesApi.getAll();
			setRiskRegisterTypes(data);
		} catch {
			addAlert('error', 'Failed to load the list of register types');
		} finally {
			setAreTypesLoading(false);
		}
	};

	return (
		<Dialog open={true} maxWidth="md">
			<DialogTitle>{register ? 'Update risk register' : 'New risk register'}</DialogTitle>
			<DialogContent>
				<PropertyDisplay name="Parent register">
					<Typography>{parent ? parent.name : 'None (top level register)'}</Typography>
				</PropertyDisplay>
				<Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: (theme) => theme.spacing(2) }}>
					<TextField
						label="Risk register name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
						sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2), width: '300px' }}
					/>
					<TextField
						select
						label="Register type"
						name="risk_register_type_id"
						value={formik.values.risk_register_type_id}
						SelectProps={{
							native: true,
						}}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(event) => formik.setFieldValue('risk_register_type_id', Number(event.target.value))}
						onBlur={formik.handleBlur}
						error={formik.touched.risk_register_type_id && Boolean(formik.errors.risk_register_type_id)}
						helperText={formik.touched.risk_register_type_id && formik.errors.risk_register_type_id}
						sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2) }}
						disabled={areTypesLoading}
					>
						{riskRegisterTypes.map((type) => (
							<option value={type.risk_register_type_id} key={type.risk_register_type_id}>
								{type.name}
							</option>
						))}
					</TextField>
					{selectedType && selectedType.lifecycle_statuses && (
						<>
							<TextField
								select
								label="Lifecycle status"
								name="lifecycle_status"
								value={formik.values.risk_register_lifecycle_status_id}
								SelectProps={{
									native: true,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(event) =>
									formik.setFieldValue('risk_register_lifecycle_status_id', Number(event.target.value))
								}
								onBlur={formik.handleBlur}
								error={
									formik.touched.risk_register_lifecycle_status_id &&
									Boolean(formik.errors.risk_register_lifecycle_status_id)
								}
								helperText={
									formik.touched.risk_register_lifecycle_status_id && formik.errors.risk_register_lifecycle_status_id
								}
								sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2) }}
							>
								<option value="">-- Pick from list --</option>
								{selectedType.lifecycle_statuses.map((status) => (
									<option
										value={status.risk_register_lifecycle_status_id}
										key={status.risk_register_lifecycle_status_id}
									>
										{status.name}
									</option>
								))}
							</TextField>
							{!!formik.values.risk_register_lifecycle_status_id &&
								formik.values.risk_register_lifecycle_status_id !==
									existingLifecycleStatus?.risk_register_lifecycle_status_id && (
									<CatalogueItemsPicker
										lifecycleStatusId={formik.values.risk_register_lifecycle_status_id}
										value={formik.values.catalogue_items || []}
										onChange={(value) => formik.setFieldValue('catalogue_items', value)}
										error={Boolean(formik.errors.catalogue_items)}
										helperText={formik.touched.catalogue_items && formik.errors.catalogue_items}
										disabled={false}
									/>
								)}
						</>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose(false)} variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					startIcon={<Save />}
					color="primary"
					variant="contained"
					disabled={!formik.isValid || isSaving}
					onClick={() => formik.submitForm()}
				>
					{register ? 'Save register' : 'Create register'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
