import { Box, Typography } from '@mui/material';

interface IPropertyDisplayProps {
	name: string;
	compact?: boolean;
	width?: string;
	children: React.ReactNode;
}

export default function PropertyDisplay({ name, compact, width, children }: IPropertyDisplayProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', marginTop: (theme) => (compact ? 0 : theme.spacing(1)) }}>
			<Typography
				variant="subtitle2"
				sx={{ width: width || (compact ? '80px' : '240px'), flexShrink: 0, maxWidth: '40%' }}
			>
				{name}:
			</Typography>
			<Box sx={{ maxWidth: '40rem' }}>{children}</Box>
		</Box>
	);
}
