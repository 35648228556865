import React, { useState } from 'react';
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import { Add, Delete, Edit, Undo } from '@mui/icons-material';
import {
	RiskControlType,
	IApiRiskCatalogueControlUpdate,
	IApiRiskCatalogueControl,
} from '@mitie/risk-register-api-types';

import AddOrUpdateRiskCatalogueControlDialog from './AddOrUpdateRiskCatalogueControlDialog';

interface IRiskCatalogueControlsInputProps {
	type: RiskControlType;
	data: { saved?: IApiRiskCatalogueControl; updated: IApiRiskCatalogueControlUpdate | null }[];
	onChange: (data: { saved?: IApiRiskCatalogueControl; updated: IApiRiskCatalogueControlUpdate | null }[]) => void;
	isLoading: boolean;
}

export default function RiskCatalogueControlsInput({
	type,
	data,
	onChange,
	isLoading,
}: IRiskCatalogueControlsInputProps) {
	const [addRiskControlDialogOpen, setAddRiskControlDialogOpen] = useState(false);
	const [updatingRiskControlIndex, setUpdatingRiskControlIndex] = useState<number>();

	const addRiskControl = ({ description }: { description: string }) => {
		const newRiskControl: IApiRiskCatalogueControlUpdate = {
			type,
			description,
		};

		const updatedData = [...data, { updated: newRiskControl }];
		onChange(updatedData);
	};

	const removeRiskControl = (index: number) => {
		const updatedData = data.slice();
		const item = updatedData[index];

		if (item) {
			if (item.saved) {
				item.updated = null;
			} else {
				updatedData.splice(index, 1);
			}
		}

		onChange(updatedData);
	};

	const restoreRiskControl = (index: number) => {
		const updatedData = data.slice();
		const item = updatedData[index];

		if (item && item.saved) {
			const { risk_catalogue_control_id, type, description } = item.saved;
			item.updated = { risk_catalogue_control_id, type, description };
		}

		onChange(updatedData);
	};

	const updateRiskControl = (index: number, { description }: { description: string }) => {
		const updatedData = data.slice();

		if (updatedData[index]) {
			const updatedRiskControl: IApiRiskCatalogueControlUpdate = {
				type,
				description,
			};
			const saved = updatedData[index].saved;

			if (saved !== undefined) {
				updatedRiskControl.risk_catalogue_control_id = saved.risk_catalogue_control_id;
			}

			updatedData[index].updated = updatedRiskControl;
		}

		onChange(updatedData);
	};

	return (
		<Box>
			{isLoading && <LinearProgress />}
			<TableContainer
				component={Paper}
				sx={{ boxShadow: 'none', border: '1px solid rgb(224, 224, 224)', borderBottom: 0, maxHeight: '350px' }}
			>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>{type} measure</TableCell>
							<TableCell sx={{ width: '64px' }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length === 0 ? (
							<TableRow>
								<TableCell colSpan={2}>No measure in place</TableCell>
							</TableRow>
						) : (
							data.map(({ saved, updated }, index) => {
								const isDeleted = updated === null;
								const isNew = !saved;
								const isUpdated = saved && updated && saved.description !== updated.description;

								return (
									<TableRow
										key={index}
										sx={{
											'&>td': {
												textDecoration: isDeleted ? 'line-through' : 'none',
												color: isUpdated || isNew || isDeleted ? 'red' : 'default',
											},
										}}
									>
										<TableCell>{updated ? updated.description : saved?.description}</TableCell>
										<TableCell>
											{!isDeleted && (
												<Tooltip title="Change">
													<IconButton
														edge="end"
														size="small"
														onClick={() => {
															setUpdatingRiskControlIndex(index);
															setAddRiskControlDialogOpen(true);
														}}
													>
														<Edit />
													</IconButton>
												</Tooltip>
											)}
											{isDeleted || isUpdated ? (
												<Tooltip title={isUpdated ? 'Revert' : 'Restore'}>
													<IconButton edge="end" size="small" onClick={() => restoreRiskControl(index)}>
														<Undo />
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip title="Remove">
													<IconButton edge="end" size="small" onClick={() => removeRiskControl(index)}>
														<Delete />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</TableRow>
								);
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				size="small"
				startIcon={<Add />}
				variant="outlined"
				sx={{ marginTop: '16px' }}
				onClick={() => setAddRiskControlDialogOpen(true)}
			>
				Add...
			</Button>

			{addRiskControlDialogOpen && (
				<AddOrUpdateRiskCatalogueControlDialog
					data={
						updatingRiskControlIndex !== undefined
							? {
									description: data[updatingRiskControlIndex]?.updated?.description || '',
							  }
							: undefined
					}
					type={type}
					onClose={(data) => {
						if (data) {
							if (updatingRiskControlIndex !== undefined) {
								updateRiskControl(updatingRiskControlIndex, data);
							} else {
								addRiskControl(data);
							}
						}

						setUpdatingRiskControlIndex(undefined);
						setAddRiskControlDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
