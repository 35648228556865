import { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { IApiStrategicPillarWithImage } from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close, Delete, ImageNotSupported, NotInterested, Save, Undo } from '@mui/icons-material';
import { Restore } from 'mdi-material-ui';

import * as StrategicPillarsApi from 'api/strategicPillars';
import ConfirmDialog from './ConfirmDialog';
import { fileToBase64 } from 'utils';

interface IStrategicPillarFormProps {
	item?: IApiStrategicPillarWithImage;
	onSave: () => void;
	onCancel?: () => void;
	disabled?: boolean;
	canDelete?: boolean;
}

interface IFormData {
	type: string;
	name: string;
	description: string | null;
	image: string | null;
}

const validationSchema = yup.object({
	type: yup.string().required('Type is required').max(45, 'Type should be less than 45 characters'),
	name: yup.string().required('Name is required').max(45, 'Name should be less than 45 characters'),
	description: yup.string().required('Description is required').max(255, 'Name should be less than 255 characters'),
	image: yup.string().nullable(),
});

export default function StrategicPillarForm({
	item,
	onSave,
	onCancel,
	disabled,
	canDelete,
}: IStrategicPillarFormProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const formik = useFormik<IFormData>({
		initialValues: { type: '', name: '', description: '', image: null },
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			try {
				if (item) {
					await StrategicPillarsApi.savePillar(item.strategic_pillar_id, data);
				} else {
					await StrategicPillarsApi.createPillar(data);
				}

				onSave();
			} finally {
				setIsSaving(false);
			}
		},
	});

	useEffect(() => {
		if (item) {
			formik.resetForm({ values: item });
		}
	}, [item]);

	const deleteItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await StrategicPillarsApi.deletePillar(item.strategic_pillar_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const disableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await StrategicPillarsApi.disablePillar(item.strategic_pillar_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const enableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await StrategicPillarsApi.enablePillar(item.strategic_pillar_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Box>
			<TextField
				label="Pillar name"
				name="name"
				value={formik.values.name}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.name)}
				helperText={formik.touched.name && formik.errors.name}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2), marginRight: (theme) => theme.spacing(2), width: '300px' }}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Pillar type"
				name="type"
				value={formik.values.type}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.type)}
				helperText={formik.touched.type && formik.errors.type}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2), marginRight: (theme) => theme.spacing(2), width: '300px' }}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Description"
				name="description"
				value={formik.values.description}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.description)}
				helperText={formik.touched.description && formik.errors.description}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<Typography variant="subtitle2">Pillar icon:</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{formik.values.image ? (
					<Box
						sx={{
							padding: (theme) => theme.spacing(1),
							backgroundColor: 'rgb(169, 0, 97)',
							minWidth: '64px',
							minHeight: '64px',
						}}
					>
						<img src={`data:image/png;base64,${formik.values.image}`} alt="pillar-icon" height="64px" />
					</Box>
				) : (
					<Box
						sx={{
							margin: (theme) => theme.spacing(1),
							padding: '14px',
							border: '2px dashed #ccc',
						}}
					>
						<ImageNotSupported sx={{ fontSize: 32 }} htmlColor="#ccc" />
					</Box>
				)}

				<Box sx={{ marginLeft: (theme) => theme.spacing(2) }}>
					<Button variant="outlined" component="label">
						Upload file...
						<input
							type="file"
							hidden
							accept="image/png"
							onChange={async (event) => {
								const file = event.target.files?.[0];

								if (!file) {
									return;
								}

								const base64Image = await fileToBase64(file);
								formik.setFieldValue('image', base64Image);
							}}
						/>
					</Button>
					<Typography sx={{ maxWidth: '600px', marginTop: (theme) => theme.spacing(1) }}>
						The image needs to be in PNG format. For best results the image should be white with a transparent
						background and have a size of 64 x 64 pixels.
					</Typography>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', marginTop: (theme) => theme.spacing(2) }}>
				{onCancel && (
					<Button startIcon={<Close />} onClick={() => onCancel()} disabled={isSaving || disabled} variant="outlined">
						Cancel
					</Button>
				)}
				{item && (
					<>
						<Button
							startIcon={<Undo />}
							onClick={() => formik.resetForm()}
							disabled={isSaving || !formik.dirty || disabled}
							variant="outlined"
						>
							Discard changes
						</Button>
						{item.disabled ? (
							<Button
								startIcon={<Restore />}
								onClick={() => enableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Enable
							</Button>
						) : (
							<Button
								startIcon={<NotInterested />}
								onClick={() => disableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Disable
							</Button>
						)}
						<Button
							startIcon={<Delete />}
							onClick={() => setIsDeleteConfirmDialogOpen(true)}
							disabled={isSaving || disabled || !canDelete}
							variant="outlined"
							sx={{ marginLeft: (theme) => theme.spacing(1) }}
						>
							Delete
						</Button>
					</>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					onClick={() => formik.submitForm()}
					disabled={isSaving || !formik.dirty || disabled}
					variant="contained"
				>
					Save
				</Button>
			</Box>
			{isDeleteConfirmDialogOpen && item && (
				<ConfirmDialog
					title="Confirm deletion of strategic pillar"
					message={`Are you sure you want to delete the strategic pillar '${item.name}'? This action is irreversible`}
					confirmLabel="Delete"
					cancelLabel="Cancel"
					onClose={() => setIsDeleteConfirmDialogOpen(false)}
					onConfirm={() => deleteItem()}
				/>
			)}
		</Box>
	);
}
