import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import RiskCategoryForm from './RiskCategoryForm';

interface IAddRiskCategoryDialogProps {
	onClose: (success: boolean) => void;
}

export default function AddRiskCategoryDialog({ onClose }: IAddRiskCategoryDialogProps) {
	return (
		<Dialog open={true} onClose={() => onClose(false)}>
			<DialogTitle>New risk category</DialogTitle>
			<DialogContent>
				<Box sx={{ paddingTop: (theme) => theme.spacing(2) }}>
					<RiskCategoryForm onSave={() => onClose(true)} onCancel={() => onClose(false)} />
				</Box>
			</DialogContent>
		</Dialog>
	);
}
