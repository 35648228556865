import { Link, Paper, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import { Page } from 'routing/routes';
import { useNavigate } from 'routing/routing';
import logo from '../images/risksafe-logo.png';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			width: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		logo: {
			float: 'left',
			width: '50%',
		},
		errorContent: {
			margin: theme.spacing(2),
			'&>p': {
				marginBottom: theme.spacing(2),
			},
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: theme.spacing(2),
		},
	}),
);

export default function Unauthenticated() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'UnauthenticatedPage');
	const classes = useStyles();
	const navigate = useNavigate();

	componentTracking();

	return (
		<div className={classes.root}>
			<Paper elevation={3} className={classes.content}>
				<img className={classes.logo} src={logo} alt="Logo" />
				<div className={classes.errorContent}>
					<Typography>Failed to authenticate.</Typography>
					<Typography>If you require access to Risk Safe, please contact your Risk Safe administrator.</Typography>

					<Link component="button" variant="body1" onClick={() => navigate(Page.RiskRegisters)}>
						Go back to the sign in page
					</Link>
				</div>
			</Paper>
		</div>
	);
}
