import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { IApiRiskCategory, RiskAppetite } from '@mitie/risk-register-api-types';
import RiskAppetitePickerDialog from './RiskAppetitePickerDialog';

interface IRiskAppetitePickerProps {
	value: RiskAppetite | '';
	onChange: (value: RiskAppetite) => void;
	disabled: boolean;
	error?: boolean;
	helperText?: React.ReactNode;
	riskCategory?: IApiRiskCategory;
}

export default function RiskAppetitePicker({
	value,
	onChange,
	disabled,
	error,
	helperText,
	riskCategory,
}: IRiskAppetitePickerProps) {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	if (!riskCategory) {
		return null;
	}

	return (
		<TextField
			variant="outlined"
			sx={{ margin: (theme) => theme.spacing(1) }}
			label="Risk appetite"
			value={value || 'Not set'}
			error={error}
			helperText={helperText}
			InputProps={{
				endAdornment: (
					<InputAdornment
						position="end"
						sx={{
							height: 'auto',
							margin: '12px 8px',
						}}
					>
						<>
							<Button disabled={disabled} onClick={() => setIsPickerOpen(true)}>
								{value ? 'Change...' : 'Pick...'}
							</Button>
							{isPickerOpen && (
								<RiskAppetitePickerDialog
									onClose={(value) => {
										if (value) {
											onChange(value);
										}

										setIsPickerOpen(false);
									}}
									riskCategory={riskCategory}
								/>
							)}
						</>
					</InputAdornment>
				),
				disabled,
				inputProps: {
					disabled: true,
				},
				sx: {
					color: error ? 'red' : 'default',
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}
