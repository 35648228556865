import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	ListItemText,
	MenuItem,
	TextField,
} from '@mui/material';
import { IApiAuthenticationProvider } from '@mitie/risk-register-api-types';
import { Close, Check } from '@mui/icons-material';

import * as AuthenticationProvidersApi from 'api/authenticationProviders';

interface IAuthenticationProviderPickerDialogProps {
	selected?: IApiAuthenticationProvider;
	onSelect: (item: IApiAuthenticationProvider) => void;
	onClose: () => void;
}

export default function AuthenticationProviderPickerDialog({
	selected,
	onSelect,
	onClose,
}: IAuthenticationProviderPickerDialogProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [authenticationProviders, setAuthenticationProviders] = useState<IApiAuthenticationProvider[]>([]);
	const [selectedAuthenticationProviderId, setSelectedAuthenticationProviderId] = useState<number | null>(null);

	useEffect(() => {
		setSelectedAuthenticationProviderId(selected?.authentication_provider_id || null);
	}, [selected, authenticationProviders]);

	useEffect(() => {
		loadAuthenticationProviders();
	}, []);

	const loadAuthenticationProviders = async () => {
		setIsLoading(true);

		try {
			const data = await AuthenticationProvidersApi.getAllAuthenticationProviders();
			setAuthenticationProviders(data.sort((a, b) => (a.name > b.name ? 1 : -1)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>Select authentication provider</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<CircularProgress />
				) : (
					<TextField
						select
						size="small"
						value={selectedAuthenticationProviderId || 0}
						onChange={(event) =>
							setSelectedAuthenticationProviderId(event.target.value ? Number(event.target.value) : null)
						}
						sx={{ width: '500px' }}
					>
						<MenuItem value={0}>
							<ListItemText primary="Pick from list..." />
						</MenuItem>
						{authenticationProviders.map((item) => (
							<MenuItem key={item.authentication_provider_id} value={item.authentication_provider_id}>
								<ListItemText primary={item.name} secondary={item.type} />
							</MenuItem>
						))}
					</TextField>
				)}
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						if (!selectedAuthenticationProviderId) {
							return;
						}

						const selectedAuthenticationProvider = authenticationProviders.find(
							(item) => item.authentication_provider_id === selectedAuthenticationProviderId,
						);

						if (!selectedAuthenticationProvider) {
							return;
						}

						onSelect(selectedAuthenticationProvider);
						onClose();
					}}
					color="primary"
					variant="contained"
					disabled={!selectedAuthenticationProviderId}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
