import { Typography, Breadcrumbs, useMediaQuery, useTheme } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';

interface IRegisterBreadcrumbsProps {
	selectedRegister: IApiRiskRegister;
	selectedRegisterPath: IApiRiskRegister[];
}

export default function RegisterBreadcrumbs({ selectedRegister, selectedRegisterPath }: IRegisterBreadcrumbsProps) {
	const theme = useTheme();
	const isSmallOrMedium = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Breadcrumbs
			separator={<NavigateNext fontSize="small" />}
			sx={{
				marginRight: (theme) => theme.spacing(2),
				minWidth: 0,
				'& ol': { flexWrap: 'nowrap', flexGrow: 1 },
				'& li': { minWidth: 0 },
			}}
		>
			{!isSmallOrMedium &&
				selectedRegisterPath.map((r, index) => (
					<Typography
						variant="subtitle1"
						key={index}
						sx={{
							color: (theme) => theme.palette.text.primary,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}
					>
						{r.name}
					</Typography>
				))}
			<Typography
				variant="h6"
				color="textPrimary"
				sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
			>
				{selectedRegister.name}
			</Typography>
		</Breadcrumbs>
	);
}
