import {
	IApiRiskImpactCategory,
	IApiRiskImpactCategoryCreate,
	IApiRiskImpactCategoryUpdate,
	IApiRiskImpactCategoryUsage,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getLiveImpactCategories() {
	return Api.get<IApiRiskImpactCategory[]>('risk-impact-categories');
}

export function getDisabledImpactCategories() {
	return Api.get<IApiRiskImpactCategory[]>('risk-impact-categories/disabled');
}

export function getUsageForCategory(riskImpactCategoryId: number) {
	return Api.get<IApiRiskImpactCategoryUsage>(`risk-impact-categories/${riskImpactCategoryId}/usage`);
}

export function saveImpactCategory(riskImpactCategoryId: number, data: IApiRiskImpactCategoryUpdate) {
	return Api.patch<void>(`risk-impact-categories/${riskImpactCategoryId}`, data);
}

export function createImpactCategory(data: IApiRiskImpactCategoryCreate) {
	return Api.post<void>('risk-impact-categories', data);
}

export function deleteImpactCategory(riskImpactCategoryId: number) {
	return Api.delete_<void>(`risk-impact-categories/${riskImpactCategoryId}`);
}

export function disableImpactCategory(riskImpactCategoryId: number) {
	return Api.patch<void>(`risk-impact-categories/${riskImpactCategoryId}`, { disabled: true });
}

export function enableImpactCategory(riskImpactCategoryId: number) {
	return Api.patch<void>(`risk-impact-categories/${riskImpactCategoryId}`, { disabled: false });
}

export function moveImpactCategoryUp(riskImpactCategoryId: number) {
	return Api.post<void>(`risk-impact-categories/${riskImpactCategoryId}/move-up`, {});
}

export function moveImpactCategoryDown(riskImpactCategoryId: number) {
	return Api.post<void>(`risk-impact-categories/${riskImpactCategoryId}/move-down`, {});
}
