import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import RiskImpactCategoryForm from './RiskImpactCategoryForm';

interface IAddRiskImpactCategoryDialogProps {
	onClose: (success: boolean) => void;
}

export default function AddRiskImpactCategoryDialog({ onClose }: IAddRiskImpactCategoryDialogProps) {
	return (
		<Dialog open={true} onClose={() => onClose(false)}>
			<DialogTitle>New risk impact category</DialogTitle>
			<DialogContent>
				<Box sx={{ paddingTop: (theme) => theme.spacing(2) }}>
					<RiskImpactCategoryForm onSave={() => onClose(true)} onCancel={() => onClose(false)} />
				</Box>
			</DialogContent>
		</Dialog>
	);
}
