import { useState } from 'react';
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { IApiUserCreate } from '@mitie/risk-register-api-types';
import { Close, Add } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';

import * as UsersApi from '../api/users';

interface IAddUserDialogProps {
	onClose: (addedUserId?: string) => void;
}

const validationSchema = yup.object({
	user_id: yup
		.string()
		.required("Enter the user's email address")
		.max(255, 'The email address should be less than 266 characters')
		.email('Please enter a valid email address'),
	name: yup.string().required('User name is required').max(255, 'The user name should be less than 255 characters'),
});

export default function AddUserManuallyDialog({ onClose }: IAddUserDialogProps) {
	const [isSaving, setIsSaving] = useState(false);
	const formik = useFormik<IApiUserCreate>({
		initialValues: { user_id: '', name: '' },
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			try {
				await UsersApi.create(data);

				onClose(data.user_id);
			} finally {
				setIsSaving(false);
			}
		},
	});

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>Add user</DialogTitle>
			<DialogContent>
				<TextField
					label="Email address"
					name="user_id"
					value={formik.values.user_id}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.user_id)}
					helperText={formik.touched.user_id && formik.errors.user_id}
					disabled={isSaving}
					fullWidth
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				/>
				<TextField
					label="User name"
					name="name"
					value={formik.values.name}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					disabled={isSaving}
					fullWidth
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				/>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Add />}
					onClick={() => formik.submitForm()}
					color="primary"
					variant="contained"
					disabled={isSaving || !formik.dirty || !formik.isValid}
				>
					Add user
				</Button>
			</DialogActions>
		</Dialog>
	);
}
