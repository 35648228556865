import {
	IApiRisk,
	IApiRiskCreate,
	IApiRiskFull,
	IApiRiskInsuranceReview,
	IApiRiskInsuranceReviewCreate,
	IApiRiskRegister,
	IApiRiskRegisterCreate,
	IApiRiskRegisterUpdate,
	IApiRiskRegisterWithoutPermissions,
	IApiRiskReview,
	IApiRiskReviewCreate,
	IApiRiskUpdate,
	IApiRiskWithRegister,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAll() {
	return Api.get<IApiRiskRegister[]>('risk-registers');
}

export function getById(registerId: number) {
	return Api.get<IApiRiskRegisterWithoutPermissions>(`risk-registers/${registerId}`);
}

export function update(registerId: number, data: IApiRiskRegisterUpdate) {
	return Api.patch<IApiRiskRegisterWithoutPermissions>(`risk-registers/${registerId}`, data);
}

export function archive(registerId: number) {
	return Api.delete_(`risk-registers/${registerId}`);
}

export function create(data: IApiRiskRegisterCreate) {
	return Api.post<IApiRiskRegisterWithoutPermissions>('risk-registers', data);
}

export function getArchivedRegisters() {
	return Api.get<IApiRiskRegisterWithoutPermissions[]>('risk-registers/archived');
}

export function restoreArchivedRegister(registerId: number) {
	return Api.post<void>(`risk-registers/archived/${registerId}/restore`, {});
}

export function deleteArchivedRegister(registerId: number) {
	return Api.delete_<void>(`risk-registers/archived/${registerId}`);
}

export function getRisksForRegister(registerId: number) {
	return Api.get<IApiRisk[]>(`risk-registers/${registerId}/risks`);
}

export function getDeletedRisks() {
	return Api.get<IApiRiskWithRegister[]>('deleted-risks');
}

export function restoreRetiredRisk(riskId: number) {
	return Api.post<void>(`deleted-risks/${riskId}/restore`, {});
}

export function deleteRetiredRisk(riskId: number) {
	return Api.delete_<void>(`deleted-risks/${riskId}`);
}

export function getRiskById(registerId: number, riskId: number) {
	return Api.get<IApiRiskFull>(`risk-registers/${registerId}/risks/${riskId}`);
}

export function updateRisk(registerId: number, riskId: number, data: IApiRiskUpdate) {
	return Api.patch<void>(`risk-registers/${registerId}/risks/${riskId}`, data);
}

export function deleteRisk(registerId: number, riskId: number) {
	return Api.delete_(`risk-registers/${registerId}/risks/${riskId}`);
}

export function createRisk(registerId: number, data: IApiRiskCreate) {
	return Api.post<IApiRisk>(`risk-registers/${registerId}/risks`, data);
}

export function moveRisk(existingRegisterId: number, newRegisterId: number, riskId: number) {
	return Api.post<void>(`risk-registers/${existingRegisterId}/risks/${riskId}/move`, {
		risk_register_id: newRegisterId,
	});
}

export function completeReview(
	registerId: number,
	riskId: number,
	data: { review: IApiRiskReviewCreate; risk: IApiRiskUpdate },
) {
	return Api.post<void>(`risk-registers/${registerId}/risks/${riskId}/reviews`, data);
}

export function getReviews(registerId: number, riskId: number) {
	return Api.get<IApiRiskReview[]>(`risk-registers/${registerId}/risks/${riskId}/reviews`);
}

export function cascadeRiskToChildren(
	registerId: number,
	riskId: number,
	children_registers: number[],
	include_controls: boolean,
) {
	return Api.post<{ created: number }>(`risk-registers/${registerId}/risks/${riskId}/cascade`, {
		children_registers,
		include_controls,
	});
}

export function escalateRisk(registerId: number, riskId: number, include_controls: boolean) {
	return Api.post<{ success: boolean; message: string }>(`risk-registers/${registerId}/risks/${riskId}/escalate`, {
		include_controls,
	});
}

export function completeInsuranceReview(registerId: number, riskId: number, data: IApiRiskInsuranceReviewCreate) {
	return Api.post<void>(`risk-registers/${registerId}/risks/${riskId}/insurance-review`, data);
}

export function getInsuranceReview(registerId: number, riskId: number) {
	return Api.get<IApiRiskInsuranceReview | undefined>(`risk-registers/${registerId}/risks/${riskId}/insurance-review`);
}

export function getRegisterOwners(registerId: number) {
	return Api.get<string[]>(`risk-registers/${registerId}/owners`);
}
