import { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { IApiRiskCategory, IApiRiskCategoryUpdate } from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close, Delete, NotInterested, Save, Undo } from '@mui/icons-material';
import { Restore } from 'mdi-material-ui';

import * as RiskCategoriesApi from 'api/riskCategories';
import ConfirmDialog from './ConfirmDialog';

interface IRiskCategoryFormProps {
	item?: IApiRiskCategory;
	onSave: () => void;
	onCancel?: () => void;
	disabled?: boolean;
	canDelete?: boolean;
}

const validationSchema = yup.object({
	name: yup.string().required('Name is required').max(45, 'Name should be less than 45 characters'),
	description: yup.string().required('Description is required'),
	averse_appetite_definition: yup
		.string()
		.required('Enter a definition for averse appetite')
		.max(255, 'Should be less than 255 characters'),
	cautious_appetite_definition: yup
		.string()
		.required('Enter a definition for cautious appetite')
		.max(255, 'Should be less than 255 characters'),
	eager_appetite_definition: yup
		.string()
		.required('Enter a definition for eager appetite')
		.max(255, 'Should be less than 255 characters'),
});

export default function RiskCategoryForm({ item, onSave, onCancel, disabled, canDelete }: IRiskCategoryFormProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const formik = useFormik<IApiRiskCategoryUpdate>({
		initialValues: {},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			try {
				if (item) {
					await RiskCategoriesApi.saveCategory(item.risk_category_id, data);
				} else {
					await RiskCategoriesApi.createCategory(data);
				}

				onSave();
			} finally {
				setIsSaving(false);
			}
		},
	});

	useEffect(() => {
		if (item) {
			formik.resetForm({ values: item });
		}
	}, [item]);

	const deleteItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskCategoriesApi.deleteCategory(item.risk_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const disableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskCategoriesApi.disableCategory(item.risk_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const enableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskCategoriesApi.enableCategory(item.risk_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Box>
			<TextField
				label="Category name"
				name="name"
				value={formik.values.name}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.name)}
				helperText={formik.touched.name && formik.errors.name}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Description"
				name="description"
				value={formik.values.description}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.description)}
				helperText={formik.touched.description && formik.errors.description}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				fullWidth
			/>
			<TextField
				label="Definition for averse appetite"
				name="averse_appetite_definition"
				value={formik.values.averse_appetite_definition}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.averse_appetite_definition)}
				helperText={formik.touched.averse_appetite_definition && formik.errors.averse_appetite_definition}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<TextField
				label="Definition for cautious appetite"
				name="cautious_appetite_definition"
				value={formik.values.cautious_appetite_definition}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.cautious_appetite_definition)}
				helperText={formik.touched.cautious_appetite_definition && formik.errors.cautious_appetite_definition}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<TextField
				label="Definition for eager appetite"
				name="eager_appetite_definition"
				value={formik.values.eager_appetite_definition}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.eager_appetite_definition)}
				helperText={formik.touched.eager_appetite_definition && formik.errors.eager_appetite_definition}
				disabled={isSaving || disabled}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<Box sx={{ display: 'flex', marginTop: (theme) => theme.spacing(2) }}>
				{onCancel && (
					<Button startIcon={<Close />} onClick={() => onCancel()} disabled={isSaving || disabled} variant="outlined">
						Cancel
					</Button>
				)}
				{item && (
					<>
						<Button
							startIcon={<Undo />}
							onClick={() => formik.resetForm()}
							disabled={isSaving || !formik.dirty || disabled}
							variant="outlined"
						>
							Discard changes
						</Button>
						{item.disabled ? (
							<Button
								startIcon={<Restore />}
								onClick={() => enableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Enable
							</Button>
						) : (
							<Button
								startIcon={<NotInterested />}
								onClick={() => disableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Disable
							</Button>
						)}
						<Button
							startIcon={<Delete />}
							onClick={() => setIsDeleteConfirmDialogOpen(true)}
							disabled={isSaving || disabled || !canDelete}
							variant="outlined"
							sx={{ marginLeft: (theme) => theme.spacing(1) }}
						>
							Delete
						</Button>
					</>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					onClick={() => formik.submitForm()}
					disabled={isSaving || !formik.dirty || disabled}
					variant="contained"
				>
					Save
				</Button>
			</Box>
			{isDeleteConfirmDialogOpen && item && (
				<ConfirmDialog
					title="Confirm deletion of risk category"
					message={`Are you sure you want to delete the risk category '${item.name}'? This action is irreversible`}
					confirmLabel="Delete"
					cancelLabel="Cancel"
					onClose={() => setIsDeleteConfirmDialogOpen(false)}
					onConfirm={() => deleteItem()}
				/>
			)}
		</Box>
	);
}
