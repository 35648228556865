import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { IApiStrategicPillar } from '@mitie/risk-register-api-types';

import PillarsPickerDialog from './PillarsPickerDialog';

interface IPillarsPickerProps {
	value: IApiStrategicPillar[];
	onChange: (value: IApiStrategicPillar[]) => void;
	disabled: boolean;
	error?: boolean;
	helperText?: React.ReactNode;
}

export default function PillarsPicker({ value, onChange, disabled, error, helperText }: IPillarsPickerProps) {
	const [isPickerOpen, setIsPickerOpen] = useState(false);

	return (
		<TextField
			variant="outlined"
			sx={{ margin: (theme) => theme.spacing(1) }}
			label="Impacted strategic pillars"
			value={value.length > 0 ? value.map((p) => p.name).join(', ') : 'Not set'}
			error={error}
			helperText={helperText}
			InputProps={{
				endAdornment: (
					<InputAdornment
						position="end"
						sx={{
							height: 'auto',
							margin: '12px 8px',
						}}
					>
						<>
							<Button disabled={disabled} onClick={() => setIsPickerOpen(true)}>
								{value ? 'Change...' : 'Select...'}
							</Button>
							{isPickerOpen && (
								<PillarsPickerDialog
									value={value}
									onClose={(value) => {
										if (value) {
											onChange(value);
										}

										setIsPickerOpen(false);
									}}
								/>
							)}
						</>
					</InputAdornment>
				),
				disabled,
				inputProps: {
					disabled: true,
				},
				sx: {
					color: error ? 'red' : 'default',
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}
