import { useEffect, useState } from 'react';
import {
	Box,
	CircularProgress,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { IApiAuthenticationProvider, IApiUser } from '@mitie/risk-register-api-types';
import { Close } from '@mui/icons-material';

import * as AuthenticationProvidersApi from 'api/authenticationProviders';
import PropertyDisplay from './PropertyDisplay';
import AuthenticationProviderForm from './AuthenticationProviderForm';

export default function AuthenticationProvider({
	item,
	onChange,
	isDataLoading,
}: {
	item: IApiAuthenticationProvider;
	onChange: () => void;
	isDataLoading: boolean;
}) {
	const [isUsageLoading, setIsUsageLoading] = useState(false);
	const [usage, setUsage] = useState<IApiUser[] | null>(null);
	const [isUsageDrawerOpen, setIsUsageDrawerOpen] = useState(false);

	useEffect(() => {
		loadUsageData();
	}, [item]);

	const loadUsageData = async () => {
		setIsUsageLoading(true);

		try {
			const data = await AuthenticationProvidersApi.getUsageForAuthenticationProvider(item.authentication_provider_id);

			setUsage(data);
		} finally {
			setIsUsageLoading(false);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<AuthenticationProviderForm
					item={item}
					onSave={onChange}
					disabled={isDataLoading || isUsageLoading}
					canDelete={usage ? !usage.length : false}
				/>
			</Box>
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<PropertyDisplay name="Number of users using this authentication provider" width="400px">
					{isUsageLoading ? (
						<CircularProgress size={16} />
					) : (
						<Link component="button" underline="hover" onClick={() => setIsUsageDrawerOpen(true)}>
							{usage?.length}
						</Link>
					)}
				</PropertyDisplay>
			</Box>
			<Drawer anchor="right" open={isUsageDrawerOpen} onClose={() => setIsUsageDrawerOpen(false)}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ flexGrow: 1, margin: (theme) => `0 ${theme.spacing(2)}` }}>
						Users using this authentication provider
					</Typography>
					<IconButton onClick={() => setIsUsageDrawerOpen(false)}>
						<Close />
					</IconButton>
				</Box>
				{usage && (
					<List>
						{usage.map((user) => (
							<ListItem key={user.user_id}>
								<ListItemText primary={user.name} secondary={user.user_id} />
							</ListItem>
						))}
					</List>
				)}
			</Drawer>
		</Box>
	);
}
