import { useEffect, useState } from 'react';
import {
	Box,
	TextField,
	InputAdornment,
	Button,
	LinearProgress,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	IconButton,
	Typography,
	Alert,
} from '@mui/material';
import { Delete, Info } from '@mui/icons-material';
import {
	IApiRiskRegister,
	IApiRole,
	IApiUserRoleAssignment,
	IApiUserRoleAssignmentCreate,
	Role,
} from '@mitie/risk-register-api-types';

import RiskRegisterSelectDialog from './RiskRegisterSelectDialog';
import useUser from 'hooks/useUser';

interface IUserRoleAssignmentsProps {
	userId: string;
	roleAssignments: IApiUserRoleAssignment[] | null;
	addRoleAssignment: (data: IApiUserRoleAssignmentCreate) => void;
	removeRoleAssignment: (data: IApiUserRoleAssignmentCreate) => void;
	isLoading: boolean;
	isSaving: boolean;
	riskRegistersList: IApiRiskRegister[] | undefined;
	rolePermissions: Record<string, IApiRole> | undefined;
}

export default function UserRoleAssignments({
	roleAssignments,
	addRoleAssignment,
	removeRoleAssignment,
	isLoading,
	isSaving,
	riskRegistersList,
	rolePermissions,
}: IUserRoleAssignmentsProps) {
	const [selectedRiskRegister, setSelectedRiskRegister] = useState<IApiRiskRegister>();
	const [selectedRole, setSelectedRole] = useState<Role | ''>('');
	const [registerSelectOpen, setRegisterSelectOpen] = useState(false);
	const [roleOptions, setRoleOptions] = useState<Role[]>([]);
	const { user } = useUser();

	useEffect(() => {
		if (selectedRiskRegister) {
			setRoleOptions(selectedRiskRegister.roles);

			if (!selectedRole || !selectedRiskRegister.roles.includes(selectedRole)) {
				setSelectedRole(selectedRiskRegister.roles[0] || '');
			}
		} else {
			setRoleOptions([]);
			setSelectedRole('');
		}
	}, [selectedRiskRegister]);

	const generateTooltipForRole = (role: IApiRole) =>
		`${role.description}. Includes the following permissions:\n\n${role.permissions.map((p) => p.name).join('\n')}`;

	const canEdit = user?.roles.includes('Contributor');

	return (
		<Box>
			<Typography variant="h6">Role assignments</Typography>
			{isLoading && <LinearProgress />}
			{roleAssignments && rolePermissions && (
				<>
					<TableContainer component={Paper} sx={{ marginTop: (theme) => theme.spacing(2), maxHeight: '350px' }}>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow sx={{ '>th': { backgroundColor: (theme) => theme.palette.background.paper } }}>
									<TableCell>Risk register</TableCell>
									<TableCell>Role</TableCell>
									<TableCell sx={{ width: '64px' }}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{roleAssignments.length === 0 ? (
									<TableRow>
										<TableCell colSpan={3}>No role is assigned for this user</TableCell>
									</TableRow>
								) : (
									roleAssignments.map(({ role_id, risk_register_id, risk_register_name }, index) => {
										const hasAccess =
											riskRegistersList &&
											riskRegistersList.find(
												(r) => r.risk_register_id === risk_register_id && r.roles.includes(role_id),
											) !== undefined;
										const tooltip = rolePermissions[role_id] ? generateTooltipForRole(rolePermissions[role_id]) : '';

										return (
											<TableRow
												key={index}
												sx={{
													'>td': {
														color: (theme) => (hasAccess ? theme.palette.text.primary : theme.palette.text.disabled),
													},
												}}
											>
												<TableCell>{risk_register_name}</TableCell>
												<TableCell>
													{role_id}
													<Tooltip
														title={tooltip}
														arrow
														placement="right"
														PopperProps={{ style: { whiteSpace: 'pre-line' } }}
													>
														<IconButton color="default" size="small">
															<Info fontSize="inherit" />
														</IconButton>
													</Tooltip>
												</TableCell>
												<TableCell>
													<Tooltip
														title={hasAccess ? 'Remove' : 'You are not authorised to remove this role assignment'}
														arrow
														placement="right"
													>
														<span>
															<IconButton
																edge="end"
																size="small"
																onClick={() => removeRoleAssignment({ role_id, risk_register_id })}
																disabled={!hasAccess}
															>
																<Delete />
															</IconButton>
														</span>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>

					{canEdit && (
						<Paper
							sx={{
								marginTop: (theme) => theme.spacing(2),
								padding: (theme) => theme.spacing(2),
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-end',
								}}
							>
								<TextField
									label="Risk register"
									variant="standard"
									value={selectedRiskRegister?.name || ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Button onClick={() => setRegisterSelectOpen(true)}>...</Button>
											</InputAdornment>
										),
										disabled: isSaving,
										inputProps: {
											disabled: true,
										},
									}}
									sx={{ marginRight: (theme) => theme.spacing(2) }}
								/>
								<TextField
									select={true}
									label="Role"
									variant="standard"
									value={selectedRole}
									onChange={(event) => {
										setSelectedRole(event.target.value as Role);
									}}
									SelectProps={{
										native: true,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!selectedRiskRegister || isSaving}
									sx={{ marginRight: (theme) => theme.spacing(2) }}
								>
									{roleOptions.map((role) => (
										<option key={role} value={role}>
											{role}
										</option>
									))}
								</TextField>
								<Button
									disabled={!selectedRiskRegister || !selectedRole || isSaving}
									onClick={() => {
										if (!selectedRiskRegister || !selectedRole) {
											return;
										}

										addRoleAssignment({
											role_id: selectedRole,
											risk_register_id: selectedRiskRegister.risk_register_id,
										});
										setSelectedRiskRegister(undefined);
									}}
									variant="outlined"
								>
									Add role assignment
								</Button>
							</Box>
							{selectedRole && rolePermissions[selectedRole] && (
								<Alert severity="info" sx={{ marginTop: (theme) => theme.spacing(2), whiteSpace: 'pre-line' }}>
									{generateTooltipForRole(rolePermissions[selectedRole])}
								</Alert>
							)}
						</Paper>
					)}
				</>
			)}
			{registerSelectOpen && riskRegistersList && (
				<RiskRegisterSelectDialog
					list={riskRegistersList}
					onChange={(selected: IApiRiskRegister[]) => setSelectedRiskRegister(selected[selected.length - 1])}
					onClose={() => setRegisterSelectOpen(false)}
				/>
			)}
		</Box>
	);
}
