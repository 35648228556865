import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';
import { Check, Close } from '@mui/icons-material';
import RiskRegisterTreeMultiselect from './RiskRegisterTreeMultiselect';

interface IRegistersPickerDialogProps {
	registers: IApiRiskRegister[];
	value: IApiRiskRegister[][];
	onClose: (value?: IApiRiskRegister[][]) => void;
}

export default function RegistersPickerDialog({ registers, value, onClose }: IRegistersPickerDialogProps) {
	const [selected, setSelected] = useState(value);

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="sm" fullWidth>
			<DialogTitle>Select registers</DialogTitle>
			<DialogContent sx={{ height: '400px' }}>
				<RiskRegisterTreeMultiselect list={registers} selected={value} onSelect={setSelected} />
			</DialogContent>
			<DialogActions>
				<Button
					startIcon={<Close />}
					onClick={() => {
						onClose();
					}}
					variant="outlined"
				>
					Cancel
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						onClose(selected);
					}}
					variant="contained"
					disabled={selected.length === 0}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
