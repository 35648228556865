import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RiskControlType } from '@mitie/risk-register-api-types';
import { Close, Save } from '@mui/icons-material';

interface IFormData {
	description: string;
	owner: string;
}

interface IAddOrUpdateRiskControlDialogProps {
	type: RiskControlType;
	data?: IFormData;
	onClose: (data?: IFormData) => void;
}

const validationSchema = yup.object({
	description: yup.string().required('Description is required').max(1024),
	owner: yup.string().required('Owner is required').max(255),
});

export default function AddOrUpdateRiskControlDialog({ data, type, onClose }: IAddOrUpdateRiskControlDialogProps) {
	const formik = useFormik<IFormData>({
		initialValues: data || { description: '', owner: '' },
		validationSchema,
		validateOnMount: true,
		onSubmit: (data) => onClose(data),
	});

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>{`${data ? 'Update' : 'Add'} ${type} measure`}</DialogTitle>
			<DialogContent>
				<TextField
					label="Description"
					name="description"
					value={formik.values.description}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.description)}
					helperText={formik.touched.description && formik.errors.description}
					fullWidth
					multiline
					rows={3}
					sx={{ marginTop: (theme) => theme.spacing(1) }}
				/>
				<TextField
					label="Owners"
					name="owner"
					value={formik.values.owner}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.owner)}
					helperText={formik.touched.owner && formik.errors.owner}
					sx={{ marginTop: (theme) => theme.spacing(2) }}
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					onClick={() => {
						formik.submitForm();
					}}
					color="primary"
					variant="contained"
					disabled={!formik.isValid}
					startIcon={<Save />}
				>
					{data ? 'Update' : 'Add'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
