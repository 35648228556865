import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	ListItemText,
	MenuItem,
	TextField,
} from '@mui/material';
import { IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';
import { Close, Check } from '@mui/icons-material';

import * as RiskCatalogueApi from 'api/riskCatalogue';

interface ICatalogueItemPickerDialogProps {
	lifecycleStatusId: number;
	onSelect: (catalogueItem: IApiRiskCatalogueItem) => void;
	onClose: () => void;
}

export default function CatalogueItemPickerDialog({
	lifecycleStatusId,
	onSelect,
	onClose,
}: ICatalogueItemPickerDialogProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [catalogueItems, setCatalogueItems] = useState<IApiRiskCatalogueItem[]>([]);
	const [selectedCatalogueItemId, setSelectedCatalogueItemId] = useState<number | null>(null);

	useEffect(() => {
		loadCatalogue();
	}, []);

	const loadCatalogue = async () => {
		setIsLoading(true);

		try {
			const data = await RiskCatalogueApi.getAll();
			setCatalogueItems(
				data
					.filter((item) => item.risk_register_lifecycle_status.risk_register_lifecycle_status_id === lifecycleStatusId)
					.sort((a, b) => (a.name > b.name ? 1 : -1)),
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>Pick risk from catalogue</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<CircularProgress />
				) : (
					<TextField
						select
						size="small"
						value={selectedCatalogueItemId || 0}
						onChange={(event) => setSelectedCatalogueItemId(event.target.value ? Number(event.target.value) : null)}
						sx={{ width: '500px' }}
					>
						<MenuItem value={0}>
							<ListItemText primary="Pick from list..." />
						</MenuItem>
						{catalogueItems.map((item) => (
							<MenuItem
								key={item.risk_catalogue_id}
								value={item.risk_catalogue_id}
								sx={{
									maxWidth: '500px',
									whiteSpace: 'normal',
								}}
							>
								<ListItemText primary={item.name} secondary={item.description} />
							</MenuItem>
						))}
					</TextField>
				)}
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						if (!selectedCatalogueItemId) {
							return;
						}

						const selectedCatalogueItem = catalogueItems.find(
							(item) => item.risk_catalogue_id === selectedCatalogueItemId,
						);

						if (!selectedCatalogueItem) {
							return;
						}

						onSelect(selectedCatalogueItem);
						onClose();
					}}
					color="primary"
					variant="contained"
					disabled={!selectedCatalogueItemId}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
