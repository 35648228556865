import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, LinearProgress, Typography } from '@mui/material';
import { IApiAuthenticationProvider, IApiCompany } from '@mitie/risk-register-api-types';

import PropertyDisplay from './PropertyDisplay';
import { formatDate } from 'utils';
import CompanyRenameDialog from './CompanyRenameDialog';
import LicenseUpdateDialog from './LicenseUpdateDialog';
import MiReportUpdateDialog from './MiReportUpdateDialog';
import UserDirectoryConfigureDialog from './UserDirectoryConfigureDialog';
import * as AuthenticationProvidersApi from '../api/authenticationProviders';
import * as CompaniesApi from '../api/companies';
import AuthenticationProviderPickerDialog from './AuthenticationProviderPickerDialog';
import CompanyDeleteDialog from './CompanyDeleteDialog';

export default function Company({
	item,
	onChange,
	isDataLoading,
}: {
	item: IApiCompany;
	onChange: () => void;
	isDataLoading: boolean;
}) {
	const [isCompanyRenameDialogOpen, setIsCompanyRenameDialogOpen] = useState(false);
	const [isUserDirectoryConfigureDialogOpen, setIsUserDirectoryConfigureDialogOpen] = useState(false);
	const [isLicenseUpdateDialogOpen, setIsLicenseUpdateDialogOpen] = useState(false);
	const [isMiReportUpdateDialogOpen, setIsMiReportUpdateDialogOpen] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const [isAuthenticationProviderLoading, setIsAuthenticationProviderLoading] = useState(false);
	const [defaultAuthenticationProvider, setDefaultAuthenticationProvider] = useState<IApiAuthenticationProvider>();
	const [isAuthenticationProviderPickerDialogOpen, setIsAuthenticationProviderPickerDialogOpen] = useState(false);
	const [isDefaultAuthProviderSaving, setIsDefaulAuthProviderSaving] = useState(false);

	useEffect(() => {
		loadDefaultAuthenticationProvider();
	}, [item]);

	const loadDefaultAuthenticationProvider = async () => {
		if (!item.default_authentication_provider_id) {
			return;
		}
		setIsAuthenticationProviderLoading(true);

		try {
			const authProvider = await AuthenticationProvidersApi.getAuthenticationProviderById(
				item.default_authentication_provider_id,
			);
			setDefaultAuthenticationProvider(authProvider);
		} finally {
			setIsAuthenticationProviderLoading(false);
		}
	};

	const updateDefaultAuthenticationProvider = async (authProvider: IApiAuthenticationProvider) => {
		setIsDefaulAuthProviderSaving(true);

		try {
			await CompaniesApi.saveCompany(item.company_id, {
				default_authentication_provider_id: authProvider.authentication_provider_id,
			});

			onChange();
		} finally {
			setIsDefaulAuthProviderSaving(false);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
			{isDataLoading && <LinearProgress />}
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<PropertyDisplay name="Company">
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography>{item.name}</Typography>
						<Button
							variant="outlined"
							size="small"
							onClick={() => setIsCompanyRenameDialogOpen(true)}
							sx={{ marginLeft: (theme) => theme.spacing(2) }}
						>
							Rename...
						</Button>
					</Box>
				</PropertyDisplay>
				<PropertyDisplay name="Company unique identifier">
					<Typography>{item.company_id}</Typography>
				</PropertyDisplay>
			</Box>
			<Divider />
			<Box sx={{ margin: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}` }}>
				<Box>
					<Typography component="span" variant="h6">
						License details
					</Typography>
					<Button
						variant="outlined"
						size="small"
						onClick={() => setIsLicenseUpdateDialogOpen(true)}
						sx={{ marginLeft: (theme) => theme.spacing(2) }}
					>
						Update license...
					</Button>
				</Box>
				<PropertyDisplay name="Status">
					<Typography>{item.license_enabled ? 'Live' : 'Expired'}</Typography>
				</PropertyDisplay>
				<PropertyDisplay name="License type">
					<Typography>{item.license_type}</Typography>
				</PropertyDisplay>
				<PropertyDisplay name="License start date">
					<Typography>{item.license_start_date ? formatDate(item.license_start_date) : '-'}</Typography>
				</PropertyDisplay>
				<PropertyDisplay name="License end date">
					<Typography>{item.license_end_date ? formatDate(item.license_end_date) : 'Indefinite'}</Typography>
				</PropertyDisplay>
				<PropertyDisplay name="Maximum users">
					<Typography>{item.max_users ? item.max_users.toLocaleString() : 'Unlimited'}</Typography>
				</PropertyDisplay>
				<PropertyDisplay name="Current number of users">
					<Typography>{item.users_count.toLocaleString()}</Typography>
				</PropertyDisplay>
			</Box>
			<Divider />
			<Box sx={{ margin: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}` }}>
				<Box>
					<Typography component="span" variant="h6">
						MI reporting
					</Typography>
					<PropertyDisplay name="Power BI report details">
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{item.mi_group_id && item.mi_dataset_id && item.mi_report_id ? (
								<Typography>Configured</Typography>
							) : (
								<Typography>Not configured</Typography>
							)}
							<Button
								variant="outlined"
								size="small"
								onClick={() => setIsMiReportUpdateDialogOpen(true)}
								sx={{ marginLeft: (theme) => theme.spacing(2) }}
							>
								Change...
							</Button>
						</Box>
					</PropertyDisplay>
				</Box>
			</Box>
			<Divider />
			<Box sx={{ margin: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}` }}>
				<Typography variant="h6">Users directory</Typography>
				<Typography variant="caption">
					When a user directory is configured, the following features will be enabled for this customer:
				</Typography>
				<ul>
					<Typography component="li" variant="caption">
						Auto completion of names when adding a user
					</Typography>
					<Typography component="li" variant="caption">
						Auto completion of names when assigning owners and sponsors to a risk
					</Typography>
					<Typography component="li" variant="caption">
						Display user details (job title and avatar) when hovering user names on risk details page
					</Typography>
					<Typography component="li" variant="caption">
						Display user&apos;s avatar in user menu
					</Typography>
				</ul>
				<Typography variant="caption">
					Azure Active Directory is supported as user directory provider at the moment in Risk Safe.
				</Typography>
				<PropertyDisplay name="Users directory">
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{item.user_directory ? (
							<Typography>Using {item.user_directory.type}</Typography>
						) : (
							<Typography>Not configured</Typography>
						)}
						<Button
							variant="outlined"
							size="small"
							onClick={() => setIsUserDirectoryConfigureDialogOpen(true)}
							sx={{ marginLeft: (theme) => theme.spacing(2) }}
						>
							Configure...
						</Button>
					</Box>
				</PropertyDisplay>
			</Box>
			<Divider />
			<Box sx={{ margin: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}` }}>
				<Typography variant="h6">Users authentication</Typography>
				<Typography variant="caption" component="p">
					You need to select a default authentication provider. When a user is added to this customer, it will use this
					authentication provider. If an existing Risk Safe user is added to another customer, it will not change their
					authentication method.
				</Typography>
				<Typography variant="caption" component="p">
					You can bypass the default authentication provider by adding a user through the administration panel, rather
					than adding them to the company directly.
				</Typography>
				<PropertyDisplay name="Default authentication provider">
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{item.default_authentication_provider_id ? (
							isAuthenticationProviderLoading && isDefaultAuthProviderSaving ? (
								<CircularProgress />
							) : (
								<Typography>{defaultAuthenticationProvider?.name}</Typography>
							)
						) : (
							<Typography>Not set</Typography>
						)}
						<Button
							variant="outlined"
							size="small"
							onClick={() => setIsAuthenticationProviderPickerDialogOpen(true)}
							sx={{ marginLeft: (theme) => theme.spacing(2) }}
						>
							Change...
						</Button>
					</Box>
				</PropertyDisplay>
			</Box>
			<Divider />
			<Box sx={{ margin: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}` }}>
				<Typography variant="h6">Delete customer</Typography>
				<Typography variant="caption" component="p">
					Use the button below to remove the customer from Risk Safe. All the data for this customer will become
					unavailable, and the users won&apos;t be able to sign in any longer.
				</Typography>
				<Typography variant="caption" component="p">
					The data will not be deleted from the database, it may still be recovered if required.
				</Typography>
				<Button
					variant="outlined"
					size="small"
					onClick={() => setIsDeleteConfirmDialogOpen(true)}
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				>
					Delete customer...
				</Button>
			</Box>
			{isCompanyRenameDialogOpen && (
				<CompanyRenameDialog
					companyId={item.company_id}
					currentName={item.name}
					onClose={() => setIsCompanyRenameDialogOpen(false)}
					onChange={() => onChange()}
				/>
			)}
			{isUserDirectoryConfigureDialogOpen && (
				<UserDirectoryConfigureDialog
					company={item}
					currentUserDirectory={item.user_directory}
					onClose={() => setIsUserDirectoryConfigureDialogOpen(false)}
					onChange={() => onChange()}
				/>
			)}
			{isLicenseUpdateDialogOpen && (
				<LicenseUpdateDialog
					company={item}
					onClose={() => setIsLicenseUpdateDialogOpen(false)}
					onChange={() => onChange()}
				/>
			)}
			{isMiReportUpdateDialogOpen && (
				<MiReportUpdateDialog
					company={item}
					onClose={() => setIsMiReportUpdateDialogOpen(false)}
					onChange={() => onChange()}
				/>
			)}
			{isAuthenticationProviderPickerDialogOpen && (
				<AuthenticationProviderPickerDialog
					selected={defaultAuthenticationProvider}
					onClose={() => setIsAuthenticationProviderPickerDialogOpen(false)}
					onSelect={updateDefaultAuthenticationProvider}
				/>
			)}
			{isDeleteConfirmDialogOpen && (
				<CompanyDeleteDialog
					companyId={item.company_id}
					onClose={() => setIsDeleteConfirmDialogOpen(false)}
					onChange={() => onChange()}
				/>
			)}
		</Box>
	);
}
