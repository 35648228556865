import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import RiskRegisterTypeForm from './RiskRegisterTypeForm';

interface IAddRiskRegisterTypeDialogProps {
	onClose: (success: boolean) => void;
}

export default function AddRiskRegisterTypeDialog({ onClose }: IAddRiskRegisterTypeDialogProps) {
	return (
		<Dialog open={true} onClose={() => onClose(false)}>
			<DialogTitle>New risk register type</DialogTitle>
			<DialogContent>
				<RiskRegisterTypeForm onSave={() => onClose(true)} onCancel={() => onClose(false)} />
			</DialogContent>
		</Dialog>
	);
}
