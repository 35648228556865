import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiCompany } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as CompaniesApi from 'api/companies';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import Company from './Company';
import AddCompanyDialog from './AddCompanyDialog';

export default function CompaniesAdmin() {
	const [selectedCompany, setSelectedCompany] = useState<IApiCompany | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [companies, setCompanies] = useState<IApiCompany[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedCompany(companies.find((c) => encodeStringForUrl(c.company_id) === selected) || null);
	}, [companies, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await CompaniesApi.getAllCompanies();

			setCompanies(data.sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto', flexShrink: 0 }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						New customer
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{companies.map((company) => (
					<ListItemButton
						key={company.company_id}
						selected={selectedCompany?.company_id === company.company_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(company.company_id) })}
					>
						<ListItemText primary={company.name} secondary={!company.license_enabled ? 'License expired' : null} />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{selectedCompany && <Company item={selectedCompany} onChange={loadData} isDataLoading={isLoading} />}
			{isAddDialogOpen && (
				<AddCompanyDialog
					onChange={() => loadData()}
					onClose={() => {
						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
