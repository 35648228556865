import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

interface IRetireRiskDialogProps {
	onClose: (comment?: string) => void;
}

export default function RetireRiskDialog({ onClose }: IRetireRiskDialogProps) {
	const [comment, setComment] = useState('');

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>Retire risk</DialogTitle>
			<DialogContent>
				<Typography>
					Please enter the rationale for retiring this risk. It will be reviewed by an owner of this register.
				</Typography>
				<TextField
					label="Rationale"
					value={comment}
					onChange={(event) => setComment(event.currentTarget.value)}
					error={!comment.length || comment.length > 255}
					helperText={
						comment.length === 0
							? 'Rationale is required'
							: comment.length > 255
							? 'Rationale must be less than 255 characters'
							: undefined
					}
					autoFocus={true}
					fullWidth={true}
					sx={{ marginTop: '16px' }}
				/>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						onClose(comment);
					}}
					color="primary"
					variant="contained"
					disabled={!comment.length || comment.length > 255}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
