import { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { IApiRiskWithRegister } from '@mitie/risk-register-api-types';

import * as RiskRegistersApi from 'api/riskRegisters';
import useAlerts from 'hooks/useAlerts';
import RetiredRiskCard from './RetiredRiskCard';
import TextFilterInput from './TextFilterInput';

export default function DeletedRisks() {
	const [isLoading, setIsLoading] = useState(false);
	const [deletedRisks, setDeletedRisks] = useState<IApiRiskWithRegister[]>([]);
	const [displayedRisks, setDisplayedRisks] = useState<IApiRiskWithRegister[]>([]);
	const [filter, setFilter] = useState<string>('');
	const { addAlert } = useAlerts();

	useEffect(() => {
		loadDeletedRisks();
	}, []);

	useEffect(() => {
		if (filter.length) {
			const searchTerm = filter.trim().toLowerCase();

			setDisplayedRisks(
				deletedRisks.filter(
					({ name, risk_register }) =>
						name.toLowerCase().includes(searchTerm) || risk_register.name.toLowerCase().includes(searchTerm),
				),
			);
		} else {
			setDisplayedRisks(deletedRisks);
		}
	}, [filter, deletedRisks]);

	const loadDeletedRisks = async () => {
		setIsLoading(true);

		try {
			const data = await RiskRegistersApi.getDeletedRisks();
			setDeletedRisks(data);
		} catch {
			addAlert('error', 'Failed to load the list of retired risks');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
			}}
		>
			<Box
				sx={{
					padding: (theme) => theme.spacing(1),
					paddingLeft: (theme) => theme.spacing(2),
					borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
				}}
			>
				<TextFilterInput value={filter} onChange={(newFilter) => setFilter(newFilter)} label="Filter retired risks" />
			</Box>
			{isLoading && <LinearProgress />}
			<Box sx={{ overflowY: 'auto', display: 'flex', flexWrap: 'wrap', margin: (theme) => theme.spacing(1) }}>
				{displayedRisks.map((risk) => (
					<RetiredRiskCard key={risk.risk_id} risk={risk} onChange={loadDeletedRisks} />
				))}
			</Box>
		</Box>
	);
}
