import { useEffect, useState } from 'react';
import { FilterAlt } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

import useDebounce from 'hooks/useDebounce';

interface ITextFilterInputProps {
	label: string;
	value: string;
	onChange: (value: string) => void;
	sx?: SxProps<Theme>;
}

export default function TextFilterInput({ label, value, onChange, sx }: ITextFilterInputProps) {
	const [filter, setFilter] = useState<string>('');
	const debouncedFilter = useDebounce(filter, 100);

	useEffect(() => {
		setFilter(value);
	}, [value]);

	useEffect(() => {
		onChange(debouncedFilter);
	}, [debouncedFilter, onChange]);

	return (
		<TextField
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<FilterAlt />
					</InputAdornment>
				),
			}}
			type="search"
			variant="standard"
			margin="none"
			placeholder={label}
			value={filter}
			onChange={(e) => setFilter(e.target.value)}
			sx={sx}
		/>
	);
}
