import { useEffect, useState } from 'react';
import { Alert, Box, LinearProgress, Link } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';

import * as miApi from '../api/mi';
import useUser from 'hooks/useUser';
import { Launch } from '@mui/icons-material';

export default function PowerBiReport() {
	const [accessToken, setAccessToken] = useState<string>();
	const [powerBiUrl, setPowerBiUrl] = useState<string>();
	const [embedUrl, setEmbedUrl] = useState<string>();
	const [isLoading, setIsLoading] = useState(true);
	const [embeddedReport, setEmbeddedReport] = useState<Report>();
	const { user } = useUser();

	useEffect(() => {
		loadAccessToken();
	}, []);

	useEffect(() => {
		embeddedReport?.on('loaded', () => {
			setIsLoading(false);
		});

		return () => {
			embeddedReport?.off('loaded');
		};
	}, [embeddedReport]);

	const loadAccessToken = async () => {
		const { access_token, report_id, group_id } = await miApi.getAccessToken();
		setAccessToken(access_token);
		setPowerBiUrl(`https://app.powerbi.com/groups/${group_id}/reports/${report_id}`);
		setEmbedUrl(`https://app.powerbi.com/reportEmbed?reportId=${report_id}&groupId=${group_id}`);
	};

	// Only display the link to go to Power BI if the user is a Mitie user
	// Otherwise they won't be able to sign in to Power BI
	const displayLink = user?.selected_company.company_id === 'mitie';

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			}}
		>
			{isLoading && <LinearProgress />}
			{accessToken !== undefined && (
				<>
					{displayLink && (
						<Box>
							<Alert severity="info">
								<Link
									href={powerBiUrl}
									variant="body1"
									underline="hover"
									target="_blank"
									rel="noopener"
									sx={{ display: 'flex', alignItems: 'center' }}
								>
									View in PowerBI <Launch fontSize="small" sx={{ marginLeft: (theme) => theme.spacing(1) }} />
								</Link>
							</Alert>
						</Box>
					)}
					<Box
						sx={{
							display: 'flex',
							flexGrow: 1,
							'& .report': { width: '100%', flexGrow: 1 },
							'& .report iframe': { border: 'none' },
						}}
					>
						<PowerBIEmbed
							key={accessToken}
							embedConfig={{
								type: 'report',
								embedUrl,
								tokenType: models.TokenType.Embed,
								accessToken,
							}}
							getEmbeddedComponent={(r) => {
								setEmbeddedReport(r as Report);
							}}
							cssClassName="report"
						/>
					</Box>
				</>
			)}
		</Box>
	);
}
