export function login(email: string) {
	window.location.replace(
		`${process.env.REACT_APP_API_URL}/auth/login?ref=${encodeURIComponent(
			window.location.href,
		)}&email=${encodeURIComponent(email)}`,
	);
}

export function logout() {
	window.location.replace(
		`${process.env.REACT_APP_API_URL}/auth/logout?ref=${encodeURIComponent(window.location.origin)}`,
	);
}
