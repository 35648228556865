import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiRiskImpactCategory } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as RiskImpactCategoriesApi from 'api/riskImpactCategories';
import RiskImpactCategory from './RiskImpactCategory';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import AddRiskImpactCategoryDialog from './AddRiskImpactCategoryDialog';

export default function RiskImpactMatrix() {
	const [selectedImpactCategory, setSelectedImpactCategory] = useState<IApiRiskImpactCategory | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [liveImpactCategories, setLiveImpactCategories] = useState<IApiRiskImpactCategory[]>([]);
	const [disabledImpactCategories, setDisabledImpactCategories] = useState<IApiRiskImpactCategory[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedImpactCategory(
			liveImpactCategories.find((c) => encodeStringForUrl(c.name) === selected) ||
				disabledImpactCategories.find((c) => encodeStringForUrl(c.name) === selected) ||
				null,
		);
	}, [disabledImpactCategories, liveImpactCategories, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await Promise.all([
				RiskImpactCategoriesApi.getLiveImpactCategories(),
				RiskImpactCategoriesApi.getDisabledImpactCategories(),
			]);

			setLiveImpactCategories(data[0].sort((c1, c2) => (c1.sequence > c2.sequence ? 1 : -1)));
			setDisabledImpactCategories(data[1].sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto' }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						Add risk impact category
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{liveImpactCategories.map((riskImpactCategory) => (
					<ListItemButton
						key={riskImpactCategory.risk_impact_category_id}
						selected={selectedImpactCategory?.risk_impact_category_id === riskImpactCategory.risk_impact_category_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskImpactCategory.name) })}
					>
						<ListItemText primary={riskImpactCategory.name} />
					</ListItemButton>
				))}
				{disabledImpactCategories.map((riskImpactCategory) => (
					<ListItemButton
						key={riskImpactCategory.risk_impact_category_id}
						selected={selectedImpactCategory?.risk_impact_category_id === riskImpactCategory.risk_impact_category_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskImpactCategory.name) })}
					>
						<ListItemText primary={riskImpactCategory.name} secondary="Disabled" />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{selectedImpactCategory && (
				<RiskImpactCategory
					item={selectedImpactCategory}
					isFirst={selectedImpactCategory.risk_impact_category_id === liveImpactCategories[0]?.risk_impact_category_id}
					isLast={
						selectedImpactCategory.risk_impact_category_id ===
						liveImpactCategories[liveImpactCategories.length - 1]?.risk_impact_category_id
					}
					onChange={loadData}
					isDataLoading={isLoading}
				/>
			)}
			{isAddDialogOpen && (
				<AddRiskImpactCategoryDialog
					onClose={(success: boolean) => {
						if (success) {
							loadData();
						}

						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
