import { useEffect, useState } from 'react';
import {
	Box,
	CircularProgress,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { IApiRiskCategory, IApiRiskCategoryUsage } from '@mitie/risk-register-api-types';
import { ArrowForward, Close } from '@mui/icons-material';

import * as RiskCategoriesApi from 'api/riskCategories';
import PropertyDisplay from './PropertyDisplay';
import { useNavigate } from 'routing/routing';
import { Page } from 'routing/routes';
import RiskCategoryForm from './RiskCategoryForm';

export default function RiskCategory({
	item,
	onChange,
	isDataLoading,
}: {
	item: IApiRiskCategory;
	onChange: () => void;
	isDataLoading: boolean;
}) {
	const [isUsageLoading, setIsUsageLoading] = useState(false);
	const [usage, setUsage] = useState<IApiRiskCategoryUsage | null>(null);
	const [isUsageDrawerOpen, setIsUsageDrawerOpen] = useState(false);
	const [isCatalogueUsageDrawerOpen, setIsCatalogueUsageDrawerOpen] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		loadUsageData();
	}, [item]);

	const loadUsageData = async () => {
		setIsUsageLoading(true);

		try {
			const data = await RiskCategoriesApi.getUsageForCategory(item.risk_category_id);

			setUsage(data);
		} finally {
			setIsUsageLoading(false);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<RiskCategoryForm
					item={item}
					onSave={onChange}
					disabled={isDataLoading || isUsageLoading}
					canDelete={usage ? !usage.catalogue_items.length && !usage.risks.length : false}
				/>
			</Box>
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<PropertyDisplay name="Number of risks using this category" width="400px">
					{isUsageLoading ? (
						<CircularProgress size={16} />
					) : (
						<Link component="button" underline="hover" onClick={() => setIsUsageDrawerOpen(true)}>
							{usage?.risks.length}
						</Link>
					)}
				</PropertyDisplay>
				<PropertyDisplay name="Number of catalogue items using this category" width="400px">
					{isUsageLoading ? (
						<CircularProgress size={16} />
					) : (
						<Link component="button" underline="hover" onClick={() => setIsCatalogueUsageDrawerOpen(true)}>
							{usage?.catalogue_items.length}
						</Link>
					)}
				</PropertyDisplay>
			</Box>
			<Drawer anchor="right" open={isUsageDrawerOpen} onClose={() => setIsUsageDrawerOpen(false)}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ flexGrow: 1, margin: (theme) => `0 ${theme.spacing(2)}` }}>
						Risks using this category
					</Typography>
					<IconButton onClick={() => setIsUsageDrawerOpen(false)}>
						<Close />
					</IconButton>
				</Box>
				{usage && (
					<List>
						{usage.risks.map((risk) => (
							<ListItem
								key={risk.risk_id}
								secondaryAction={
									<IconButton
										edge="end"
										onClick={() => {
											if (risk.state === 'Retired') {
												navigate(Page.DeletedData, {
													page: 'deleted-risks',
												});
											} else {
												navigate(Page.RiskRegisters, {
													registerId: risk.risk_register.risk_register_id,
													registerName: risk.risk_register.name,
													riskId: risk.risk_id,
													riskName: risk.name,
												});
											}
										}}
									>
										<ArrowForward />
									</IconButton>
								}
							>
								<ListItemText primary={risk.name} secondary={risk.risk_register.name} />
							</ListItem>
						))}
					</List>
				)}
			</Drawer>
			<Drawer anchor="right" open={isCatalogueUsageDrawerOpen} onClose={() => setIsCatalogueUsageDrawerOpen(false)}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ flexGrow: 1, margin: (theme) => `0 ${theme.spacing(2)}` }}>
						Catalogue items using this category
					</Typography>
					<IconButton onClick={() => setIsCatalogueUsageDrawerOpen(false)}>
						<Close />
					</IconButton>
				</Box>
				{usage && (
					<List>
						{usage.catalogue_items.map((item) => (
							<ListItem
								key={item.risk_catalogue_id}
								secondaryAction={
									<IconButton
										edge="end"
										onClick={() => {
											if (item.deleted) {
												navigate(Page.DeletedData, { page: 'deleted-catalogue-items' });
											} else {
												navigate(Page.RiskCatalogue);
											}
										}}
									>
										<ArrowForward />
									</IconButton>
								}
							>
								<ListItemText
									primary={item.name}
									secondary={`Register type and status: ${item.risk_register_type.name} / ${item.risk_register_lifecycle_status.name}`}
								/>
							</ListItem>
						))}
					</List>
				)}
			</Drawer>
		</Box>
	);
}
