import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	LinearProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';
import { Check, Close } from '@mui/icons-material';

import * as RiskCatalogueApi from 'api/riskCatalogue';

interface ICatalogueItemsPickerDialogProps {
	lifecycleStatusId: number;
	value: IApiRiskCatalogueItem[];
	onClose: (selected?: IApiRiskCatalogueItem[]) => void;
}

export default function CatalogueItemsPickerDialog({
	lifecycleStatusId,
	value,
	onClose,
}: ICatalogueItemsPickerDialogProps) {
	const [selected, setSelected] = useState(value);
	const [isLoading, setIsLoading] = useState(false);
	const [catalogueItems, setCatalogueItems] = useState<IApiRiskCatalogueItem[]>([]);

	useEffect(() => {
		loadCatalogue();
	}, []);

	const loadCatalogue = async () => {
		setIsLoading(true);

		try {
			const data = await RiskCatalogueApi.getAll();
			setCatalogueItems(
				data
					.filter((item) => item.risk_register_lifecycle_status.risk_register_lifecycle_status_id === lifecycleStatusId)
					.sort((a, b) => (a.name > b.name ? 1 : -1)),
			);
		} finally {
			setIsLoading(false);
		}
	};

	const buttonClicked = (catalogueItem: IApiRiskCatalogueItem) => {
		setSelected((previous) => {
			if (previous.find((i) => i.risk_catalogue_id === catalogueItem.risk_catalogue_id)) {
				// Remove item from selected list
				return previous.filter((i) => i.risk_catalogue_id !== catalogueItem.risk_catalogue_id);
			} else {
				// Add item to selected list
				return [...previous, catalogueItem];
			}
		});
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
			<DialogTitle>Select the catalogue risks to add to this register</DialogTitle>
			<DialogContent>
				{isLoading && <LinearProgress />}
				<FormGroup sx={{ display: 'flex', alignItems: 'stretch', overflowY: 'auto', gap: (theme) => theme.spacing(2) }}>
					{catalogueItems.map((catalogueItem) => (
						<FormControlLabel
							control={
								<Checkbox
									checked={selected.find((i) => i.risk_catalogue_id === catalogueItem.risk_catalogue_id) !== undefined}
									onChange={() => buttonClicked(catalogueItem)}
								/>
							}
							label={
								<>
									<Typography variant="subtitle1">{catalogueItem.name}</Typography>
									<Typography variant="caption">{catalogueItem.description}</Typography>
								</>
							}
							key={catalogueItem.risk_catalogue_id}
						/>
					))}
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button
					startIcon={<Close />}
					onClick={() => {
						onClose();
					}}
					variant="outlined"
				>
					Cancel
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						onClose(selected);
					}}
					variant="contained"
					disabled={selected.length === 0}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
