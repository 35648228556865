import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Typography,
	LinearProgress,
	List,
	ListItemText,
	ListItemButton,
} from '@mui/material';
import { IApiUser, IApiUserCreate } from '@mitie/risk-register-api-types';
import { Close, Add } from '@mui/icons-material';

import * as UsersApi from '../api/users';
import * as UserDirectoryApi from '../api/userDirectory';
import useDebounce from 'hooks/useDebounce';

interface IAddUserDialogProps {
	onClose: (addedUserId?: string) => void;
}

export default function AddUserFromDirectoryDialog({ onClose }: IAddUserDialogProps) {
	const [userDetails, setUserDetails] = useState<IApiUserCreate>();
	const [input, setInput] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const [matchedUsers, setMatchedUsers] = useState<IApiUser[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const debouncedInput = useDebounce(input, 500);
	const inputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		if (debouncedInput.length > 2) {
			updateMatchedUsers();
		}
	}, [debouncedInput]);

	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 0);
	}, [inputRef]);

	const updateMatchedUsers = async () => {
		setIsLoading(true);

		try {
			const data = await UserDirectoryApi.search(debouncedInput);

			if (userDetails) {
				// Reset selected user if not in new list
				if (!data.find(({ user_id }) => user_id === userDetails.user_id)) {
					setUserDetails(undefined);
				}
			}

			setMatchedUsers(data);
		} finally {
			setIsLoading(false);
		}
	};

	const createUser = async () => {
		if (!userDetails) {
			return;
		}

		setIsSaving(true);

		try {
			await UsersApi.create(userDetails);
			onClose(input);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>Add user</DialogTitle>
			<DialogContent>
				<TextField
					inputRef={inputRef}
					label="Search user"
					value={input}
					onChange={(event) => setInput(event.currentTarget.value)}
					error={!input.length}
					fullWidth
					sx={{ marginTop: '8px' }}
				/>
				<List>
					{isLoading && <LinearProgress />}
					{matchedUsers.map(({ user_id, name }, index) => {
						const isSelected = userDetails && userDetails.user_id === user_id;

						return (
							<ListItemButton
								key={index}
								onClick={() => setUserDetails({ user_id, name })}
								sx={{
									backgroundColor: isSelected ? (theme) => theme.palette.secondary.light : 'inherit',
								}}
							>
								<ListItemText
									primary={name}
									secondary={
										<>
											<Typography variant="body2">{user_id}</Typography>
										</>
									}
								/>
							</ListItemButton>
						);
					})}
				</List>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Add />}
					onClick={() => createUser()}
					color="primary"
					variant="contained"
					disabled={!userDetails || isSaving}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
