import React, { useState } from 'react';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import RiskRegisterTree from './RiskRegisterTree';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/system';

interface IRiskRegisterSelectDialogProps {
	list: IApiRiskRegister[];
	currentlySelected?: IApiRiskRegister[];
	onChange: (selected: IApiRiskRegister[]) => void;
	onClose: () => void;
}

export default function RiskRegisterSelectDialog({
	list,
	currentlySelected,
	onChange,
	onClose,
}: IRiskRegisterSelectDialogProps) {
	const [selected, setSelected] = useState(currentlySelected);

	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Choose risk register</DialogTitle>
			<DialogContent sx={{ height: '400px' }}>
				<RiskRegisterTree
					list={list}
					selected={selected}
					onSelect={(selected: IApiRiskRegister[]) => setSelected(selected)}
				/>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					onClick={() => {
						onChange(selected!);
						onClose();
					}}
					disabled={!selected}
					variant="contained"
				>
					Select
				</Button>
			</DialogActions>
		</Dialog>
	);
}
