import { IApiUser, IApiUserAdmin, IApiUserUpdateAdmin } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getUsers(page: number) {
	return Api.get<IApiUser[]>('users-admin', { page });
}

export function getUser(userId: string) {
	return Api.get<IApiUserAdmin>(`users-admin/${userId}`);
}

export function updateUser(userId: string, data: IApiUserUpdateAdmin) {
	return Api.patch<IApiUserAdmin>(`users-admin/${userId}`, data);
}

export function deleteUser(userId: string) {
	return Api.delete_<IApiUserAdmin>(`users-admin/${userId}`);
}

export function search(search: string) {
	return Api.post<IApiUser[]>('users-admin/search', { search });
}
