import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper,
	TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	IApiRiskCategory,
	IApiRiskType,
	RiskAppetite,
	IApiStrategicPillar,
	IApiRiskCatalogueItem,
	IApiRiskCatalogueControlUpdate,
	IApiRiskCatalogueItemCreate,
	IApiRiskCatalogueControl,
	IApiRiskRegisterTypeWithLifecycleStatuses,
} from '@mitie/risk-register-api-types';

import useAlerts from 'hooks/useAlerts';
import * as RiskTypesApi from '../api/riskTypes';
import * as RiskCategoriesApi from '../api/riskCategories';
import * as RiskCatalogueApi from 'api/riskCatalogue';
import * as RiskRegisterTypesApi from '../api/riskRegisterTypes';
import RiskAppetitePicker from './RiskAppetitePicker';
import RiskTypePicker from './RiskTypePicker';
import RiskCategoryPicker from './RiskCategoryPicker';
import RiskCatalogueControlsInput from './RiskCatalogueControlsInput';
import PillarsPicker from './PillarsPicker';
import LinkedPrincipalRisksPicker from './LinkedPrincipalRisksPicker';
import { ArrowBack, ArrowForward, Close, Save } from '@mui/icons-material';

interface IFormData {
	name?: string;
	description?: string;
	riskTypeId?: number;
	riskCategoryId?: number;
	appetite?: RiskAppetite;
	linkedPrincipalRisks: number[];
	strategicPillars?: IApiStrategicPillar[];
	controlsAssuranceSources?: string;
	registerLifecycleStatus?: number;
}

// The following validation schemas are used by formik to validate the form
const validationSchemasByStep = [
	yup.object({
		name: yup.string().required('Name is required'),
		description: yup.string().required('Description is required'),
		registerLifecycleStatus: yup
			.number()
			.notOneOf([0], 'Specify which registers this catalogue item applies to')
			.required('Specify which registers this catalogue item applies to'),
		riskTypeId: yup.number().required('Risk type is required'),
		riskCategoryId: yup.number().required('Risk category is required'),
		appetite: yup.string().required('Specify a risk appetite'),
		strategicPillars: yup
			.array()
			.of(yup.object())
			.min(1, 'Choose at least one strategy pillar')
			.required('Choose at least one strategy pillar'),
		linkedPrincipalRisks: yup.array().of(yup.number()),
	}),
	yup.object({}),
	yup.object({}),
	yup.object({
		controlsAssuranceSources: yup.string().required('Enter sources of assurance'),
	}),
];

const getFormDataFromCatalogueItem = (catalogueItem: IApiRiskCatalogueItem): IFormData => {
	return {
		name: catalogueItem.name,
		description: catalogueItem.description,
		riskTypeId: catalogueItem.risk_type_id,
		riskCategoryId: catalogueItem.risk_category_id,
		appetite: catalogueItem.appetite,
		strategicPillars: catalogueItem.catalogue_strategic_pillars,
		controlsAssuranceSources: catalogueItem.controls_assurance_sources,
		registerLifecycleStatus: catalogueItem.risk_register_lifecycle_status.risk_register_lifecycle_status_id,
		linkedPrincipalRisks: catalogueItem.catalogue_linked_principal_risks,
	};
};

interface ICreateOrUpdateRiskDialogProps {
	catalogueItem?: IApiRiskCatalogueItem;
	onClose: (result: boolean) => void;
}

export default function CreateOrUpdateCatalogueItemDialog({ catalogueItem, onClose }: ICreateOrUpdateRiskDialogProps) {
	const [riskTypes, setRiskTypes] = useState<IApiRiskType[]>([]);
	const [riskCategories, setRiskCategories] = useState<IApiRiskCategory[]>([]);
	const [areRiskDefinitionsLoading, setAreRiskDefinitionsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [registerTypes, setRegisterTypes] = useState<IApiRiskRegisterTypeWithLifecycleStatuses[]>([]);
	const [areTypesLoading, setAreTypesLoading] = useState(false);
	const formik = useFormik<IFormData>({
		initialValues: catalogueItem ? getFormDataFromCatalogueItem(catalogueItem) : { linkedPrincipalRisks: [] },
		validationSchema: validationSchemasByStep[activeStep],
		validateOnMount: true,
		onSubmit: async ({
			name,
			description,
			riskTypeId,
			riskCategoryId,
			appetite,
			strategicPillars,
			controlsAssuranceSources,
			registerLifecycleStatus,
			linkedPrincipalRisks,
		}) => {
			if (
				!name ||
				!description ||
				!riskTypeId ||
				!riskCategoryId ||
				!appetite ||
				!strategicPillars ||
				!controlsAssuranceSources ||
				!registerLifecycleStatus
			) {
				// Makes sure that Formik validation has happened. Should not go there but this avoids typing errors
				return;
			}

			setIsSaving(true);

			try {
				const controls: IApiRiskCatalogueControlUpdate[] = [...riskControls, ...riskMitigations].flatMap(
					({ updated }) => (updated ? [updated] : []),
				);

				const typeObject = registerTypes.find(
					(type) =>
						type.lifecycle_statuses &&
						type.lifecycle_statuses.find(
							(status) => status.risk_register_lifecycle_status_id === registerLifecycleStatus,
						),
				);

				if (!typeObject) {
					return;
				}

				const catalogueItemData: IApiRiskCatalogueItemCreate = {
					name,
					description,
					risk_type_id: riskTypeId,
					risk_category_id: riskCategoryId,
					catalogue_strategic_pillars: strategicPillars.map((p) => p.strategic_pillar_id),
					controls_assurance_sources: controlsAssuranceSources,
					catalogue_controls: controls,
					appetite,
					risk_register_type_id: typeObject.risk_register_type_id,
					risk_register_lifecycle_status_id: registerLifecycleStatus,
					catalogue_linked_principal_risks: linkedPrincipalRisks,
				};

				if (catalogueItem) {
					await RiskCatalogueApi.updateRiskCatalogueItem(catalogueItem.risk_catalogue_id, catalogueItemData);
				} else {
					await RiskCatalogueApi.createRiskCatalogueItem(catalogueItemData);
				}

				onClose(true);
			} finally {
				setIsSaving(false);
			}
		},
	});
	const [riskControls, setRiskControls] = useState<
		{ saved?: IApiRiskCatalogueControl; updated: IApiRiskCatalogueControlUpdate | null }[]
	>([]);
	const [riskMitigations, setRiskMitigations] = useState<
		{ saved?: IApiRiskCatalogueControl; updated: IApiRiskCatalogueControlUpdate | null }[]
	>([]);
	const { addAlert } = useAlerts();

	useEffect(() => {
		loadRiskDefinitions();
		loadRegisterTypes();
	}, []);

	useEffect(() => {
		if (catalogueItem) {
			setRiskControls(
				catalogueItem.catalogue_controls
					.filter((rc) => rc.type === 'Control')
					.map((rc) => ({
						saved: rc,
						updated: {
							risk_control_id: rc.risk_catalogue_control_id,
							type: rc.type,
							description: rc.description,
						},
					})),
			);
			setRiskMitigations(
				catalogueItem.catalogue_controls
					.filter((rc) => rc.type === 'Mitigation')
					.map((rc) => ({
						saved: rc,
						updated: {
							risk_control_id: rc.risk_catalogue_control_id,
							type: rc.type,
							description: rc.description,
						},
					})),
			);
		}
	}, [catalogueItem]);

	useEffect(() => {
		formik.validateForm();
	}, [activeStep]);

	const handleFieldChange = (fieldName: string, value: any) => {
		// Update field in formik
		formik.setFieldValue(fieldName, value);
	};

	const handleChange = ({ target }: React.ChangeEvent<any> | any) => {
		handleFieldChange(target.name, target.value);
	};

	const loadRiskDefinitions = async () => {
		setAreRiskDefinitionsLoading(true);

		try {
			const data = await Promise.all([RiskTypesApi.getLiveRiskTypes(), RiskCategoriesApi.getLiveCategories()]);
			setRiskTypes(data[0]);
			setRiskCategories(data[1]);
		} finally {
			setAreRiskDefinitionsLoading(false);
		}
	};

	const loadRegisterTypes = async () => {
		setAreTypesLoading(true);

		try {
			const data = await RiskRegisterTypesApi.getAll();
			setRegisterTypes(data);
		} catch {
			addAlert('error', 'Failed to load the list of register types');
		} finally {
			setAreTypesLoading(false);
		}
	};

	const goToNextStep = () => {
		if (activeStep < steps.length - 1) {
			setActiveStep(activeStep + 1);
		}
	};

	const toGoPreviousStep = () => {
		if (activeStep >= 0) {
			setActiveStep(activeStep - 1);
		}
	};

	const steps = ['Risk details', 'Control measures', 'Mitigation measures', 'Sources of assurance'];

	const riskCategory = formik.values.riskCategoryId
		? riskCategories.find((c) => c.risk_category_id === formik.values.riskCategoryId)
		: undefined;

	return (
		<Dialog open={true} fullWidth maxWidth="lg">
			<DialogTitle>{catalogueItem ? 'Update catalogue item' : 'New catalogue item'}</DialogTitle>
			<DialogContent sx={{ display: 'flex', minHeight: '500px' }}>
				<Box>
					<Stepper sx={{ marginBottom: (theme) => theme.spacing(2) }} orientation="vertical">
						{steps.map((label, step) => {
							const completed = activeStep > step;
							const active = activeStep === step;
							return (
								<Step key={label} completed={completed} active={active}>
									<StepLabel
										componentsProps={{
											label: { style: { fontWeight: active ? 500 : 'normal', whiteSpace: 'nowrap' } },
										}}
										StepIconProps={{
											sx: !active && completed ? { color: (theme) => `${theme.palette.success.main} !important` } : {},
										}}
									>
										{label}
									</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				</Box>
				<Box sx={{ marginLeft: (theme) => theme.spacing(2), flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
					<form onSubmit={formik.handleSubmit}>
						{activeStep === 0 && (
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Box sx={{ display: 'flex' }}>
									<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
										<TextField
											label="Risk name"
											name="name"
											value={formik.values.name}
											onChange={handleChange}
											onBlur={formik.handleBlur}
											error={Boolean(formik.errors.name)}
											helperText={formik.touched.name && formik.errors.name}
											sx={{ margin: (theme) => theme.spacing(1) }}
										/>
										<TextField
											label="Scope"
											name="registerLifecycleStatus"
											value={formik.values.registerLifecycleStatus}
											select={true}
											SelectProps={{
												native: true,
											}}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={(event) => {
												const value = event.target.value || undefined;
												handleFieldChange('registerLifecycleStatus', Number(value));
											}}
											onBlur={formik.handleBlur}
											error={formik.touched.registerLifecycleStatus && Boolean(formik.errors.registerLifecycleStatus)}
											helperText={formik.touched.registerLifecycleStatus && formik.errors.registerLifecycleStatus}
											sx={{ margin: (theme) => theme.spacing(1) }}
											disabled={areTypesLoading}
										>
											<option value={0}>-- Select from list --</option>
											{registerTypes.map((type) => {
												if (type.lifecycle_statuses) {
													return (
														<React.Fragment key={type.risk_register_type_id}>
															<option disabled>{type.name}</option>
															{type.lifecycle_statuses.map((status) => (
																<option
																	value={status.risk_register_lifecycle_status_id}
																	key={status.risk_register_lifecycle_status_id}
																>
																	{status.name}
																</option>
															))}
														</React.Fragment>
													);
												} else {
													return null;
												}
											})}
										</TextField>
										<RiskTypePicker
											value={formik.values.riskTypeId}
											onChange={(value) => handleFieldChange('riskTypeId', value)}
											list={riskTypes}
											loading={areRiskDefinitionsLoading}
											disabled={Object.keys(riskTypes).length === 0}
											error={Boolean(formik.errors.riskTypeId)}
											helperText={formik.touched.riskTypeId && formik.errors.riskTypeId}
										/>
										<RiskCategoryPicker
											value={formik.values.riskCategoryId}
											onChange={(value) => handleFieldChange('riskCategoryId', value)}
											list={riskCategories}
											loading={areRiskDefinitionsLoading}
											disabled={Object.keys(riskTypes).length === 0}
											error={Boolean(formik.errors.riskCategoryId)}
											helperText={formik.touched.riskCategoryId && formik.errors.riskCategoryId}
										/>
										<RiskAppetitePicker
											value={formik.values.appetite || ''}
											onChange={(value) => handleFieldChange('appetite', value)}
											disabled={formik.values.riskCategoryId === 0}
											riskCategory={riskCategory}
											error={Boolean(formik.errors.appetite)}
											helperText={formik.touched.appetite && formik.errors.appetite}
										/>
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
										<TextField
											label="Description"
											name="description"
											multiline
											rows={10}
											value={formik.values.description}
											onChange={handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.description && Boolean(formik.errors.description)}
											helperText={formik.touched.description && formik.errors.description}
											sx={{ margin: (theme) => theme.spacing(1) }}
										/>
										<PillarsPicker
											value={formik.values.strategicPillars || []}
											onChange={(value) => handleFieldChange('strategicPillars', value)}
											error={Boolean(formik.errors.strategicPillars)}
											helperText={formik.touched.strategicPillars && formik.errors.strategicPillars}
											disabled={false}
										/>
									</Box>
								</Box>
								<LinkedPrincipalRisksPicker
									value={formik.values.linkedPrincipalRisks || []}
									onChange={(value) => handleFieldChange('linkedPrincipalRisks', value)}
									disabled={false}
									error={Boolean(formik.errors.linkedPrincipalRisks)}
									helperText={formik.touched.linkedPrincipalRisks && formik.errors.linkedPrincipalRisks}
								/>
							</Box>
						)}
						{activeStep === 1 && (
							<RiskCatalogueControlsInput
								type="Control"
								data={riskControls}
								onChange={(data) => setRiskControls(data)}
								isLoading={false}
							/>
						)}
						{activeStep === 2 && (
							<RiskCatalogueControlsInput
								type="Mitigation"
								data={riskMitigations}
								onChange={(data) => setRiskMitigations(data)}
								isLoading={false}
							/>
						)}
						{activeStep === 3 && (
							<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
								<TextField
									label="Sources of assurance"
									name="controlsAssuranceSources"
									multiline
									fullWidth
									rows={4}
									value={formik.values.controlsAssuranceSources}
									onChange={handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.controlsAssuranceSources && Boolean(formik.errors.controlsAssuranceSources)}
									helperText={formik.touched.controlsAssuranceSources && formik.errors.controlsAssuranceSources}
									sx={{ margin: (theme) => theme.spacing(1) }}
								/>
							</Box>
						)}
					</form>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose(false)} variant="outlined">
					Cancel
				</Button>
				{activeStep > 0 && (
					<Button startIcon={<ArrowBack />} onClick={() => toGoPreviousStep()} variant="outlined">
						Back
					</Button>
				)}
				<Box sx={{ flex: '1 1 auto' }} />
				{activeStep < steps.length - 1 ? (
					<Button
						endIcon={<ArrowForward />}
						onClick={() => goToNextStep()}
						variant="contained"
						disabled={!formik.isValid}
					>
						Next
					</Button>
				) : (
					<Button
						startIcon={<Save />}
						onClick={() => {
							formik.submitForm();
						}}
						variant="contained"
						disabled={isSaving || !formik.isValid}
					>
						{catalogueItem ? 'Save catalogue item' : 'Add catalogue item'}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
