import { IApiRiskCategory, IApiRiskCategoryUsage } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getLiveCategories() {
	return Api.get<IApiRiskCategory[]>('risk-categories');
}

export function getDisabledCategories() {
	return Api.get<IApiRiskCategory[]>('risk-categories/disabled');
}

export function getUsageForCategory(riskCategoryId: number) {
	return Api.get<IApiRiskCategoryUsage>(`risk-categories/${riskCategoryId}/usage`);
}

export function saveCategory(
	riskCategoryId: number,
	data: Partial<
		IApiRiskCategory & {
			disabled: boolean;
		}
	>,
) {
	return Api.patch<void>(`risk-categories/${riskCategoryId}`, data);
}

export function createCategory(
	data: Partial<
		IApiRiskCategory & {
			disabled: boolean;
		}
	>,
) {
	return Api.post<void>('risk-categories', data);
}

export function deleteCategory(riskCategoryId: number) {
	return Api.delete_<void>(`risk-categories/${riskCategoryId}`);
}

export function disableCategory(riskCategoryId: number) {
	return Api.patch<void>(`risk-categories/${riskCategoryId}`, { disabled: true });
}

export function enableCategory(riskCategoryId: number) {
	return Api.patch<void>(`risk-categories/${riskCategoryId}`, { disabled: false });
}
