import { useEffect, useState } from 'react';
import { Box, Input, Typography } from '@mui/material';
import { IApiRiskScoreUpsert } from '@mitie/risk-register-api-types';

interface IRiskScoreDirectInputProps {
	value: IApiRiskScoreUpsert | null;
	label: string;
	onChange: (value: IApiRiskScoreUpsert) => void;
}

export default function RiskScoreDirectInput({ value, label, onChange }: IRiskScoreDirectInputProps) {
	const [likelihood, setLikelihood] = useState(value ? String(value.likelihood) : '');
	const [impact, setImpact] = useState(value ? String(value.impact) : '');
	const backgroundColor = value
		? value.score < 5
			? '#00B050'
			: value.score < 11
			? '#FFFF00'
			: value.score < 16
			? '#FFC000'
			: '#FF0000'
		: '#CCCCCC';
	const color = 'black';
	const size = '48px';

	useEffect(() => {
		if (likelihood && impact) {
			const likelihoodNumber = Number(likelihood);
			const impactNumber = Number(impact);

			if (
				Number.isInteger(likelihoodNumber) &&
				likelihoodNumber > 0 &&
				likelihoodNumber < 6 &&
				Number.isInteger(impactNumber) &&
				impactNumber > 0 &&
				impactNumber < 6
			) {
				const score = likelihoodNumber * impactNumber;
				onChange({
					risk_score_id: value?.risk_score_id,
					likelihood: likelihoodNumber,
					impact: impactNumber,
					score,
					risk_score_impacts: [],
				});
			}
		}
	}, [likelihood, impact]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				border: (theme) =>
					`1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
				borderRadius: '8px',
				margin: (theme) => theme.spacing(1),
				padding: (theme) => theme.spacing(1),
			}}
		>
			<Typography variant="subtitle2">{label}</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box
					sx={{
						borderRadius: '50%',
						marginRight: '8px',
						marginTop: '8px',
						flexShrink: 0,
						color,
						backgroundColor,
						width: size,
						height: size,
					}}
				>
					<Typography
						sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', cursor: 'default', lineHeight: size }}
					>
						{value ? value.score : '?'}
					</Typography>
				</Box>
				<Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="body2" sx={{ flexGrow: 1 }}>
							Likelihood:
						</Typography>
						<Input
							type="number"
							value={likelihood}
							onChange={(e) => setLikelihood(e.target.value)}
							inputProps={{ min: 1, max: 5 }}
							sx={{ width: '30px', marginLeft: (theme) => theme.spacing(1) }}
						/>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="body2" sx={{ flexGrow: 1 }}>
							Consequence:
						</Typography>
						<Input
							type="number"
							value={impact}
							onChange={(e) => setImpact(e.target.value)}
							inputProps={{ min: 1, max: 5 }}
							sx={{ width: '30px', marginLeft: (theme) => theme.spacing(1) }}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
