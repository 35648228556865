import { useState } from 'react';
import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';

import * as RiskCatalogueApi from 'api/riskCatalogue';
import PropertyDisplay from './PropertyDisplay';
import CreateOrUpdateCatalogueItemDialog from './CreateOrUpdateCatalogueItemDialog';
import ConfirmDialog from './ConfirmDialog';
import useAlerts from 'hooks/useAlerts';

interface ICatalogueItemCardProps {
	catalogueItem: IApiRiskCatalogueItem;
	onChange: () => void;
}

export default function CatalogueItemCard({ catalogueItem, onChange }: ICatalogueItemCardProps) {
	const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
	const { addAlert } = useAlerts();

	return (
		<Card
			sx={{
				margin: (theme) => theme.spacing(1),
				width: '400px',
			}}
		>
			<Box sx={{ margin: (theme) => theme.spacing(1) }}>
				<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: (theme) => theme.spacing(1) }}>
					<Typography
						component="div"
						variant="h6"
						sx={{ flexGrow: 1, marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(1) }}
					>
						{catalogueItem.name}
					</Typography>
				</Box>
				<Box sx={{ marginLeft: (theme) => theme.spacing(1) }}>
					<PropertyDisplay name="Register type" compact width="120px">
						<Typography>{catalogueItem.risk_register_type.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Lifecycle status" compact width="120px">
						<Typography>{catalogueItem.risk_register_lifecycle_status.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Risk type" compact width="120px">
						<Typography>{catalogueItem.risk_type.name}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Risk category" compact width="120px">
						<Typography>{catalogueItem.risk_category.name}</Typography>
					</PropertyDisplay>
				</Box>
			</Box>
			<CardActions>
				<Box sx={{ flexGrow: 1 }} />
				<Button startIcon={<Edit />} onClick={() => setIsEditPopupOpen(true)}>
					Edit
				</Button>
				<Button startIcon={<Delete />} onClick={() => setIsDeletePopupOpen(true)}>
					Delete
				</Button>
			</CardActions>
			{isEditPopupOpen && (
				<CreateOrUpdateCatalogueItemDialog
					catalogueItem={catalogueItem}
					onClose={(result: boolean) => {
						if (result) {
							onChange();
						}

						setIsEditPopupOpen(false);
					}}
				/>
			)}
			{isDeletePopupOpen && (
				<ConfirmDialog
					title="Confirm action"
					message={`Are you sure you want to delete the catalogue item '${catalogueItem.name}'?`}
					cancelLabel="Cancel"
					confirmLabel="Confirm"
					onClose={() => setIsDeletePopupOpen(false)}
					onConfirm={async () => {
						await RiskCatalogueApi.deleteRiskCatalogueItem(catalogueItem.risk_catalogue_id);
						addAlert('success', `The item '${catalogueItem.name}' has been deleted`);
						onChange();
					}}
				/>
			)}
		</Card>
	);
}
