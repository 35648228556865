import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import AlertsBanner from './AlertsBanner';
import HeaderBar from './HeaderBar';
import NavBar from './NavBar';
import useUser from 'hooks/useUser';
import Unauthorised from 'pages/Unauthorised';
import Unauthenticated from 'pages/Unauthenticated';
import TermsOfUse from 'pages/TermsOfUse';
import Login from 'pages/Login';
import ServerError from 'pages/ServerError';
import Unlicensed from 'pages/Unlicensed';

interface ISecuredPageProps {
	pageName: string;
	component: React.ReactNode;
}

export default function SecuredPage({ pageName, component }: ISecuredPageProps) {
	const [navBarOpen, setNavBarOpen] = useState(false);
	const { fetchProfile, authenticationStatus } = useUser();

	useEffect(() => {
		fetchProfile();
	}, []);

	switch (authenticationStatus) {
		case 'Unauthenticated':
			return <Login />;
		case 'Authenticating':
			return (
				<Box>
					<CircularProgress />
				</Box>
			);
		case 'Failed To Authenticate':
			return <Unauthenticated />;
		case 'Server Error':
			return <ServerError />;
		case 'Terms Not Accepted':
			return <TermsOfUse onTermsAccepted={() => fetchProfile()} />;
		case 'Unauthorised':
			return <Unauthorised reason="You do not have acces to Risk Safe" />;
		case 'License Expired':
		case 'No role assignment':
		case 'OK':
			if (authenticationStatus === 'No role assignment') {
				component = (
					<Unauthorised reason="You do not have access as no permission have been configured for your account for this company." />
				);
			} else if (authenticationStatus === 'License Expired') {
				component = <Unlicensed />;
			}

			return (
				<Box
					sx={{
						height: 'calc(100% - 64px)',
						width: '100%',
						marginTop: '64px',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<HeaderBar onNavBarToggle={() => setNavBarOpen((previous) => !previous)} pageName={pageName} />
					<NavBar open={navBarOpen} onClose={() => setNavBarOpen(false)} />
					<AlertsBanner />
					<Box
						sx={{
							display: 'flex',
							height: '100%',
							width: '100%',
						}}
					>
						{component}
					</Box>
				</Box>
			);
	}
}
