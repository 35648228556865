import { IApiRiskAction, IApiRiskActionCreate, RiskActionType } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function completeRiskAction(registerId: number, riskId: number, actionId: number) {
	return Api.post<void>(`risk-registers/${registerId}/risks/${riskId}/actions/${actionId}/complete`, {});
}

export function createRiskAction(registerId: number, riskId: number, data: IApiRiskActionCreate) {
	return Api.post<void>(`risk-registers/${registerId}/risks/${riskId}/actions`, data);
}

export function getLastCompletedActionForRiskAndType(registerId: number, riskId: number, actionType: RiskActionType) {
	return Api.get<IApiRiskAction | null>(`risk-registers/${registerId}/risks/${riskId}/actions/${actionType}/completed`);
}
