import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Route, Routes, Navigate } from 'react-router-dom';

import { reactPlugin } from './AppInsights';
import CustomTheme from './Theme';
import SecuredPage from './components/SecuredPage';
import { routes } from './routing/routes';
import useAlerts from 'hooks/useAlerts';

const App = () => {
	const { addAlert } = useAlerts();

	useEffect(() => {
		window.addEventListener('error', (ev) => {
			addAlert('error', ev.message);
			reactPlugin.trackException({ exception: ev.error });
		});

		window.addEventListener('unhandledrejection', (ev) => {
			addAlert('error', String(ev.reason));
			reactPlugin.trackException({ exception: new Error(ev.reason) });
		});
	}, []);

	return (
		<CustomTheme>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: (theme) => theme.palette.background.default,
					color: (theme) => theme.palette.text.primary,
				}}
			>
				<Routes>
					{routes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							element={
								route.secured ? (
									<SecuredPage pageName={route.label} component={route.component} />
								) : (
									<div style={{ width: '100%', height: '100%' }}>{route.component}</div>
								)
							}
						/>
					))}
					<Route path="/" element={<Navigate to="/risk-registers" />} />
				</Routes>
			</Box>
		</CustomTheme>
	);
};

export default App;
