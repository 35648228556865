import React from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { useNavigate } from '../routing/routing';
import { Page, routesByName } from 'routing/routes';
import useUser from 'hooks/useUser';

interface INavBarProps {
	open: boolean;
	onClose: () => void;
}

export default function NavBar({ open, onClose }: INavBarProps) {
	const navigate = useNavigate();
	const { user } = useUser();

	if (!user) {
		return null;
	}

	const pages = user.roles.includes('Administrator')
		? [
				Page.RiskRegisters,
				Page.RiskRegistersEdit,
				Page.ManagementInformation,
				Page.RiskCatalogue,
				Page.Configuration,
				Page.DeletedData,
				Page.Users,
		  ]
		: user.roles.includes('Contributor')
		? [Page.RiskRegisters, Page.RiskRegistersEdit, Page.ManagementInformation, Page.Users]
		: [Page.RiskRegisters, Page.ManagementInformation];

	return (
		<Drawer anchor="left" open={open} onClose={onClose}>
			<List>
				{pages.map((routeName) => {
					const route = routesByName[routeName];
					return (
						<ListItemButton
							key={route.path}
							onClick={() => {
								navigate(route.path);
								onClose();
							}}
						>
							<ListItemIcon>{route.icon}</ListItemIcon>
							<ListItemText primary={route.label} />
						</ListItemButton>
					);
				})}
			</List>
		</Drawer>
	);
}
