import { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import { IApiCompany, IApiCompanyUpdate } from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';

import * as CompaniesApi from '../api/companies';
import useAlerts from '../hooks/useAlerts';

interface IMiReportUpdateDialogProps {
	company: IApiCompany;
	onClose: () => void;
	onChange: () => void;
}

interface IFormData {
	mi_group_id: string | null;
	mi_dataset_id: string | null;
	mi_report_id: string | null;
}

const validationSchema = yup.object({
	mi_group_id: yup.string().uuid(),
	mi_dataset_id: yup.string().uuid(),
	mi_report_id: yup.string().uuid(),
});

export default function MiReportUpdateDialog({ company, onChange, onClose }: IMiReportUpdateDialogProps) {
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();
	const formik = useFormik<IFormData>({
		initialValues: {
			mi_group_id: company.mi_group_id,
			mi_dataset_id: company.mi_dataset_id,
			mi_report_id: company.mi_report_id,
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async ({ mi_group_id, mi_dataset_id, mi_report_id }) => {
			setIsSaving(true);

			const updateData: Partial<IApiCompanyUpdate> = {
				mi_group_id,
				mi_dataset_id,
				mi_report_id,
			};

			try {
				await CompaniesApi.saveCompany(company.company_id, updateData);
				addAlert('success', `The MI report details for company '${company.name}' have been updated`);

				onChange();
				onClose();
			} finally {
				setIsSaving(false);
			}
		},
	});

	return (
		<Dialog open={true}>
			<DialogTitle>Configure MI reporting</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '320px' }}>
					<TextField
						label="Power BI workspace (group) ID"
						value={formik.values.mi_group_id}
						onChange={(event) => formik.setFieldValue('mi_group_id', event.target.value)}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.mi_group_id)}
						helperText={formik.touched.mi_group_id && formik.errors.mi_group_id}
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
					<TextField
						label="Power BI dataset ID"
						value={formik.values.mi_dataset_id}
						onChange={(event) => formik.setFieldValue('mi_dataset_id', event.target.value)}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.mi_dataset_id)}
						helperText={formik.touched.mi_dataset_id && formik.errors.mi_dataset_id}
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
					<TextField
						label="Power BI report ID"
						value={formik.values.mi_report_id}
						onChange={(event) => formik.setFieldValue('mi_report_id', event.target.value)}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.mi_report_id)}
						helperText={formik.touched.mi_report_id && formik.errors.mi_report_id}
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					variant="contained"
					onClick={() => formik.submitForm()}
					color="primary"
					disabled={!formik.isValid || isSaving}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
