import * as React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { Close, Check, PanTool } from '@mui/icons-material';

interface IConfirmDialogProps {
	title: string;
	message: string;
	confirmLabel: string;
	rejectLabel?: string;
	cancelLabel: string;
	onClose: () => void;
	onConfirm: () => void;
	onReject?: () => void;
}

export default class ConfirmDialog extends React.Component<IConfirmDialogProps> {
	public render() {
		const { title, message, confirmLabel, cancelLabel, rejectLabel, onClose, onConfirm, onReject } = this.props;
		return (
			<Dialog open={true} onClose={onClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{ whiteSpace: 'pre-line' }}>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button startIcon={<Close />} variant="outlined" onClick={onClose} color="primary">
						{cancelLabel}
					</Button>
					<Box sx={{ flexGrow: 1 }} />
					{rejectLabel !== undefined && onReject !== undefined && (
						<Button
							startIcon={<PanTool />}
							variant="outlined"
							onClick={() => {
								onReject();
								onClose();
							}}
							color="primary"
							sx={{ marginRight: (theme) => theme.spacing(1) }}
						>
							{rejectLabel}
						</Button>
					)}
					<Button
						startIcon={<Check />}
						variant="contained"
						onClick={() => {
							onConfirm();
							onClose();
						}}
						color="primary"
					>
						{confirmLabel}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
