import React, { useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, LinearProgress, NativeSelect, Typography } from '@mui/material';
import { IApiRiskCatalogueItem, IApiRiskRegisterTypeWithLifecycleStatuses } from '@mitie/risk-register-api-types';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import useAlerts from 'hooks/useAlerts';
import * as RiskCatalogueApi from 'api/riskCatalogue';
import * as RiskRegisterTypesApi from '../api/riskRegisterTypes';
import CreateOrUpdateCatalogueItemDialog from 'components/CreateOrUpdateCatalogueItemDialog';
import CatalogueItemCard from 'components/CatalogueItemCard';
import TextFilterInput from 'components/TextFilterInput';

export default function RiskCatalogue() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'ManageRiskCataloguePage');
	const [categoryFilter, setCategoryFilter] = useState<number>(0);
	const [textFilter, setTextFilter] = useState<string>('');
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [catalogueItems, setCatalogueItems] = useState<IApiRiskCatalogueItem[]>([]);
	const [displayedItems, setDisplayedItems] = useState<IApiRiskCatalogueItem[]>([]);
	const [filterOptions, setFilterOptions] = useState<IApiRiskRegisterTypeWithLifecycleStatuses[]>([]);
	const [areTypesLoading, setAreTypesLoading] = useState(false);
	const { addAlert } = useAlerts();

	useEffect(() => {
		loadCatalogue();
		loadRegisterTypes();
	}, []);

	useEffect(() => {
		// Client side filtering
		let filtered = catalogueItems;

		if (categoryFilter !== 0) {
			filtered = catalogueItems.filter(
				({ risk_register_lifecycle_status: { risk_register_lifecycle_status_id } }) =>
					risk_register_lifecycle_status_id === categoryFilter,
			);
		}

		if (textFilter) {
			const searchTerm = textFilter.toLowerCase();

			filtered = filtered.filter(({ name, description, risk_category, risk_type }) => {
				const toSearchList = [name, description, risk_category.name, risk_type.name];
				const toSearch = toSearchList.join('~').toLowerCase();
				return toSearch.includes(searchTerm);
			});
		}

		setDisplayedItems(filtered);
	}, [catalogueItems, categoryFilter, textFilter]);

	const loadCatalogue = async () => {
		setIsLoading(true);

		try {
			const data = await RiskCatalogueApi.getAll();
			setCatalogueItems(data);
		} finally {
			setIsLoading(false);
		}
	};

	const loadRegisterTypes = async () => {
		setAreTypesLoading(true);

		try {
			const data = await RiskRegisterTypesApi.getAll();
			setFilterOptions(data);
		} catch {
			addAlert('error', 'Failed to load the list of register types');
		} finally {
			setAreTypesLoading(false);
		}
	};

	componentTracking();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}`,
					borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
				}}
			>
				<Box sx={{ marginRight: (theme) => theme.spacing(2), flexGrow: 1, display: 'flex' }}>
					<Button
						startIcon={<Add />}
						variant="contained"
						onClick={() => {
							setIsPopupOpen(true);
						}}
					>
						Add catalogue item...
					</Button>
					<Box sx={{ flexGrow: 1 }}></Box>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ marginRight: (theme) => theme.spacing(2), display: 'flex', alignItems: 'center' }}>
							<Typography>Category filter: </Typography>
							<NativeSelect
								value={categoryFilter}
								onChange={(event) => setCategoryFilter(Number(event.target.value))}
								variant="standard"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
								disabled={areTypesLoading}
							>
								<option value={0}>View all</option>
								{filterOptions.map((type) => {
									if (type.lifecycle_statuses) {
										return (
											<React.Fragment key={type.risk_register_type_id}>
												<option disabled>{type.name}</option>
												{type.lifecycle_statuses.map((status) => (
													<option
														value={status.risk_register_lifecycle_status_id}
														key={status.risk_register_lifecycle_status_id}
													>
														{status.name}
													</option>
												))}
											</React.Fragment>
										);
									} else {
										return null;
									}
								})}
							</NativeSelect>
						</Box>
						<TextFilterInput
							label="Filter items"
							value={textFilter}
							onChange={(newTextFilter) => setTextFilter(newTextFilter)}
						/>
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexWrap: 'wrap', overflow: 'auto', margin: (theme) => theme.spacing(1) }}>
				{isLoading && <LinearProgress />}
				{displayedItems.map((item) => (
					<CatalogueItemCard
						key={item.risk_catalogue_id}
						catalogueItem={item}
						onChange={() => {
							loadCatalogue();
						}}
					/>
				))}
			</Box>
			{isPopupOpen && (
				<CreateOrUpdateCatalogueItemDialog
					onClose={async (result: boolean) => {
						if (result) {
							loadCatalogue();
						}

						setIsPopupOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
