import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';
import { Archive, Edit } from '@mui/icons-material';
import { FolderMove } from 'mdi-material-ui';

import * as RiskRegistersApi from '../api/riskRegisters';
import ConfirmDialog from './ConfirmDialog';
import PropertyDisplay from './PropertyDisplay';
import RiskRegisterEditDialog from './RiskRegisterEditDialog';
import RiskRegisterSelectDialog from './RiskRegisterSelectDialog';
import PersonsList from './PersonsList';

interface IRiskRegisterDetailsProps {
	list: IApiRiskRegister[];
	register: IApiRiskRegister;
	parents: IApiRiskRegister[] | null;
	onChange: () => void;
}

export default function RiskRegisterDetails({ list, register, parents, onChange }: IRiskRegisterDetailsProps) {
	const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
	const [moveDialogOpen, setMoveDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [areRisksLoading, setAreRisksLoading] = useState(false);
	const [areOwnersLoading, setAreOwnersLoading] = useState(false);
	const [risksCount, setRisksCount] = useState<number | null>(null);
	const [liveRisksCount, setLiveRisksCount] = useState<number | null>(null);
	const [owners, setOwners] = useState<string>('');

	useEffect(() => {
		loadRisks();
		loadOwners();
	}, [register]);

	const loadRisks = async () => {
		setAreRisksLoading(true);

		try {
			const list = await RiskRegistersApi.getRisksForRegister(register.risk_register_id);

			setRisksCount(list.length);
			setLiveRisksCount(list.filter((r) => r.state === 'Live').length);
		} finally {
			setAreRisksLoading(false);
		}
	};

	const loadOwners = async () => {
		setAreOwnersLoading(true);

		try {
			const list = await RiskRegistersApi.getRegisterOwners(register.risk_register_id);

			setOwners(list.join(','));
		} finally {
			setAreOwnersLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
					padding: (theme) => theme.spacing(1),
					'& button': {
						marginLeft: (theme) => theme.spacing(1),
					},
				}}
			>
				<Typography variant="h6" sx={{ margin: (theme) => `0 ${theme.spacing(2)}` }}>
					{register.name}
				</Typography>
				{register.permissions.includes('update-register') && (
					<Button variant="outlined" onClick={() => setUpdateDialogOpen(true)} startIcon={<Edit />}>
						Edit register...
					</Button>
				)}
				{register.permissions.includes('move-register') && (
					<Button variant="outlined" onClick={() => setMoveDialogOpen(true)} startIcon={<FolderMove />}>
						Change parent...
					</Button>
				)}
				{register.permissions.includes('delete-register') && (
					<Button variant="outlined" onClick={() => setDeleteDialogOpen(true)} startIcon={<Archive />}>
						Archive register...
					</Button>
				)}
			</Box>
			<Paper
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					padding: (theme) => theme.spacing(2),
					margin: (theme) => theme.spacing(2),
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<PropertyDisplay name="Parent register">
						<Typography>
							{parents && parents.length ? parents[parents.length - 1].name : 'None (top level register)'}
						</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Type">
						<Typography>{register.risk_register_type.name}</Typography>
					</PropertyDisplay>
					{register.risk_register_lifecycle_status && (
						<PropertyDisplay name="Lifecycle status">
							<Typography>{register.risk_register_lifecycle_status.name}</Typography>
						</PropertyDisplay>
					)}
					<PropertyDisplay name="Risks count">
						{areRisksLoading ? (
							<CircularProgress size={16} />
						) : (
							<Typography>{`${risksCount} (including ${liveRisksCount} live risks)`}</Typography>
						)}
					</PropertyDisplay>
					<PropertyDisplay name="Owners">
						{areOwnersLoading ? (
							<CircularProgress size={16} />
						) : owners.length > 0 ? (
							<PersonsList emails={owners} />
						) : (
							<Typography>None</Typography>
						)}
					</PropertyDisplay>
				</Box>
			</Paper>
			{updateDialogOpen && (
				<RiskRegisterEditDialog
					register={register}
					parent={parents ? parents[parents.length - 1] : null}
					onClose={(result) => {
						setUpdateDialogOpen(false);

						if (result) {
							onChange();
							loadRisks();
						}
					}}
				/>
			)}
			{moveDialogOpen && (
				<RiskRegisterSelectDialog
					list={list}
					currentlySelected={parents || undefined}
					onChange={async (selected: IApiRiskRegister[]) => {
						const newParentId = selected.length === 0 ? null : selected[selected.length - 1].risk_register_id;

						await RiskRegistersApi.update(register.risk_register_id, { parent_risk_register_id: newParentId });

						onChange();
					}}
					onClose={() => setMoveDialogOpen(false)}
				/>
			)}
			{deleteDialogOpen && (
				<ConfirmDialog
					title="Archive risk register?"
					message="This register and all the risk it contains will be removed"
					confirmLabel="Archive register"
					cancelLabel="Cancel"
					onClose={() => setDeleteDialogOpen(false)}
					onConfirm={async () => {
						await RiskRegistersApi.archive(register.risk_register_id);
						onChange();
					}}
				/>
			)}
		</Box>
	);
}
