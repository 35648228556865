import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	IconButton,
	LinearProgress,
	Link,
	List,
	ListItem,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { IApiUser, IApiUserAdmin } from '@mitie/risk-register-api-types';
import { RemoveCircleOutline, Delete, Undo, Save, Add } from '@mui/icons-material';
import { useFormik } from 'formik';

import * as UsersAdminApi from '../api/usersAdmin';
import ConfirmDialog from './ConfirmDialog';
import useAlerts from 'hooks/useAlerts';
import { useNavigate } from 'routing/routing';
import PropertyDisplay from './PropertyDisplay';
import { encodeStringForUrl, formatDate } from 'utils';
import { Page } from 'routing/routes';
import AddCompanyToUserDialog from './AddCompanyToUserDialog';

interface IUserAdminProps {
	user: IApiUser;
	onChange: () => void;
}

export default function UserAdmin({ user, onChange }: IUserAdminProps) {
	const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [userDetails, setUserDetails] = useState<IApiUserAdmin>();
	const [isAddCompanyDialogOpen, setIsAddCompanyDialogOpen] = useState(false);
	const { addAlert } = useAlerts();
	const navigate = useNavigate();
	const formik = useFormik<{ superuser: boolean; companies: { company_id: string; name: string }[] }>({
		initialValues: { superuser: false, companies: [] },
		onSubmit: async (data) => {
			setIsSaving(true);

			try {
				await UsersAdminApi.updateUser(user.user_id, data);

				loadUserDetails();
				onChange();
			} finally {
				setIsSaving(false);
			}
		},
	});

	useEffect(() => {
		if (userDetails) {
			const { superuser, companies } = userDetails;
			formik.resetForm({ values: { superuser, companies } });
		}
	}, [userDetails]);

	// Fetch registers list
	useEffect(() => {
		loadUserDetails();
	}, []);

	useEffect(() => {
		loadUserDetails();
	}, [user.user_id]);

	const loadUserDetails = async () => {
		setIsLoading(true);

		try {
			const data = await UsersAdminApi.getUser(user.user_id);
			setUserDetails(data);
		} finally {
			setIsLoading(false);
		}
	};

	const addCompany = (newCompany: { company_id: string; name: string }) => {
		const companies = formik.values.companies.slice();
		companies.push(newCompany);
		formik.setFieldValue('companies', companies);
	};

	const removeCompany = (index: number) => {
		const companies = [...formik.values.companies.slice(0, index), ...formik.values.companies.slice(index + 1)];
		formik.setFieldValue('companies', companies);
	};

	return (
		<Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
			<Box sx={{ display: 'flex', alignItems: 'start', margin: (theme) => theme.spacing(2) }}>
				<Box>
					<Typography variant="h5">{user.name}</Typography>
					<Typography variant="subtitle1">{user.user_id}</Typography>
				</Box>
				<Button
					startIcon={<Delete />}
					variant="outlined"
					onClick={() => setRemoveConfirmDialogOpen(true)}
					sx={{ marginLeft: (theme) => theme.spacing(2) }}
				>
					Remove user
				</Button>
			</Box>
			{isLoading && <LinearProgress />}
			{userDetails && (
				<Box>
					<Box sx={{ margin: (theme) => theme.spacing(2) }}>
						<PropertyDisplay name="Authentication provider">
							<Typography>
								{userDetails.authentication_provider.name} ({userDetails.authentication_provider.type})
							</Typography>
						</PropertyDisplay>
						<PropertyDisplay name="Is super user?">
							<Checkbox
								checked={!!formik.values.superuser}
								onChange={(event) => formik.setFieldValue('superuser', event.target.checked)}
								sx={{
									padding: 0,
									marginLeft: '-4px',
								}}
							/>
						</PropertyDisplay>
						<PropertyDisplay name="Has accepted T&Cs?">
							<Typography>
								{userDetails.has_accepted_terms && userDetails.terms_accepted_on
									? `Yes (on ${formatDate(userDetails.terms_accepted_on)})`
									: 'No'}
							</Typography>
						</PropertyDisplay>
					</Box>
					<Divider />
					<Box
						sx={{ margin: (theme) => theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'start' }}
					>
						<Typography variant="h6">Customers</Typography>
						<Typography gutterBottom>This user has access to the following companies.</Typography>
						<Typography gutterBottom>
							If you want to change which risk registers this user has access to within each company, you will need to
							go the{' '}
							<Link
								href="#"
								underline="hover"
								onClick={() => navigate(Page.Users, { user: encodeStringForUrl(user.user_id) })}
							>
								user management page
							</Link>{' '}
							for that company.
						</Typography>
						<List
							sx={{
								border: (theme) => `1px solid ${theme.palette.divider}`,
								margin: (theme) => theme.spacing(2),
							}}
						>
							{formik.values.companies.map(({ company_id, name }, index) => (
								<ListItem
									key={company_id}
									secondaryAction={
										formik.values.companies.length > 1 ? (
											<Tooltip title="Remove access for this user to this company" placement="right">
												<IconButton onClick={() => removeCompany(index)}>
													<RemoveCircleOutline />
												</IconButton>
											</Tooltip>
										) : null
									}
								>
									<ListItemText primary={name} sx={{ marginRight: (theme) => theme.spacing(2) }} />
								</ListItem>
							))}
							<ListItem>
								<Button startIcon={<Add />} variant="outlined" onClick={() => setIsAddCompanyDialogOpen(true)}>
									Add...
								</Button>
							</ListItem>
						</List>
					</Box>
					<Box sx={{ display: 'flex', margin: (theme) => theme.spacing(2) }}>
						<Button
							startIcon={<Undo />}
							onClick={() => formik.resetForm()}
							disabled={isSaving || !formik.dirty}
							variant="outlined"
						>
							Discard changes
						</Button>
						<Box sx={{ flexGrow: 1 }} />
						<Button
							startIcon={<Save />}
							onClick={() => formik.submitForm()}
							disabled={isSaving || !formik.dirty}
							variant="contained"
						>
							Save
						</Button>
					</Box>
				</Box>
			)}
			{isAddCompanyDialogOpen && formik.values.companies && (
				<AddCompanyToUserDialog
					currentCompanies={formik.values.companies}
					onAdd={addCompany}
					onClose={() => setIsAddCompanyDialogOpen(false)}
				/>
			)}
			{removeConfirmDialogOpen && (
				<ConfirmDialog
					title="Confirm removing user"
					message={`Are you sure you want to remove user '${user.name}' from Risk Safe?`}
					cancelLabel="Cancel"
					confirmLabel="Remove"
					onClose={() => setRemoveConfirmDialogOpen(false)}
					onConfirm={async () => {
						await UsersAdminApi.deleteUser(user.user_id);
						addAlert('success', `The user ${user.name} has been removed`);
						onChange();
						navigate(null, { page: 'users' });
					}}
				/>
			)}
		</Box>
	);
}
