import { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Close, Save } from '@mui/icons-material';

import * as CompaniesApi from '../api/companies';
import useAlerts from '../hooks/useAlerts';

interface ICompanyRenameDialogProps {
	companyId: string;
	currentName: string;
	onClose: () => void;
	onChange: () => void;
}

export default function CompanyRenameDialog({ companyId, currentName, onChange, onClose }: ICompanyRenameDialogProps) {
	const { addAlert } = useAlerts();
	const [companyName, setCompanyName] = useState(currentName);

	const save = async () => {
		try {
			await CompaniesApi.saveCompany(companyId, { name: companyName });
			addAlert('success', `The company '${currentName}' has been renamed to '${companyName}'`);
			onChange();
			onClose();
		} catch {
			addAlert('error', 'Failed to update the company name');
		}
	};

	return (
		<Dialog open={true}>
			<DialogTitle>Change company name</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<TextField value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					variant="contained"
					onClick={save}
					color="primary"
					disabled={!companyName.length || companyName === currentName}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
