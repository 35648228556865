import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

import useDebounce from 'hooks/useDebounce';

interface ITextFilterInputMobileProps {
	label: string;
	value: string;
	onChange: (value: string) => void;
}

export default function TextFilterInputMobile({ label, value, onChange }: ITextFilterInputMobileProps) {
	const [filter, setFilter] = useState<string>('');
	const debouncedFilter = useDebounce(filter, 1000);

	useEffect(() => {
		setFilter(value);
	}, [value]);

	useEffect(() => {
		onChange(debouncedFilter);
	}, [debouncedFilter, onChange]);

	return (
		<TextField
			type="search"
			variant="standard"
			label={label}
			value={filter}
			onChange={(e) => setFilter(e.target.value)}
			sx={{ marginTop: (theme) => theme.spacing(1) }}
		/>
	);
}
