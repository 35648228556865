import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { IApiRisk, RiskIsInsurable, IApiRiskInsuranceReviewCreate } from '@mitie/risk-register-api-types';
import { Check, Close } from '@mui/icons-material';

import * as RisksRegisterApi from '../api/riskRegisters';
import { Box } from '@mui/system';

interface IFormData {
	insurable: RiskIsInsurable;
	comment: string;
}

const validationSchema = yup.object({
	insurable: yup.string().required(),
	comment: yup.string().max(255),
});

interface IRiskInsuranceReviewDialogProps {
	risk: IApiRisk;
	onClose: (result: boolean) => void;
}

export default function RiskInsuranceReviewDialog({ risk, onClose }: IRiskInsuranceReviewDialogProps) {
	const [isSaving, setIsSaving] = useState(false);
	const formik = useFormik<IFormData>({
		initialValues: {
			insurable: 'No',
			comment: '',
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async ({ insurable, comment }) => {
			setIsSaving(true);

			try {
				const riskReviewData: IApiRiskInsuranceReviewCreate = {
					risk_id: risk.risk_id,
					insurable,
					comment,
				};

				await RisksRegisterApi.completeInsuranceReview(risk.risk_register_id, risk.risk_id, riskReviewData);

				onClose(true);
			} finally {
				setIsSaving(false);
			}
		},
	});

	return (
		<Dialog open={true} onClose={() => onClose(false)} fullWidth maxWidth="md">
			<DialogTitle>{`Risk insurance review: ${risk.name}`}</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
				<form onSubmit={formik.handleSubmit}>
					<ToggleButtonGroup
						value={formik.values.insurable}
						exclusive
						onChange={(event, value) => formik.setFieldValue('insurable', value as RiskIsInsurable)}
					>
						<ToggleButton value="No">Not insurable</ToggleButton>
						<ToggleButton value="Yes">Insurable</ToggleButton>
						<ToggleButton value="Partially">Partially insurable</ToggleButton>
					</ToggleButtonGroup>
					<TextField
						label="Review comment"
						name="comment"
						multiline
						fullWidth
						rows={4}
						value={formik.values.comment}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.comment && Boolean(formik.errors.comment)}
						helperText={formik.touched.comment && formik.errors.comment}
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose(false)} variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					onClick={() => formik.submitForm()}
					autoFocus
					variant="contained"
					disabled={isSaving || !formik.isValid}
				>
					Complete insurance review
				</Button>
			</DialogActions>
		</Dialog>
	);
}
