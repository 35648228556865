import {
	IApiRiskRegisterTypeCreate,
	IApiRiskRegisterTypeUpdate,
	IApiRiskRegisterTypeWithLifecycleStatuses,
	IApiRiskRegisterWithoutPermissions,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAll() {
	return Api.get<IApiRiskRegisterTypeWithLifecycleStatuses[]>('risk-register-types');
}

export function getAllIncludingDisabled() {
	return Api.get<IApiRiskRegisterTypeWithLifecycleStatuses[]>('risk-register-types/all');
}

export function getUsageForRegisterType(riskRegisterTypeId: number) {
	return Api.get<IApiRiskRegisterWithoutPermissions[]>(`risk-register-types/${riskRegisterTypeId}/usage`);
}

export function createRegisterType(data: IApiRiskRegisterTypeCreate) {
	return Api.post<void>('risk-register-types', data);
}

export function updateRegisterType(id: number, data: IApiRiskRegisterTypeUpdate) {
	return Api.patch<void>(`risk-register-types/${id}`, data);
}

export function deleteRegisterType(id: number) {
	return Api.delete_<void>(`risk-register-types/${id}`);
}

export function disableRegisterType(id: number) {
	return Api.patch<void>(`risk-register-types/${id}`, { disabled: true });
}

export function enableRegisterType(id: number) {
	return Api.patch<void>(`risk-register-types/${id}`, { disabled: false });
}
