import { RefObject, useEffect, useState } from 'react';

export default function useInfiniteScroll(
	component: RefObject<HTMLElement>,
	fetchData: (page: number) => Promise<number>,
	fetchSize: number,
) {
	const [doneFetching, setDoneFetching] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [hasScrollbar, setHasScrollbar] = useState(false);
	const [lastPage, setLastPage] = useState(0);
	const [scrollBottom, setScrollBottom] = useState(false);

	useEffect(() => {
		// Load first page on init
		doFetchData(1);
	}, []);

	useEffect(() => {
		// Fetch next page if previous finished loading, there is no scroll bar, and there is more data to load
		if (lastPage > 0 && !doneFetching && !isFetching && (!hasScrollbar || scrollBottom)) {
			doFetchData(lastPage + 1);
		}
	}, [lastPage, hasScrollbar, doneFetching, scrollBottom]);

	useEffect(() => {
		// Updade "hasScrollbar" after data has been loaded
		if (!isFetching) {
			const scrollElement = component.current;

			if (!scrollElement) {
				return;
			}

			setHasScrollbar(scrollElement.scrollHeight > scrollElement.getBoundingClientRect().height + 1); // the "+1" is necessary due to rounding issues observed in Chrome
		}
	}, [isFetching]);

	useEffect(() => {
		// Updade "scrollBottom" when user scrolls
		const scrollElement = component.current;

		if (!scrollElement) {
			return;
		}
		const scrollHandler = () => {
			const isAtBottom = scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight;
			setScrollBottom(isAtBottom);
		};

		scrollElement.addEventListener('scroll', scrollHandler);

		return () => {
			scrollElement.removeEventListener('scroll', scrollHandler);
		};
	}, [component]);

	const doFetchData = async (page: number) => {
		setIsFetching(true);

		const fetchCount = await fetchData(page);

		if (fetchCount < fetchSize) {
			setDoneFetching(true);
		}

		if (page > lastPage) {
			setLastPage(page);
		}

		setIsFetching(false);
	};

	return {
		isFetching,
	};
}
