import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiRiskCategory } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as RiskCategoriesApi from 'api/riskCategories';
import RiskCategory from './RiskCategory';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import AddRiskCategoryDialog from './AddRiskCategoryDialog';

export default function RiskCategories() {
	const [selectedCategory, setSelectedCategory] = useState<IApiRiskCategory | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [liveCategories, setLiveCategories] = useState<IApiRiskCategory[]>([]);
	const [disabledCategories, setDisabledCategories] = useState<IApiRiskCategory[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedCategory(
			liveCategories.find((c) => encodeStringForUrl(c.name) === selected) ||
				disabledCategories.find((c) => encodeStringForUrl(c.name) === selected) ||
				null,
		);
	}, [disabledCategories, liveCategories, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await Promise.all([
				RiskCategoriesApi.getLiveCategories(),
				RiskCategoriesApi.getDisabledCategories(),
			]);

			setLiveCategories(data[0].sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
			setDisabledCategories(data[1].sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto' }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						Add risk category
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{liveCategories.map((riskCategory) => (
					<ListItemButton
						key={riskCategory.risk_category_id}
						selected={selectedCategory?.risk_category_id === riskCategory.risk_category_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskCategory.name) })}
					>
						<ListItemText primary={riskCategory.name} />
					</ListItemButton>
				))}
				{disabledCategories.map((riskCategory) => (
					<ListItemButton
						key={riskCategory.risk_category_id}
						selected={selectedCategory?.risk_category_id === riskCategory.risk_category_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskCategory.name) })}
					>
						<ListItemText primary={riskCategory.name} secondary="Disabled" />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{selectedCategory && <RiskCategory item={selectedCategory} onChange={loadData} isDataLoading={isLoading} />}
			{isAddDialogOpen && (
				<AddRiskCategoryDialog
					onClose={(success: boolean) => {
						if (success) {
							loadData();
						}

						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
