import { BrowserRouter as Router } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/en-GB';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

import App from './App';
import UserProvider from 'providers/UserProvider';
import UsersProvider from 'providers/UsersProvider';
import AlertsProvider from 'providers/AlertsProvider';
import NotificationsProvider from 'providers/NotificationsProvider';
import ColorModeProvider from 'providers/ColorModeProvider';

const AppContainer = () => (
	<AppInsightsContext.Provider value={reactPlugin}>
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
			<ColorModeProvider>
				<UserProvider>
					<UsersProvider>
						<AlertsProvider>
							<NotificationsProvider>
								<Router>
									<App />
								</Router>
							</NotificationsProvider>
						</AlertsProvider>
					</UsersProvider>
				</UserProvider>
			</ColorModeProvider>
		</LocalizationProvider>
	</AppInsightsContext.Provider>
);

export default AppContainer;
