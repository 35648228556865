import { IApiRisk, IApiRiskWithRegister } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAll() {
	return Api.get<IApiRisk[]>('principal-risks');
}

export function getRisksLinkedToPrincipalRisk(principalRiskId: number) {
	return Api.get<IApiRiskWithRegister[]>(`principal-risks/${principalRiskId}/linked-risks`);
}
