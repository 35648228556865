import { ErrorWithHttpCode } from '../utils';

export function get<T>(url: string, params: { [name: string]: any } = {}) {
	const strParams = Object.keys(params).reduce((acc: string, name) => {
		const value = params[name];

		if (acc !== '') {
			acc += '&';
		}

		acc += name + '=';

		if (value instanceof Date) {
			acc += value.toISOString();
		} else if (value instanceof Array) {
			acc += encodeURIComponent(JSON.stringify(value));
		} else {
			acc += encodeURIComponent(value);
		}

		return acc;
	}, '');

	return runApiRequest<T>(params ? `${url}?${strParams}` : url, {
		method: 'GET',
		headers: {},
	});
}

export function post<T>(url: string, postData: any = {}) {
	return runApiRequest<T>(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(postData),
	});
}

export function patch<T>(url: string, postData: any = {}) {
	return runApiRequest<T>(url, {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(postData),
	});
}

export function put<T>(url: string, postData: any = {}) {
	return runApiRequest<T>(url, {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(postData),
	});
}

export function delete_<T>(url: string, params: { [name: string]: any } = {}) {
	const strParams = Object.keys(params).reduce((acc: string, name) => {
		const value = params[name];

		if (acc !== '') {
			acc += '&';
		}

		acc += name + '=';

		if (value instanceof Date) {
			acc += value.toISOString();
		} else if (value instanceof Array) {
			acc += encodeURIComponent(JSON.stringify(value));
		} else {
			acc += encodeURIComponent(value);
		}

		return acc;
	}, '');

	return runApiRequest<T>(params ? `${url}?${strParams}` : url, {
		method: 'DELETE',
		headers: {},
	});
}

async function runApiRequest<T>(url: string, options: RequestInit): Promise<T> {
	if (!options.headers) {
		options.headers = {};
	}

	options.credentials = 'include';

	const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);

	return parseResponse(response);
}

async function parseResponse(response: Response) {
	let json: any;

	try {
		json = await response.json();
	} catch (e) {
		json = undefined;
	}

	const status = response.status;
	if (status < 200 || status > 299) {
		const error: string =
			typeof json?.error === 'string'
				? json.error
				: typeof json?.message === 'string'
				? json.message
				: response.statusText;

		throw new ErrorWithHttpCode(status, error);
	} else {
		return json;
	}
}
