import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import {
	Box,
	Button,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Theme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { SxProps } from '@mui/system';

export interface IActionButtonProps {
	label: string;
	icon?: React.ReactNode;
	onClick: () => void;
}

interface IActionButtonsProps {
	items: IActionButtonProps[];
	sx?: SxProps<Theme>;
}

export default function ActionButtons({ sx, items }: IActionButtonsProps) {
	const theme = useTheme();
	const isSmallOrMedium = useMediaQuery(theme.breakpoints.down('md'));
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	return (
		<Box sx={sx}>
			{isSmallOrMedium ? (
				<Box>
					<IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
						<MoreVert />
					</IconButton>
					<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
						{items.map(({ label, icon, onClick }, index) => (
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									onClick();
								}}
								key={index}
							>
								{icon && <ListItemIcon>{icon}</ListItemIcon>}
								<ListItemText>{label}</ListItemText>
							</MenuItem>
						))}
					</Menu>
				</Box>
			) : (
				<Box sx={{ margin: (theme) => theme.spacing(1) }}>
					{items.map(({ label, icon, onClick }, index) => (
						<Button
							startIcon={icon || undefined}
							variant="outlined"
							onClick={onClick}
							sx={{ marginLeft: index > 0 ? (theme) => theme.spacing(1) : 0 }}
							key={index}
						>
							{label}
						</Button>
					))}
				</Box>
			)}
		</Box>
	);
}
