import { useEffect, useState } from 'react';
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { Close, Add } from '@mui/icons-material';

import * as CompaniesApi from '../api/companies';
import { IApiCompany } from '@mitie/risk-register-api-types';

interface IAddCompanyToUserDialogProps {
	currentCompanies: { company_id: string; name: string }[];
	onAdd: (newCompany: { company_id: string; name: string }) => void;
	onClose: () => void;
}

export default function AddCompanyToUserDialog({ currentCompanies, onAdd, onClose }: IAddCompanyToUserDialogProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [companies, setCompanies] = useState<IApiCompany[]>([]);
	const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');

	useEffect(() => {
		loadCompanies();
	}, []);

	const loadCompanies = async () => {
		setIsLoading(true);

		try {
			const data = await CompaniesApi.getAllCompanies();
			const companiesToExclude = currentCompanies.map((company) => company.company_id);
			setCompanies(data.filter((company) => !companiesToExclude.includes(company.company_id)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={true} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle>Add company to user</DialogTitle>
			<DialogContent>
				<TextField
					label="Pick company from list"
					name="user_id"
					value={selectedCompanyId}
					onChange={(event) => {
						setSelectedCompanyId(event.target.value);
					}}
					select
					fullWidth
					disabled={isLoading}
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						shrink: true,
					}}
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				>
					<option value="">---Pick from list---</option>
					{companies.map(({ company_id, name }) => (
						<option key={company_id} value={company_id}>
							{name}
						</option>
					))}
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Add />}
					onClick={() => {
						if (!selectedCompanyId) {
							return;
						}

						const selectedCompany = companies.find((c) => c.company_id === selectedCompanyId);

						if (!selectedCompany) {
							return;
						}

						onAdd(selectedCompany);
						onClose();
					}}
					color="primary"
					variant="contained"
					disabled={isLoading || !selectedCompanyId}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
