import React, { useCallback, useState } from 'react';
import { IApiUserPhoto, IApiUserProfile } from '@mitie/risk-register-api-types';

import * as AuthApi from '../api/auth';
import * as UserApi from '../api/user';
import * as UserDirectoryApi from '../api/userDirectory';

export type AuthenticationStatus =
	| UserApi.UserAccessStatus
	| 'Authenticating'
	| 'Failed To Authenticate'
	| 'Server Error';

interface IUserStore {
	user?: IApiUserProfile;
	userPhoto?: IApiUserPhoto;
	authenticationStatus: AuthenticationStatus;
	setAuthenticationStatus: (newStatus: AuthenticationStatus) => void;
	fetchProfile: () => Promise<void>;
	switchCompany: (companyId: string) => void;
	logout: () => void;
}

export const UserContext = React.createContext<IUserStore>({
	authenticationStatus: 'Unauthenticated',
	setAuthenticationStatus: () => undefined,
	fetchProfile: async () => undefined,
	switchCompany: () => undefined,
	logout: () => undefined,
});

export default function UserProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [user, setUser] = useState<IApiUserProfile>();
	const [userPhoto, setUserPhoto] = useState<IApiUserPhoto>();
	const [authenticationStatus, setAuthenticationStatus] = useState<AuthenticationStatus>('Unauthenticated');

	const logout = () => {
		setUser(undefined);
		setUserPhoto(undefined);
		AuthApi.logout();
	};

	const fetchProfile = async () => {
		if (authenticationStatus === 'Authenticating') {
			return;
		}

		setAuthenticationStatus('Authenticating');

		try {
			const { status, profile } = await UserApi.getProfile();

			if (profile) {
				const companyIds = profile.companies.map(({ company_id }) => company_id);

				if (companyIds.length && !companyIds.includes(profile.selected_company.company_id)) {
					// If the user has been removed access from its selected company,
					// then switch to another one they have access to (if possible)
					await switchCompany(companyIds[0]);
					return;
				}
			}

			setAuthenticationStatus(status);
			setUser(profile);

			if (profile && profile.selected_company.has_user_directory) {
				const [photo] = await UserDirectoryApi.getPhotosByIds([profile.user_id]);

				if (photo) {
					setUserPhoto(photo);
				}
			}
		} catch (error) {
			setAuthenticationStatus('Server Error');
			throw error;
		}
	};

	const switchCompany = async (companyId: string) => {
		await UserApi.switchCompany(companyId);
		await fetchProfile();
	};

	const contextValue = {
		user,
		photo: userPhoto,
		authenticationStatus,
		setAuthenticationStatus,
		fetchProfile: useCallback(fetchProfile, [authenticationStatus]),
		switchCompany: useCallback(switchCompany, []),
		logout: useCallback(logout, []),
	};

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
