import {
	IApiAuthenticationProvider,
	IApiAuthenticationProviderCreate,
	IApiAuthenticationProviderUpdate,
	IApiUser,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAllAuthenticationProviders() {
	return Api.get<IApiAuthenticationProvider[]>('authentication-providers');
}

export function getAuthenticationProviderById(id: number) {
	return Api.get<IApiAuthenticationProvider>(`authentication-providers/${id}`);
}

export function getUsageForAuthenticationProvider(id: number) {
	return Api.get<IApiUser[]>(`authentication-providers/${id}/usage`);
}

export function saveAuthenticationProvider(id: number, data: IApiAuthenticationProviderUpdate) {
	return Api.patch<void>(`authentication-providers/${id}`, data);
}

export function createAuthenticationProvider(data: IApiAuthenticationProviderCreate) {
	return Api.post<void>('authentication-providers', data);
}

export function deleteAuthenticationProvider(id: number) {
	return Api.delete_<void>(`authentication-providers/${id}`);
}
