import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { Add, Delete, Edit, Undo } from '@mui/icons-material';
import { RiskControlType, IApiRiskControlUpdate, IApiRiskControl } from '@mitie/risk-register-api-types';
import cloneDeep from 'lodash/cloneDeep';

import AddOrUpdateRiskControlDialog from './AddOrUpdateRiskControlDialog';
import { formatDateTime } from 'utils';

interface IRiskControlsInputProps {
	type: RiskControlType;
	initialData: IApiRiskControl[];
	data: IApiRiskControlUpdate[];
	onChange: (updatedData: IApiRiskControlUpdate[]) => void;
	isLoading: boolean;
}

export default function RiskControlsInput({ type, initialData, data, onChange, isLoading }: IRiskControlsInputProps) {
	const [addRiskControlDialogOpen, setAddRiskControlDialogOpen] = useState(false);
	const [updatingRiskControl, setUpdatingRiskControl] = useState<IApiRiskControlUpdate>();
	const [workingData, setWorkingData] = useState<{ saved?: IApiRiskControl; updated: IApiRiskControlUpdate | null }[]>(
		[],
	);

	useEffect(() => {
		const updatedWorkingData: { saved?: IApiRiskControl; updated: IApiRiskControlUpdate | null }[] = [];

		for (const saved of initialData) {
			const updated = data.find((d) => d.risk_control_id === saved.risk_control_id);

			if (!updated) {
				// This item was removed
				updatedWorkingData.push({ saved, updated: null });
			} else {
				// This item is either unchanged or modified
				updatedWorkingData.push({ saved, updated });
			}
		}

		for (const updated of data) {
			if (!updated.risk_control_id) {
				// This item was added
				updatedWorkingData.push({ updated });
			}
		}

		setWorkingData(updatedWorkingData);
	}, [initialData, data]);

	const addRiskControl = ({ description, owner }: { description: string; owner: string }) => {
		const newRiskControl: IApiRiskControlUpdate = {
			type,
			description,
			owner,
		};

		onChange([...data, newRiskControl]);
	};

	const removeRiskControl = (index: number) => {
		const item = workingData[index];

		if (item) {
			if (item.saved) {
				onChange(data.filter((d) => d.risk_control_id !== item.saved!.risk_control_id));
			} else {
				// Since this item was not saved yet, the ID does not exist so we cannot use it to identify
				// the item to remove. So matches with the description instead
				// If multiple items have the same description, they will all be removed
				// There is probably a better way of doing this
				onChange(data.filter((d) => d.description !== item.updated!.description));
			}
		}
	};

	const restoreRiskControl = (index: number) => {
		const item = workingData[index];

		if (item && item.saved) {
			const indexInData = data.findIndex((d) => d.risk_control_id === item.saved!.risk_control_id);

			if (indexInData !== -1) {
				// Revert changes
				const updatedData = cloneDeep(data);
				updatedData[indexInData].description = item.saved.description;
				updatedData[indexInData].owner = item.saved.owner;
				onChange(updatedData);
			} else {
				// Restore deleted
				const updatedData = [...data];
				updatedData.push(item.saved);
				onChange(updatedData);
			}
		}
	};

	const updateRiskControl = (oldItem: IApiRiskControlUpdate, newItem: { description: string; owner: string }) => {
		const itemIndex = data.findIndex((d) => d.description === oldItem.description);

		if (itemIndex !== -1) {
			const updatedData = cloneDeep(data);
			updatedData[itemIndex].description = newItem.description;
			updatedData[itemIndex].owner = newItem.owner;
			onChange(updatedData);
		}
	};

	return (
		<Box>
			{isLoading && <LinearProgress />}
			<TableContainer
				component={Paper}
				sx={{ boxShadow: 'none', border: '1px solid rgb(224, 224, 224)', borderBottom: 0, maxHeight: '350px' }}
			>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>{type} measure</TableCell>
							<TableCell>Owners</TableCell>
							<TableCell>Date added</TableCell>
							<TableCell>Date updated</TableCell>
							<TableCell sx={{ width: '64px' }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{workingData.length === 0 ? (
							<TableRow>
								<TableCell colSpan={5}>No measure in place</TableCell>
							</TableRow>
						) : (
							workingData.map(({ saved, updated }, index) => {
								const isDeleted = updated === null;
								const isNew = !saved;
								const isUpdated =
									saved && updated && (saved.description !== updated.description || saved.owner !== updated.owner);

								return (
									<TableRow
										key={index}
										sx={{
											'&>td': {
												textDecoration: isDeleted ? 'line-through' : 'none',
												color: isUpdated || isNew || isDeleted ? 'red' : 'default',
											},
										}}
									>
										<TableCell>{updated ? updated.description : saved?.description}</TableCell>
										<TableCell>{updated ? updated.owner : saved?.owner}</TableCell>
										<TableCell>{!saved ? '-' : formatDateTime(saved.time_added)}</TableCell>
										<TableCell>
											{isUpdated ? (
												<Typography variant="body2" sx={{ color: 'red', fontStyle: 'italic' }}>
													(Updated)
												</Typography>
											) : !saved ? (
												<Typography variant="body2" sx={{ color: 'red', fontStyle: 'italic' }}>
													(New)
												</Typography>
											) : (
												<>{formatDateTime(saved.time_updated)}</>
											)}
										</TableCell>
										<TableCell>
											{!isDeleted && (
												<Tooltip title="Change">
													<IconButton
														edge="end"
														size="small"
														onClick={() => {
															setUpdatingRiskControl(updated);
															setAddRiskControlDialogOpen(true);
														}}
													>
														<Edit />
													</IconButton>
												</Tooltip>
											)}
											{isDeleted || isUpdated ? (
												<Tooltip title={isUpdated ? 'Revert' : 'Restore'}>
													<IconButton edge="end" size="small" onClick={() => restoreRiskControl(index)}>
														<Undo />
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip title="Remove">
													<IconButton edge="end" size="small" onClick={() => removeRiskControl(index)}>
														<Delete />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</TableRow>
								);
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				size="small"
				startIcon={<Add />}
				variant="outlined"
				sx={{ marginTop: '16px' }}
				onClick={() => setAddRiskControlDialogOpen(true)}
			>
				Add...
			</Button>

			{addRiskControlDialogOpen && (
				<AddOrUpdateRiskControlDialog
					data={
						updatingRiskControl !== undefined
							? {
									description: updatingRiskControl.description || '',
									owner: updatingRiskControl.owner || '',
							  }
							: undefined
					}
					type={type}
					onClose={(data) => {
						if (data) {
							if (updatingRiskControl !== undefined) {
								updateRiskControl(updatingRiskControl, data);
							} else {
								addRiskControl(data);
							}
						}

						setUpdatingRiskControl(undefined);
						setAddRiskControlDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
