import { Fragment, useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { IApiStrategicPillarWithImage } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as StrategicPillarsApi from 'api/strategicPillars';
import StrategicPillar from './StrategicPillar';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import AddPillarDialog from './AddPillarDialog';

export default function StrategicPillars() {
	const [selectedPillar, setSelectedPillar] = useState<IApiStrategicPillarWithImage | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pillars, setPillars] = useState<IApiStrategicPillarWithImage[]>([]);
	const [pillarsByType, setPillarsByType] = useState<Record<string, IApiStrategicPillarWithImage[]>>({});
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedPillar(pillars.find((c) => encodeStringForUrl(c.name) === selected) || null);
	}, [pillars, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await StrategicPillarsApi.getAll();

			setPillars(data);
			setPillarsByType(
				data.reduce((acc, cur) => {
					if (!acc[cur.type]) {
						acc[cur.type] = [cur];
					} else {
						acc[cur.type].push(cur);
					}

					return acc;
				}, {} as Record<string, IApiStrategicPillarWithImage[]>),
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						Add strategic pillar
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				<Box sx={{ flexGrow: 1, maxWidth: '300px', overflowY: 'auto' }}>
					{Object.entries(pillarsByType).map(([type, list], index) => (
						<Fragment key={index}>
							{index > 0 && <Divider />}
							<List
								component="nav"
								subheader={
									<ListSubheader key={type} sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
										{type}
									</ListSubheader>
								}
							>
								{list.map((pillar) => (
									<ListItemButton
										key={pillar.strategic_pillar_id}
										selected={selectedPillar?.strategic_pillar_id === pillar.strategic_pillar_id}
										onClick={() => navigate(null, { page, selected: encodeStringForUrl(pillar.name) })}
									>
										<ListItemText primary={pillar.name} secondary={pillar.disabled ? 'Disabled' : undefined} />
									</ListItemButton>
								))}
							</List>
						</Fragment>
					))}
				</Box>
			</Box>
			<Divider orientation="vertical" />
			{selectedPillar && <StrategicPillar item={selectedPillar} onChange={loadData} isDataLoading={isLoading} />}
			{isAddDialogOpen && (
				<AddPillarDialog
					onClose={(success: boolean) => {
						if (success) {
							loadData();
						}

						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
