import { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';

import useUser from 'hooks/useUser';
import DirectoryPersonChip from './DirectoryPersonChip';

interface IPersonsListProps {
	emails: string;
}

export default function PersonsList({ emails }: IPersonsListProps) {
	const { user } = useUser();
	const [loadFromDirectory, setLoadFromDirectory] = useState(false);

	useEffect(() => {
		if (user) {
			setLoadFromDirectory(user.selected_company.has_user_directory);
		} else {
			setLoadFromDirectory(false);
		}
	}, [user]);

	return (
		<Box
			sx={{
				'&>div': {
					marginRight: (theme) => theme.spacing(1),
				},
			}}
		>
			{emails.split(',').map((email, index) => {
				if (loadFromDirectory) {
					return <DirectoryPersonChip key={index} email={email} />;
				} else {
					return <Chip key={index} variant="outlined" size="small" label={email} />;
				}
			})}
		</Box>
	);
}
