import { useState } from 'react';
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	Link,
	ListItem,
	ListItemIcon,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AccountCircle, Logout, Settings } from '@mui/icons-material';

import ColourModePicker from './ColourModePicker';
import ColourModePickerSmall from './ColourModePickerSmall';
import useUser from 'hooks/useUser';
import { initials } from 'utils';
import CompanySelect from './CompanySelect';
import { useNavigate } from 'routing/routing';
import { Page } from 'routing/routes';

export default function UserMenu() {
	const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);
	const [isCompanyPickerOpen, setIsCompanyPickerOpen] = useState(false);
	const { user, userPhoto, logout, switchCompany } = useUser();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const openMenu = (e: React.MouseEvent<HTMLElement>) => {
		setUserMenuAnchorEl(e.currentTarget);
	};

	const closeMenu = () => {
		setUserMenuAnchorEl(null);
		setIsCompanyPickerOpen(false);
	};

	if (!user) {
		return <Avatar>?</Avatar>;
	} else {
		return (
			<>
				<IconButton sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={openMenu} color="inherit">
					{userPhoto?.image ? <Avatar src={userPhoto.image} alt="avatar" /> : <Avatar>{initials(user.name)}</Avatar>}
				</IconButton>
				<Menu
					anchorEl={userMenuAnchorEl}
					keepMounted={true}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(userMenuAnchorEl)}
					onClose={closeMenu}
				>
					<ListItem sx={{ padding: '0.5rem 1rem' }}>
						<ListItemIcon>
							<AccountCircle fontSize="large" />
						</ListItemIcon>
						<Box>
							<Typography variant="subtitle2">{user.name}</Typography>
							<Typography component="span" sx={{ marginRight: (theme) => theme.spacing(1) }}>
								Company:
							</Typography>
							{isCompanyPickerOpen ? (
								<CompanySelect
									value={user.selected_company.company_id}
									onChange={(companyId) => {
										setIsCompanyPickerOpen(false);

										if (companyId) {
											switchCompany(companyId);
										}
									}}
									list={user.companies}
								/>
							) : (
								<>
									<Typography variant="subtitle2" component="span">
										{user.selected_company.name}
									</Typography>
									{user.companies.length > 1 && !isCompanyPickerOpen && (
										<Link
											href="#"
											underline="hover"
											variant="subtitle2"
											onClick={() => setIsCompanyPickerOpen(true)}
											sx={{ marginLeft: (theme) => theme.spacing(1) }}
										>
											(Switch)
										</Link>
									)}
								</>
							)}
						</Box>
					</ListItem>
					<Divider />
					{isSmall ? <ColourModePickerSmall /> : <ColourModePicker />}
					<Divider />
					{user.superuser && (
						<MenuItem
							onClick={() => {
								closeMenu();
								navigate(Page.Administration);
							}}
						>
							<ListItemIcon>
								<Settings fontSize="small" />
							</ListItemIcon>
							Administration panel
						</MenuItem>
					)}
					<MenuItem
						onClick={() => {
							closeMenu();
							logout();
						}}
					>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Logout
					</MenuItem>
				</Menu>
			</>
		);
	}
}
