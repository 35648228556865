import { IApiRiskRegisterLifecycleStatusCreateOrUpdate } from '@mitie/risk-register-api-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close, Save } from '@mui/icons-material';

interface IAddOrUpdateLifecycleStatusDialogProps {
	item?: IApiRiskRegisterLifecycleStatusCreateOrUpdate;
	onClose: () => void;
	onSave: (newItem: IApiRiskRegisterLifecycleStatusCreateOrUpdate) => void;
}

interface IFormData {
	name: string;
	description: string;
}

const validationSchema = yup.object({
	name: yup.string().max(45, 'The name must be less than 45 characters').required(),
	description: yup.string().max(255, 'The description must be less than 45 characters').required(),
});

export default function AddOrUpdateLifecycleStatusDialog({
	item,
	onClose,
	onSave,
}: IAddOrUpdateLifecycleStatusDialogProps) {
	const formik = useFormik<IFormData>({
		initialValues: {
			name: item?.name || '',
			description: item?.description || '',
		},
		validationSchema,
		onSubmit: async ({ name, description }) => {
			const data: IApiRiskRegisterLifecycleStatusCreateOrUpdate = {
				name,
				description,
			};

			if (item) {
				data.risk_register_lifecycle_status_id = item.risk_register_lifecycle_status_id;
			}

			onSave(data);
			onClose();
		},
	});
	return (
		<Dialog open={true} onClose={onClose}>
			<DialogTitle>New lifecycle status</DialogTitle>
			<DialogContent>
				<Box>
					<TextField
						label="Lifecycle status"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
						fullWidth
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
					<TextField
						label="Description"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
						fullWidth
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} color="primary" variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					onClick={() => {
						formik.submitForm();
					}}
					color="primary"
					variant="contained"
					disabled={!formik.isValid}
					startIcon={<Save />}
				>
					{item ? 'Update' : 'Add'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
