import { useEffect, useState } from 'react';

import useUser from 'hooks/useUser';
import AddUserFromDirectoryDialog from './AddUserFromDirectoryDialog';
import AddUserManuallyDialog from './AddUserManuallyDialog';

interface IAddUserDialogProps {
	onClose: (addedUserId?: string) => void;
}

export default function AddUserDialog(props: IAddUserDialogProps) {
	const { user } = useUser();
	const [useDirectory, setUseDirectory] = useState(false);

	useEffect(() => {
		if (user) {
			setUseDirectory(user.selected_company.has_user_directory);
		} else {
			setUseDirectory(false);
		}
	}, [user]);

	return useDirectory ? <AddUserFromDirectoryDialog {...props} /> : <AddUserManuallyDialog {...props} />;
}
