import { IApiUser, IApiUserCreate } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAll() {
	return Api.get<IApiUser[]>('users');
}

export function create(data: IApiUserCreate) {
	return Api.post<IApiUserCreate>('users', data);
}

export function deleteUser(userId: string) {
	return Api.delete_<void>(`users/${userId}`);
}
