import { useState } from 'react';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { IApiRisk, IApiRiskRegister } from '@mitie/risk-register-api-types';

import * as RiskRegistersApi from '../api/riskRegisters';
import useAlerts from '../hooks/useAlerts';
import RegistersPicker from './RegistersPicker';

interface ICascadeRiskDialogProps {
	risk: IApiRisk;
	register: IApiRiskRegister;
	childrenRegisters: IApiRiskRegister[];
	onClose: (result?: boolean) => void;
}

export default function CascadeRiskDialog({ risk, register, childrenRegisters, onClose }: ICascadeRiskDialogProps) {
	const { addAlert } = useAlerts();
	const [selectedRegisters, setSelectedRegisters] = useState<IApiRiskRegister[][]>([]);
	const [includeControls, setIncludeControls] = useState(false);

	return (
		<Dialog open={true}>
			<DialogTitle>Cascade risk</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Typography>Select the children registers you want to cascade this risk to:</Typography>
					<RegistersPicker
						registers={childrenRegisters}
						value={selectedRegisters}
						onChange={(value) => setSelectedRegisters(value)}
					/>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={includeControls}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										setIncludeControls(event.target.checked);
									}}
								/>
							}
							label="Include controls and mitigation measures"
						/>
					</FormGroup>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					variant="contained"
					onClick={async () => {
						const registerIds = selectedRegisters.map((registers) => registers[registers.length - 1].risk_register_id);
						const { created } = await RiskRegistersApi.cascadeRiskToChildren(
							register.risk_register_id,
							risk.risk_id,
							registerIds,
							includeControls,
						);
						addAlert('success', `The risk '${risk.name}' has been cascaded down to ${created} risk registers`);
						onClose(true);
					}}
					color="primary"
					disabled={selectedRegisters.length === 0}
				>
					Cascade risk
				</Button>
			</DialogActions>
		</Dialog>
	);
}
