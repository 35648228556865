import { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import {
	IApiAuthenticationProvider,
	AuthenticationProviderType,
	IOpenIDConnectConfiguration,
} from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close, Delete, Save, Undo } from '@mui/icons-material';

import useAlerts from 'hooks/useAlerts';
import * as AuthenticationProvidersApi from 'api/authenticationProviders';
import ConfirmDialog from './ConfirmDialog';

interface IAuthenticationProviderFormProps {
	item?: IApiAuthenticationProvider;
	onSave: () => void;
	onCancel?: () => void;
	disabled?: boolean;
	canDelete?: boolean;
}

interface IFormData {
	name: string;
	type: AuthenticationProviderType;
	configuration: IOpenIDConnectConfiguration;
}

const validationSchema = yup.object({
	name: yup.string().max(100, 'The name must be less than 100 characters').required(),
	type: yup.string().oneOf(['OpenID Connect']).required(),
	configuration: yup.object({
		discovery_url: yup.string().max(1000).required('Enter the discovery URL'),
		client_id: yup.string().max(1000).required('The client ID is required'),
		client_secret: yup.string().max(1000).required('The client secret is required'),
	}),
});

export default function AuthenticationProviderForm({
	item,
	onSave,
	onCancel,
	disabled,
	canDelete,
}: IAuthenticationProviderFormProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const { addAlert } = useAlerts();
	const formik = useFormik<IFormData>({
		initialValues: {
			name: item?.name || '',
			type: item?.type || 'OpenID Connect',
			configuration: item?.configuration || { discovery_url: '', client_id: '', client_secret: '' },
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			try {
				if (item) {
					await AuthenticationProvidersApi.saveAuthenticationProvider(item.authentication_provider_id, data);
					addAlert('success', `Authentication provider '${data.name}' has been updated`);

					onSave();
				} else {
					await AuthenticationProvidersApi.createAuthenticationProvider(data);
					addAlert('success', `Authentication provider '${data.name}' has been added`);

					onSave();
				}
			} finally {
				setIsSaving(false);
			}
		},
	});

	useEffect(() => {
		if (item) {
			formik.resetForm({ values: item });
		}
	}, [item]);

	const deleteItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await AuthenticationProvidersApi.deleteAuthenticationProvider(item.authentication_provider_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					label="Authentication provider name"
					name="name"
					autoComplete="off"
					value={formik.values.name}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					disabled={isSaving || disabled}
					sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
				/>
				<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
					This is the name used to refer to this authentication provider within Risk Safe
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					label="Type"
					name="type"
					select
					value={formik.values.type}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.type)}
					helperText={formik.touched.type && formik.errors.type}
					disabled={isSaving || disabled}
					SelectProps={{
						native: true,
					}}
					sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
				>
					<option>OpenID Connect</option>
				</TextField>
				<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
					Only OpenID Connect is supported at the moment. Most authentication providers support this protocol (inlcuding
					Microsoft Azure, Google, Facebook...)
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					label="Discovery URL"
					name="configuration.discovery_url"
					autoComplete="off"
					value={formik.values.configuration.discovery_url}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.configuration?.discovery_url)}
					helperText={formik.touched.configuration?.discovery_url && formik.errors.configuration?.discovery_url}
					disabled={isSaving || disabled}
					sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
				/>
				<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
					URL for the OpenID Connect discovery document
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					label="Client ID"
					name="configuration.client_id"
					autoComplete="off"
					value={formik.values.configuration.client_id}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.configuration?.client_id)}
					helperText={formik.touched.configuration?.client_id && formik.errors.configuration?.client_id}
					disabled={isSaving || disabled}
					sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
				/>
				<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
					Client ID for the OAuth 2.0 credentials
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					label="Client secret"
					name="configuration.client_secret"
					type="password"
					autoComplete="off"
					value={formik.values.configuration.client_secret}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={Boolean(formik.errors.configuration?.client_secret)}
					helperText={formik.touched.configuration?.client_secret && formik.errors.configuration?.client_secret}
					disabled={isSaving || disabled}
					sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
				/>
				<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
					Secret for the OAuth 2.0 credentials
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', marginTop: (theme) => theme.spacing(2) }}>
				{onCancel && (
					<Button startIcon={<Close />} onClick={() => onCancel()} disabled={isSaving || disabled} variant="outlined">
						Cancel
					</Button>
				)}
				{item && (
					<>
						<Button
							startIcon={<Undo />}
							onClick={() => formik.resetForm()}
							disabled={isSaving || !formik.dirty || disabled}
							variant="outlined"
						>
							Discard changes
						</Button>
						<Button
							startIcon={<Delete />}
							onClick={() => setIsDeleteConfirmDialogOpen(true)}
							disabled={isSaving || disabled || !canDelete}
							variant="outlined"
							sx={{ marginLeft: (theme) => theme.spacing(1) }}
						>
							Delete
						</Button>
					</>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					onClick={() => formik.submitForm()}
					disabled={isSaving || !formik.dirty || !formik.isValid || disabled}
					variant="contained"
				>
					Save
				</Button>
			</Box>
			{isDeleteConfirmDialogOpen && item && (
				<ConfirmDialog
					title="Confirm deletion of authentication provider"
					message={`Are you sure you want to delete the authentication provider '${item.name}'?\n\nThis action is irreversible. Users authenticating using this provider will not be able to access Risk Safe any more`}
					confirmLabel="Delete"
					cancelLabel="Cancel"
					onClose={() => setIsDeleteConfirmDialogOpen(false)}
					onConfirm={() => deleteItem()}
				/>
			)}
		</Box>
	);
}
