import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Tooltip, Typography } from '@mui/material';

export interface IRiskScore {
	likelihood: number;
	impact: number;
	score: number;
}

interface IRiskScoreProps {
	value?: IRiskScore | null;
	label?: string;
	size: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: '50%',
			margin: theme.spacing(1),
			flexShrink: 0,
		},
		score: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: '20px',
			cursor: 'default',
		},
	}),
);

export default function RiskScore({ value, label, size }: IRiskScoreProps) {
	const classes = useStyles();
	const backgroundColor = value
		? value.score < 5
			? '#00B050'
			: value.score < 11
			? '#FFFF00'
			: value.score < 16
			? '#FFC000'
			: '#FF0000'
		: '#CCCCCC';
	const color = 'black';

	const scoreCircle = (
		<div className={classes.root} style={{ color, backgroundColor, width: size, height: size }}>
			<Typography className={classes.score} style={{ lineHeight: size }}>
				{value ? value.score : '?'}
			</Typography>
		</div>
	);

	if (label) {
		return (
			<Tooltip
				title={
					value ? (
						<>
							<Typography variant="subtitle2">{label}:</Typography>
							<Typography variant="subtitle2">{`Likelihood: ${value.likelihood}`}</Typography>
							<Typography variant="subtitle2">{`Consequence / Impact: ${value.impact}`}</Typography>
						</>
					) : (
						<Typography variant="subtitle2">{`${label} is not defined`}</Typography>
					)
				}
				placement="left"
			>
				{scoreCircle}
			</Tooltip>
		);
	} else {
		return scoreCircle;
	}
}
