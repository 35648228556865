import {
	IApiStrategicPillarCreate,
	IApiStrategicPillarUpdate,
	IApiStrategicPillarUsage,
	IApiStrategicPillarWithImage,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAll() {
	return Api.get<IApiStrategicPillarWithImage[]>('strategic-pillars');
}

export function getUsageForPillar(pillarId: number) {
	return Api.get<IApiStrategicPillarUsage>(`strategic-pillars/${pillarId}/usage`);
}

export function savePillar(pillarId: number, data: IApiStrategicPillarUpdate) {
	return Api.patch<void>(`strategic-pillars/${pillarId}`, data);
}

export function createPillar(data: IApiStrategicPillarCreate) {
	return Api.post<void>('strategic-pillars', data);
}

export function deletePillar(pillarId: number) {
	return Api.delete_<void>(`strategic-pillars/${pillarId}`);
}

export function disablePillar(pillarId: number) {
	return Api.patch<void>(`strategic-pillars/${pillarId}`, { disabled: true });
}

export function enablePillar(pillarId: number) {
	return Api.patch<void>(`strategic-pillars/${pillarId}`, { disabled: false });
}
