import React, { useState } from 'react';
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { Add, Delete, Done, Edit, Undo } from '@mui/icons-material';
import { IApiRiskTreatmentAction, IApiRiskTreatmentActionUpdate } from '@mitie/risk-register-api-types';

import AddOrUpdateRiskTreatmentActionDialog from './AddOrUpdateRiskTreatmentActionDialog';
import { formatDate, formatDateTime } from 'utils';

interface IRiskTreatmentActionsInputProps {
	data: { saved?: IApiRiskTreatmentAction; updated: IApiRiskTreatmentActionUpdate | null }[];
	onChange: (data: { saved?: IApiRiskTreatmentAction; updated: IApiRiskTreatmentActionUpdate | null }[]) => void;
	isLoading: boolean;
}

export default function RiskTreatmentActionsInput({ data, onChange, isLoading }: IRiskTreatmentActionsInputProps) {
	const [addRiskTreatmentActionDialogOpen, setAddRiskTreatmentActionDialogOpen] = useState(false);
	const [updatingRiskTreatmentActionIndex, setUpdatingRiskTreatmentActionIndex] = useState<number>();

	const addRiskTreatmentAction = ({
		description,
		owner,
		start_date,
		end_date,
		completed,
	}: {
		description: string;
		owner: string;
		start_date: string;
		end_date: string;
		completed: boolean;
	}) => {
		const newRiskTreatmentAction: IApiRiskTreatmentActionUpdate = {
			description,
			owner,
			start_date,
			end_date,
			completed,
		};

		const updatedData = [...data, { updated: newRiskTreatmentAction }];
		onChange(updatedData);
	};

	const removeRiskTreatmentAction = (index: number) => {
		const updatedData = data.slice();
		const item = updatedData[index];

		if (item) {
			if (item.saved) {
				item.updated = null;
			} else {
				updatedData.splice(index, 1);
			}
		}

		onChange(updatedData);
	};

	const restoreRiskTreatmentAction = (index: number) => {
		const updatedData = data.slice();
		const item = updatedData[index];

		if (item && item.saved) {
			const { risk_treatment_action_id, description, owner, start_date, end_date, completed } = item.saved;
			item.updated = { risk_treatment_action_id, description, owner, start_date, end_date, completed };
		}

		onChange(updatedData);
	};

	const updateRiskTreatmentAction = (
		index: number,
		{
			description,
			owner,
			start_date,
			end_date,
			completed,
		}: { description: string; owner: string; start_date: string; end_date: string; completed: boolean },
	) => {
		const updatedData = data.slice();

		if (updatedData[index]) {
			const updatedRiskTreatmentAction: IApiRiskTreatmentActionUpdate = {
				description,
				owner,
				start_date,
				end_date,
				completed,
			};
			const saved = updatedData[index].saved;

			if (saved !== undefined) {
				updatedRiskTreatmentAction.risk_treatment_action_id = saved.risk_treatment_action_id;
			}

			updatedData[index].updated = updatedRiskTreatmentAction;
		}

		onChange(updatedData);
	};

	return (
		<Box>
			{isLoading && <LinearProgress />}
			<TableContainer
				component={Paper}
				sx={{ boxShadow: 'none', border: '1px solid rgb(224, 224, 224)', borderBottom: 0, maxHeight: '150px' }}
			>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Action</TableCell>
							<TableCell>Owners</TableCell>
							<TableCell align="center">Start date</TableCell>
							<TableCell align="center">End date</TableCell>
							<TableCell align="center">Completed?</TableCell>
							<TableCell align="center">Date added</TableCell>
							<TableCell align="center">Date updated</TableCell>
							<TableCell sx={{ width: '64px' }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length === 0 ? (
							<TableRow>
								<TableCell colSpan={7}>No action in place</TableCell>
							</TableRow>
						) : (
							data.map(({ saved, updated }, index) => {
								const isDeleted = updated === null;
								const isNew = !saved;
								const isUpdated =
									saved &&
									updated &&
									(saved.description !== updated.description ||
										saved.owner !== updated.owner ||
										saved.start_date !== updated.start_date ||
										saved.end_date !== updated.end_date);
								const isCompleted = !!(updated?.completed || saved?.completed);

								return (
									<TableRow
										key={index}
										sx={{
											'&>td': {
												textDecoration: isDeleted ? 'line-through' : 'none',
												color: isUpdated || isNew || isDeleted ? 'red' : 'default',
											},
										}}
									>
										<TableCell>{updated ? updated.description : saved?.description}</TableCell>
										<TableCell>{updated ? updated.owner : saved?.owner}</TableCell>
										<TableCell align="center">
											{updated ? formatDate(updated.start_date) : saved ? formatDate(saved.start_date) : '-'}
										</TableCell>
										<TableCell align="center">
											{updated ? formatDate(updated.end_date) : saved ? formatDate(saved.end_date) : '-'}
										</TableCell>
										<TableCell align="center">{isCompleted ? <Done color="success" /> : '-'}</TableCell>
										<TableCell align="center">{!saved ? '-' : formatDateTime(saved.time_added)}</TableCell>
										<TableCell align="center">
											{isUpdated ? (
												<Typography variant="body2" sx={{ color: 'red', fontStyle: 'italic' }}>
													(Updated)
												</Typography>
											) : !saved ? (
												<Typography variant="body2" sx={{ color: 'red', fontStyle: 'italic' }}>
													(New)
												</Typography>
											) : (
												<>{formatDateTime(saved.time_updated)}</>
											)}
										</TableCell>
										<TableCell align="center">
											{!isDeleted && (
												<Tooltip title="Change">
													<IconButton
														edge="end"
														size="small"
														onClick={() => {
															setUpdatingRiskTreatmentActionIndex(index);
															setAddRiskTreatmentActionDialogOpen(true);
														}}
													>
														<Edit />
													</IconButton>
												</Tooltip>
											)}
											{isDeleted || isUpdated ? (
												<Tooltip title={isUpdated ? 'Revert' : 'Restore'}>
													<IconButton edge="end" size="small" onClick={() => restoreRiskTreatmentAction(index)}>
														<Undo />
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip title="Remove">
													<IconButton edge="end" size="small" onClick={() => removeRiskTreatmentAction(index)}>
														<Delete />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</TableRow>
								);
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				size="small"
				startIcon={<Add />}
				variant="outlined"
				sx={{ marginTop: '16px' }}
				onClick={() => setAddRiskTreatmentActionDialogOpen(true)}
			>
				Add action...
			</Button>

			{addRiskTreatmentActionDialogOpen && (
				<AddOrUpdateRiskTreatmentActionDialog
					data={
						updatingRiskTreatmentActionIndex !== undefined
							? {
									description: data[updatingRiskTreatmentActionIndex]?.updated?.description || '',
									owner: data[updatingRiskTreatmentActionIndex]?.updated?.owner || '',
									start_date: data[updatingRiskTreatmentActionIndex]?.updated?.start_date || '',
									end_date: data[updatingRiskTreatmentActionIndex]?.updated?.end_date || '',
									completed: data[updatingRiskTreatmentActionIndex]?.updated?.completed || false,
							  }
							: undefined
					}
					onClose={(data) => {
						if (data) {
							if (updatingRiskTreatmentActionIndex !== undefined) {
								updateRiskTreatmentAction(updatingRiskTreatmentActionIndex, data);
							} else {
								addRiskTreatmentAction(data);
							}
						}

						setUpdatingRiskTreatmentActionIndex(undefined);
						setAddRiskTreatmentActionDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
