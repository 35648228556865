import {
	IApiCompany,
	IApiCompanyCreate,
	IApiCompanyUpdate,
	IApiUserDirectory,
	IApiUserDirectoryCreate,
} from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getAllCompanies() {
	return Api.get<IApiCompany[]>('companies');
}

export function saveCompany(companyId: string, data: Partial<IApiCompanyUpdate>) {
	return Api.patch<void>(`companies/${companyId}`, data);
}

export function createCompany(data: IApiCompanyCreate) {
	return Api.post<void>('companies', data);
}

export function deleteCompany(companyId: string) {
	return Api.delete_<void>(`companies/${companyId}`);
}

export function createUserDirectory(companyId: string, data: IApiUserDirectoryCreate) {
	return Api.post<void>(`companies/${companyId}/user-directory`, data);
}

export function updateUserDirectory(companyId: string, userDirectoryId: number, data: Partial<IApiUserDirectory>) {
	return Api.patch<void>(`companies/${companyId}/user-directory/${userDirectoryId}`, data);
}

export function deleteUserDirectory(companyId: string, userDirectoryId: number) {
	return Api.delete_<void>(`companies/${companyId}/user-directory/${userDirectoryId}`);
}
