import { Box, Tab } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import { useNavigate, useParams } from 'routing/routing';
import DeletedRegisters from 'components/DeletedRegisters';
import DeletedRisks from 'components/DeletedRisks';
import DeletedCatalogueItems from 'components/DeletedCatalogueItems';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export default function DeletedData() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'DeletedRisks');
	const navigate = useNavigate();
	const { page } = useParams();

	componentTracking();

	const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
		navigate(null, { page: value });
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
			<TabContext value={page || 'deleted-registers'}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleTabChange}>
						<Tab label="Archived registers" value="deleted-registers" />
						<Tab label="Retired risks" value="deleted-risks" />
						<Tab label="Deleted catalogue items" value="deleted-catalogue-items" />
					</TabList>
				</Box>
				<TabPanel value="deleted-registers" sx={{ flexGrow: 1, padding: 0, overflowY: 'auto' }}>
					<DeletedRegisters />
				</TabPanel>
				<TabPanel value="deleted-risks" sx={{ flexGrow: 1, padding: 0, overflowY: 'auto' }}>
					<DeletedRisks />
				</TabPanel>
				<TabPanel value="deleted-catalogue-items" sx={{ flexGrow: 1, padding: 0, overflowY: 'auto' }}>
					<DeletedCatalogueItems />
				</TabPanel>
			</TabContext>
		</Box>
	);
}
