import { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';

import * as RiskCatalogueApi from 'api/riskCatalogue';
import TextFilterInput from './TextFilterInput';
import DeletedCatalogueItemCard from './DeletedCatalogueItemCard';

export default function DeletedCatalogueItems() {
	const [isLoading, setIsLoading] = useState(false);
	const [deletedCatalogueItems, setDeletedCatalogueItems] = useState<IApiRiskCatalogueItem[]>([]);
	const [displayedCatalogueItems, setDisplayedCatalogueItems] = useState<IApiRiskCatalogueItem[]>([]);
	const [filter, setFilter] = useState<string>('');

	useEffect(() => {
		loadDeletedCatalogueItems();
	}, []);

	useEffect(() => {
		if (filter.length) {
			const searchTerm = filter.trim().toLowerCase();

			setDisplayedCatalogueItems(deletedCatalogueItems.filter(({ name }) => name.toLowerCase().includes(searchTerm)));
		} else {
			setDisplayedCatalogueItems(deletedCatalogueItems);
		}
	}, [filter, deletedCatalogueItems]);

	const loadDeletedCatalogueItems = async () => {
		setIsLoading(true);

		try {
			const data = await RiskCatalogueApi.getDeletedCatalogueItems();
			setDeletedCatalogueItems(data);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
			}}
		>
			<Box
				sx={{
					padding: (theme) => theme.spacing(1),
					paddingLeft: (theme) => theme.spacing(2),
					borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
				}}
			>
				<TextFilterInput value={filter} onChange={(newFilter) => setFilter(newFilter)} label="Filter items" />
			</Box>
			{isLoading && <LinearProgress />}
			<Box sx={{ overflowY: 'auto', display: 'flex', flexWrap: 'wrap', margin: (theme) => theme.spacing(1) }}>
				{displayedCatalogueItems.map((item) => (
					<DeletedCatalogueItemCard
						key={item.risk_catalogue_id}
						catalogueItem={item}
						onChange={loadDeletedCatalogueItems}
					/>
				))}
			</Box>
		</Box>
	);
}
