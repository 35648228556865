import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiRiskType } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as RiskTypesApi from 'api/riskTypes';
import RiskType from './RiskType';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import AddRiskTypeDialog from './AddRiskTypeDialog';

export default function RiskTypes() {
	const [selectedType, setSelectedType] = useState<IApiRiskType | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [liveTypes, setLiveTypes] = useState<IApiRiskType[]>([]);
	const [disabledTypes, setDisabledTypes] = useState<IApiRiskType[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedType(
			liveTypes.find((c) => encodeStringForUrl(c.name) === selected) ||
				disabledTypes.find((c) => encodeStringForUrl(c.name) === selected) ||
				null,
		);
	}, [disabledTypes, liveTypes, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await Promise.all([RiskTypesApi.getLiveRiskTypes(), RiskTypesApi.getDisabledTypes()]);

			setLiveTypes(data[0].sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
			setDisabledTypes(data[1].sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto' }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						Add risk type
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{liveTypes.map((riskType) => (
					<ListItemButton
						key={riskType.risk_type_id}
						selected={selectedType?.risk_type_id === riskType.risk_type_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskType.name) })}
					>
						<ListItemText primary={riskType.name} />
					</ListItemButton>
				))}
				{disabledTypes.map((riskType) => (
					<ListItemButton
						key={riskType.risk_type_id}
						selected={selectedType?.risk_type_id === riskType.risk_type_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskType.name) })}
					>
						<ListItemText primary={riskType.name} secondary="Disabled" />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{selectedType && <RiskType item={selectedType} onChange={loadData} isDataLoading={isLoading} />}
			{isAddDialogOpen && (
				<AddRiskTypeDialog
					onClose={(success: boolean) => {
						if (success) {
							loadData();
						}

						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
