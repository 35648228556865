import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import { IApiRisk, IApiRiskRegister } from '@mitie/risk-register-api-types';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import * as RiskRegistersApi from '../api/riskRegisters';
import { useNavigate, useParams } from '../routing/routing';
import RiskRegisterSelectDialog from '../components/RiskRegisterSelectDialog';
import Risks from 'components/Risks';
import RisksMobile from 'components/RisksMobile';
import useAlerts from 'hooks/useAlerts';
import RegisterBreadcrumbs from 'components/RegisterBreadcrumbs';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},
		topBar: {
			width: '100%',
			display: 'flex',
			flexShrink: 0,
			borderBottom: `1px solid ${theme.palette.divider}`,
			height: '52px',
			alignItems: 'center',
			boxSizing: 'border-box',
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		risksList: {
			flexGrow: 1,
		},
		marginRight: {
			marginRight: theme.spacing(2),
		},
	}),
);

const getDescendants = (parent: IApiRiskRegister, list: IApiRiskRegister[]): IApiRiskRegister[] => {
	const children = list.filter((r) => r.parent_risk_register_id === parent.risk_register_id);
	const descendants: IApiRiskRegister[] = [];

	for (const child of children) {
		descendants.push(child, ...getDescendants(child, list));
	}

	return descendants;
};

export default function RiskRegisters() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'RiskRegistersPage');
	const classes = useStyles();
	const [riskRegisters, setRiskRegisters] = useState<IApiRiskRegister[]>();
	const [selectedRegister, setSelectedRegister] = useState<IApiRiskRegister>();
	const [selectedRegisterPath, setSelectedRegisterPath] = useState<IApiRiskRegister[]>(); // Array storing parents of selected registers, to display in the breadcrumbs
	const [risks, setRisks] = useState<{ registerId: number; list: IApiRisk[]; byId: { [id: number]: IApiRisk } }>();
	const [childrenRegisters, setChildrenRegisters] = useState<IApiRiskRegister[]>([]);
	const [selectedRisk, setSelectedRisk] = useState<IApiRisk | null>(null);
	const [areRegistersLoading, setAreRegistersLoading] = useState(false);
	const [areRisksLoading, setAreRisksLoading] = useState(false);
	const [riskRegisterSelectOpen, setRiskRegisterSelectOpen] = useState(false);
	const { registerId, registerName, riskId } = useParams();
	const navigate = useNavigate();
	const { addAlert } = useAlerts();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
	const isSmallOrMedium = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		// Load list of registers initially
		loadRegisters();
	}, []);

	useEffect(() => {
		// Update `selectedRegister`, `selectedRegisterPath` and `childrenRegisters` when register changes in URL or when list of registers is loaded
		if (riskRegisters) {
			const selected = riskRegisters.find((r) => r.risk_register_id === registerId);

			if (selected) {
				const hierarchy = [];

				for (
					let parent: IApiRiskRegister | undefined = selected, i = 0;
					parent !== undefined;
					parent = riskRegisters.find((r) => r.risk_register_id === parent!.parent_risk_register_id), i++
				) {
					if (i > 0) {
						hierarchy.unshift(parent);
					}
				}

				setSelectedRegister(selected);
				setSelectedRegisterPath(hierarchy);

				setChildrenRegisters(getDescendants(selected, riskRegisters));
			}
		}
	}, [registerId, riskRegisters]);

	useEffect(() => {
		// Load list of risks when `selectedRegister` changes
		loadRisks();

		return () => {
			// Clear list of risks when selected register changes
			setRisks(undefined);
		};
	}, [selectedRegister]);

	useEffect(() => {
		// Update `selectedRisk` when risk changes in URL or when risks in register are loaded
		if (!riskId) {
			setSelectedRisk(null);
		} else if (risks && risks.registerId === registerId) {
			const selected = risks.byId[riskId];

			if (!selected) {
				navigate(null, { registerId, registerName });
			} else {
				setSelectedRisk(selected);
			}
		}
	}, [riskId, risks]);

	const loadRegisters = async () => {
		setAreRegistersLoading(true);

		try {
			const riskRegistersData = await RiskRegistersApi.getAll();

			setRiskRegisters(riskRegistersData);

			if (riskRegistersData.length > 0 && registerId === undefined) {
				// Select first register if none is selected
				navigate(null, { registerId: riskRegistersData[0].risk_register_id, registerName: riskRegistersData[0].name });
			}
		} catch {
			addAlert('error', 'Failed to load the list of risk registers');
		} finally {
			setAreRegistersLoading(false);
		}
	};

	const loadRisks = async () => {
		if (!selectedRegister) {
			return;
		}

		setAreRisksLoading(true);

		try {
			const list = await RiskRegistersApi.getRisksForRegister(selectedRegister.risk_register_id);
			const byId = list.reduce((acc: { [id: number]: IApiRisk }, cur) => {
				acc[cur.risk_id] = cur;
				return acc;
			}, {});

			setRisks({
				registerId: selectedRegister.risk_register_id,
				list,
				byId,
			});
		} finally {
			setAreRisksLoading(false);
		}
	};

	componentTracking();

	return (
		<div className={classes.root}>
			<div className={classes.topBar}>
				{!isSmall && (
					<Typography variant="subtitle1" sx={{ marginRight: (theme) => theme.spacing(1), whiteSpace: 'nowrap' }}>
						Selected risk register:
					</Typography>
				)}
				{areRegistersLoading && <CircularProgress />}
				{riskRegisters && (
					<>
						{selectedRegister && selectedRegisterPath ? (
							<RegisterBreadcrumbs selectedRegister={selectedRegister} selectedRegisterPath={selectedRegisterPath} />
						) : (
							<Typography variant="h6">None</Typography>
						)}
						{riskRegisters.length > 1 && (
							<Button variant="outlined" onClick={() => setRiskRegisterSelectOpen(true)}>
								{isSmallOrMedium ? '...' : 'Change...'}
							</Button>
						)}
						{riskRegisterSelectOpen && (
							<RiskRegisterSelectDialog
								list={riskRegisters}
								currentlySelected={
									selectedRegisterPath && selectedRegister ? [...selectedRegisterPath, selectedRegister] : undefined
								}
								onChange={(selected: IApiRiskRegister[]) => {
									if (selected.length === 0) {
										return;
									}

									navigate(null, {
										registerId: selected[selected.length - 1].risk_register_id,
										registerName: selected[selected.length - 1].name,
									});
								}}
								onClose={() => setRiskRegisterSelectOpen(false)}
							/>
						)}
					</>
				)}
			</div>
			{selectedRegister && selectedRegisterPath && riskRegisters && (
				<>
					{areRisksLoading && <LinearProgress />}
					{risks &&
						(isSmallOrMedium ? (
							<RisksMobile
								register={selectedRegister}
								registerParents={selectedRegisterPath}
								childrenRegisters={childrenRegisters}
								allRegisters={riskRegisters}
								risks={risks.list}
								selectedRisk={selectedRisk}
								onChange={loadRisks}
							/>
						) : (
							<Risks
								register={selectedRegister}
								registerParents={selectedRegisterPath}
								childrenRegisters={childrenRegisters}
								allRegisters={riskRegisters}
								risks={risks.list}
								selectedRisk={selectedRisk}
								onChange={loadRisks}
							/>
						))}
				</>
			)}
		</div>
	);
}
