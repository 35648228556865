import { Button, NativeSelect } from '@mui/material';
import { useState } from 'react';

interface ICompanySelectProps {
	value: string;
	onChange: (newValue?: string) => void;
	list: { company_id: string; name: string }[];
}

export default function CompanySelect({ value, onChange, list }: ICompanySelectProps) {
	const [dropdownValue, setDropdownValue] = useState(value);

	return (
		<>
			<NativeSelect value={dropdownValue} onChange={(event) => setDropdownValue(event.target.value)}>
				{list.map(({ company_id, name }) => (
					<option value={company_id} key={company_id}>
						{name}
					</option>
				))}
			</NativeSelect>
			<Button size="small" sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={() => onChange()}>
				Cancel
			</Button>
			<Button
				size="small"
				disabled={dropdownValue === value}
				sx={{ marginLeft: (theme) => theme.spacing(1) }}
				onClick={() => onChange(dropdownValue)}
			>
				Confirm
			</Button>
		</>
	);
}
