import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';

export interface IRiskScore {
	likelihood: number;
	impact: number;
	score: number;
}

interface IRiskScoreProps {
	value?: IRiskScore | null;
	label: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'inline-flex',
			flexDirection: 'column',
			alignItems: 'center',
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: '8px',
			margin: theme.spacing(1),
			padding: theme.spacing(1),
			flexShrink: 0,
		},
		score: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: '20px',
			cursor: 'default',
		},
	}),
);

export default function RiskScoreFull({ value, label }: IRiskScoreProps) {
	const classes = useStyles();
	const backgroundColor = value
		? value.score < 5
			? '#00B050'
			: value.score < 11
			? '#FFFF00'
			: value.score < 16
			? '#FFC000'
			: '#FF0000'
		: '#CCCCCC';
	const color = 'black';
	const size = '48px';

	return (
		<div className={classes.root}>
			<Typography variant="subtitle2">{label}</Typography>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div
					style={{
						borderRadius: '50%',
						marginRight: '8px',
						marginTop: '8px',
						flexShrink: 0,
						color,
						backgroundColor,
						width: size,
						height: size,
					}}
				>
					<Typography className={classes.score} style={{ lineHeight: size }}>
						{value ? value.score : '?'}
					</Typography>
				</div>
				{value ? (
					<div>
						<Typography variant="body2">{`Likelihood: ${value.likelihood}`}</Typography>
						<Typography variant="body2">{`Consequence: ${value.impact}`}</Typography>
					</div>
				) : (
					<Typography variant="body2">Risk score is not defined</Typography>
				)}
			</div>
		</div>
	);
}
