import { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { IApiRiskImpactCategory, IApiRiskImpactCategoryUpdate } from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Close, Delete, MoveDown, MoveUp, NotInterested, Save, Undo } from '@mui/icons-material';
import { Restore } from 'mdi-material-ui';

import * as RiskImpactCategoriesApi from 'api/riskImpactCategories';
import ConfirmDialog from './ConfirmDialog';

interface IRiskImpactCategoryFormProps {
	item?: IApiRiskImpactCategory;
	isFirst?: boolean;
	isLast?: boolean;
	onSave: () => void;
	onCancel?: () => void;
	disabled?: boolean;
	canDelete?: boolean;
}

const validationSchema = yup.object({
	name: yup.string().required('Name is required').max(45, 'Name should be less than 45 characters'),
	impact_description_1: yup
		.string()
		.required('Enter a definition for averse appetite')
		.max(2000, 'Should be less than 2000 characters'),
	impact_description_2: yup
		.string()
		.required('Enter a definition for averse appetite')
		.max(2000, 'Should be less than 2000 characters'),
	impact_description_3: yup
		.string()
		.required('Enter a definition for averse appetite')
		.max(2000, 'Should be less than 2000 characters'),
	impact_description_4: yup
		.string()
		.required('Enter a definition for averse appetite')
		.max(2000, 'Should be less than 2000 characters'),
	impact_description_5: yup
		.string()
		.required('Enter a definition for averse appetite')
		.max(2000, 'Should be less than 2000 characters'),
});

export default function RiskImpactCategoryForm({
	item,
	isFirst,
	isLast,
	onSave,
	onCancel,
	disabled,
	canDelete,
}: IRiskImpactCategoryFormProps) {
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
	const formik = useFormik<IApiRiskImpactCategoryUpdate>({
		initialValues: {},
		validationSchema,
		onSubmit: async ({
			name,
			impact_description_1,
			impact_description_2,
			impact_description_3,
			impact_description_4,
			impact_description_5,
		}) => {
			setIsSaving(true);

			if (
				!name ||
				!impact_description_1 ||
				!impact_description_2 ||
				!impact_description_3 ||
				!impact_description_4 ||
				!impact_description_5
			) {
				return;
			}

			try {
				if (item) {
					await RiskImpactCategoriesApi.saveImpactCategory(item.risk_impact_category_id, {
						name,
						impact_description_1,
						impact_description_2,
						impact_description_3,
						impact_description_4,
						impact_description_5,
					});
				} else {
					await RiskImpactCategoriesApi.createImpactCategory({
						name,
						impact_description_1,
						impact_description_2,
						impact_description_3,
						impact_description_4,
						impact_description_5,
					});
				}

				onSave();
			} finally {
				setIsSaving(false);
			}
		},
	});

	useEffect(() => {
		if (item) {
			formik.resetForm({ values: item });
		}
	}, [item]);

	const deleteItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskImpactCategoriesApi.deleteImpactCategory(item.risk_impact_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const disableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskImpactCategoriesApi.disableImpactCategory(item.risk_impact_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const enableItem = async () => {
		if (!item) {
			return;
		}

		setIsSaving(true);

		try {
			await RiskImpactCategoriesApi.enableImpactCategory(item.risk_impact_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const moveUp = async () => {
		if (!item) {
			return;
		}

		try {
			await RiskImpactCategoriesApi.moveImpactCategoryUp(item.risk_impact_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};

	const moveDown = async () => {
		if (!item) {
			return;
		}

		try {
			await RiskImpactCategoriesApi.moveImpactCategoryDown(item.risk_impact_category_id);

			onSave();
		} finally {
			setIsSaving(false);
		}
	};
	return (
		<Box>
			<TextField
				label="Impact category name"
				name="name"
				value={formik.values.name}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.name)}
				helperText={formik.touched.name && formik.errors.name}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Description for impact rating 1 (minor impact)"
				name="impact_description_1"
				value={formik.values.impact_description_1}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.impact_description_1)}
				helperText={formik.touched.impact_description_1 && formik.errors.impact_description_1}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<TextField
				label="Description for impact rating 2 (moderate impact)"
				name="impact_description_2"
				value={formik.values.impact_description_2}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.impact_description_2)}
				helperText={formik.touched.impact_description_2 && formik.errors.impact_description_2}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<TextField
				label="Description for impact rating 3 (significant impact)"
				name="impact_description_3"
				value={formik.values.impact_description_3}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.impact_description_3)}
				helperText={formik.touched.impact_description_3 && formik.errors.impact_description_3}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<TextField
				label="Description for impact rating 4 (major impact)"
				name="impact_description_4"
				value={formik.values.impact_description_4}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.impact_description_4)}
				helperText={formik.touched.impact_description_4 && formik.errors.impact_description_4}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<TextField
				label="Description for impact rating 5 (critical impact)"
				name="impact_description_5"
				value={formik.values.impact_description_5}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={Boolean(formik.errors.impact_description_5)}
				helperText={formik.touched.impact_description_5 && formik.errors.impact_description_5}
				disabled={isSaving || disabled}
				sx={{ marginBottom: (theme) => theme.spacing(2) }}
				InputLabelProps={{
					shrink: true,
				}}
				multiline
				rows={3}
				fullWidth
			/>
			<Box sx={{ display: 'flex', marginTop: (theme) => theme.spacing(2) }}>
				{onCancel && (
					<Button startIcon={<Close />} onClick={() => onCancel()} disabled={isSaving || disabled} variant="outlined">
						Cancel
					</Button>
				)}
				{item && (
					<>
						<Button
							startIcon={<Undo />}
							onClick={() => formik.resetForm()}
							disabled={isSaving || !formik.dirty || disabled}
							variant="outlined"
						>
							Discard changes
						</Button>
						{item.disabled ? (
							<Button
								startIcon={<Restore />}
								onClick={() => enableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Enable
							</Button>
						) : (
							<Button
								startIcon={<NotInterested />}
								onClick={() => disableItem()}
								disabled={isSaving}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(1) }}
							>
								Disable
							</Button>
						)}
						{item && !item.disabled && (
							<>
								<Button
									startIcon={<MoveUp />}
									onClick={() => moveUp()}
									disabled={isSaving || isFirst}
									variant="outlined"
									sx={{ marginLeft: (theme) => theme.spacing(1) }}
								>
									Move up
								</Button>
								<Button
									startIcon={<MoveDown />}
									onClick={() => moveDown()}
									disabled={isSaving || isLast}
									variant="outlined"
									sx={{ marginLeft: (theme) => theme.spacing(1) }}
								>
									Move down
								</Button>
							</>
						)}
						<Button
							startIcon={<Delete />}
							onClick={() => setIsDeleteConfirmDialogOpen(true)}
							disabled={isSaving || disabled || !canDelete}
							variant="outlined"
							sx={{ marginLeft: (theme) => theme.spacing(1) }}
						>
							Delete
						</Button>
					</>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					onClick={() => formik.submitForm()}
					disabled={isSaving || !formik.dirty || !formik.isValid || disabled}
					variant="contained"
				>
					Save
				</Button>
			</Box>
			{isDeleteConfirmDialogOpen && item && (
				<ConfirmDialog
					title="Confirm deletion of risk category"
					message={`Are you sure you want to delete the risk category '${item.name}'? This action is irreversible`}
					confirmLabel="Delete"
					cancelLabel="Cancel"
					onClose={() => setIsDeleteConfirmDialogOpen(false)}
					onConfirm={() => deleteItem()}
				/>
			)}
		</Box>
	);
}
