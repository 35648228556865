import enGB from 'date-fns/locale/en-GB';
import formatRelative from 'date-fns/formatRelative';
import words from 'lodash/words';
import { RiskAppetite, RiskAppetiteStatus } from '@mitie/risk-register-api-types';

export function encodeStringForUrl(input: string) {
	return input
		.toLowerCase()
		.replace(/[^a-z0-9\s]/gim, '')
		.replace(/\s+/gm, '-');
}

export interface ITreeNode<T extends { [key: string]: any }> {
	node: T;
	children: ITreeNode<T>[];
}

export function createTreeFromArray<T extends { [key: string]: any }>(
	data: T[],
	idProperty: string,
	parentIdProperty: string,
	sortProperty: string,
) {
	const nodes: { [key: string]: ITreeNode<T> } = {};
	data.forEach((node) => (nodes[String(node[idProperty])] = { node, children: [] }));

	const tree: ITreeNode<T>[] = [];

	data.forEach((node) => {
		const id = String(node[idProperty]);
		const item = nodes[id];
		const parentId = node[parentIdProperty];
		let siblings: ITreeNode<T>[];

		if (parentId && nodes[parentId]) {
			siblings = nodes[parentId].children;
		} else {
			siblings = tree;
		}

		// Insert node at the correct place within siblings array
		let inserted = false;

		for (let index = 0; index < siblings.length; index++) {
			const existingItem = siblings[index];

			if (existingItem.node[sortProperty].toLowerCase() > node[sortProperty].toLowerCase()) {
				siblings.splice(index, 0, item);
				inserted = true;
				break;
			}
		}

		if (!inserted) {
			siblings.push(item);
		}
	});

	return tree;
}

/**
 * Get the value of a cookie
 * Source: https://vanillajstoolkit.com/helpers/getcookie/
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
export function getCookie(name: string) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()!.split(';').shift();

	return undefined;
}

export function setCookie(name: string, value: string) {
	document.cookie = `${name}=${value};path=/;max-age=31536000;secure`;
}

export function clearCookie(name: string) {
	document.cookie = `${name}=; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function calculateAppetiteStatus(riskRating: number, appetite: RiskAppetite): RiskAppetiteStatus {
	if (appetite === 'Averse') {
		if (riskRating < 12) {
			return 'In line with appetite';
		} else {
			return 'Above appetite';
		}
	} else if (appetite === 'Cautious') {
		if (riskRating < 12) {
			return 'Below appetite';
		} else if (riskRating < 16) {
			return 'In line with appetite';
		} else {
			// >= 16
			return 'Above appetite';
		}
	} else {
		// Eager
		if (riskRating < 16) {
			return 'Below appetite';
		} else {
			// >= 16
			return 'In line with appetite';
		}
	}
}

/** Custom version of date-fns' `formatRelative`, that only include the date and not the time */
export function formatDate(dateString: string | Date) {
	const formatRelativeLocale = {
		lastWeek: "'last' eeee",
		yesterday: "'yesterday'",
		today: "'today'",
		tomorrow: "'tomorrow'",
		nextWeek: "'next' eeee",
		other: 'dd/MM/yyyy',
	};

	const locale = {
		...enGB,
		formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
	};

	const date = typeof dateString === 'string' ? new Date(dateString) : dateString;

	return formatRelative(date, new Date(), { locale });
}

/** Shorthand of date-fns' `formatRelative` with locale pre-set */
export function formatDateTime(date: string) {
	return formatRelative(new Date(date), new Date(), { locale: enGB });
}

export function initials(name: string) {
	return words(name)
		.map((w) => w[0])
		.join('');
}

export async function fileToBase64(file: File) {
	const fileToDataUrl = (f: File): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					resolve(reader.result);
				} else {
					reject();
				}
			};
			reader.onerror = reject;
			reader.readAsDataURL(f);
		});

	const text = await fileToDataUrl(file);

	return text.slice(text.indexOf(',') + 1);
}

export enum HttpCode {
	Ok = 200,
	Created = 201,
	NoContent = 204,
	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	InternalServerError = 500,
}

export class ErrorWithHttpCode extends Error {
	public code: HttpCode;
	constructor(code: HttpCode, message = '') {
		super(message);
		this.code = code;
	}
}

export function emailIsValid(email: string) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
