import { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import { IApiCompanyCreate } from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { useFormik } from 'formik';

import * as CompaniesApi from '../api/companies';
import useAlerts from '../hooks/useAlerts';
import useUser from 'hooks/useUser';

interface ILicenseUpdateDialogProps {
	onClose: () => void;
	onChange: () => void;
}

interface IFormData {
	company_id: string;
	name: string;
	admin_user_id: string;
	admin_user_name: string;
}

const validationSchema = yup.object({
	company_id: yup
		.string()
		.max(45, 'Company ID must be less than 45 characters')
		.matches(/^[a-z0-9-]+$/, 'only lower case letters, numbers and "-" are allowed for the company ID'),
	name: yup.string().max(100, 'Company name must be less than 100 characters'),
	admin_user_id: yup
		.string()
		.email('User ID must be a valid email address')
		.max(255, 'User ID must be less than 255 characters'),
	admin_user_name: yup.string().max(255, 'User name must be less than 255 characters'),
});

export default function AddCompanyDialog({ onChange, onClose }: ILicenseUpdateDialogProps) {
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();
	const { fetchProfile } = useUser();
	const formik = useFormik<IFormData>({
		initialValues: {
			company_id: '',
			name: '',
			admin_user_id: '',
			admin_user_name: '',
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async ({ company_id, name, admin_user_id, admin_user_name }) => {
			setIsSaving(true);

			const companyData: IApiCompanyCreate = {
				company_id,
				name,
				admin_user: { user_id: admin_user_id, name: admin_user_name },
			};

			try {
				await CompaniesApi.createCompany(companyData);
				await fetchProfile(); // Reload user profile, to update list of companies the current user has access to
				addAlert('success', `Company '${companyData.name}' has been added to Risk Safe`);

				onChange();
				onClose();
			} catch {
				addAlert('error', `Failed to add company '${companyData.name}' to Risk Safe`);
			} finally {
				setIsSaving(false);
			}
		},
	});

	return (
		<Dialog open={true}>
			<DialogTitle>Add customer to Risk Safe</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						label="Customer name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
						disabled={isSaving}
						sx={{ margin: (theme) => `${theme.spacing(1)} 0`, width: '300px', flexShrink: 0 }}
					/>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						label="CompanyId"
						name="company_id"
						value={formik.values.company_id}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.company_id)}
						helperText={formik.touched.company_id && formik.errors.company_id}
						disabled={isSaving}
						sx={{ margin: (theme) => `${theme.spacing(1)} 0`, width: '300px', flexShrink: 0 }}
					/>
					<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
						The company ID will be the database name for this customer. It needs to be unique and cannot be changed
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
						<TextField
							label="Admin user email"
							name="admin_user_id"
							value={formik.values.admin_user_id}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.admin_user_id)}
							helperText={formik.touched.admin_user_id && formik.errors.admin_user_id}
							disabled={isSaving}
							sx={{ margin: (theme) => `${theme.spacing(1)} 0`, width: '300px' }}
						/>
						<TextField
							label="Admin user name"
							name="admin_user_name"
							value={formik.values.admin_user_name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.admin_user_name)}
							helperText={formik.touched.admin_user_name && formik.errors.admin_user_name}
							disabled={isSaving}
							sx={{ margin: (theme) => `${theme.spacing(1)} 0`, width: '300px' }}
						/>
					</Box>
					<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
						Enter the name and email address for the initial user. This user will have Administrator role, and will be
						the only person that can add other users initially.
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					variant="contained"
					onClick={() => formik.submitForm()}
					color="primary"
					disabled={!formik.isValid || isSaving}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
