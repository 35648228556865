import { useEffect, useRef, useState } from 'react';
import { Box, Divider, LinearProgress, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { IApiUser } from '@mitie/risk-register-api-types';

import * as UsersAdminApi from 'api/usersAdmin';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import UserAdmin from './UserAdmin';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import TextFilterInput from './TextFilterInput';

export default function UsersAdmin() {
	const pageSize = 20;
	const [selectedUser, setSelectedUser] = useState<IApiUser | null>(null);
	const [users, setUsers] = useState<IApiUser[]>([]);
	const [filteredUsers, setFilteredUsers] = useState<IApiUser[]>([]);
	const [areUsersFiltered, setAreUsersFiltered] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();
	const listComponent = useRef<HTMLElement | null>(null);
	const { isFetching } = useInfiniteScroll(listComponent, (page) => loadData(page), 20);
	const [filter, setFilter] = useState<string>('');

	useEffect(() => {
		if (areUsersFiltered) {
			setSelectedUser(filteredUsers.find((c) => encodeStringForUrl(c.user_id) === selected) || null);
		} else {
			setSelectedUser(users.find((c) => encodeStringForUrl(c.user_id) === selected) || null);
		}
	}, [users, filteredUsers, areUsersFiltered, selected]);

	useEffect(() => {
		if (filter.length > 2) {
			setAreUsersFiltered(true);
			loadUsersWithFilter(filter);
		} else {
			setAreUsersFiltered(false);
		}
	}, [filter]);

	const loadData = async (page = 1) => {
		try {
			const pageData = await UsersAdminApi.getUsers(page);

			setUsers((current) => {
				const pagesBefore = current.slice(0, pageSize * (page - 1));
				const pagesAfter = current.slice(page * pageSize);

				return [...pagesBefore, ...pageData, ...pagesAfter];
			});

			return pageData.length;
		} catch {
			return 0;
		}
	};

	const loadUsersWithFilter = async (search: string) => {
		try {
			setIsSearching(true);

			const matchingUsers = await UsersAdminApi.search(search);

			setFilteredUsers(matchingUsers);
		} finally {
			setIsSearching(false);
		}
	};

	const selectUser = (user: IApiUser) => () => {
		navigate(null, { page, selected: encodeStringForUrl(user.user_id) });
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<TextFilterInput value={filter} onChange={(newFilter) => setFilter(newFilter)} label="Filter users" />
				{(isFetching || isSearching) && <LinearProgress />}
				{areUsersFiltered ? (
					<List component="nav" sx={{ minWidth: '200px', overflowY: 'auto' }}>
						{filteredUsers.map((user, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton selected={selectedUser?.user_id === user.user_id} onClick={selectUser(user)}>
									<ListItemText>{user.name || `${user.user_id} (Not found in AD)`}</ListItemText>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				) : (
					<List component="nav" sx={{ minWidth: '200px', overflowY: 'auto' }} ref={listComponent}>
						{users.map((user, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton selected={selectedUser?.user_id === user.user_id} onClick={selectUser(user)}>
									<ListItemText>{user.name || `${user.user_id} (Not found in AD)`}</ListItemText>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				)}
			</Box>
			<Divider orientation="vertical" />
			{selectedUser && <UserAdmin user={selectedUser} onChange={() => loadData()} />}
		</Box>
	);
}
