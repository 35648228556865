import { Box, ButtonBase, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { IApiRiskType } from '@mitie/risk-register-api-types';

interface IRiskTypePickerDialogProps {
	list: IApiRiskType[];
	onClose: (selected?: number) => void;
}

export default function RiskTypePickerDialog({ list, onClose }: IRiskTypePickerDialogProps) {
	const buttonClicked = (value: number) => {
		onClose(value);
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md">
			<DialogTitle>Pick risk type</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{list.map(({ risk_type_id, name, description }) => (
						<ButtonBase
							sx={{
								margin: (theme) => theme.spacing(1),
								flexDirection: 'column',
								flexShrink: 0,
								borderRadius: '4px',
								padding: (theme) => theme.spacing(1),
								'&:hover': {
									'&>div': {
										opacity: (theme) => (theme.palette.mode === 'light' ? 0.1 : 0.1),
									},
								},
								border: (theme) => `1px solid ${theme.palette.primary.main}`,
								color: (theme) => theme.palette.primary.main,
							}}
							focusRipple
							onClick={() => buttonClicked(risk_type_id)}
							key={risk_type_id}
						>
							<Typography sx={{ zIndex: 1 }}>{name}</Typography>
							<Typography variant="caption" sx={{ zIndex: 1 }}>
								{description}
							</Typography>
							<Box
								sx={{
									position: 'absolute',
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
									backgroundColor: (theme) =>
										theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
									opacity: (theme) => (theme.palette.mode === 'light' ? 0 : 0),
									transition: (theme) => theme.transitions.create('opacity'),
								}}
							/>
						</ButtonBase>
					))}
				</Box>
			</DialogContent>
		</Dialog>
	);
}
