import { FormEvent, useState } from 'react';
import { Alert, Box, Button, TextField } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

import * as AuthApi from 'api/auth';
import { emailIsValid, ErrorWithHttpCode } from 'utils';

export default function LoginForm() {
	const [isFormDisplayed, setIsFormDisplayed] = useState(false);
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const login = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setErrorMessage('');

		try {
			await AuthApi.login(email);
		} catch (error) {
			if (error instanceof ErrorWithHttpCode) {
				setErrorMessage(error.message);
			}
		}
	};

	if (!isFormDisplayed) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: (theme) => theme.spacing(4),
					marginBottom: (theme) => theme.spacing(2),
				}}
			>
				<Button color="secondary" variant="contained" onClick={() => setIsFormDisplayed(true)} sx={{ height: '52px' }}>
					Sign In
				</Button>
			</Box>
		);
	} else {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginTop: (theme) => theme.spacing(4),
					marginBottom: (theme) => theme.spacing(2),
				}}
			>
				<form onSubmit={login} style={{ display: 'flex', alignItems: 'stretch', flexGrow: 1 }}>
					<TextField
						label="Enter your email address"
						name="username"
						id="username"
						autoComplete="username"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						sx={{ flexGrow: 1 }}
					/>
					<Button
						disabled={!emailIsValid(email)}
						type="submit"
						color="secondary"
						variant="contained"
						endIcon={<ArrowForward />}
						sx={{ marginLeft: (theme) => theme.spacing(2) }}
					>
						Sign In
					</Button>
				</form>
				{errorMessage && (
					<Alert severity="error" sx={{ marginTop: (theme) => theme.spacing(2) }}>
						{errorMessage}
					</Alert>
				)}
			</Box>
		);
	}
}
