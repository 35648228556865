import React from 'react';
import { Typography } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

export default function ServerError() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'ServerError');

	componentTracking();

	return (
		<Typography>
			Unable to load Risk Safe due to a server error. Please try again or contact us if the error persists.
		</Typography>
	);
}
