import { Alert, Snackbar } from '@mui/material';

import useAlerts from 'hooks/useAlerts';

export default function AlertsBanner() {
	const { alerts, removeAlertWithId } = useAlerts();

	const removeAlert = (alertId: string) => {
		return (event?: React.SyntheticEvent | Event, reason?: string) => {
			if (reason === 'clickaway') {
				return;
			}

			removeAlertWithId(alertId);
		};
	};

	const currentAlert = alerts[0];

	if (!currentAlert) {
		return null;
	}

	return (
		<Snackbar
			open={true}
			onClose={removeAlert(currentAlert.id)}
			autoHideDuration={currentAlert.timeout}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			key={currentAlert.id}
		>
			<Alert severity={currentAlert.type} elevation={6} variant="filled" onClose={removeAlert(currentAlert.id)}>
				{currentAlert.message}
			</Alert>
		</Snackbar>
	);
}
