import { IRiskRegisterNotification } from '@mitie/risk-register-api-types';
import React, { useCallback, useContext, useState } from 'react';

import * as NotificationsApi from '../api/notifications';
import { UserContext } from './UserProvider';
import { ErrorWithHttpCode, HttpCode } from '../utils';

interface INotificationStore {
	notifications: IRiskRegisterNotification[];
	notificationsFetching: boolean;
	fetchNotifications: () => void;
}

export const NotificationsContext = React.createContext<INotificationStore>({
	notifications: [],
	notificationsFetching: false,
	fetchNotifications: () => undefined,
});

export default function NotificationsProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [notifications, setNotifications] = useState<IRiskRegisterNotification[]>([]);
	const [notificationsFetching, setNotificationsFetching] = useState(false);
	const { setAuthenticationStatus } = useContext(UserContext);

	const fetchNotifications = async () => {
		try {
			setNotificationsFetching(true);

			const notifications = await NotificationsApi.getAll();
			setNotifications(notifications);
		} catch (error) {
			if (error instanceof ErrorWithHttpCode && error.code === HttpCode.Unauthorized) {
				setAuthenticationStatus('Unauthenticated');
			}
		} finally {
			setNotificationsFetching(false);
		}
	};

	const contextValue = {
		notifications,
		notificationsFetching,
		fetchNotifications: useCallback(fetchNotifications, [setAuthenticationStatus]),
	};

	return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
}
