import { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

import RiskScorePickerDialog from './RiskScorePickerDialog';
import { IApiRiskImpactCategory, IApiRiskScoreUpsert } from '@mitie/risk-register-api-types';

interface IRiskScoreInputProps {
	value: IApiRiskScoreUpsert | null;
	defaultValue?: IApiRiskScoreUpsert | null;
	impactCategories: IApiRiskImpactCategory[];
	loading: boolean;
	label: string;
	onChange: (value: IApiRiskScoreUpsert) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
			borderRadius: '8px',
			margin: theme.spacing(1),
			padding: theme.spacing(1),
		},
		score: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: '20px',
			cursor: 'default',
		},
	}),
);

export default function RiskScoreInput({
	value,
	defaultValue,
	loading,
	impactCategories,
	label,
	onChange,
}: IRiskScoreInputProps) {
	const classes = useStyles();
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const backgroundColor = value
		? value.score < 5
			? '#00B050'
			: value.score < 11
			? '#FFFF00'
			: value.score < 16
			? '#FFC000'
			: '#FF0000'
		: '#CCCCCC';
	const color = 'black';
	const size = '48px';
	const valueForEditing = value || defaultValue || null;

	return (
		<div className={classes.root}>
			<Typography variant="subtitle2">{label}</Typography>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div
					style={{
						borderRadius: '50%',
						marginRight: '8px',
						marginTop: '8px',
						flexShrink: 0,
						color,
						backgroundColor,
						width: size,
						height: size,
					}}
				>
					<Typography className={classes.score} style={{ lineHeight: size }}>
						{value ? value.score : '?'}
					</Typography>
				</div>
				{value ? (
					<div>
						<Typography variant="body2">{`Likelihood: ${value.likelihood}`}</Typography>
						<Typography variant="body2">{`Consequence: ${value.impact}`}</Typography>
					</div>
				) : (
					<Typography variant="body2">Risk score is not defined</Typography>
				)}
				<Button
					variant="outlined"
					size="small"
					sx={{ marginLeft: '16px' }}
					onClick={() => setIsPickerOpen(true)}
					disabled={loading}
				>
					{value ? 'Change...' : 'Pick...'}
				</Button>
			</div>
			{isPickerOpen && (
				<RiskScorePickerDialog
					value={valueForEditing}
					impactCategories={impactCategories}
					onClose={(value) => {
						if (value) {
							onChange(value);
						}

						setIsPickerOpen(false);
					}}
				/>
			)}
		</div>
	);
}
