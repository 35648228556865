import { useState } from 'react';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { IApiRisk, IApiRiskRegister } from '@mitie/risk-register-api-types';

import * as RiskRegistersApi from '../api/riskRegisters';
import useAlerts from '../hooks/useAlerts';

interface IEscalateRiskDialogProps {
	risk: IApiRisk;
	register: IApiRiskRegister;
	onClose: (result?: boolean) => void;
}

export default function EscalateRiskDialog({ risk, register, onClose }: IEscalateRiskDialogProps) {
	const { addAlert } = useAlerts();
	const [includeControls, setIncludeControls] = useState(false);

	return (
		<Dialog open={true}>
			<DialogTitle>Escalate risk</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Typography>
						Please confirm you want to escalate this risk. it will be added to the parent risk register.
					</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={includeControls}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										setIncludeControls(event.target.checked);
									}}
								/>
							}
							label="Include controls and mitigation measures"
						/>
					</FormGroup>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					variant="contained"
					onClick={async () => {
						const { success, message } = await RiskRegistersApi.escalateRisk(
							register.risk_register_id,
							risk.risk_id,
							includeControls,
						);
						if (success) {
							addAlert('success', message);
						} else {
							addAlert('warning', message);
						}

						onClose(success);
					}}
					color="primary"
				>
					Escalate risk
				</Button>
			</DialogActions>
		</Dialog>
	);
}
