import { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';
import { Close, RemoveCircle, Save } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { IApiCompany, IApiUserDirectory, IAzureAdDirectoryConfiguration } from '@mitie/risk-register-api-types';

import * as CompaniesApi from '../api/companies';
import useAlerts from '../hooks/useAlerts';
import ConfirmDialog from './ConfirmDialog';

interface IUserDirectoryConfigureDialogProps {
	company: IApiCompany;
	currentUserDirectory: IApiUserDirectory | null;
	onClose: () => void;
	onChange: () => void;
}

const validationSchema = yup.object({
	tenant_id: yup.string().required('The tenant ID is required').uuid('Enter a valid tenant ID'),
	client_id: yup.string().required('The client ID is required').uuid('Enter a valid client ID'),
	client_secret: yup
		.string()
		.required('The client secret is required')
		.not(['******'], 'Please re-enter the client secret'),
});

export default function UserDirectoryConfigureDialog({
	company,
	currentUserDirectory,
	onChange,
	onClose,
}: IUserDirectoryConfigureDialogProps) {
	const { addAlert } = useAlerts();
	const [isSaving, setIsSaving] = useState(false);
	const [isRemoveConfirmDialogOpen, setIsRemoveConfirmDialogOpen] = useState(false);
	const formik = useFormik<IAzureAdDirectoryConfiguration>({
		initialValues: {
			tenant_id: currentUserDirectory?.configuration.tenant_id || '',
			client_id: currentUserDirectory?.configuration.client_id || '',
			client_secret: currentUserDirectory ? '******' : '',
		},
		validationSchema,
		onSubmit: async (data) => {
			setIsSaving(true);

			try {
				if (currentUserDirectory) {
					await CompaniesApi.updateUserDirectory(company.company_id, currentUserDirectory.user_directory_id, {
						configuration: data,
					});
				} else {
					await CompaniesApi.createUserDirectory(company.company_id, { type: 'Azure AD', configuration: data });
				}

				addAlert('success', `The user directory has been updated for company ${company.name}`);
				onChange();
				onClose();
			} finally {
				setIsSaving(false);
			}
		},
	});

	const removeUserDirectory = async () => {
		if (!currentUserDirectory) {
			return;
		}

		setIsSaving(true);

		try {
			await CompaniesApi.deleteUserDirectory(company.company_id, currentUserDirectory.user_directory_id);

			addAlert('success', `The user directory has been updated for company ${company.name}`);
			onChange();
			onClose();
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Dialog open={true} maxWidth="md">
			<DialogTitle>Configure user directory</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						label="Tenant ID"
						name="tenant_id"
						autoComplete="off"
						value={formik.values.tenant_id}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.tenant_id)}
						helperText={formik.touched.tenant_id && formik.errors.tenant_id}
						sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
					/>
					<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
						The Azure tenant ID. Enter it as a UUID using the following format: 123e4567-e89b-12d3-a456-426655440000
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						label="Client ID"
						name="client_id"
						autoComplete="off"
						value={formik.values.client_id}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.client_id)}
						helperText={formik.touched.client_id && formik.errors.client_id}
						sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
					/>
					<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
						The client ID for the service principal used to access Azure AD. This service principal requires the
						following Graph API permission: &quot;User.Read.All&quot;. Enter it as a UUID using the following format:
						123e4567-e89b-12d3-a456-426655440000
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						label="Client secret"
						name="client_secret"
						type="password"
						autoComplete="new-password"
						value={formik.values.client_secret}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.client_secret)}
						helperText={formik.touched.client_secret && formik.errors.client_secret}
						sx={{ margin: (theme) => `${theme.spacing(2)} 0`, width: '300px', flexShrink: 0 }}
					/>
					<Typography variant="caption" sx={{ marginLeft: (theme) => theme.spacing(2) }}>
						The secret for the service principal.
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<RemoveCircle />}
					variant="outlined"
					onClick={() => setIsRemoveConfirmDialogOpen(true)}
					color="primary"
					disabled={!currentUserDirectory || isSaving}
				>
					Remove
				</Button>
				<Button
					startIcon={<Save />}
					variant="contained"
					onClick={formik.submitForm}
					color="primary"
					disabled={!formik.dirty || !formik.isValid || isSaving}
				>
					Save
				</Button>
			</DialogActions>
			{isRemoveConfirmDialogOpen && (
				<ConfirmDialog
					title="Confirm removing user directory"
					message={`Are you sure you want to remove the user directory configuration for customer '${company.name}'?`}
					confirmLabel="Remove user directory"
					cancelLabel="Cancel"
					onClose={() => setIsRemoveConfirmDialogOpen(false)}
					onConfirm={removeUserDirectory}
				/>
			)}
		</Dialog>
	);
}
