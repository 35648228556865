import { useEffect } from 'react';
import { Avatar, Box, Chip, CircularProgress, Tooltip, Typography } from '@mui/material';

import useUsers from 'hooks/useUsers';
import { initials } from 'utils';

interface IDirectoryPersonChipProps {
	email: string;
}

export default function DirectoryPersonChip({ email }: IDirectoryPersonChipProps) {
	const { usersCache, fetchUserFromDirectory } = useUsers();

	useEffect(() => {
		fetchUserFromDirectory(email);
	}, [email]);

	const user = usersCache[email.toLowerCase()];
	const isLoading = user?.isLoading;

	return isLoading ? (
		<CircularProgress size={16} sx={{ marginRight: (theme) => theme.spacing(1) }} />
	) : !user?.data ? (
		<Chip variant="outlined" size="small" label={email} />
	) : (
		<Tooltip
			title={
				<Box sx={{ padding: '4px', display: 'flex' }}>
					{user.photo ? (
						<Avatar src={user.photo.image} alt="avatar" sx={{ marginRight: (theme) => theme.spacing(1) }} />
					) : (
						<Avatar sx={{ marginRight: (theme) => theme.spacing(1) }}>{initials(user.data.name)}</Avatar>
					)}
					<Box>
						<Typography variant="subtitle2">{user.data.name}</Typography>
						<Typography variant="caption" component="div">
							{user.data.job_title}
						</Typography>
						<Typography variant="caption" component="div">
							{user.data.user_id}
						</Typography>
					</Box>
				</Box>
			}
			placement="bottom"
			arrow
		>
			<Chip size="small" label={user.data.name} sx={{ cursor: 'pointer' }} />
		</Tooltip>
	);
}
