import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, NativeSelect, Typography } from '@mui/material';
import { IApiRiskRegister, IApiRisk, IApiRiskCatalogueItem } from '@mitie/risk-register-api-types';
import sortBy from 'lodash/sortBy';
import { Add } from '@mui/icons-material';
import { Bookshelf } from 'mdi-material-ui';

import CreateOrUpdateRiskDialog from './CreateOrUpdateRiskDialog';
import RiskSummary from './RiskSummary';
import useNotifications from 'hooks/useNotifications';
import RiskDetails from './RiskDetails';
import CatalogueItemPickerDialog from './CatalogueItemPickerDialog';
import ActionButtons, { IActionButtonProps } from './ActionButtons';
import TextFilterInput from './TextFilterInput';

interface IRisksProps {
	register: IApiRiskRegister;
	registerParents: IApiRiskRegister[];
	childrenRegisters: IApiRiskRegister[];
	allRegisters: IApiRiskRegister[];
	risks: IApiRisk[];
	selectedRisk?: IApiRisk | null;
	onChange: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
		},
		riskItem: {
			margin: theme.spacing(1),
		},
		riskHeader: {
			display: 'flex',
			margin: theme.spacing(1),
		},
		riskHeaderText: {
			marginLeft: theme.spacing(2),
		},
	}),
);

type SortOption = 'residualRisk' | 'state' | 'owner' | 'principalRisk' | 'appetiteStatus';

const generateBucketsForRisk = ({
	residual_score,
	state,
	owner,
	principal_risk,
	linked_principal_risks,
	appetite_status,
}: IApiRisk) => {
	const riskBucket =
		residual_score === null
			? { sortName: '5', displayName: 'Residual score not defined' }
			: residual_score.score < 5
			? { sortName: '4', displayName: 'Manageable risks' }
			: residual_score.score < 11
			? { sortName: '3', displayName: 'Material risks' }
			: residual_score.score < 16
			? { sortName: '2', displayName: 'Significant risks' }
			: { sortName: '1', displayName: 'Severe risks' };
	const principalRiskBucket = principal_risk
		? { sortName: '1', displayName: 'Principal risks' }
		: linked_principal_risks.length
		? { sortName: '2', displayName: 'Linked with principal risk' }
		: { sortName: '3', displayName: 'Other risks' };
	const stateBucket =
		state === 'Pending acceptance'
			? { sortName: '1', displayName: 'Added from another registry and requires a review' }
			: state === 'Pending treatment'
			? { sortName: '2', displayName: 'Risk treatment not completed' }
			: state === 'Live'
			? { sortName: '3', displayName: 'Live risks' }
			: { sortName: '4', displayName: 'Retired risks' };
	const ownerBucket = owner
		? { sortName: owner, displayName: `Owner: ${owner}` }
		: { sortName: '', displayName: 'No owner' };
	const appetiteStatusBucket =
		appetite_status === 'Above appetite'
			? { sortName: '1', displayName: 'Above appetite' }
			: appetite_status === 'In line with appetite'
			? { sortName: '2', displayName: 'In line with appetite' }
			: appetite_status === 'Below appetite'
			? { sortName: '3', displayName: 'Below appetite' }
			: { sortName: '4', displayName: 'Appetite status not defined' };

	const buckets: { [sortOption in SortOption]: { sortName: string; displayName: string } } = {
		residualRisk: riskBucket,
		state: stateBucket,
		owner: ownerBucket,
		principalRisk: principalRiskBucket,
		appetiteStatus: appetiteStatusBucket,
	};

	return buckets;
};

export default function Risks({
	register,
	registerParents,
	childrenRegisters,
	allRegisters,
	risks,
	selectedRisk,
	onChange,
}: IRisksProps) {
	const classes = useStyles();
	const [risksInRegister, setRisksInRegister] = useState<
		{
			risk: IApiRisk;
			buckets: { [sortOption in SortOption]: { sortName: string; displayName: string } };
		}[]
	>();
	const [isAddRiskPopupOpen, setIsAddRiskPopupOpen] = useState(false);
	const [isAddRiskFromCataloguePopupOpen, setIsAddRiskFromCataloguePopupOpen] = useState(false);
	const [selectedCatalogueItem, setSelectedCatalogueItem] = useState<IApiRiskCatalogueItem | undefined>();
	const [orderBy, setOrderBy] = useState<SortOption>('residualRisk');
	const [displayedRisks, setDisplayedRisks] = useState<{ sortName: string; displayName: string; risks: IApiRisk[] }[]>(
		[],
	);
	const { fetchNotifications } = useNotifications();
	const [filter, setFilter] = useState<string>('');

	useEffect(() => {
		setRisksInRegister(risks.map((risk) => ({ buckets: generateBucketsForRisk(risk), risk })));
	}, [risks]);

	useEffect(() => {
		// Client side sorting

		if (!risksInRegister) {
			setDisplayedRisks([]);
		} else {
			// Filter according to the Filter term
			let filteredRisks: {
				risk: IApiRisk;
				buckets: { [sortOption in SortOption]: { sortName: string; displayName: string } };
			}[];

			if (filter) {
				const searchTerm = filter.toLowerCase();

				filteredRisks = risksInRegister.filter(({ risk }) => {
					const toSearchList = [risk.name, risk.description, risk.owner, risk.sponsor];

					if (risk.risk_category) {
						toSearchList.push(risk.risk_category.name);
					}

					if (risk.risk_type) {
						toSearchList.push(risk.risk_type.name);
					}

					const toSearch = toSearchList.join('~').toLowerCase();

					return toSearch.includes(searchTerm);
				});
			} else {
				filteredRisks = risksInRegister;
			}

			// Group by bucket according to the "Group by" dropdown
			const risksByBucketObj = filteredRisks.reduce(
				(
					risksByBucket: {
						[bucket: string]: { sortName: string; displayName: string; risks: IApiRisk[] };
					},
					{ risk, buckets },
				) => {
					if (buckets[orderBy]) {
						const { sortName, displayName } = buckets[orderBy];

						if (!risksByBucket[sortName]) {
							risksByBucket[sortName] = { sortName, displayName, risks: [] };
						}

						risksByBucket[sortName].risks.push(risk);
					}

					return risksByBucket;
				},
				{},
			);

			// Always sort risks descending residual score inside each bucket
			const risksByBucket = Object.values(risksByBucketObj).map(({ sortName, displayName, risks }) => ({
				sortName,
				displayName,
				risks: risks.sort((a, b) => (b.residual_score?.score || 0) - (a.residual_score?.score || 0)),
			}));

			setDisplayedRisks(sortBy(Object.values(risksByBucket), 'sortName'));
		}
	}, [risksInRegister, orderBy, filter]);

	const actions: IActionButtonProps[] = [
		{ label: 'Add risk...', icon: <Add />, onClick: () => setIsAddRiskPopupOpen(true) },
	];

	if (register?.risk_register_lifecycle_status) {
		actions.push({
			label: 'Add risk from catalogue...',
			icon: <Bookshelf />,
			onClick: () => setIsAddRiskFromCataloguePopupOpen(true),
		});
	}

	return (
		<div className={classes.root}>
			{selectedRisk ? (
				<RiskDetails
					register={register}
					registerParents={registerParents}
					childrenRegisters={childrenRegisters}
					allRegisters={allRegisters}
					risk={selectedRisk}
					onChange={() => {
						onChange();
						fetchNotifications();
					}}
				/>
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							{register.permissions.includes('create-risk') && <ActionButtons items={actions} />}
						</Box>
						<Box sx={{ display: 'flex', marginRight: (theme) => theme.spacing(1) }}>
							<Box sx={{ marginRight: (theme) => theme.spacing(2), display: 'flex', alignItems: 'center' }}>
								<Typography sx={{ whiteSpace: 'nowrap' }}>Group by: </Typography>
								<NativeSelect
									value={orderBy}
									onChange={(event) => setOrderBy(event.target.value as SortOption)}
									variant="standard"
									sx={{ marginLeft: (theme) => theme.spacing(1) }}
								>
									<option value="residualRisk">Residual score</option>
									<option value="appetiteStatus">Appetite status</option>
									<option value="principalRisk">Principal risk</option>
									<option value="owner">Risk owner</option>
									<option value="state">State</option>
								</NativeSelect>
							</Box>
							<TextFilterInput label="Filter risks" value={filter} onChange={(newFilter) => setFilter(newFilter)} />
						</Box>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							flexWrap: 'nowrap',
							overflowX: 'auto',
						}}
					>
						{displayedRisks.length > 0 ? (
							displayedRisks.map(({ displayName, risks }, index) => (
								<Box
									sx={{
										padding: (theme) => theme.spacing(2),
										display: 'flex',
										flexDirection: 'column',
										flexGrow: 1,
									}}
									key={index}
								>
									<Typography variant="h6" sx={{ flexWrap: 'wrap' }}>
										{displayName}
									</Typography>
									<Box sx={{ overflowY: 'auto', display: 'flex', flexWrap: 'wrap' }}>
										{risks.map((risk) => (
											<RiskSummary
												key={risk.risk_id}
												risk={risk}
												sx={{
													margin: (theme) => theme.spacing(1),
													width: '400px',
												}}
											/>
										))}
									</Box>
								</Box>
							))
						) : (
							<Box sx={{ margin: (theme) => theme.spacing(1) }}>
								<Typography>There is no risk in this register</Typography>
							</Box>
						)}
					</Box>
				</>
			)}
			{isAddRiskPopupOpen && (
				<CreateOrUpdateRiskDialog
					register={register}
					mode="create"
					catalogueItem={selectedCatalogueItem}
					onClose={async (result: boolean) => {
						if (result) {
							onChange();
							fetchNotifications();
						}

						setSelectedCatalogueItem(undefined);
						setIsAddRiskPopupOpen(false);
					}}
				/>
			)}
			{isAddRiskFromCataloguePopupOpen && register.risk_register_lifecycle_status && (
				<CatalogueItemPickerDialog
					lifecycleStatusId={register.risk_register_lifecycle_status?.risk_register_lifecycle_status_id}
					onSelect={(catalogueItem) => {
						setSelectedCatalogueItem(catalogueItem);
						setIsAddRiskPopupOpen(true);
					}}
					onClose={() => {
						setIsAddRiskFromCataloguePopupOpen(false);
					}}
				/>
			)}
		</div>
	);
}
