import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiAuthenticationProvider } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as AuthProvidersApi from 'api/authenticationProviders';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import AuthenticationProvider from './AuthenticationProvider';
import AddAuthenticationProviderDialog from './AddAuthenticationProviderDialog';

export default function AuthenticationProvidersAdmin() {
	const [selectedAuthProvider, setSelectedAuthProvider] = useState<IApiAuthenticationProvider | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [authProviders, setAuthProviders] = useState<IApiAuthenticationProvider[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedAuthProvider(authProviders.find((c) => encodeStringForUrl(c.name) === selected) || null);
	}, [authProviders, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await AuthProvidersApi.getAllAuthenticationProviders();

			setAuthProviders(data.sort((c1, c2) => (c1.name > c2.name ? 1 : -1)));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto', flexShrink: 0 }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						New authentication provider
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{authProviders.map((authProvider) => (
					<ListItemButton
						key={authProvider.authentication_provider_id}
						selected={selectedAuthProvider?.authentication_provider_id === authProvider.authentication_provider_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(authProvider.name) })}
					>
						<ListItemText primary={authProvider.name} />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{selectedAuthProvider && (
				<AuthenticationProvider item={selectedAuthProvider} onChange={loadData} isDataLoading={isLoading} />
			)}
			{isAddDialogOpen && (
				<AddAuthenticationProviderDialog
					onChange={() => loadData()}
					onClose={() => {
						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
