import { Box, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import { useNavigate, useParams } from 'routing/routing';
import RiskRegisterTypes from 'components/RiskRegisterTypes';
import RiskTypes from 'components/RiskTypes';
import RiskCategories from 'components/RiskCategories';
import RiskImpactMatrix from 'components/RiskImpactMatrix';
import StrategicPillars from 'components/StrategicPillars';

interface IDataset {
	key: string;
	name: string;
	description: string;
}

const datasets: IDataset[] = [
	{ key: 'risk-register-types', name: 'Risk register types', description: 'Configure risk register types' },
	{ key: 'risk-types', name: 'Risk types', description: 'Configure risk types' },
	{ key: 'risk-categories', name: 'Risk categories', description: 'Configure risk categories' },
	{
		key: 'risk-impact-matrix',
		name: 'Risk impact matrix',
		description: 'Configure the risk impact matrix',
	},
	{ key: 'pillars', name: 'Strategic pillars', description: 'Configure strategic pillars' },
];

export default function Configuration() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'GlobalConfigurationPage');
	const navigate = useNavigate();
	const { page } = useParams();

	componentTracking();

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto' }}>
				{datasets.map(({ key, name, description }) => (
					<ListItemButton key={key} selected={key === page} onClick={() => navigate(null, { page: key })}>
						<ListItemText primary={name} secondary={description} />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{page === 'risk-register-types' && <RiskRegisterTypes />}
			{page === 'risk-types' && <RiskTypes />}
			{page === 'risk-categories' && <RiskCategories />}
			{page === 'risk-impact-matrix' && <RiskImpactMatrix />}
			{page === 'pillars' && <StrategicPillars />}
		</Box>
	);
}
