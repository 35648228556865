import React, { useEffect, useState } from 'react';
import { IApiUserFromDirectory, IApiUserPhoto } from '@mitie/risk-register-api-types';

import * as UserDirectoryApi from '../api/userDirectory';

export interface IUserDetailsFromDirectory {
	isLoading: boolean;
	data?: IApiUserFromDirectory;
	photo?: IApiUserPhoto;
}

interface IUsersStore {
	usersCache: { [userId: string]: IUserDetailsFromDirectory };
	fetchUserFromDirectory: (id: string) => void;
	saveUsersInCache: (data: IApiUserFromDirectory[]) => void;
}

export const UsersContext = React.createContext<IUsersStore>({
	usersCache: {},
	fetchUserFromDirectory: () => undefined,
	saveUsersInCache: () => undefined,
});

export default function UsersProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [usersCache, setUsers] = useState<{
		[userId: string]: IUserDetailsFromDirectory;
	}>({});
	const [usersToLoad, setUsersToLoad] = useState<string[]>([]);

	useEffect(() => {
		if (!usersToLoad.length) {
			return;
		}

		const handler = setTimeout(() => doFetchUsers(), 500);

		return () => clearTimeout(handler);
	}, [usersToLoad]);

	const fetchUserFromDirectory = (id: string) => {
		const lowercaseId = id.toLowerCase();

		if (!usersCache[lowercaseId]) {
			setUsersToLoad((current) => [...new Set([...current, lowercaseId]).values()]);
		}
	};

	const doFetchUsers = async () => {
		setUsers((current) => {
			const data = { ...current };

			for (const id of usersToLoad) {
				if (!data[id]) {
					data[id] = { isLoading: true };
				} else {
					data[id].isLoading = true;
				}
			}

			return data;
		});

		const usersData = await UserDirectoryApi.getByIds(usersToLoad);
		saveUsersInCache(usersData);

		const photosData = await UserDirectoryApi.getPhotosByIds(usersToLoad);
		saveUsersPhotos(photosData);

		setUsersToLoad([]);
	};

	const saveUsersInCache = (usersData: IApiUserFromDirectory[]) => {
		setUsers((current) => {
			const data = { ...current };

			for (const userData of usersData) {
				const id = userData.user_id.toLowerCase();

				if (data[id]) {
					data[id].isLoading = false;
					data[id].data = userData;
				} else {
					data[id] = {
						isLoading: false,
						data: userData,
					};
				}
			}

			for (const userLoading of usersToLoad) {
				if (data[userLoading]?.isLoading) {
					data[userLoading].isLoading = false;
				}
			}

			return data;
		});
	};

	const saveUsersPhotos = (photos: IApiUserPhoto[]) => {
		setUsers((current) => {
			const data = { ...current };

			for (const photo of photos) {
				const id = photo.user_id.toLowerCase();

				if (data[id]) {
					data[id].photo = photo;
				}
			}

			return data;
		});
	};

	const contextValue = {
		usersCache,
		fetchUserFromDirectory,
		saveUsersInCache,
	};

	return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
}
