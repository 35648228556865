import { useState } from 'react';
import { Button, Chip, InputAdornment, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';

interface IManualPersonInputProps {
	value: string[];
	label: string;
	addLabel: string;
	disabled?: boolean;
	onChange: (value: string[]) => void;
}

export default function ManualPersonInput({ value, label, addLabel, disabled, onChange }: IManualPersonInputProps) {
	const delimiterKeys = ['Enter', 'Tab'];
	const [inputValue, setInputValue] = useState('');

	const addItem = () => {
		if (inputValue.length > 0 && !value.includes(inputValue)) {
			onChange([...value, inputValue]);
			setInputValue('');
		}
	};

	const removeItem = (item: string) => {
		if (value.includes(item)) {
			onChange(value.filter((t) => t !== item));
		}
	};

	const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (delimiterKeys.includes(event.key)) {
			addItem();

			event.preventDefault(); // prevent triggering the onChange event
		}
	};

	return (
		<TextField
			value={inputValue}
			label={label}
			onKeyDown={keyDown}
			onChange={(event) => setInputValue(event.currentTarget.value)}
			placeholder={disabled ? '' : addLabel}
			disabled={disabled}
			InputProps={{
				startAdornment: (
					<InputAdornment
						position="start"
						sx={{
							height: 'auto',
							maxHeight: 'none',
							flexWrap: 'wrap',
						}}
					>
						{value.map((item) => (
							<Chip
								key={item}
								label={item}
								onDelete={disabled ? undefined : () => removeItem(item)}
								sx={{ margin: (theme) => theme.spacing(1) }}
							/>
						))}
					</InputAdornment>
				),
				endAdornment: disabled ? null : (
					<InputAdornment position="end">
						<Button
							variant="outlined"
							size="small"
							disabled={inputValue.length === 0}
							startIcon={<Add />}
							onClick={addItem}
						>
							Add
						</Button>
					</InputAdornment>
				),
			}}
			sx={{
				margin: (theme) => `${theme.spacing(1)} ${theme.spacing(1)}`,
				'& input': {
					width: '20rem',
					flexGrow: 1,
				},
			}}
		/>
	);
}
