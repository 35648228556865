import { useState, useEffect } from 'react';
import { Box, LinearProgress, Button, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiUser } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import * as Api from '../api/users';
import { useNavigate, useParams } from '../routing/routing';
import { encodeStringForUrl } from '../utils';
import useAlerts from 'hooks/useAlerts';
import AddUserDialog from 'components/AddUserDialog';
import UserDetails from 'components/UserDetails';
import TextFilterInput from 'components/TextFilterInput';

export default function Users() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'ManageUsersPage');
	const [users, setUsers] = useState<IApiUser[]>();
	const [selectedUser, setSelectedUser] = useState<IApiUser>();
	const [isLoading, setIsLoading] = useState(false);
	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
	const [filter, setFilter] = useState<string>('');
	const { user } = useParams();
	const navigate = useNavigate();
	const { addAlert } = useAlerts();

	useEffect(() => {
		loadUsersList();
	}, []);

	useEffect(() => {
		// Select user in url on page load
		if (users) {
			const match = users.find((r) => encodeStringForUrl(r.user_id) === user);
			setSelectedUser(match);
		}
	}, [user, users]);

	const loadUsersList = async () => {
		setIsLoading(true);

		try {
			const data = await Api.getAll();
			setUsers(data.sort((a, b) => (a.name > b.name ? 1 : -1)));

			if (data.length > 0 && user === undefined) {
				const user = encodeStringForUrl(data[0].user_id);
				navigate(null, { user });
			}
		} catch {
			addAlert('error', 'Failed to load the list of users');
		} finally {
			setIsLoading(false);
		}
	};

	componentTracking();

	return (
		<Box sx={{ display: 'flex', width: '100%' }}>
			<Box
				sx={{
					borderRight: (theme) => `1px solid ${theme.palette.divider}`,
					minWidth: '300px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setAddUserDialogOpen(true)}
					>
						Add user
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{users && (
					<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
						<TextFilterInput value={filter} onChange={(newFilter) => setFilter(newFilter)} label="Filter users" />
						<List component="nav" sx={{ overflowY: 'auto', flexGrow: 1 }}>
							{users
								.filter((u) => !filter.length || u.name.toLowerCase().includes(filter.toLowerCase()))
								.map((user) => (
									<ListItemButton
										selected={user.user_id === selectedUser?.user_id}
										onClick={() => navigate(null, { user: encodeStringForUrl(user.user_id) })}
										key={user.user_id}
									>
										<ListItemText>{user.name || `${user.user_id} (Not found in AD)`}</ListItemText>
									</ListItemButton>
								))}
						</List>
					</Box>
				)}
			</Box>
			<Box sx={{ display: 'flex', flexGrow: 1, overflow: 'auto' }}>
				{selectedUser && <UserDetails user={selectedUser} onChange={loadUsersList} />}
			</Box>
			{addUserDialogOpen && (
				<AddUserDialog
					onClose={async (addedUserId) => {
						if (addedUserId) {
							await loadUsersList();
							navigate(null, { user: encodeStringForUrl(addedUserId) });
						}

						setAddUserDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
