import { IApiRiskType, IApiRiskTypeUsage } from '@mitie/risk-register-api-types';

import * as Api from './api';

export function getLiveRiskTypes() {
	return Api.get<IApiRiskType[]>('risk-types');
}

export function getDisabledTypes() {
	return Api.get<IApiRiskType[]>('risk-types/disabled');
}

export function getUsageForType(riskTypeId: number) {
	return Api.get<IApiRiskTypeUsage>(`risk-types/${riskTypeId}/usage`);
}

export function saveType(
	riskTypeId: number,
	data: Partial<
		IApiRiskType & {
			disabled: boolean;
		}
	>,
) {
	return Api.patch<void>(`risk-types/${riskTypeId}`, data);
}

export function createType(
	data: Partial<
		IApiRiskType & {
			disabled: boolean;
		}
	>,
) {
	return Api.post<void>('risk-types', data);
}

export function deleteType(riskTypeId: number) {
	return Api.delete_<void>(`risk-types/${riskTypeId}`);
}

export function disableType(riskTypeId: number) {
	return Api.patch<void>(`risk-types/${riskTypeId}`, { disabled: true });
}

export function enableType(riskTypeId: number) {
	return Api.patch<void>(`risk-types/${riskTypeId}`, { disabled: false });
}
