import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { TreeView, TreeItem, useTreeItem, TreeItemContentProps, TreeItemProps } from '@mui/lab';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import classNames from 'classnames';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';

import { ITreeNode, createTreeFromArray } from '../utils';
import TextFilterInput from './TextFilterInput';

interface IRiskRegisterTreeProps {
	list: IApiRiskRegister[];
	selected?: IApiRiskRegister[];
	onSelect: (selected: IApiRiskRegister[]) => void;
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
	const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

	const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
		useTreeItem(nodeId);

	const icon = iconProp || expansionIcon || displayIcon;

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		preventSelection(event);
	};

	const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleExpansion(event);
	};

	const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleSelection(event);
	};

	return (
		<Box
			className={classNames(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.selected]: selected,
				[classes.focused]: focused,
				[classes.disabled]: disabled,
			})}
			onMouseDown={handleMouseDown}
			ref={ref as React.Ref<HTMLDivElement>}
			sx={{ padding: '0 !important' }} // Overwrite default padding that messes up with overflow
		>
			<div onClick={handleExpansionClick} className={classes.iconContainer}>
				{icon}
			</div>
			<div onClick={handleSelectionClick} className={classes.label}>
				{label}
			</div>
		</Box>
	);
});

const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={CustomContent} {...props} />;

export default function RiskRegisterTree({ list, selected, onSelect }: IRiskRegisterTreeProps) {
	const [tree, setTree] = useState<ITreeNode<IApiRiskRegister>[]>([]);
	const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [filter, setFilter] = useState<string>('');

	// Generate tree nodes
	useEffect(() => {
		const filteredList =
			filter.length > 0 ? list.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase())) : list;
		setTree(createTreeFromArray<IApiRiskRegister>(filteredList, 'risk_register_id', 'parent_risk_register_id', 'name'));
	}, [list, filter]);

	// Select tree node
	useEffect(() => {
		const expanded: string[] = [];

		if (selected) {
			for (let index = 0; index < selected.length - 1; index++) {
				const parents = selected
					.slice(0, index + 1)
					.map((r) => r.risk_register_id)
					.join('/');
				expanded.push(parents);
			}

			setSelectedNodes([selected.map((r) => r.risk_register_id).join('/')]);
		} else {
			setSelectedNodes([]);
		}

		setExpandedNodes((current) => [...current, ...expanded]);
	}, [selected]);

	const renderTree = ({ node, children }: ITreeNode<IApiRiskRegister>, parents: IApiRiskRegister[] = []) => {
		const newParents = [...parents, node];
		const nodeId = newParents.map((r) => r.risk_register_id).join('/');

		return (
			<CustomTreeItem
				key={node.risk_register_id}
				nodeId={nodeId}
				label={
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Typography component="span">{node.name}</Typography>
						<Typography component="span" variant="caption">
							{` (${node.risk_register_type.name})`}
						</Typography>
					</Box>
				}
			>
				{children.map((n) => renderTree(n, newParents))}
			</CustomTreeItem>
		);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
			<TextFilterInput
				label="Filter registers"
				value={filter}
				onChange={(newFilter) => setFilter(newFilter)}
				sx={{ marginBottom: (theme) => theme.spacing(1) }}
			/>
			<Box sx={{ overflowY: 'auto' }}>
				<TreeView
					defaultCollapseIcon={<ExpandMore />}
					defaultExpandIcon={<ChevronRight />}
					expanded={expandedNodes}
					selected={selectedNodes}
					multiSelect={true}
					onNodeToggle={(event: React.SyntheticEvent, nodeIds: string[]) => {
						setExpandedNodes(nodeIds);
					}}
					onNodeSelect={(event: React.SyntheticEvent, value: string[]) => {
						if (value.length !== 1) {
							return;
						}

						setSelectedNodes(value);

						const node = value[0];
						const ids = node.split('/').map((id) => Number(id));
						const nodes = ids
							.map((id) => list.find((r) => r.risk_register_id === id))
							.filter((r) => r !== undefined) as IApiRiskRegister[];
						onSelect(nodes);
					}}
				>
					{tree.map((node) => renderTree(node))}
				</TreeView>
			</Box>
		</Box>
	);
}
