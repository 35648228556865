import { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Button, Card, CardActions, Chip, Typography } from '@mui/material';
import { ChevronRight, PriorityHigh } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { IApiRiskAction, IApiRisk } from '@mitie/risk-register-api-types';

import RiskScore from './RiskScore';
import useNotifications from 'hooks/useNotifications';
import { useNavigate, useParams } from 'routing/routing';
import PropertyDisplay from './PropertyDisplay';

interface IRiskSummaryProps {
	risk: IApiRisk;
	sx?: SxProps<Theme>;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		expandButton: {
			transform: 'rotate(0deg)',
			marginLeft: 'auto',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandButtonOpen: {
			transform: 'rotate(180deg)',
		},
		pendingChip: {
			float: 'left',
			marginRight: theme.spacing(1),
			marginTop: '4px',
			'&>span': {
				paddingRight: 0,
			},
		},
		grow: {
			flexGrow: 1,
		},
	}),
);

export default function RiskSummary({ risk, sx }: IRiskSummaryProps) {
	const classes = useStyles();
	const navigate = useNavigate();
	const { notifications } = useNotifications();
	const { registerId, registerName } = useParams();
	const [pendingAction, setPendingAction] = useState<IApiRiskAction | null>(null);

	useEffect(() => {
		const pendingAction = notifications.find((n) => n.data.risk_id === risk.risk_id);
		setPendingAction(pendingAction ? pendingAction.data : null);
	}, [notifications, risk.risk_id]);

	return (
		<Card key={risk.risk_id} sx={sx}>
			<Box sx={{ margin: (theme) => theme.spacing(1) }}>
				<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: (theme) => theme.spacing(1) }}>
					{pendingAction && (
						<Chip color="secondary" icon={<PriorityHigh />} size="small" className={classes.pendingChip} />
					)}
					<Typography
						component="div"
						variant="h6"
						sx={{ flexGrow: 1, marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(1) }}
					>
						{risk.name}
					</Typography>
					<RiskScore value={risk.inherent_score} label="Inherent risk rating" size="48px" />
					<RiskScore value={risk.residual_score} label="Residual risk rating" size="48px" />
				</Box>
				<Box sx={{ marginLeft: (theme) => theme.spacing(1) }}>
					<PropertyDisplay name="Type" compact>
						<Typography>{risk.risk_type?.name || 'Not set'}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Category" compact>
						<Typography>{risk.risk_category?.name || 'Not set'}</Typography>
					</PropertyDisplay>
					<PropertyDisplay name="Appetite" compact>
						<Typography>{risk.appetite || 'Not set'}</Typography>
						<Typography sx={{ color: risk.appetite_status === 'Above appetite' ? 'red' : 'default' }}>
							{risk.appetite_status ? risk.appetite_status : ''}
						</Typography>
					</PropertyDisplay>
				</Box>
			</Box>
			<CardActions>
				<div className={classes.grow} />
				<Button
					endIcon={<ChevronRight />}
					onClick={() =>
						navigate(null, {
							registerId,
							registerName,
							riskId: risk.risk_id,
							riskName: risk.name,
						})
					}
				>
					View
				</Button>
			</CardActions>
		</Card>
	);
}
