import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Chip,
	CircularProgress,
	Drawer,
	IconButton,
	LinearProgress,
	Link,
	List,
	ListItem,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {
	IApiRiskAction,
	IApiRiskControl,
	IApiRiskInsuranceReview,
	IApiRiskRegister,
	IApiRiskReview,
	IApiRisk,
	IApiRiskTreatmentAction,
	IApiRiskWithRegister,
} from '@mitie/risk-register-api-types';
import { ClipboardCheck, Equal, ShieldCheck, TransferDown, TransferUp } from 'mdi-material-ui';

import { useNavigate, useParams } from 'routing/routing';
import {
	ArrowForward,
	Close,
	Delete,
	Done,
	DriveFileMove,
	Edit,
	ExpandLess,
	ExpandMore,
	InfoOutlined,
	NavigateBefore,
} from '@mui/icons-material';
import useNotifications from 'hooks/useNotifications';
import * as RiskRegistersApi from '../api/riskRegisters';
import * as RiskActionsApi from '../api/riskActions';
import ConfirmDialog from './ConfirmDialog';
import CreateOrUpdateRiskDialog from './CreateOrUpdateRiskDialog';
import RiskScoreFull from './RiskScoreFull';
import PropertyDisplay from './PropertyDisplay';
import { getRiskControlsForRisk } from '../api/riskControls';
import RiskReviewDialog from './RiskReviewDialog';
import { getReviews, getInsuranceReview } from '../api/riskRegisters';
import { getRiskTreatmentActionsForRisk } from '../api/riskTreatmentActions';
import * as PrincipalRisksApi from '../api/principalRisks';
import RiskInsuranceReviewDialog from './RiskInsuranceReviewDialog';
import { formatDate, formatDateTime } from 'utils';
import PersonsList from './PersonsList';
import MoveRiskDialog from './MoveRiskDialog';
import ActionButtons, { IActionButtonProps } from './ActionButtons';
import { Page } from 'routing/routes';
import CascadeRiskDialog from './CascadeRiskDialog';
import EscalateRiskDialog from './EscalatedRiskDialog';

interface IRiskProps {
	register: IApiRiskRegister;
	registerParents: IApiRiskRegister[];
	childrenRegisters: IApiRiskRegister[];
	allRegisters: IApiRiskRegister[];
	risk: IApiRisk;
	onChange: () => void;
}

export default function Risk({
	register,
	registerParents,
	childrenRegisters,
	allRegisters,
	risk,
	onChange,
}: IRiskProps) {
	const navigate = useNavigate();
	const { registerId, registerName } = useParams();
	const { notifications } = useNotifications();
	const [retireReviewDialogOpen, setRetireReviewDialogOpen] = useState(false);
	const [cascadeConfirmDialogOpen, setCascadeConfirmDialogOpen] = useState(false);
	const [escalateConfirmDialogOpen, setEscalateConfirmDialogOpen] = useState(false);
	const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
	const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
	const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
	const [moveDialogOpen, setMoveDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [insuranceReviewDialogOpen, setInsuranceReviewDialogOpen] = useState(false);
	const [pendingActions, setPendingActions] = useState<IApiRiskAction[]>([]);
	const [areRiskControlsLoading, setAreRiskControlsLoading] = useState(false);
	const [areTreatmentActionsLoading, setAreTreatmentActionsLoading] = useState(false);
	const [riskControls, setRiskControls] = useState<IApiRiskControl[]>([]);
	const [treatmentActions, setTreatmentActions] = useState<IApiRiskTreatmentAction[]>([]);
	const [riskMitigations, setRiskMitigations] = useState<IApiRiskControl[]>([]);
	const [currentAction, setCurrentAction] = useState<IApiRiskAction | null>(null);
	const [areReviewsExpanded, setAreReviewsExpanded] = useState(false);
	const [areReviewsLoading, setAreReviewsLoading] = useState<boolean | null>(null);
	const [reviews, setReviews] = useState<IApiRiskReview[]>([]);
	const [isInsuranceReviewLoading, setIsInsuranceReviewLoading] = useState<boolean | null>(null);
	const [insuranceReview, setInsuranceReview] = useState<IApiRiskInsuranceReview | undefined>();
	const [linkedPrincipalRisks, setLinkedPrincipalRisks] = useState<(IApiRisk | undefined)[]>([]);
	const [areLinkedPrincipalRisksLoading, setAreLinkedPrincipalRisksLoading] = useState(false);
	const [linkedRisks, setLinkedRisks] = useState<IApiRiskWithRegister[]>([]);
	const [areLinkedRisksLoading, setAreLinkedRisksLoading] = useState(false);
	const [isLinkedRisksDrawerOpen, setIsLinkedRisksDrawerOpen] = useState(false);

	useEffect(() => {
		loadControls();
		loadTreatmentActions();
		loadInsuranceReview();
		loadLinkedPrincipalRisks();
		loadLinkedRisks();
	}, [risk]);

	useEffect(() => {
		setPendingActions(
			notifications.filter((n) => n.data.risk_id === risk.risk_id).map((notification) => notification.data),
		);
	}, [notifications, risk.risk_id]);

	useEffect(() => {
		if (areReviewsExpanded) {
			loadReviews();
		}
	}, [areReviewsExpanded, risk]);

	const loadControls = async () => {
		setAreRiskControlsLoading(true);

		try {
			const data = await getRiskControlsForRisk(register.risk_register_id, risk.risk_id);
			setRiskControls(data.filter((rc) => rc.type === 'Control'));
			setRiskMitigations(data.filter((rc) => rc.type === 'Mitigation'));
		} finally {
			setAreRiskControlsLoading(false);
		}
	};

	const loadTreatmentActions = async () => {
		setAreTreatmentActionsLoading(true);

		try {
			const data = await getRiskTreatmentActionsForRisk(register.risk_register_id, risk.risk_id);
			setTreatmentActions(data);
		} finally {
			setAreTreatmentActionsLoading(false);
		}
	};

	const loadReviews = async () => {
		setAreReviewsLoading(true);

		try {
			const data = await getReviews(register.risk_register_id, risk.risk_id);
			setReviews(data);
		} finally {
			setAreReviewsLoading(false);
		}
	};

	const loadInsuranceReview = async () => {
		setIsInsuranceReviewLoading(true);

		try {
			const data = await getInsuranceReview(register.risk_register_id, risk.risk_id);
			setInsuranceReview(data);
		} finally {
			setIsInsuranceReviewLoading(false);
		}
	};

	const loadLinkedPrincipalRisks = async () => {
		if (risk.linked_principal_risks.length > 0) {
			setAreLinkedPrincipalRisksLoading(true);

			try {
				const data = await PrincipalRisksApi.getAll();
				setLinkedPrincipalRisks(
					risk.linked_principal_risks.map((linkedRiskId) => data.find((risk) => risk.risk_id === linkedRiskId)),
				);
			} finally {
				setAreLinkedPrincipalRisksLoading(false);
			}
		}
	};

	const loadLinkedRisks = async () => {
		if (risk.principal_risk) {
			setAreLinkedRisksLoading(true);

			try {
				const data = await PrincipalRisksApi.getRisksLinkedToPrincipalRisk(risk.risk_id);
				setLinkedRisks(data);
			} finally {
				setAreLinkedRisksLoading(false);
			}
		}
	};

	const showRiskTreatmentPlan = risk.desired_score !== null;

	const actions: IActionButtonProps[] = [];

	if (register.permissions.includes('update-risk') && risk.state === 'Pending treatment') {
		actions.push({
			label: 'Complete data entry...',
			icon: <Edit />,
			onClick: () => setUpdateDialogOpen(true),
		});
	} else if (register.permissions.includes('update-live-risk')) {
		actions.push({
			label: 'Edit risk...',
			icon: <Edit />,
			onClick: () => setUpdateDialogOpen(true),
		});
	}

	if (register.permissions.includes('move-risk')) {
		actions.push({
			label: 'Move risk...',
			icon: <DriveFileMove />,
			onClick: () => setMoveDialogOpen(true),
		});
	}

	if (register.permissions.includes('retire-risk')) {
		actions.push({
			label: 'Delete risk...',
			icon: <Delete />,
			onClick: () => setDeleteDialogOpen(true),
		});
	}

	if (risk.state === 'Live') {
		if (register.permissions.includes('review-risk')) {
			actions.push({
				label: 'Review risk...',
				icon: <ClipboardCheck />,
				onClick: () => setReviewDialogOpen(true),
			});
		}

		if (register.permissions.includes('cascade-risk')) {
			if (childrenRegisters.length > 0) {
				actions.push({
					label: 'Cascade down...',
					icon: <TransferDown />,
					onClick: () => setCascadeConfirmDialogOpen(true),
				});
			}
		}

		if (register.permissions.includes('escalate-risk')) {
			if (register.parent_risk_register_id) {
				actions.push({
					label: 'Escalate...',
					icon: <TransferUp />,
					onClick: () => setEscalateConfirmDialogOpen(true),
				});
			}
		}

		if (register.permissions.includes('insurance-review')) {
			actions.push({
				label: 'Insurance review...',
				icon: <ShieldCheck />,
				onClick: () => setInsuranceReviewDialogOpen(true),
			});
		}
	}

	return (
		<Box
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', margin: (theme) => `0 ${theme.spacing(1)}` }}>
				<Box sx={{ flexGrow: 1 }}>
					<Button startIcon={<NavigateBefore />} onClick={() => navigate(null, { registerId, registerName })}>
						Return to list
					</Button>
				</Box>
				{actions.length > 0 && <ActionButtons items={actions} />}
			</Box>
			{pendingActions.map((pendingAction, index) => (
				<Box key={index} sx={{ margin: (theme) => `0 ${theme.spacing(2)}`, display: 'flex', flexDirection: 'column' }}>
					<Button
						sx={{
							paddingLeft: (theme) => theme.spacing(2),
							textTransform: 'none',
							justifyContent: 'flex-start',
						}}
						color="secondary"
						variant="outlined"
						onClick={() => {
							setCurrentAction(pendingAction);

							if (pendingAction.action_type === 'accept-risk') {
								setAcceptDialogOpen(true);
							} else if (pendingAction.action_type === 'update-risk') {
								setUpdateDialogOpen(true);
							} else if (pendingAction.action_type === 'retire-risk') {
								setRetireReviewDialogOpen(true);
							} else if (pendingAction.action_type === 'insurance-review') {
								setInsuranceReviewDialogOpen(true);
							} else if (pendingAction.action_type === 'review-risk') {
								setReviewDialogOpen(true);
							}
						}}
					>
						<Typography variant="body2">{pendingAction.description}</Typography>
					</Button>
				</Box>
			))}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					margin: (theme) => theme.spacing(2),
					overflowY: 'auto',
					overflowX: 'hidden',
					minHeight: 0,
				}}
			>
				<Paper sx={{ display: 'flex', alignItems: 'flex-start', padding: '16px', marginBottom: '16px' }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="h6" component="div" sx={{ marginRight: '16px', display: 'inline-flex' }}>
							{risk.name}
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{risk.principal_risk === true && <Chip label="Principal risk" variant="outlined" />}
							{risk.state !== 'Live' && risk.state !== 'Retired' && (
								<Chip
									label="Not live"
									variant="filled"
									icon={<InfoOutlined sx={{ color: 'rgb(33, 150, 243) !important' }} />}
									sx={{ marginLeft: '8px', color: 'rgb(13, 60, 97)', backgroundColor: 'rgb(232, 244, 253)' }}
								/>
							)}
							{risk.state === 'Retired' && (
								<Chip
									label="Retired"
									variant="filled"
									icon={<InfoOutlined sx={{ color: 'rgb(33, 150, 243) !important' }} />}
									sx={{ marginLeft: '8px', color: 'rgb(13, 60, 97)', backgroundColor: 'rgb(232, 244, 253)' }}
								/>
							)}
						</Box>
						<Typography variant="body2" sx={{ marginBottom: '16px', maxWidth: '50rem', whiteSpace: 'pre-wrap' }}>
							{risk.description}
						</Typography>
						<PropertyDisplay name="Type">
							<Typography>{risk.risk_type?.name || 'Not set'}</Typography>
						</PropertyDisplay>
						<PropertyDisplay name="Category">
							<Typography>{risk.risk_category?.name || 'Not set'}</Typography>
						</PropertyDisplay>
						<PropertyDisplay name="Impacted strategic pillars">
							{risk.strategic_pillars
								.sort((p1, p2) => (p2.type > p1.type ? 1 : -1))
								.map((pillar) => {
									return (
										<Chip
											key={pillar.strategic_pillar_id}
											variant="outlined"
											size="small"
											label={pillar.name}
											sx={{ marginRight: (theme) => theme.spacing(1) }}
										/>
									);
								})}
						</PropertyDisplay>
						<PropertyDisplay name="Risk owner(s)">{risk.owner && <PersonsList emails={risk.owner} />}</PropertyDisplay>
						<PropertyDisplay name="Risk sponsor(s)">
							{risk.sponsor && <PersonsList emails={risk.sponsor} />}
						</PropertyDisplay>
						<PropertyDisplay name="Risk appetite">
							<Typography component="span">{risk.appetite || 'Not set'}</Typography>
							{risk.risk_category && risk.appetite && (
								<Typography component="span" variant="caption" sx={{ marginLeft: (theme) => theme.spacing(1) }}>
									{risk.appetite === 'Averse'
										? '(' + risk.risk_category.averse_appetite_definition + ')'
										: risk.appetite === 'Cautious'
										? '(' + risk.risk_category.cautious_appetite_definition + ')'
										: risk.appetite === 'Eager'
										? '(' + risk.risk_category.eager_appetite_definition + ')'
										: ''}
								</Typography>
							)}
						</PropertyDisplay>
						<PropertyDisplay name="Appetite status">
							<Typography
								component="span"
								sx={{ color: risk.appetite_status === 'Above appetite' ? 'red' : 'default' }}
							>
								{risk.appetite_status || 'Not defined'}
							</Typography>
						</PropertyDisplay>
						{risk.linked_principal_risks.length > 0 && (
							<PropertyDisplay name="Linked principal risks">
								{areLinkedPrincipalRisksLoading ? (
									<CircularProgress size="1rem" />
								) : (
									linkedPrincipalRisks.map((risk, index) => (
										<Chip
											key={index}
											label={risk ? risk.name : 'Unknown risk'}
											size="small"
											sx={{ marginRight: (theme) => theme.spacing(1) }}
										/>
									))
								)}
							</PropertyDisplay>
						)}
						{risk.principal_risk && (
							<PropertyDisplay name="Risks linked to this principal risk">
								{areLinkedRisksLoading ? (
									<CircularProgress size="1rem" />
								) : (
									<Link component="button" underline="hover" onClick={() => setIsLinkedRisksDrawerOpen(true)}>
										{linkedRisks.length}
									</Link>
								)}
							</PropertyDisplay>
						)}
					</Box>
					<RiskScoreFull value={risk.inherent_score} label="Inherent risk rating" />
					<RiskScoreFull value={risk.residual_score} label="Residual risk rating" />
				</Paper>
				<Paper sx={{ padding: '16px', marginBottom: '16px' }}>
					<Typography variant="h6">Control measures</Typography>
					{areRiskControlsLoading && <LinearProgress />}
					{!areRiskControlsLoading && riskControls.length === 0 ? (
						<Typography>No control measures in place</Typography>
					) : (
						<TableContainer>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Description</TableCell>
										<TableCell>Owners</TableCell>
										<TableCell>Date added</TableCell>
										<TableCell>Date updated</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{riskControls.map(({ description, owner, time_updated, time_added }, index) => (
										<TableRow key={index}>
											<TableCell>{description}</TableCell>
											<TableCell>{owner}</TableCell>
											<TableCell>{formatDateTime(time_added)}</TableCell>
											<TableCell>{formatDateTime(time_updated)}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Paper>
				<Paper sx={{ padding: '16px', marginBottom: '16px' }}>
					<Typography variant="h6">Mitigation measures</Typography>
					{areRiskControlsLoading && <LinearProgress />}
					{!areRiskControlsLoading && riskMitigations.length === 0 ? (
						<Typography>No mitigation measures in place</Typography>
					) : (
						<TableContainer>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Description</TableCell>
										<TableCell>Owners</TableCell>
										<TableCell>Date added</TableCell>
										<TableCell>Date updated</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{riskMitigations.map(({ description, owner, time_updated, time_added }, index) => (
										<TableRow key={index}>
											<TableCell>{description}</TableCell>
											<TableCell>{owner}</TableCell>
											<TableCell>{formatDateTime(time_added)}</TableCell>
											<TableCell>{formatDateTime(time_updated)}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Paper>
				{showRiskTreatmentPlan && (
					<Paper sx={{ padding: '16px', marginBottom: '16px' }}>
						<Typography variant="h6">Risk treatment plan</Typography>
						<RiskScoreFull value={risk.residual_score} label="Residual risk rating" />
						<RiskScoreFull value={risk.desired_score} label="Desired residual risk rating" />
						{areTreatmentActionsLoading && <LinearProgress />}
						{!areTreatmentActionsLoading && treatmentActions.length === 0 ? (
							<Typography>No Action defined in risk treatment plan</Typography>
						) : (
							<TableContainer sx={{ marginBottom: (theme) => theme.spacing(2) }}>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Action</TableCell>
											<TableCell>Owners</TableCell>
											<TableCell align="center">Start date</TableCell>
											<TableCell align="center">End date</TableCell>
											<TableCell align="center">Completed?</TableCell>
											<TableCell align="center">Date added</TableCell>
											<TableCell align="center">Date updated</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{treatmentActions.map(
											({ description, owner, start_date, end_date, completed, time_updated, time_added }, index) => (
												<TableRow key={index}>
													<TableCell>{description}</TableCell>
													<TableCell>{owner}</TableCell>
													<TableCell align="center">{formatDate(start_date)}</TableCell>
													<TableCell align="center">{formatDate(end_date)}</TableCell>
													<TableCell align="center">{completed ? <Done color="success" /> : '-'}</TableCell>
													<TableCell align="center">{formatDateTime(time_added)}</TableCell>
													<TableCell align="center">{formatDateTime(time_updated)}</TableCell>
												</TableRow>
											),
										)}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						<PropertyDisplay name="Resources required">
							<Typography sx={{ whiteSpace: 'pre-wrap' }}>
								{risk.treatment_required_resources || 'Treatment plan not completed'}
							</Typography>
						</PropertyDisplay>
					</Paper>
				)}
				{insuranceReview && (
					<Paper sx={{ padding: '16px', marginBottom: '16px' }}>
						<Typography variant="h6">Insurance review</Typography>
						{isInsuranceReviewLoading ? (
							<LinearProgress />
						) : (
							<>
								<PropertyDisplay name="Is risk insurable?">
									<Typography>{insuranceReview.insurable}</Typography>
								</PropertyDisplay>
								<PropertyDisplay name="Review date">
									<Typography>
										{formatDateTime(insuranceReview.review_time)} by {insuranceReview.reviewer}
									</Typography>
								</PropertyDisplay>
								<PropertyDisplay name="Reviewer comment">
									<Typography>{insuranceReview.comment}</Typography>
								</PropertyDisplay>
							</>
						)}
					</Paper>
				)}
				<Box>
					<Accordion expanded={areReviewsExpanded} onChange={(event, isExpanded) => setAreReviewsExpanded(isExpanded)}>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Typography variant="h6" sx={{ margin: 0 }}>
								Review history
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{areReviewsLoading && <LinearProgress />}
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Reviewer</TableCell>
											<TableCell>Score movement</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{reviews.map(({ review_time, reviewer, score_before, score_after, score_movement }, index) => (
											<TableRow key={index}>
												<TableCell>{formatDateTime(review_time)}</TableCell>
												<TableCell>{reviewer}</TableCell>
												<TableCell sx={{ display: 'flex', alignItems: 'center' }}>
													<Box
														component="span"
														sx={{ minWidth: '64px' }}
													>{`${score_before} \u2192 ${score_after}`}</Box>
													{score_movement === 'Improving' ? (
														<ExpandLess sx={{ fontSize: 'inherit', color: 'green' }} />
													) : score_movement === 'Deteriorating' ? (
														<ExpandMore sx={{ fontSize: 'inherit', color: 'red' }} />
													) : (
														<Equal sx={{ fontSize: 'inherit' }} />
													)}
													<Box
														component="span"
														sx={{
															marginLeft: '8px',
															color:
																score_movement === 'Improving'
																	? 'green'
																	: score_movement === 'Deteriorating'
																	? 'red'
																	: 'default',
														}}
													>
														{score_movement}
													</Box>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				</Box>
			</Box>
			<Drawer anchor="right" open={isLinkedRisksDrawerOpen} onClose={() => setIsLinkedRisksDrawerOpen(false)}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ flexGrow: 1, margin: (theme) => `0 ${theme.spacing(2)}` }}>
						Risks linked to this principal risk
					</Typography>
					<IconButton onClick={() => setIsLinkedRisksDrawerOpen(false)}>
						<Close />
					</IconButton>
				</Box>
				{linkedRisks && (
					<List sx={{ overflowY: 'auto' }}>
						{linkedRisks.map((risk) => (
							<ListItem
								key={risk.risk_id}
								secondaryAction={
									<IconButton
										edge="end"
										onClick={() => {
											setIsLinkedRisksDrawerOpen(false);
											navigate(Page.RiskRegisters, {
												registerId: risk.risk_register.risk_register_id,
												registerName: risk.risk_register.name,
												riskId: risk.risk_id,
												riskName: risk.name,
											});
										}}
									>
										<ArrowForward />
									</IconButton>
								}
							>
								<ListItemText primary={risk.name} secondary={risk.risk_register.name} />
							</ListItem>
						))}
					</List>
				)}
			</Drawer>
			{retireReviewDialogOpen && currentAction && (
				<ConfirmDialog
					title="Confirm action"
					message={
						`${currentAction.created_by} has requested to retire this risk\n\nHere is the rationale provided: ${currentAction.user_comment}` +
						(risk.principal_risk
							? '\n\nWARNING! You are retiring a principal risk. Doing so will also remove it from any linked risk.'
							: '')
					}
					cancelLabel="Cancel"
					confirmLabel="Approve and retire risk"
					rejectLabel="Reject"
					onClose={() => {
						setRetireReviewDialogOpen(false);
						setCurrentAction(null);
					}}
					onConfirm={async () => {
						await RiskRegistersApi.deleteRisk(register.risk_register_id, risk.risk_id);
						onChange();
					}}
					onReject={async () => {
						await RiskActionsApi.completeRiskAction(
							register.risk_register_id,
							risk.risk_id,
							currentAction.risk_action_id,
						);
						onChange();
					}}
				/>
			)}
			{cascadeConfirmDialogOpen && childrenRegisters !== undefined && (
				<CascadeRiskDialog
					risk={risk}
					register={register}
					childrenRegisters={childrenRegisters}
					onClose={() => setCascadeConfirmDialogOpen(false)}
				/>
			)}
			{escalateConfirmDialogOpen && (
				<EscalateRiskDialog risk={risk} register={register} onClose={() => setEscalateConfirmDialogOpen(false)} />
			)}
			{acceptDialogOpen && currentAction && (
				<CreateOrUpdateRiskDialog
					register={register}
					mode="accept"
					riskId={risk.risk_id}
					riskActionId={currentAction.risk_action_id}
					onClose={async (result) => {
						setAcceptDialogOpen(false);

						if (result) {
							// loadControls();
							// loadTreatmentActions();
							await onChange();
							// loadLinkedRisks();
						}
					}}
				/>
			)}
			{reviewDialogOpen && (
				<RiskReviewDialog
					register={register}
					riskId={risk.risk_id}
					onClose={(result) => {
						setReviewDialogOpen(false);

						if (result) {
							onChange();
						}
					}}
				/>
			)}
			{updateDialogOpen && (
				<CreateOrUpdateRiskDialog
					register={register}
					mode="update"
					riskId={risk.risk_id}
					onClose={(result) => {
						setUpdateDialogOpen(false);

						if (result) {
							onChange();
						}
					}}
				/>
			)}
			{moveDialogOpen && (
				<MoveRiskDialog
					registersList={allRegisters}
					register={[...registerParents, register]}
					risk={risk}
					onChange={(selected: IApiRiskRegister[]) => {
						if (selected.length === 0) {
							return;
						}

						const register = selected[selected.length - 1];
						navigate(null, { registerId: register.risk_register_id, registerName: register.name });
					}}
					onClose={() => setMoveDialogOpen(false)}
				/>
			)}
			{deleteDialogOpen && (
				<ConfirmDialog
					title="Delete risk?"
					message={
						"This should only be used if the risk was added by mistake. Otherwise, use the 'Retire' button on the risk review form" +
						(risk.principal_risk
							? '\n\nWARNING! You are deleting a principal risk. Doing so will also remove it from any linked risk.'
							: '')
					}
					confirmLabel="Delete risk"
					cancelLabel="Cancel"
					onClose={() => setDeleteDialogOpen(false)}
					onConfirm={async () => {
						await RiskRegistersApi.deleteRisk(register.risk_register_id, risk.risk_id);
						onChange();
					}}
				/>
			)}
			{insuranceReviewDialogOpen && (
				<RiskInsuranceReviewDialog
					risk={risk}
					onClose={(result) => {
						setInsuranceReviewDialogOpen(false);

						if (result) {
							onChange();
						}
					}}
				/>
			)}
		</Box>
	);
}
