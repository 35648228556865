import React, { useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Paper,
	Typography,
} from '@mui/material';
import { ArrowBack, ArrowForward, Check, Close } from '@mui/icons-material';
import { IApiRiskImpactCategory, IApiRiskScoreUpsert } from '@mitie/risk-register-api-types';

import RiskScore from './RiskScore';

interface IRiskScorePickerDialogProps {
	value: IApiRiskScoreUpsert | null;
	impactCategories: IApiRiskImpactCategory[];
	onClose: (selected?: IApiRiskScoreUpsert) => void;
}

const likelihoodData = {
	name: 'Likelihood',
	data: [
		{
			text: 'Rare',
			description:
				'May occur only in exceptional circumstances\nLess than 5% chance of happening\nUnlikely to occur in a 50 year period',
		},
		{
			text: 'Unlikely',
			description: 'Could occur at some time\n6% to 20% chance of happening\nUnlikely to occur within a 10 year period',
		},
		{
			text: 'Possible',
			description: 'Might occur at some time\n21% to 50% chance of happening\nLikely to occur within a 10 year period',
		},
		{
			text: 'Likely',
			description:
				'Will probably occur in most circumstances\n51% - 80% chance of happening\nLikely to occur within a one year period',
		},
		{
			text: 'Almost certain',
			description:
				'Is expected to occur in most circumstances\nMore than 80% chance of happening\nLikely to occur within three months',
		},
	],
};

const getColourForScore = (score: number) =>
	score < 5 ? '#00B050' : score < 11 ? '#FFFF00' : score < 16 ? '#FFC000' : '#FF0000';
const getColourForSubScore = (score: number) => (score < 3 ? '#00B050' : score < 4 ? '#FFFF00' : '#FF0000');

function RiskScoreItem({
	name,
	value,
	highlighted,
	selected,
	onClick,
}: {
	name: string;
	value: number | undefined;
	highlighted: boolean;
	selected: boolean;
	onClick: () => void;
}) {
	return (
		<Chip
			label={
				<Box sx={{ display: 'flex', flexGrow: 1, opacity: highlighted || selected ? 1 : 0.5 }}>
					<Typography variant="body2" sx={{ flexGrow: 1 }}>
						{name}
					</Typography>
					<Typography
						variant="body2"
						sx={{ fontWeight: highlighted ? 'bold' : 'normal', marginLeft: (theme) => theme.spacing(1) }}
					>
						{value || '-'}
					</Typography>
				</Box>
			}
			sx={{
				display: 'flex',
				border: 0,
				backgroundColor: selected ? (theme) => theme.palette.action.selected : 'default',
				'&>span': { display: 'flex', flexGrow: 1 },
			}}
			size="small"
			variant="outlined"
			onClick={() => onClick()}
		/>
	);
}

export default function RiskScorePickerDialog({ value, impactCategories, onClose }: IRiskScorePickerDialogProps) {
	const [currentStep, setCurrentStep] = useState(0);
	const [selectedImpacts, setSelectedImpacts] = useState(
		impactCategories.map(({ risk_impact_category_id }) => ({
			risk_impact_category_id,
			value: value?.risk_score_impacts.find((i) => i.risk_impact_category_id === risk_impact_category_id)?.value,
		})),
	);
	const [selectedImpact, setSelectedImpact] = useState(value?.impact);
	const [selectedLikelihood, setSelectedLikelihood] = useState(value?.likelihood);

	useEffect(() => {
		const allSelected = selectedImpacts.find((c) => c === undefined) === undefined;

		if (allSelected) {
			setSelectedImpact(Math.max(...selectedImpacts.map((i) => i.value as number)));
		} else {
			setSelectedImpact(undefined);
		}
	}, [selectedImpacts]);

	const stepsCount = impactCategories.length + 1;
	const isFirstStep = currentStep <= 0;
	const isLastStep = currentStep >= stepsCount - 1;
	const currentData = isLastStep
		? likelihoodData
		: {
				name: impactCategories[currentStep].name,
				data: [
					{
						text: 'Minor',
						description: impactCategories[currentStep].impact_description_1,
					},
					{
						text: 'Moderate',
						description: impactCategories[currentStep].impact_description_2,
					},
					{
						text: 'Significant',
						description: impactCategories[currentStep].impact_description_3,
					},
					{
						text: 'Major',
						description: impactCategories[currentStep].impact_description_4,
					},
					{
						text: 'Critical',
						description: impactCategories[currentStep].impact_description_5,
					},
				],
		  };
	const currentValue = isLastStep ? selectedLikelihood : selectedImpacts[currentStep].value;

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
			<DialogTitle>Pick risk rating</DialogTitle>
			<DialogContent>
				<Box sx={{ marginBottom: '8px' }}>
					<Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
						{isLastStep ? 'Likelihood:' : `Impact on ${currentData.name}:`}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box sx={{ width: '48px' }}>
						{!isFirstStep && (
							<IconButton
								onClick={() => {
									setCurrentStep((current) => current - 1);
								}}
							>
								<ArrowBack />
							</IconButton>
						)}
					</Box>
					{currentData.data.map(({ text }, index) => {
						const rating = index + 1;

						return (
							<Paper
								onClick={() => {
									if (isLastStep) {
										setSelectedLikelihood(rating);
									} else {
										setSelectedImpacts((current) => {
											const updated = [...current];
											updated[currentStep].value = rating;
											return updated;
										});
									}
								}}
								elevation={currentValue === rating ? 8 : 0}
								sx={{
									cursor: 'pointer',
									flex: 1,
									textAlign: 'center',
									backgroundColor: `${
										isLastStep ? getColourForScore(rating * selectedImpact!) : getColourForSubScore(rating)
									}${currentValue === rating ? 'ff' : '99'}`,
									margin: '2px',
									padding: '8px 0',
									borderRadius: 0,
								}}
								key={index}
							>
								<Typography>{rating}</Typography>
								<Typography>{text}</Typography>
							</Paper>
						);
					})}
					<Box sx={{ width: '48px' }}>
						{!isLastStep && (
							<IconButton
								onClick={() => {
									setCurrentStep((current) => current + 1);
								}}
								disabled={!currentValue}
							>
								<ArrowForward />
							</IconButton>
						)}
					</Box>
				</Box>
				<Box sx={{ height: '150px' }}>
					{currentValue !== undefined && (
						<Alert severity="info" sx={{ marginTop: '16px', padding: '8px', whiteSpace: 'break-spaces' }}>
							{currentData.data[currentValue - 1].description}
						</Alert>
					)}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							border: (theme) => `1px solid ${theme.palette.divider}`,
							borderRadius: '8px',
							padding: (theme) => theme.spacing(1),
						}}
					>
						<Typography variant="subtitle2" sx={{ marginBottom: '8px' }}>
							Selected risk rating
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box sx={{ marginRight: '16px', marginLeft: '8px' }}>
								{impactCategories.map(({ name }, index) => (
									<RiskScoreItem
										key={index}
										name={name}
										value={selectedImpacts[index].value}
										highlighted={selectedImpacts[index].value === selectedImpact}
										selected={currentStep === index}
										onClick={() => setCurrentStep(index)}
									/>
								))}
							</Box>
							<Box sx={{ marginRight: '16px' }}>
								<RiskScoreItem
									name="Likelihood"
									value={selectedLikelihood}
									highlighted={true}
									selected={currentStep === stepsCount - 1}
									onClick={() => setCurrentStep(stepsCount - 1)}
								/>
							</Box>
							<RiskScore
								value={
									selectedLikelihood && selectedImpact
										? {
												likelihood: selectedLikelihood,
												impact: selectedImpact,
												score: selectedLikelihood * selectedImpact,
										  }
										: null
								}
								size="48px"
							/>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose()} variant="outlined">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						if (!selectedLikelihood || !selectedImpact) {
							return;
						}

						const risk_score_impacts = selectedImpacts.filter((i) => i.value !== undefined) as {
							risk_impact_category_id: number;
							value: number;
						}[];

						const newScore: IApiRiskScoreUpsert = {
							risk_score_id: value?.risk_score_id,
							likelihood: selectedLikelihood,
							impact: selectedImpact,
							score: selectedLikelihood * selectedImpact,
							risk_score_impacts,
						};

						onClose(newScore);
					}}
					disabled={!selectedImpact || !selectedLikelihood}
					variant="contained"
				>
					Accept
				</Button>
			</DialogActions>
		</Dialog>
	);
}
