import {
	useLocation,
	useParams as useOriginalParams,
	useNavigate as useOriginalNavigate,
	matchRoutes,
	Params,
	RouteMatch,
} from 'react-router-dom';

import { Page, ApplicationParams, routesByName } from './routes';

const routesPaths = Object.values(Page).map((path) => ({ path, element: null, caseSensitive: true }));

function getMatchedRouteAndParams(pathname: string): RouteMatch | null {
	return matchRoutes(routesPaths, pathname)?.[0] || null;
}

export function getUrl(page: Page, params: ApplicationParams): string {
	const route = routesByName[page];

	if (!route) {
		return page;
	}

	return route.getUrl ? route.getUrl(params) : page;
}

export function useNavigate(): (newPage: Page | null, params?: ApplicationParams) => void {
	const navigate = useOriginalNavigate();
	const currentPage = useCurrentPage();

	return (newPage, params = {}): void => {
		const newUrl = getUrl(newPage || currentPage.path, params);

		return navigate(newUrl);
	};
}

export function useLink(): (newPage: Page, params?: ApplicationParams) => string {
	return (newPage, params = {}): string => getUrl(newPage, params);
}

function getParsedParams(
	page: Page,
	currentParams: Readonly<Params<string>>,
	location: ReturnType<typeof useLocation>,
): ApplicationParams {
	const params = { ...currentParams };
	const search = new URLSearchParams(location.search);
	for (const [key, value] of search.entries()) {
		params[key] = value;
	}

	return routesByName[page]?.getParams?.(params) || {};
}

export function useParams(page?: Page): ApplicationParams {
	const location = useLocation();
	const originalParams = useOriginalParams();
	const currentPage = useCurrentPage();
	return getParsedParams(page || currentPage.path, originalParams, location);
}

export function useCurrentPage() {
	const location = useLocation();
	const pagePath = getMatchedRouteAndParams(location.pathname)?.route.path as Page;

	return routesByName[pagePath];
}
