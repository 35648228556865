import { useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import {
	IApiRiskRegister,
	IApiRole,
	IApiUser,
	IApiUserRoleAssignment,
	IApiUserRoleAssignmentCreate,
} from '@mitie/risk-register-api-types';
import { Delete } from '@mui/icons-material';

import * as RiskRegistersApi from '../api/riskRegisters';
import * as UserRolesApi from '../api/userRoles';
import * as UsersApi from '../api/users';
import * as AssignmentsApi from '../api/userRoleAssignments';
import UserRoleAssignments from './UserRoleAssignments';
import ConfirmDialog from './ConfirmDialog';
import useAlerts from 'hooks/useAlerts';
import { useNavigate } from 'routing/routing';

interface IUserDetailsProps {
	user: IApiUser;
	onChange: () => void;
}

export default function UserDetails({ user, onChange }: IUserDetailsProps) {
	const [riskRegisters, setRiskRegisters] = useState<IApiRiskRegister[]>();
	const [rolesById, setRolesById] = useState<Record<string, IApiRole>>();
	const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState(false);
	const [roleAssignments, setRoleAssignments] = useState<IApiUserRoleAssignment[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();
	const navigate = useNavigate();

	// Fetch registers list
	useEffect(() => {
		loadRiskRegisters();
		loadRoles();
	}, []);

	useEffect(() => {
		setRoleAssignments(null);
		loadRoleAssignments();
	}, [user.user_id]);

	const loadRiskRegisters = async () => {
		const data = await RiskRegistersApi.getAll();
		setRiskRegisters(data);
	};

	const loadRoles = async () => {
		const data = await UserRolesApi.getAll();
		setRolesById(
			data.reduce((acc, role) => {
				acc[role.role_id] = role;

				return acc;
			}, {} as Record<string, IApiRole>),
		);
	};

	const loadRoleAssignments = async () => {
		setIsLoading(true);

		try {
			const data = await AssignmentsApi.getAssignmentsForUser(user.user_id);
			setRoleAssignments(data);
		} finally {
			setIsLoading(false);
		}
	};

	const removeRoleAssignment = async (data: IApiUserRoleAssignmentCreate) => {
		setIsSaving(true);

		try {
			await AssignmentsApi.remove(user.user_id, data);
			loadRoleAssignments();
			addAlert('success', 'Role assignment successfully removed from user');
		} finally {
			setIsSaving(false);
		}
	};

	const addRoleAssignment = async (data: IApiUserRoleAssignmentCreate) => {
		setIsSaving(true);

		try {
			await AssignmentsApi.create(user.user_id, data);
			loadRoleAssignments();
			addAlert('success', 'Role assignment successfully added to user');
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', margin: (theme) => theme.spacing(2) }}>
			<Box sx={{ display: 'flex', alignItems: 'start' }}>
				<Box sx={{ marginBottom: (theme) => theme.spacing(2) }}>
					<Typography variant="h5">{user.name}</Typography>
					<Typography variant="subtitle1">{user.user_id}</Typography>
				</Box>
				{!roleAssignments || Boolean(roleAssignments.length) ? (
					<Tooltip
						title="You cannot remove a user that has role assignments. Remove all role assignments first"
						placement="right"
						arrow
					>
						<span>
							<Button
								startIcon={<Delete />}
								variant="outlined"
								sx={{ marginLeft: (theme) => theme.spacing(2) }}
								disabled
							>
								Remove user
							</Button>
						</span>
					</Tooltip>
				) : (
					<Button
						startIcon={<Delete />}
						variant="outlined"
						onClick={() => setRemoveConfirmDialogOpen(true)}
						sx={{ marginLeft: (theme) => theme.spacing(2) }}
					>
						Remove user
					</Button>
				)}
			</Box>
			<UserRoleAssignments
				userId={user.user_id}
				roleAssignments={roleAssignments}
				addRoleAssignment={addRoleAssignment}
				removeRoleAssignment={removeRoleAssignment}
				isLoading={isLoading}
				isSaving={isSaving}
				riskRegistersList={riskRegisters}
				rolePermissions={rolesById}
			/>
			{removeConfirmDialogOpen && (
				<ConfirmDialog
					title="Confirm removing user"
					message={`Are you sure you want to remove user ${user.name} from Risk Safe?`}
					cancelLabel="Cancel"
					confirmLabel="Remove"
					onClose={() => setRemoveConfirmDialogOpen(false)}
					onConfirm={async () => {
						await UsersApi.deleteUser(user.user_id);
						addAlert('success', `The user ${user.name} has been removed`);
						onChange();
						navigate(null, {});
					}}
				/>
			)}
		</Box>
	);
}
