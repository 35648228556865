import { IApiUserProfile } from '@mitie/risk-register-api-types';

import { ErrorWithHttpCode } from '../utils';
import * as Api from './api';

export type UserAccessStatus =
	| 'Unauthenticated'
	| 'Terms Not Accepted'
	| 'License Expired'
	| 'No role assignment'
	| 'Unauthorised'
	| 'OK';

function getAccessStatusFromProfile(profile: IApiUserProfile): UserAccessStatus {
	if (!profile.has_accepted_terms) {
		return 'Terms Not Accepted';
	} else if (!profile.selected_company.license_enabled && !profile.superuser) {
		return 'License Expired';
	} else if (profile.roles.length === 0) {
		return 'No role assignment';
	} else {
		return 'OK';
	}
}

export async function getProfile(): Promise<{ status: UserAccessStatus; profile?: IApiUserProfile }> {
	try {
		const profile = await Api.get<IApiUserProfile>('user/profile');
		const status: UserAccessStatus = getAccessStatusFromProfile(profile);

		return { status, profile };
	} catch (error) {
		if (error instanceof ErrorWithHttpCode && error.code === 401) {
			return { status: 'Unauthenticated' };
		} else if (error instanceof ErrorWithHttpCode && error.code === 403) {
			return { status: 'Unauthorised' };
		} else {
			// Not and authentication or permission error,
			// throw error back so it is caught by global error handled
			throw error;
		}
	}
}

export function acceptTerms() {
	return Api.post<void>('user/acceptTerms', {});
}

export function switchCompany(company_id: string) {
	return Api.post<void>('user/switch-company', { company_id });
}
