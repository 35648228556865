import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { IApiRiskCategory } from '@mitie/risk-register-api-types';

import RiskCategoryPickerDialog from './RiskCategoryPickerDialog';

interface IRiskCategoryPickerProps {
	value?: number | null;
	onChange: (value: number) => void;
	list: IApiRiskCategory[];
	loading?: boolean;
	disabled: boolean;
	error?: boolean;
	helperText?: React.ReactNode;
}

export default function RiskCategoryPicker({
	value,
	onChange,
	list,
	loading,
	disabled,
	error,
	helperText,
}: IRiskCategoryPickerProps) {
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const riskCategory = list.find((item) => item.risk_category_id === value);

	return (
		<TextField
			variant="outlined"
			sx={{ margin: (theme) => theme.spacing(1) }}
			label="Risk category"
			value={loading ? 'Loading...' : value ? riskCategory?.name || 'Invalid risk category' : 'Not set'}
			error={error}
			helperText={helperText}
			InputProps={{
				endAdornment: (
					<InputAdornment
						position="end"
						sx={{
							height: 'auto',
							margin: '12px 8px',
						}}
					>
						<>
							<Button disabled={disabled} onClick={() => setIsPickerOpen(true)}>
								{value ? 'Change...' : 'Pick...'}
							</Button>
							{isPickerOpen && (
								<RiskCategoryPickerDialog
									list={list}
									onClose={(value) => {
										if (value) {
											onChange(value);
										}

										setIsPickerOpen(false);
									}}
								/>
							)}
						</>
					</InputAdornment>
				),
				disabled,
				inputProps: {
					disabled: true,
				},
				sx: {
					color: error ? 'red' : 'default',
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}
