import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
	Alert,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	FormGroup,
	Step,
	StepLabel,
	Stepper,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import {
	IApiRiskCategory,
	IApiRiskCreate,
	IApiRiskType,
	RiskState,
	RiskAppetite,
	IApiRiskRegister,
	IApiRiskControlUpdate,
	IApiRiskControl,
	ReviewInterval,
	IApiRiskTreatmentActionUpdate,
	IApiRiskTreatmentAction,
	IApiStrategicPillar,
	IApiRiskScoreUpsert,
	IApiRiskFull,
	IApiRiskImpactCategory,
	IApiRiskCatalogueItem,
	IApiRisk,
	IApiRiskAction,
} from '@mitie/risk-register-api-types';

import * as RisksRegisterApi from '../api/riskRegisters';
import * as RiskTypesApi from '../api/riskTypes';
import * as RiskCategoriesApi from '../api/riskCategories';
import * as RiskActionsApi from '../api/riskActions';
import * as RiskImpactCategoriesApi from '../api/riskImpactCategories';
import * as PrincipalRisksApi from '../api/principalRisks';
import RiskScoreInput from './RiskScoreInput';
import { createRiskAction } from 'api/riskActions';
import { getRiskControlsForRisk } from 'api/riskControls';
import RiskAppetitePicker from './RiskAppetitePicker';
import RiskTypePicker from './RiskTypePicker';
import RiskCategoryPicker from './RiskCategoryPicker';
import RiskControlsInput from './RiskControlsInput';
import ConfirmDialog from './ConfirmDialog';
import PillarsPicker from './PillarsPicker';
import { getRiskTreatmentActionsForRisk } from 'api/riskTreatmentActions';
import RiskTreatmentActionsInput from './RiskTreatmentActionsInput';
import RiskScoreFull from './RiskScoreFull';
import RiskScoreDirectInput from './RiskScoreDirectInput';
import { calculateAppetiteStatus, formatDate } from 'utils';
import PersonInput from './PersonInput';
import LinkedPrincipalRisksPicker from './LinkedPrincipalRisksPicker';
import { ArrowBack, ArrowForward, Save, PanTool, Close, Publish } from '@mui/icons-material';

interface IFormData {
	name: string;
	description: string;
	riskTypeId?: number | null;
	riskCategoryId?: number | null;
	principalRisk: boolean;
	linkedToPrincipalRisk: boolean;
	linkedPrincipalRisks: number[];
	appetite: RiskAppetite | '';
	strategic_pillars: IApiStrategicPillar[];
	inherent_score: IApiRiskScoreUpsert | null;
	owner: string;
	sponsor: string;
	controls: (IApiRiskControl | IApiRiskControlUpdate)[];
	mitigations: (IApiRiskControl | IApiRiskControlUpdate)[];
	controlsAssuranceSources: string;
	residual_score: IApiRiskScoreUpsert | null;
	state: RiskState;
	desired_score: IApiRiskScoreUpsert | null;
	treatmentRequiredResources: string;
	reviewInterval: ReviewInterval | '';
	insuranceReviewRequired: boolean | null;
}

// The following validation schemas are used by formik to validate the form
const validationSchemasByStep = [
	yup.object({
		name: yup.string().required('Name is required').max(255, 'Name should be less than 255 characters'),
		description: yup.string(),
		riskTypeId: yup.number().nullable(),
		riskCategoryId: yup.number().nullable(),
		principalRisk: yup.boolean(),
		linkedToPrincipalRisk: yup.boolean(),
		linkedPrincipalRisks: yup.array().of(yup.number()),
		appetite: yup.string(),
	}),
	yup.object({
		strategic_pillars: yup.array().of(yup.object()),
		owner: yup.string().max(255, 'Owners should be less than 255 characters'),
		sponsor: yup.string().max(255, 'Sponsors should be less than 255 characters'),
	}),
	yup.object({
		controls: yup.array().of(yup.object()),
	}),
	yup.object({
		mitigations: yup.array().of(yup.object()),
	}),
	yup.object({
		controlsAssuranceSources: yup.string(),
	}),
	yup.object({
		treatmentRequiredResources: yup.string(),
	}),
	yup.object({
		reviewInterval: yup.string(),
		insuranceReviewRequired: yup.boolean(),
	}),
];

// The following validation schema is used to mark steps as completed. It is not required to complete all steps to submit the form
const validationSchemasByStepForCompletion = [
	yup.object({
		name: yup.string().required('Name is required').max(255, 'Name should be less than 255 characters'),
		description: yup.string().required('Description is required'),
		riskTypeId: yup.number().nullable(true).required('Risk type is required'),
		riskCategoryId: yup.number().nullable(true).required('Risk category is required'),
		principalRisk: yup.boolean(),
		linkedToPrincipalRisk: yup.boolean(),
		linkedPrincipalRisks: yup.array().of(yup.number()),
		appetite: yup.string().required('Specify a risk appetite'),
	}),
	yup.object({
		strategic_pillars: yup.array().of(yup.object()).min(1, 'Choose at least one strategy pillar'),
		inherent_score: yup
			.object({
				likelihood: yup.number().required(),
				impact: yup.number().required(),
				score: yup.number().required(),
				risk_score_impacts: yup.array().required(),
			})
			.nullable(true)
			.default(null)
			.required('Enter the inherent risk rating'),
		owner: yup.string().required('Enter at least one risk owner').max(255, 'Owners should be less than 255 characters'),
		sponsor: yup
			.string()
			.required('Enter at least one sponsor')
			.max(255, 'Sponsors should be less than 255 characters'),
	}),
	yup.object({
		controls: yup.array().of(yup.object()).min(1, 'You need to enter at least one control measure'),
	}),
	yup.object({}),
	yup.object({
		controlsAssuranceSources: yup.string().required('Enter sources of assurance'),
		residual_score: yup
			.object({
				likelihood: yup.number().required(),
				impact: yup.number().required(),
				score: yup.number().required(),
				risk_score_impacts: yup.array().required(),
			})
			.nullable(true)
			.default(null)
			.required('Enter the residual risk rating'),
	}),
	yup.object({
		desired_score: yup
			.object({
				likelihood: yup.number().required(),
				impact: yup.number().required(),
				score: yup.number().required(),
				risk_score_impacts: yup.array().required(),
			})
			.nullable(true)
			.default(null)
			.required('Enter the desired residual risk rating'),
		treatmentRequiredResources: yup
			.string()
			.required('Enter the resources required to carry out for the treatment plan'),
	}),
	yup.object({
		reviewInterval: yup.string().required('Enter the interval for reviews'),
		insuranceReviewRequired: yup.boolean(),
	}),
];

const getInitialFormData = (catalogueItem?: IApiRiskCatalogueItem): IFormData => {
	return {
		name: catalogueItem?.name || '',
		description: catalogueItem?.description || '',
		riskTypeId: catalogueItem?.risk_type_id || null,
		riskCategoryId: catalogueItem?.risk_category_id || null,
		principalRisk: false,
		linkedToPrincipalRisk: false,
		linkedPrincipalRisks: catalogueItem?.catalogue_linked_principal_risks || [],
		appetite: catalogueItem?.appetite || '',
		strategic_pillars: catalogueItem?.catalogue_strategic_pillars || [],
		inherent_score: null,
		owner: '',
		sponsor: '',
		controls:
			catalogueItem?.catalogue_controls
				.filter((rc) => rc.type === 'Control')
				.map((rc) => ({
					type: rc.type,
					description: rc.description,
					owner: '',
				})) || [],
		mitigations:
			catalogueItem?.catalogue_controls
				.filter((rc) => rc.type === 'Mitigation')
				.map((rc) => ({
					type: rc.type,
					description: rc.description,
					owner: '',
				})) || [],

		controlsAssuranceSources: catalogueItem?.controls_assurance_sources || '',
		residual_score: null,
		desired_score: null,
		treatmentRequiredResources: '',
		reviewInterval: '',
		insuranceReviewRequired: false,
		state: 'Pending treatment',
	};
};

const getFormDataFromRisk = (risk: IApiRiskFull, riskControls: IApiRiskControl[]): IFormData => {
	return {
		name: risk.name,
		description: risk.description,
		riskTypeId: risk.risk_type_id,
		riskCategoryId: risk.risk_category_id,
		principalRisk: risk.principal_risk,
		linkedToPrincipalRisk: Boolean(risk.linked_principal_risks.length),
		linkedPrincipalRisks: risk.linked_principal_risks,
		appetite: risk.appetite || '',
		strategic_pillars: risk.strategic_pillars,
		inherent_score: risk.inherent_score,
		owner: risk.owner || '',
		sponsor: risk.sponsor || '',
		controls: riskControls.filter((rc) => rc.type === 'Control'),
		mitigations: riskControls.filter((rc) => rc.type === 'Mitigation'),
		controlsAssuranceSources: risk.controls_assurance_sources || '',
		residual_score: risk.residual_score,
		desired_score: risk.desired_score,
		treatmentRequiredResources: risk.treatment_required_resources || '',
		reviewInterval: risk.review_interval || '',
		insuranceReviewRequired: risk.insurance_review_required,
		state: risk.state || 'Pending treatment',
	};
};

interface ICreateOrUpdateRiskDialogProps {
	register: IApiRiskRegister;
	mode: 'create' | 'update' | 'accept';
	catalogueItem?: IApiRiskCatalogueItem;
	riskId?: number;
	riskActionId?: number;
	onClose: (result: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		field: {
			margin: theme.spacing(1),
			minWidth: '250px',
		},
	}),
);

export default function CreateOrUpdateRiskDialog({
	register,
	mode,
	catalogueItem,
	riskId,
	riskActionId,
	onClose,
}: ICreateOrUpdateRiskDialogProps) {
	const classes = useStyles();
	const [riskTypes, setRiskTypes] = useState<IApiRiskType[]>([]);
	const [riskCategories, setRiskCategories] = useState<IApiRiskCategory[]>([]);
	const [riskImpactCategories, setRiskImpactCategories] = useState<IApiRiskImpactCategory[]>([]);
	const [areRiskDefinitionsLoading, setAreRiskDefinitionsLoading] = useState(false);
	const [areRiskImpactCategoriesLoading, setAreRiskImpactCategoriesLoading] = useState(false);
	const [isRiskDataLoading, setIsRiskDataLoading] = useState(false);
	const [areRiskTreatmentActionsLoading, setAreRiskTreatmentActionsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [isRejectConfirmDialogOpen, setIsRejectConfirmDialogOpen] = useState(false);
	const [completedSteps, setCompletedSteps] = useState<(boolean | undefined)[]>([]);
	const [optionalSteps, setOptionalSteps] = useState<(boolean | undefined)[]>([]);
	const [treatmentPlanRequired, setTreatmentPlanRequired] = useState(false);
	const [treatmentPlanEnabled, setTreatmentPlanEnabled] = useState(false);
	const [areLinkedRisksLoading, setAreLinkedRisksLoading] = useState(false);
	const [linkedRisks, setLinkedRisks] = useState<IApiRisk[]>([]);
	const [lastInsuranceReview, setLastInsuranceReview] = useState<IApiRiskAction | null>(null);
	const [isLastInsuranceReviewLoading, setIsLastInsuranceReviewLoading] = useState(false);
	const formik = useFormik<IFormData>({
		initialValues: getInitialFormData(catalogueItem),
		validationSchema: validationSchemasByStep[activeStep],
		onSubmit: async ({
			name,
			description,
			riskTypeId,
			riskCategoryId,
			principalRisk,
			linkedPrincipalRisks,
			appetite,
			strategic_pillars,
			inherent_score,
			owner,
			sponsor,
			controls,
			mitigations,
			controlsAssuranceSources,
			residual_score,
			desired_score,
			treatmentRequiredResources,
			reviewInterval,
			insuranceReviewRequired,
			state,
		}) => {
			setIsSaving(true);

			try {
				const risk_register_id = register.risk_register_id;

				const treatment_actions: IApiRiskTreatmentActionUpdate[] = riskTreatmentActions.flatMap(({ updated }) =>
					updated ? [updated] : [],
				);

				const appetite_status =
					residual_score && appetite ? calculateAppetiteStatus(residual_score.score, appetite) : null;

				const riskData: IApiRiskCreate = {
					risk_register_id,
					name,
					description,
					risk_type_id: riskTypeId!,
					risk_category_id: riskCategoryId!,
					strategic_pillars: strategic_pillars.map((p) => p.strategic_pillar_id),
					inherent_score,
					owner,
					sponsor,
					state,
					controls_assurance_sources: controlsAssuranceSources || null,
					residual_score,
					controls: [...controls, ...mitigations],
					principal_risk: principalRisk,
					linked_principal_risks: linkedPrincipalRisks,
					appetite: appetite || null,
					appetite_status,
					desired_score,
					treatment_required_resources: treatmentRequiredResources || null,
					treatment_actions,
					review_interval: reviewInterval || null,
					insurance_review_required: insuranceReviewRequired,
				};

				let risk_id: number;

				if (mode === 'update' || mode === 'accept') {
					if (!riskId) {
						return;
					}

					risk_id = riskId;
					await RisksRegisterApi.updateRisk(risk_register_id, risk_id, riskData);

					if (mode === 'accept' && riskActionId) {
						await RiskActionsApi.completeRiskAction(register.risk_register_id, riskId, riskActionId);
					}
				} else {
					const newRisk = await RisksRegisterApi.createRisk(risk_register_id, riskData);
					risk_id = newRisk.risk_id;
				}

				if (!riskId) {
					await createRiskAction(risk_register_id, risk_id, {
						risk_id,
						action_type: 'update-risk',
						description: 'Initial risk information needs completing',
						user_comment: null,
					});
				}

				onClose(true);
			} finally {
				setIsSaving(false);
			}
		},
	});
	const [riskTreatmentActions, setRiskTreatmentActions] = useState<
		{ saved?: IApiRiskTreatmentAction; updated: IApiRiskTreatmentActionUpdate | null }[]
	>([]);
	const [validationWarnings, setValidationWarnings] = useState<{ [fieldName: string]: string[] }[]>([]);

	useEffect(() => {
		loadRiskDefinitions();
		loadRiskImpactCategories();

		if (riskId) {
			loadRiskData();
			loadTreatmentActions();
		}
	}, [riskId]);

	useEffect(() => {
		if (riskId && formik.values.insuranceReviewRequired) {
			loadLastInsuranceReview();
		}
	}, [riskId, formik.values.insuranceReviewRequired]);

	useEffect(() => {
		runValidation(formik.values);
	}, [activeStep]);

	useEffect(() => {
		// Make risk treatment plan step optional depending on the risk score and appetite status
		const treatmentRequired =
			formik.values.residual_score && formik.values.appetite
				? formik.values.residual_score.score >= 16 ||
				  calculateAppetiteStatus(formik.values.residual_score.score, formik.values.appetite) === 'Above appetite'
				: false;

		setOptionalSteps((current) => {
			const updated = [...current];
			updated[5] = !treatmentRequired && !treatmentPlanEnabled;

			return updated;
		});
		setTreatmentPlanRequired(treatmentRequired);
	}, [formik.values.residual_score, formik.values.appetite, treatmentPlanEnabled]);

	useEffect(() => {
		// Show risk treatment plan if relevant
		const treatmentCompleted = formik.values.desired_score !== null;

		if (treatmentPlanRequired || treatmentCompleted) {
			setTreatmentPlanEnabled(true);
		}
	}, [treatmentPlanRequired, formik.values.desired_score]);

	useEffect(() => {
		if (formik.values.principalRisk && riskId) {
			loadLinkedRisks();
		}
	}, [formik.values.principalRisk, riskId]);

	const runValidation = (formData: IFormData) => {
		// Update list of warnings for current step
		const warningsForStep: { [fieldName: string]: string[] } = {};

		for (const field of Object.keys(validationSchemasByStepForCompletion[activeStep].fields)) {
			try {
				validationSchemasByStepForCompletion[activeStep].validateSyncAt(field, {
					[field]: (formData as any)[field],
				});
			} catch ({ errors }) {
				if (Array.isArray(errors)) {
					if (!warningsForStep[field]) {
						warningsForStep[field] = [];
					}

					warningsForStep[field].push(...errors);
				}
			}
		}

		setValidationWarnings((current) => {
			const updated = { ...current };

			updated[activeStep] = warningsForStep;

			return updated;
		});

		// let isStepCompleted = validationSchemasByStepForCompletion[activeStep].isValidSync(formData);
		// setCompletedSteps((current) => {
		// 	const updated = [...current];
		// 	updated[activeStep] = isStepCompleted;

		// 	return updated;
		// });

		updateCompletedSteps(formData);
	};

	const updateCompletedSteps = (formData: IFormData) => {
		const newCompletedSteps = steps.map((s, index) =>
			validationSchemasByStepForCompletion[index].isValidSync(formData),
		);

		setCompletedSteps(newCompletedSteps);
	};

	const handleFieldChange = (fieldName: string, value: any) => {
		// Update field in formik
		formik.setFieldValue(fieldName, value);

		// Update whether the step is completed
		const isStepCompleted = validationSchemasByStepForCompletion[activeStep].isValidSync({
			...formik.values,
			[fieldName]: value,
		});

		setCompletedSteps((current) => {
			const updated = [...current];
			updated[activeStep] = isStepCompleted;

			return updated;
		});

		// Update validation warnings
		try {
			validationSchemasByStepForCompletion[activeStep].validateSyncAt(fieldName, { [fieldName]: value });
			setValidationWarnings((current) => {
				const updated = cloneDeep(current);

				if (updated[activeStep]?.[fieldName]) {
					delete updated[activeStep][fieldName];
				}

				return updated;
			});
		} catch ({ errors }) {
			if (Array.isArray(errors)) {
				setValidationWarnings((current) => {
					const updated = cloneDeep(current);

					if (!updated[activeStep]) {
						updated[activeStep] = {};
					}

					updated[activeStep][fieldName] = errors as string[];

					return updated;
				});
			}
		}
	};

	const handleChange = ({ target }: React.ChangeEvent<any> | any) => {
		handleFieldChange(target.name, target.value);
	};

	const loadRiskDefinitions = async () => {
		setAreRiskDefinitionsLoading(true);

		try {
			const data = await Promise.all([RiskTypesApi.getLiveRiskTypes(), RiskCategoriesApi.getLiveCategories()]);
			setRiskTypes(data[0]);
			setRiskCategories(data[1]);
		} finally {
			setAreRiskDefinitionsLoading(false);
		}
	};

	const loadRiskImpactCategories = async () => {
		setAreRiskImpactCategoriesLoading(true);

		try {
			const data = await RiskImpactCategoriesApi.getLiveImpactCategories();
			setRiskImpactCategories(data);
		} finally {
			setAreRiskImpactCategoriesLoading(false);
		}
	};

	const loadRiskData = async () => {
		if (!riskId) {
			return;
		}

		setIsRiskDataLoading(true);

		try {
			const riskData = await RisksRegisterApi.getRiskById(register.risk_register_id, riskId);
			const riskControlsData = await getRiskControlsForRisk(register.risk_register_id, riskId);
			const formData = getFormDataFromRisk(riskData, riskControlsData);
			formik.resetForm({ values: formData });
			runValidation(formData);
		} finally {
			setIsRiskDataLoading(false);
		}
	};

	const loadTreatmentActions = async () => {
		if (!riskId) {
			return;
		}

		setAreRiskTreatmentActionsLoading(true);

		try {
			const data = await getRiskTreatmentActionsForRisk(register.risk_register_id, riskId);
			setRiskTreatmentActions(
				data.map((item) => ({
					saved: item,
					updated: {
						risk_treatment_action_id: item.risk_treatment_action_id,
						description: item.description,
						owner: item.owner,
						start_date: item.start_date,
						end_date: item.end_date,
						completed: item.completed,
					},
				})),
			);
		} finally {
			setAreRiskTreatmentActionsLoading(false);
		}
	};

	const loadLinkedRisks = async () => {
		if (!riskId) {
			return;
		}

		setAreLinkedRisksLoading(true);

		try {
			const data = await PrincipalRisksApi.getRisksLinkedToPrincipalRisk(riskId);
			setLinkedRisks(data);
		} finally {
			setAreLinkedRisksLoading(false);
		}
	};

	const loadLastInsuranceReview = async () => {
		if (!riskId) {
			return;
		}

		setIsLastInsuranceReviewLoading(true);

		try {
			const data = await RiskActionsApi.getLastCompletedActionForRiskAndType(
				register.risk_register_id,
				riskId,
				'insurance-review',
			);
			setLastInsuranceReview(data);
		} finally {
			setIsLastInsuranceReviewLoading(false);
		}
	};

	const rejectRisk = async () => {
		if (!riskId || !riskActionId) {
			return;
		}

		setIsSaving(true);

		try {
			await RisksRegisterApi.deleteRisk(register.risk_register_id, riskId);
			await RiskActionsApi.completeRiskAction(register.risk_register_id, riskId, riskActionId);
			onClose(true);
		} finally {
			setIsSaving(false);
		}
	};

	const goToNextStep = () => {
		const newStep = activeStep + 1;

		if (newStep < steps.length) {
			setActiveStep(newStep);
		}
	};

	const toGoPreviousStep = () => {
		const newStep = activeStep - 1;

		if (newStep >= 0) {
			setActiveStep(newStep);
		}
	};

	const isTopLevelRegister = register.parent_risk_register_id === null;
	const steps = [
		'Risk identification',
		'Risk analysis and evaluation',
		'Control measures',
		'Mitigation measures',
		'Residual score',
		'Risk treatment plan',
		'Risk reviews',
	];
	const optionalStepsCount = optionalSteps.filter((s) => s).length;
	const dataEntryCompleted =
		completedSteps.filter((s, i) => !optionalSteps[i] && s).length === steps.length - optionalStepsCount;
	const riskCategory = formik.values.riskCategoryId
		? riskCategories.find((c) => c.risk_category_id === formik.values.riskCategoryId)
		: undefined;

	return (
		<Dialog open={true} fullWidth maxWidth="lg">
			<DialogTitle>
				{mode === 'update' ? 'Update risk' : mode === 'create' ? 'Add risk to register' : 'Review risk'}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', minHeight: '500px' }}>
				{isRiskDataLoading ? (
					<Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						<Box>
							<Stepper sx={{ marginBottom: (theme) => theme.spacing(2) }} nonLinear orientation="vertical">
								{steps.map((label, step) => {
									const completed = Boolean(completedSteps[step]);
									const optional = Boolean(optionalSteps[step]);
									const active = activeStep === step;
									return (
										<Step key={label} completed={completed} active={active}>
											<StepLabel
												optional={
													completed ? undefined : (
														<Typography variant="caption">{optional ? 'Optional' : 'Not completed'}</Typography>
													)
												}
												componentsProps={{
													label: { style: { fontWeight: active ? 500 : 'normal', whiteSpace: 'nowrap' } },
												}}
												StepIconProps={{
													sx:
														!active && completed
															? { color: (theme) => `${theme.palette.success.main} !important` }
															: {},
												}}
											>
												{label}
											</StepLabel>
										</Step>
									);
								})}
							</Stepper>
						</Box>
						<Box
							sx={{ marginLeft: (theme) => theme.spacing(2), flexGrow: 1, display: 'flex', flexDirection: 'column' }}
						>
							<form onSubmit={formik.handleSubmit}>
								{activeStep === 0 && (
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Box sx={{ display: 'flex' }}>
											<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
												<TextField
													label="Risk name"
													name="name"
													value={formik.values.name}
													onChange={handleChange}
													onBlur={formik.handleBlur}
													error={Boolean(formik.errors.name)}
													helperText={formik.touched.name && formik.errors.name}
													className={classes.field}
													disabled={mode === 'accept'}
												/>
												<RiskTypePicker
													value={formik.values.riskTypeId}
													onChange={(value) => handleFieldChange('riskTypeId', value)}
													list={riskTypes}
													loading={areRiskDefinitionsLoading}
													disabled={mode === 'accept' || Object.keys(riskTypes).length === 0}
													error={Boolean(formik.errors.riskTypeId)}
													helperText={formik.touched.riskTypeId && formik.errors.riskTypeId}
												/>
												<RiskCategoryPicker
													value={formik.values.riskCategoryId}
													onChange={(value) => handleFieldChange('riskCategoryId', value)}
													list={riskCategories}
													loading={areRiskDefinitionsLoading}
													disabled={mode === 'accept' || Object.keys(riskTypes).length === 0}
													error={Boolean(formik.errors.riskCategoryId)}
													helperText={formik.touched.riskCategoryId && formik.errors.riskCategoryId}
												/>
												<RiskAppetitePicker
													value={formik.values.appetite}
													onChange={(value) => handleFieldChange('appetite', value)}
													disabled={formik.values.riskCategoryId === 0}
													riskCategory={riskCategory}
													error={Boolean(formik.errors.appetite)}
													helperText={formik.touched.appetite && formik.errors.appetite}
												/>
											</Box>
											<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
												<TextField
													label="Description"
													name="description"
													multiline
													rows={10}
													value={formik.values.description}
													onChange={handleChange}
													onBlur={formik.handleBlur}
													error={formik.touched.description && Boolean(formik.errors.description)}
													helperText={formik.touched.description && formik.errors.description}
													className={classes.field}
												/>
											</Box>
										</Box>
										{isTopLevelRegister && (
											<FormGroup sx={{ marginLeft: (theme) => theme.spacing(1) }}>
												<FormControlLabel
													control={
														<Checkbox
															name="principalRisk"
															checked={formik.values.principalRisk}
															onChange={(e) => handleFieldChange('principalRisk', e.target.checked)}
															onBlur={formik.handleBlur}
															disabled={
																formik.values.principalRisk && (areLinkedRisksLoading || linkedRisks.length > 0)
															}
														/>
													}
													label={
														<Box sx={{ display: 'flex', alignItems: 'center' }}>
															<Typography sx={{ marginRight: (theme) => theme.spacing(1) }}>Principal risk</Typography>
															{formik.values.principalRisk && riskId ? (
																areLinkedRisksLoading ? (
																	<CircularProgress size={16} />
																) : (
																	<Typography>{`(${linkedRisks.length} linked risks)`}</Typography>
																)
															) : null}
														</Box>
													}
												/>
											</FormGroup>
										)}
										{!formik.values.principalRisk && (
											<LinkedPrincipalRisksPicker
												value={formik.values.linkedPrincipalRisks}
												onChange={(value) => handleFieldChange('linkedPrincipalRisks', value)}
												disabled={mode === 'accept'}
												error={Boolean(formik.errors.linkedPrincipalRisks)}
												helperText={formik.touched.linkedPrincipalRisks && formik.errors.linkedPrincipalRisks}
											/>
										)}
									</Box>
								)}
								{activeStep === 1 && (
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<PillarsPicker
											value={formik.values.strategic_pillars}
											onChange={(value) => handleFieldChange('strategic_pillars', value)}
											disabled={mode === 'accept'}
											error={Boolean(formik.errors.strategic_pillars)}
											helperText={formik.touched.strategic_pillars && (formik.errors.strategic_pillars as string)}
										/>
										<PersonInput
											label="Risk owners"
											addLabel="Type to add risk owner"
											value={formik.values.owner.split(',').filter((v) => v.length > 0)}
											onChange={(value) => handleFieldChange('owner', value.join(','))}
										/>
										<PersonInput
											label="Risk sponsors"
											addLabel="Type to add risk owner"
											value={formik.values.sponsor.split(',').filter((v) => v.length > 0)}
											onChange={(value) => handleFieldChange('sponsor', value.join(','))}
										/>
										<Box sx={{ alignSelf: 'start' }}>
											<RiskScoreInput
												value={formik.values.inherent_score}
												impactCategories={riskImpactCategories}
												loading={areRiskImpactCategoriesLoading}
												label="Inherent risk rating"
												onChange={(value) => handleFieldChange('inherent_score', value)}
											/>
										</Box>
									</Box>
								)}
								{activeStep === 2 && (
									<RiskControlsInput
										type="Control"
										initialData={
											formik.initialValues.controls.filter((c) => c.risk_control_id !== undefined) as IApiRiskControl[]
										}
										data={formik.values.controls}
										onChange={(data) => handleFieldChange('controls', data)}
										isLoading={isRiskDataLoading}
									/>
								)}
								{activeStep === 3 && (
									<RiskControlsInput
										type="Mitigation"
										initialData={
											formik.initialValues.mitigations.filter(
												(c) => c.risk_control_id !== undefined,
											) as IApiRiskControl[]
										}
										data={formik.values.mitigations}
										onChange={(data) => handleFieldChange('mitigations', data)}
										isLoading={isRiskDataLoading}
									/>
								)}
								{activeStep === 4 && (
									<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
										<TextField
											label="Sources of assurance"
											name="controlsAssuranceSources"
											multiline
											fullWidth
											rows={4}
											value={formik.values.controlsAssuranceSources}
											onChange={handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.controlsAssuranceSources && Boolean(formik.errors.controlsAssuranceSources)}
											helperText={formik.touched.controlsAssuranceSources && formik.errors.controlsAssuranceSources}
											className={classes.field}
										/>
										<RiskScoreInput
											value={formik.values.residual_score}
											defaultValue={
												formik.values.inherent_score && !formik.values.residual_score
													? { ...formik.values.inherent_score, risk_score_id: undefined }
													: undefined
											}
											impactCategories={riskImpactCategories}
											loading={areRiskImpactCategoriesLoading}
											label="Residual risk rating"
											onChange={(value) => handleFieldChange('residual_score', value)}
										/>
									</Box>
								)}
								{activeStep === 5 && (
									<Box>
										{treatmentPlanRequired && (
											<Alert severity="info" sx={{ margin: (theme) => theme.spacing(1) }}>
												{formik.values.residual_score && formik.values.residual_score.score >= 16
													? 'Because the residual risk rating is 16 or above, you need to complete a risk treatment plan'
													: 'Because the risk exposure is above appetite, you need to complete a risk treatment plan'}
											</Alert>
										)}
										<FormControlLabel
											control={
												<Switch
													disabled={treatmentPlanRequired}
													checked={treatmentPlanEnabled}
													onChange={(event) => {
														setTreatmentPlanEnabled(event.target.checked);
													}}
												/>
											}
											label="Carry out risk treatment plan"
										/>
										{treatmentPlanEnabled && (
											<>
												<Box sx={{ display: 'flex', alignItems: 'stretch' }}>
													<RiskScoreFull value={formik.values.residual_score} label="Current residual risk rating" />
													<RiskScoreDirectInput
														value={formik.values.desired_score}
														label="Desired residual risk rating"
														onChange={(value) => handleFieldChange('desired_score', value)}
													/>
													<TextField
														label="Resources required"
														name="treatmentRequiredResources"
														sx={{ flexGrow: 1 }}
														multiline
														rows={3}
														value={formik.values.treatmentRequiredResources}
														onChange={handleChange}
														onBlur={formik.handleBlur}
														error={
															formik.touched.treatmentRequiredResources &&
															Boolean(formik.errors.treatmentRequiredResources)
														}
														helperText={
															formik.touched.treatmentRequiredResources && formik.errors.treatmentRequiredResources
														}
														className={classes.field}
													/>
												</Box>
												<RiskTreatmentActionsInput
													data={riskTreatmentActions}
													onChange={(data) => setRiskTreatmentActions(data)}
													isLoading={areRiskTreatmentActionsLoading}
												/>
											</>
										)}
									</Box>
								)}
								{activeStep === 6 && (
									<Box>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<TextField
												label="Required frequency for reviews"
												name="reviewInterval"
												value={formik.values.reviewInterval}
												select={true}
												SelectProps={{
													native: true,
												}}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) => {
													const value = event.target.value;
													handleFieldChange('reviewInterval', value);
												}}
												onBlur={formik.handleBlur}
												error={formik.touched.reviewInterval && Boolean(formik.errors.reviewInterval)}
												helperText={formik.touched.reviewInterval && formik.errors.reviewInterval}
												className={classes.field}
											>
												<option value="">-- Select from list --</option>
												<option value="quarterly">Quarterly</option>
												<option value="bimonthly">Every 2 months</option>
												<option value="monthly">Monthly</option>
												<option value="biweekly">Every 2 weeks</option>
												<option value="weekly">Weekly</option>
												<option value="daily">Daily</option>
											</TextField>
											<Typography variant="caption">
												The risk owner(s) will will notified by email and in app when this risk is due for review
											</Typography>
										</Box>
										{formik.values.riskTypeId === 2 && (
											<Box>
												<Divider
													sx={{ marginTop: (theme) => theme.spacing(2), marginBottom: (theme) => theme.spacing(2) }}
												/>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																name="insuranceReviewRequired"
																checked={Boolean(formik.values.insuranceReviewRequired)}
																onChange={(e) => handleFieldChange('insuranceReviewRequired', e.target.checked)}
																onBlur={formik.handleBlur}
															/>
														}
														label="This risk requires a review by the insurance team"
													/>
												</FormGroup>
												{riskId &&
													(isLastInsuranceReviewLoading ? (
														<CircularProgress />
													) : (
														formik.values.insuranceReviewRequired && (
															<Typography>
																{lastInsuranceReview
																	? `The last insurance review was completed on ${formatDate(
																			lastInsuranceReview.completed_time!,
																	  )}`
																	: 'Insurance review has not been carried out yet'}
															</Typography>
														)
													))}
											</Box>
										)}
									</Box>
								)}
							</form>
							<Box sx={{ flexGrow: 1 }} />
							{(activeStep !== 5 || treatmentPlanEnabled) &&
								validationWarnings[activeStep] &&
								Object.keys(validationWarnings[activeStep]).length > 0 && (
									<Alert severity="warning" sx={{ whiteSpace: 'pre-line' }}>
										{Object.values(validationWarnings[activeStep])
											.map((warnings) => warnings.join('\n'))
											.join('\n')}
									</Alert>
								)}
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} onClick={() => onClose(false)} variant="outlined">
					Cancel
				</Button>
				{activeStep > 0 && (
					<Button startIcon={<ArrowBack />} onClick={() => toGoPreviousStep()} variant="outlined">
						Back
					</Button>
				)}
				<Box sx={{ flex: '1 1 auto' }} />
				{activeStep < steps.length - 1 && (
					<Button
						endIcon={<ArrowForward />}
						onClick={() => goToNextStep()}
						variant="contained"
						disabled={!formik.isValid}
					>
						Next
					</Button>
				)}
				<Button
					startIcon={<Save />}
					onClick={() => {
						if (mode === 'accept') {
							handleFieldChange('state', 'Pending treatment');
						}

						formik.submitForm();
					}}
					variant="outlined"
					disabled={isSaving || !formik.isValid}
				>
					{mode === 'update' ? 'Save risk' : mode === 'create' ? 'Save new risk' : 'Accept risk'}
				</Button>
				{mode === 'accept' && (
					<Button
						startIcon={<PanTool />}
						onClick={() => setIsRejectConfirmDialogOpen(true)}
						variant="outlined"
						disabled={isSaving}
					>
						Reject risk
					</Button>
				)}
				{formik.values.state === 'Pending treatment' && (
					<Button
						startIcon={<Publish />}
						onClick={() => {
							handleFieldChange('state', 'Live');
							formik.submitForm();
						}}
						variant="outlined"
						disabled={isSaving || !dataEntryCompleted}
					>
						{mode === 'update'
							? 'Save and publish risk'
							: mode === 'create'
							? 'Add and publish risk'
							: 'Accept and publish risk'}
					</Button>
				)}
			</DialogActions>
			{isRejectConfirmDialogOpen && (
				<ConfirmDialog
					title="Reject risk?"
					message="If you reject this risk, it will be deleted from this register. Are you sure?"
					cancelLabel="Cancel"
					confirmLabel="Confirm and delete risk"
					onClose={() => setIsRejectConfirmDialogOpen(false)}
					onConfirm={() => rejectRisk()}
				/>
			)}
		</Dialog>
	);
}
