import React from 'react';
import { Typography } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

export default function NotFound() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'NotFoundPage');

	componentTracking();

	return <Typography>Page not found</Typography>;
}
