import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import AuthenticationProviderForm from './AuthenticationProviderForm';

interface IAddAuthenticationProviderDialogProps {
	onChange: () => void;
	onClose: () => void;
}

export default function AddAuthenticationProviderDialog({ onChange, onClose }: IAddAuthenticationProviderDialogProps) {
	return (
		<Dialog open={true} onClose={onClose}>
			<DialogTitle>New authentication provider</DialogTitle>
			<DialogContent>
				<AuthenticationProviderForm
					onSave={() => {
						onChange();
						onClose();
					}}
					onCancel={onClose}
				/>
			</DialogContent>
		</Dialog>
	);
}
