import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	ButtonBase,
	LinearProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { IApiStrategicPillar, IApiStrategicPillarWithImage } from '@mitie/risk-register-api-types';
import { Check, Close } from '@mui/icons-material';

import * as Api from '../api/strategicPillars';

interface IPillarsPickerDialogProps {
	value: IApiStrategicPillar[];
	onClose: (selected?: IApiStrategicPillar[]) => void;
}

export default function PillarsPickerDialog({ value, onClose }: IPillarsPickerDialogProps) {
	const [selected, setSelected] = useState(value);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [strategicPillars, setStrategicPillars] = useState<Record<string, IApiStrategicPillarWithImage[]>>({});

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setIsDataLoading(true);

		try {
			const data = await Api.getAll();
			setStrategicPillars(
				data.reduce((acc, cur) => {
					if (!cur.disabled) {
						// Only display enabled pillars in picker
						if (!acc[cur.type]) {
							acc[cur.type] = [cur];
						} else {
							acc[cur.type].push(cur);
						}
					}

					return acc;
				}, {} as Record<string, IApiStrategicPillarWithImage[]>),
			);
		} finally {
			setIsDataLoading(false);
		}
	};

	const buttonClicked = ({ image, ...pillar }: IApiStrategicPillarWithImage) => {
		setSelected((previous) => {
			if (previous.find((p) => p.strategic_pillar_id === pillar.strategic_pillar_id)) {
				return previous.filter((p) => p.strategic_pillar_id !== pillar.strategic_pillar_id);
			} else {
				return [...previous, pillar];
			}
		});
	};

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
			<DialogTitle>Select the impacted strategic pillars should the risk materialise</DialogTitle>
			<DialogContent>
				{isDataLoading && <LinearProgress />}
				{Object.entries(strategicPillars).map(([type, list]) => (
					<Box key={type}>
						<Typography variant="subtitle2" sx={{ marginTop: (theme) => theme.spacing(2) }}>
							{type}
						</Typography>
						<Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>
							{list.map((pillar) => (
								<ButtonBase
									sx={{
										flexDirection: 'column',
										alignItems: 'stretch',
										marginRight: (theme) => theme.spacing(1),
										padding: '3px',
										'&:hover': {
											'&>div': {
												opacity: 0.25,
											},
										},
										border:
											selected.find((p) => p.strategic_pillar_id === pillar.strategic_pillar_id) !== undefined
												? (theme) => `3px solid ${theme.palette.secondary.main}`
												: '3px solid transparent',
										textAlign: 'left',
										zIndex: 1,
										width: '200px',
									}}
									onClick={() => buttonClicked(pillar)}
									key={pillar.name}
								>
									<Box
										component="span"
										sx={{
											backgroundColor: 'rgb(169, 0, 97)',
											padding: (theme) => theme.spacing(1),
											paddingBottom: 0,
										}}
									>
										{pillar.image && (
											<img src={`data:image/png;base64,${pillar.image}`} alt={`${pillar.name}-icon`} height="64px" />
										)}
									</Box>
									<Typography
										sx={{
											backgroundColor: 'rgb(169, 0, 97)',
											fontWeight: 'bold',
											color: 'white',
											padding: (theme) => theme.spacing(1),
											paddingTop: 0,
										}}
									>
										{pillar.name}
									</Typography>
									{pillar.description && (
										<Typography
											variant="caption"
											sx={{
												backgroundColor: 'rgb(91, 31, 105)',
												color: 'white',
												padding: (theme) => theme.spacing(1),
												flexGrow: 1,
											}}
										>
											{pillar.description}
										</Typography>
									)}
									<Box
										sx={{
											position: 'absolute',
											left: 0,
											right: 0,
											top: 0,
											bottom: 0,
											backgroundColor: (theme) => theme.palette.common.white,
											opacity: 0,
											transition: (theme) => theme.transitions.create('opacity'),
										}}
									/>
								</ButtonBase>
							))}
						</Box>
					</Box>
				))}
			</DialogContent>
			<DialogActions>
				<Button
					startIcon={<Close />}
					onClick={() => {
						onClose();
					}}
					variant="outlined"
				>
					Cancel
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					startIcon={<Check />}
					onClick={() => {
						onClose(selected);
					}}
					variant="contained"
					disabled={selected.length === 0}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
