import { useState } from 'react';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Typography,
	ToggleButtonGroup,
	ToggleButton,
	InputAdornment,
} from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import { IApiCompany, IApiCompanyUpdate, LicenseType } from '@mitie/risk-register-api-types';
import * as yup from 'yup';
import { Unstable_NextDatePicker as DatePicker } from '@mui/x-date-pickers/NextDatePicker';
import { useFormik } from 'formik';
import { formatISO } from 'date-fns';
import { BabyFace, EmoticonHappy, EmoticonNeutral, PartyPopper } from 'mdi-material-ui';

import * as CompaniesApi from '../api/companies';
import useAlerts from '../hooks/useAlerts';

interface ILicenseUpdateDialogProps {
	company: IApiCompany;
	onClose: () => void;
	onChange: () => void;
}

interface IFormData {
	license_type: LicenseType;
	license_start_date: string | null;
	license_end_date: string | null;
	max_users: number;
	unlimited_users: boolean;
}

const validationSchema = yup.object({
	license_type: yup.string(),
	license_start_date: yup.string().nullable(true),
	license_end_date: yup.string().nullable(true),
	max_users: yup.number().integer().min(1),
	unlimited_users: yup.boolean(),
});

export default function LicenseUpdateDialog({ company, onChange, onClose }: ILicenseUpdateDialogProps) {
	const [isSaving, setIsSaving] = useState(false);
	const { addAlert } = useAlerts();
	const formik = useFormik<IFormData>({
		initialValues: {
			license_type: company.license_type,
			license_start_date: company.license_start_date,
			license_end_date: company.license_end_date,
			max_users: company.max_users || 250,
			unlimited_users: company.max_users === null,
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async ({ license_type, license_start_date, license_end_date, max_users, unlimited_users }) => {
			setIsSaving(true);

			const updateData: Partial<IApiCompanyUpdate> = {
				license_type,
				license_start_date,
				license_end_date,
				max_users: unlimited_users ? null : max_users,
			};

			try {
				await CompaniesApi.saveCompany(company.company_id, updateData);
				addAlert('success', `The license for company '${company.name}' has been updated`);

				onChange();
				onClose();
			} catch {
				addAlert('error', `Failed to update the license for company '${company.name}'`);
			} finally {
				setIsSaving(false);
			}
		},
	});

	return (
		<Dialog open={true}>
			<DialogTitle>Change license details</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography>License type:</Typography>
					<ToggleButtonGroup
						exclusive
						size="small"
						value={formik.values.license_type}
						onChange={(event, value) => {
							formik.setFieldValue('license_type', value);
						}}
						sx={{ marginLeft: (theme) => theme.spacing(2) }}
					>
						<ToggleButton value="Demo">
							<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
								<BabyFace sx={{ marginRight: (theme) => theme.spacing(1) }} />
								Demo
							</Box>
						</ToggleButton>
						<ToggleButton value="Standard">
							<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
								<EmoticonNeutral sx={{ marginRight: (theme) => theme.spacing(1) }} />
								Standard
							</Box>
						</ToggleButton>
						<ToggleButton value="Standard+">
							<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
								<EmoticonHappy sx={{ marginRight: (theme) => theme.spacing(1) }} />
								Standard+
							</Box>
						</ToggleButton>
						<ToggleButton value="Premium">
							<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
								<PartyPopper sx={{ marginRight: (theme) => theme.spacing(1) }} />
								Premium
							</Box>
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
				<Box sx={{ display: 'flex' }}>
					<DatePicker
						label="Start date"
						value={formik.values.license_start_date ? new Date(formik.values.license_start_date) : null}
						onChange={(newValue) => {
							if (newValue) {
								formik.setFieldValue('license_start_date', formatISO(newValue, { representation: 'date' }));
							} else {
								formik.setFieldValue('license_start_date', null);
							}
						}}
						sx={{ marginRight: (theme) => theme.spacing(2), marginTop: (theme) => theme.spacing(2) }}
					/>
					<DatePicker
						label="End date"
						value={formik.values.license_end_date ? new Date(formik.values.license_end_date) : null}
						onChange={(newValue) => {
							if (newValue) {
								formik.setFieldValue('license_end_date', formatISO(newValue, { representation: 'date' }));
							} else {
								formik.setFieldValue('license_end_date', null);
							}
						}}
						sx={{ marginTop: (theme) => theme.spacing(2) }}
					/>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', marginTop: (theme) => theme.spacing(2) }}>
					<ToggleButtonGroup
						exclusive
						size="small"
						value={formik.values.unlimited_users}
						onChange={(event, value) => {
							formik.setFieldValue('unlimited_users', value);
						}}
						sx={{ marginRight: (theme) => theme.spacing(2) }}
					>
						<ToggleButton value={true}>Unlimited users</ToggleButton>
						<ToggleButton value={false}>Set maximum users</ToggleButton>
					</ToggleButtonGroup>
					<TextField
						type="number"
						value={formik.values.max_users}
						onChange={(event) => formik.setFieldValue('max_users', event.target.value)}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.max_users)}
						helperText={formik.touched.max_users && formik.errors.max_users}
						size="small"
						sx={{
							width: '130px',
							visibility: formik.values.unlimited_users ? 'hidden' : 'visible',
						}}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							inputProps: {
								style: { textAlign: 'right' },
							},
							endAdornment: <InputAdornment position="end">users</InputAdornment>,
						}}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					variant="contained"
					onClick={() => formik.submitForm()}
					color="primary"
					disabled={!formik.isValid || isSaving}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
