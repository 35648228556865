import { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';
import { Close, Save } from '@mui/icons-material';

import * as CompaniesApi from '../api/companies';
import useAlerts from '../hooks/useAlerts';

interface ICompanyDeleteDialogProps {
	companyId: string;
	onClose: () => void;
	onChange: () => void;
}

export default function CompanyDeleteDialog({ companyId, onChange, onClose }: ICompanyDeleteDialogProps) {
	const { addAlert } = useAlerts();
	const [userInput, setUserInput] = useState('');

	const save = async () => {
		await CompaniesApi.deleteCompany(companyId);
		addAlert('success', `The company '${companyId}' has been deleted`);
		onChange();
		onClose();
	};

	return (
		<Dialog open={true}>
			<DialogTitle>Delete customer</DialogTitle>
			<DialogContent>
				<Typography component="p">
					You are going to DELETE this customer from Risk Safe. Are you sure you want to proceed?
				</Typography>
				<Typography component="p" sx={{ marginTop: (theme) => theme.spacing(2) }}>
					Please type in the company ID ({companyId}) in the box below to confirm
				</Typography>
				<TextField
					label="Enter company ID"
					value={userInput}
					onChange={(event) => setUserInput(event.target.value)}
					margin="normal"
				/>
			</DialogContent>
			<DialogActions>
				<Button startIcon={<Close />} variant="outlined" onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Box sx={{ flexGrow: 1 }} />
				<Button
					startIcon={<Save />}
					variant="contained"
					onClick={save}
					color="primary"
					disabled={userInput !== companyId}
				>
					Delete company
				</Button>
			</DialogActions>
		</Dialog>
	);
}
