import React, { createContext, useState } from 'react';
import { clearCookie, getCookie, setCookie } from 'utils';

export type ColorMode = 'auto' | 'light' | 'dark';

interface IColorModeContext {
	colorMode: ColorMode;
	setColorMode: (colorMode: ColorMode) => void;
}

export const ColorModeContext = createContext<IColorModeContext>({
	colorMode: 'auto',
	setColorMode: () => undefined,
});

export default function ColorModeProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
	const [colorMode, setMode] = useState<ColorMode>((getCookie('colorMode') as ColorMode) || 'auto');
	const setColorMode = (mode: ColorMode) => {
		setMode(mode);

		if (mode === 'auto') {
			clearCookie('colorMode');
		} else {
			setCookie('colorMode', mode);
		}
	};

	return <ColorModeContext.Provider value={{ colorMode, setColorMode }}>{children}</ColorModeContext.Provider>;
}
