import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	LinearProgress,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { DeleteForever, RestoreFromTrash } from '@mui/icons-material';
import { IApiRiskRegisterWithoutPermissions } from '@mitie/risk-register-api-types';

import useAlerts from 'hooks/useAlerts';
import * as RiskRegistersApi from 'api/riskRegisters';
import PropertyDisplay from './PropertyDisplay';
import ConfirmDialog from './ConfirmDialog';

export default function DeletedRegisters() {
	const [isLoading, setIsLoading] = useState(false);
	const [parentLoadingStatus, setParentLoadingStatus] = useState<null | 'none' | 'loading' | 'failed' | 'loaded'>(null);
	const [registers, setRegisters] = useState<IApiRiskRegisterWithoutPermissions[]>([]);
	const [selectedRegister, setSelectedRegister] = useState<IApiRiskRegisterWithoutPermissions | null>(null);
	const [parentRegister, setParentRegister] = useState<IApiRiskRegisterWithoutPermissions | null>(null);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState(false);
	const { addAlert } = useAlerts();

	useEffect(() => {
		loadDeletedRegisters();
	}, []);

	useEffect(() => {
		loadParentRegister();
	}, [selectedRegister]);

	const loadDeletedRegisters = async () => {
		setIsLoading(true);

		try {
			const data = await RiskRegistersApi.getArchivedRegisters();
			setRegisters(data);
		} catch {
			addAlert('error', 'Failed to load the list of archived risk registers');
		} finally {
			setIsLoading(false);
		}
	};

	const loadParentRegister = async () => {
		if (!selectedRegister) {
			setParentRegister(null);
			setParentLoadingStatus(null);
			return;
		}

		if (!selectedRegister.parent_risk_register_id) {
			setParentRegister(null);
			setParentLoadingStatus('none');
			return;
		}

		setParentLoadingStatus('loading');

		try {
			const data = await RiskRegistersApi.getById(selectedRegister.parent_risk_register_id);
			setParentRegister(data);
			setParentLoadingStatus('loaded');
		} catch {
			setParentLoadingStatus('failed');
		}
	};

	return (
		<Box sx={{ height: '100%', display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ width: '300px', overflowY: 'auto' }}>
				{isLoading && <LinearProgress />}
				{registers.map((riskRegister) => (
					<ListItemButton
						key={riskRegister.risk_register_id}
						selected={selectedRegister?.risk_register_id === riskRegister.risk_register_id}
						onClick={() => setSelectedRegister(riskRegister)}
					>
						<ListItemText primary={riskRegister.name} />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" flexItem />
			{selectedRegister && (
				<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
					<Box
						sx={{
							borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
							display: 'flex',
						}}
					>
						<Button
							color="primary"
							variant="outlined"
							sx={{ margin: (theme) => theme.spacing(1) }}
							startIcon={<DeleteForever />}
							onClick={() => setIsDeleteDialogOpen(true)}
							disabled={parentLoadingStatus === null || parentLoadingStatus === 'loading'}
						>
							Permanently delete
						</Button>
						<Button
							color="primary"
							variant="outlined"
							sx={{ margin: (theme) => theme.spacing(1) }}
							startIcon={<RestoreFromTrash />}
							onClick={() => setIsRestoreDialogOpen(true)}
							disabled={
								parentLoadingStatus === null || parentLoadingStatus === 'loading' || parentLoadingStatus === 'failed'
							}
						>
							Restore
						</Button>
					</Box>
					<Box sx={{ margin: (theme) => theme.spacing(2) }}>
						<PropertyDisplay name="Risk register name">
							<Typography>{selectedRegister.name}</Typography>
						</PropertyDisplay>
						<PropertyDisplay name="Type">
							<Typography>{selectedRegister.risk_register_type.name}</Typography>
						</PropertyDisplay>
						<PropertyDisplay name="Lifecycle status">
							<Typography>{selectedRegister.risk_register_lifecycle_status?.name}</Typography>
						</PropertyDisplay>
						<PropertyDisplay name="Parent register">
							{parentLoadingStatus === 'loading' ? (
								<CircularProgress />
							) : (
								<Typography>
									{parentLoadingStatus === 'none'
										? 'None'
										: parentLoadingStatus === 'loaded'
										? parentRegister?.name
										: 'Unknown (maybe it has been archived?)'}
								</Typography>
							)}
						</PropertyDisplay>
					</Box>
				</Box>
			)}
			{isDeleteDialogOpen && selectedRegister && (
				<ConfirmDialog
					title="Confirm action"
					message={`Are you sure you want to permanently delete the risk register '${selectedRegister.name}'?
					
					All the risks in this register, retired or not, will also be deleted. This action is irreversible.`}
					cancelLabel="Cancel"
					confirmLabel="Delete"
					onClose={() => setIsDeleteDialogOpen(false)}
					onConfirm={async () => {
						await RiskRegistersApi.deleteArchivedRegister(selectedRegister.risk_register_id);
						addAlert('success', `The register '${selectedRegister.name}' has been permanently deleted`);
						loadDeletedRegisters();
					}}
				/>
			)}
			{isRestoreDialogOpen && selectedRegister && (
				<ConfirmDialog
					title="Confirm action"
					message={`Are you sure you want to restore the risk register '${selectedRegister.name}'?
					
					Note that user permissions previously assigned for this register will have to be re-created. Permissions inherited from a parent register be be re-instated automatically.`}
					cancelLabel="Cancel"
					confirmLabel="Restore"
					onClose={() => setIsRestoreDialogOpen(false)}
					onConfirm={async () => {
						await RiskRegistersApi.restoreArchivedRegister(selectedRegister.risk_register_id);
						addAlert('success', `The register '${selectedRegister.name}' has been restored`);
						loadDeletedRegisters();
					}}
				/>
			)}
		</Box>
	);
}
