import { useContext } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Brightness4, Brightness7, SettingsBrightness } from '@mui/icons-material';

import { ColorModeContext } from 'providers/ColorModeProvider';

export default function ColourModePickerSmall() {
	const { colorMode, setColorMode } = useContext(ColorModeContext);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', margin: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}` }}>
			<Typography sx={{ marginRight: (theme) => theme.spacing(1) }}>Colour mode:</Typography>
			{colorMode === 'light' && (
				<Button onClick={() => setColorMode('auto')} startIcon={<Brightness7 />} variant="outlined">
					Light
				</Button>
			)}
			{colorMode === 'auto' && (
				<Button onClick={() => setColorMode('dark')} startIcon={<SettingsBrightness />} variant="outlined">
					Auto
				</Button>
			)}
			{colorMode === 'dark' && (
				<Button onClick={() => setColorMode('light')} startIcon={<Brightness4 />} variant="outlined">
					Dark
				</Button>
			)}
		</Box>
	);
}
