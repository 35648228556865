import { useEffect, useState } from 'react';
import {
	Box,
	CircularProgress,
	Divider,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import {
	IApiRiskRegisterTypeWithLifecycleStatuses,
	IApiRiskRegisterWithoutPermissions,
} from '@mitie/risk-register-api-types';
import { ArrowForward, Close } from '@mui/icons-material';

import * as RiskRegisterTypesApi from 'api/riskRegisterTypes';
import PropertyDisplay from './PropertyDisplay';
import { useNavigate } from 'routing/routing';
import { Page } from 'routing/routes';
import RiskRegisterTypeForm from './RiskRegisterTypeForm';

export default function RiskRegisterType({
	item,
	onChange,
	isDataLoading,
}: {
	item: IApiRiskRegisterTypeWithLifecycleStatuses;
	onChange: () => void;
	isDataLoading: boolean;
}) {
	const [isUsageLoading, setIsUsageLoading] = useState(false);
	const [usage, setUsage] = useState<IApiRiskRegisterWithoutPermissions[] | null>(null);
	const [isUsageDrawerOpen, setIsUsageDrawerOpen] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		loadUsageData();
	}, [item]);

	const loadUsageData = async () => {
		setIsUsageLoading(true);

		try {
			const data = await RiskRegisterTypesApi.getUsageForRegisterType(item.risk_register_type_id);

			setUsage(data);
		} finally {
			setIsUsageLoading(false);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<RiskRegisterTypeForm
					item={item}
					onSave={onChange}
					disabled={isDataLoading || isUsageLoading}
					canDelete={usage ? usage.length === 0 : false}
				/>
			</Box>
			<Divider />
			<Box sx={{ margin: (theme) => theme.spacing(2) }}>
				<PropertyDisplay name="Number of risk registers of this type" width="400px">
					{isUsageLoading ? (
						<CircularProgress size={16} />
					) : (
						<Link component="button" underline="hover" onClick={() => setIsUsageDrawerOpen(true)}>
							{usage?.length}
						</Link>
					)}
				</PropertyDisplay>
			</Box>
			<Drawer anchor="right" open={isUsageDrawerOpen} onClose={() => setIsUsageDrawerOpen(false)}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ flexGrow: 1, margin: (theme) => `0 ${theme.spacing(2)}` }}>
						Risk registers of this type
					</Typography>
					<IconButton onClick={() => setIsUsageDrawerOpen(false)}>
						<Close />
					</IconButton>
				</Box>
				{usage && (
					<List>
						{usage.map((riskRegister) => (
							<ListItem
								key={riskRegister.risk_register_id}
								secondaryAction={
									<IconButton
										edge="end"
										onClick={() => {
											if (riskRegister.deleted) {
												navigate(Page.DeletedData, {
													page: 'deleted-registers',
												});
											} else {
												navigate(Page.RiskRegisters, {
													registerId: riskRegister.risk_register_id,
													registerName: riskRegister.name,
												});
											}
										}}
									>
										<ArrowForward />
									</IconButton>
								}
							>
								<ListItemText primary={riskRegister.name} />
							</ListItem>
						))}
					</List>
				)}
			</Drawer>
		</Box>
	);
}
