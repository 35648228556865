import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: `${process.env.REACT_APP_APPINSIGHT_KEY}`,
		disableFetchTracking: false,
		appId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		enableAutoRouteTracking: true,
		extensions: [reactPlugin as any], // TODO: "as any" added due to typing conflict in appInsights repos. Revisit once react plugin is updated
	},
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
