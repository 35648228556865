import { Box, ButtonBase, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { IApiRiskCategory, RiskAppetite } from '@mitie/risk-register-api-types';

interface IRiskAppetitePickerDialogProps {
	riskCategory: IApiRiskCategory;
	onClose: (selected?: RiskAppetite) => void;
}

export default function RiskAppetitePickerDialog({ riskCategory, onClose }: IRiskAppetitePickerDialogProps) {
	const buttonClicked = (value: RiskAppetite) => {
		onClose(value);
	};
	const appetiteOptions: { value: RiskAppetite; field: string; backgroundColor: string }[] = [
		{ value: 'Averse', field: 'averse_appetite_definition', backgroundColor: 'error.light' },
		{ value: 'Cautious', field: 'cautious_appetite_definition', backgroundColor: 'warning.light' },
		{ value: 'Eager', field: 'eager_appetite_definition', backgroundColor: 'success.light' },
	];

	return (
		<Dialog open={true} onClose={() => onClose()} maxWidth="md">
			<DialogTitle>Pick risk appetite</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{appetiteOptions.map(({ value, field, backgroundColor }) => (
						<ButtonBase
							sx={{
								margin: (theme) => theme.spacing(1),
								flexDirection: 'column',
								flexShrink: 0,
								borderRadius: '4px',
								padding: (theme) => theme.spacing(1),
								'&:hover': {
									'&>div': {
										opacity: (theme) => (theme.palette.mode === 'light' ? 0.75 : 0.25),
									},
								},
								backgroundColor,
							}}
							focusRipple
							onClick={() => buttonClicked(value)}
							key={value}
						>
							<Typography sx={{ zIndex: 1 }}>{value}</Typography>
							<Typography variant="caption" sx={{ zIndex: 1 }}>
								{(riskCategory as any)[field]}
							</Typography>
							<Box
								sx={{
									position: 'absolute',
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
									backgroundColor: (theme) => theme.palette.common.white,
									opacity: (theme) => (theme.palette.mode === 'light' ? 0.5 : 0),
									transition: (theme) => theme.transitions.create('opacity'),
								}}
							/>
						</ButtonBase>
					))}
				</Box>
			</DialogContent>
		</Dialog>
	);
}
