import { Box, Paper, Typography, useTheme } from '@mui/material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import lightLogo from '../images/logo-full-light.png';
import darkLogo from '../images/logo-full-dark.png';
import LoginForm from 'components/LoginForm';

export default function Login() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'Login');
	const theme = useTheme();

	componentTracking();

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Paper
				elevation={6}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					justifyContent: 'center',
					width: '450px',
				}}
			>
				<Box sx={{ margin: (theme) => theme.spacing(2) }}>
					<img src={theme.palette.mode === 'light' ? lightLogo : darkLogo} alt="Logo" style={{ width: '100%' }} />
				</Box>
				<Box sx={{ margin: (theme) => theme.spacing(2) }}>
					<Typography variant="h5" sx={{ textAlign: 'center', marginBottom: (theme) => theme.spacing(2) }}>
						Welcome to Risk Safe
					</Typography>
					<Typography sx={{ textAlign: 'center' }}>Please sign in to access your risk registers</Typography>
					<LoginForm />
				</Box>
			</Paper>
		</Box>
	);
}
