import { Paper, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import logo from '../images/risksafe-logo.png';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			width: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		logo: {
			float: 'left',
			width: '50%',
		},
		errorContent: {
			margin: theme.spacing(2),
			'&>p': {
				marginBottom: theme.spacing(2),
			},
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: theme.spacing(2),
		},
	}),
);

export default function Unlicensed() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'Unlicensed');
	const classes = useStyles();

	componentTracking();

	return (
		<div className={classes.root}>
			<Paper elevation={3} className={classes.content}>
				<img className={classes.logo} src={logo} alt="Logo" />
				<div className={classes.errorContent}>
					<Typography>The license has expired. Please contact the Risk Safe system owner in your company.</Typography>
				</div>
			</Paper>
		</div>
	);
}
