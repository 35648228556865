import { useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { IApiRiskRegisterTypeWithLifecycleStatuses } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';

import * as RiskRegisterTypesApi from 'api/riskRegisterTypes';
import { useNavigate, useParams } from 'routing/routing';
import { encodeStringForUrl } from 'utils';
import RiskRegisterType from './RiskRegisterType';
import AddRiskRegisterTypeDialog from './AddRiskRegisterTypeDialog';

export default function RiskRegisterTypes() {
	const [selectedRegisterType, setSelectedRegisterType] = useState<IApiRiskRegisterTypeWithLifecycleStatuses | null>(
		null,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [list, setList] = useState<IApiRiskRegisterTypeWithLifecycleStatuses[]>([]);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const navigate = useNavigate();
	const { page, selected } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		setSelectedRegisterType(list.find((c) => encodeStringForUrl(c.name) === selected) || null);
	}, [list, selected]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await RiskRegisterTypesApi.getAllIncludingDisabled();

			setList(data);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<List component="nav" sx={{ maxWidth: '300px', overflowY: 'auto' }}>
				<Box
					sx={{
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						display: 'flex',
					}}
				>
					<Button
						color="primary"
						variant="contained"
						sx={{ margin: (theme) => theme.spacing(1) }}
						startIcon={<Add />}
						onClick={() => setIsAddDialogOpen(true)}
					>
						Add register type
					</Button>
				</Box>
				{isLoading && <LinearProgress />}
				{list.map((riskRegisterType) => (
					<ListItemButton
						key={riskRegisterType.risk_register_type_id}
						selected={selectedRegisterType?.risk_register_type_id === riskRegisterType.risk_register_type_id}
						onClick={() => navigate(null, { page, selected: encodeStringForUrl(riskRegisterType.name) })}
					>
						<ListItemText primary={riskRegisterType.name} secondary={riskRegisterType.disabled ? 'Disabled' : null} />
					</ListItemButton>
				))}
			</List>
			<Divider orientation="vertical" />
			{selectedRegisterType && (
				<RiskRegisterType item={selectedRegisterType} onChange={loadData} isDataLoading={isLoading} />
			)}
			{isAddDialogOpen && (
				<AddRiskRegisterTypeDialog
					onClose={(success: boolean) => {
						if (success) {
							loadData();
						}

						setIsAddDialogOpen(false);
					}}
				/>
			)}
		</Box>
	);
}
