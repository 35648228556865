import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import StrategicPillarForm from './StrategicPillarForm';

interface IAddPillarDialogProps {
	onClose: (success: boolean) => void;
}

export default function AddPillarDialog({ onClose }: IAddPillarDialogProps) {
	return (
		<Dialog open={true} onClose={() => onClose(false)}>
			<DialogTitle>New strategic pillar</DialogTitle>
			<DialogContent>
				<Box sx={{ paddingTop: (theme) => theme.spacing(2) }}>
					<StrategicPillarForm onSave={() => onClose(true)} onCancel={() => onClose(false)} />
				</Box>
			</DialogContent>
		</Dialog>
	);
}
