import { useState, useEffect } from 'react';
import { Box, LinearProgress, Button } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IApiRiskRegister } from '@mitie/risk-register-api-types';
import { Add } from '@mui/icons-material';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

import * as Api from '../api/riskRegisters';
import { useNavigate, useParams } from '../routing/routing';
import RiskRegisterTree from 'components/RiskRegisterTree';
import RiskRegisterDetails from 'components/RiskRegisterDetails';
import RiskRegisterEditDialog from 'components/RiskRegisterEditDialog';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			width: '100%',
		},
		riskRegistersContainer: {
			borderRight: `1px solid ${theme.palette.divider}`,
			minWidth: '300px',
			display: 'flex',
			flexDirection: 'column',
		},
		actionsBar: {
			borderBottom: `1px solid ${theme.palette.divider}`,
			display: 'flex',
			justifyContent: 'center',
		},
		button: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
);

export default function RiskRegistersEdit() {
	const appInsights = useAppInsightsContext();
	const componentTracking = useTrackMetric(appInsights, 'ManageRiskRegistersPage');
	const classes = useStyles();
	const [riskRegisters, setRiskRegisters] = useState<IApiRiskRegister[]>();
	const [selectedRegister, setSelectedRegister] = useState<IApiRiskRegister>();
	const [selectedRegisterParents, setSelectedRegisterParents] = useState<IApiRiskRegister[] | null>();
	const [isLoading, setIsLoading] = useState(false);
	const [addRegisterDialogOpen, setAddRegisterDialogOpen] = useState(false);
	const { registerId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		if (riskRegisters) {
			const match = riskRegisters.find((r) => r.risk_register_id === registerId);

			if (match) {
				setSelectedRegister(match);

				if (match.parent_risk_register_id) {
					const hierarchy: IApiRiskRegister[] = [];

					for (
						let parent: IApiRiskRegister | undefined = riskRegisters.find(
							(r) => r.risk_register_id === match.parent_risk_register_id,
						);
						parent !== undefined;
						parent = riskRegisters.find((r) => r.risk_register_id === parent!.parent_risk_register_id)
					) {
						hierarchy.unshift(parent);
					}

					setSelectedRegisterParents(hierarchy);
				} else {
					setSelectedRegisterParents(null);
				}
			} else {
				setSelectedRegister(riskRegisters[0]);
				setSelectedRegisterParents(null);
			}
		}
	}, [registerId, riskRegisters]);

	const loadData = async () => {
		setIsLoading(true);

		try {
			const data = await Api.getAll();
			setRiskRegisters(data);

			if (data.length > 0 && registerId === undefined) {
				navigate(null, { registerId: data[0].risk_register_id, registerName: data[0].name });
			}
		} finally {
			setIsLoading(false);
		}
	};

	componentTracking();

	return (
		<div className={classes.root}>
			<div className={classes.riskRegistersContainer}>
				<div className={classes.actionsBar}>
					{selectedRegister && selectedRegister.permissions.includes('create-register') && (
						<Button
							color="primary"
							variant="contained"
							className={classes.button}
							startIcon={<Add />}
							onClick={() => setAddRegisterDialogOpen(true)}
						>
							Add child register...
						</Button>
					)}
				</div>
				{isLoading && <LinearProgress />}
				{riskRegisters && (
					<Box sx={{ minHeight: 0, display: 'flex' }}>
						<Box sx={{ display: 'flex', flexGrow: 1, margin: (theme) => theme.spacing(2) }}>
							<RiskRegisterTree
								list={riskRegisters}
								selected={
									selectedRegister
										? selectedRegisterParents
											? [...selectedRegisterParents, selectedRegister]
											: [selectedRegister]
										: undefined
								}
								onSelect={(selected) =>
									navigate(null, {
										registerId: selected[selected.length - 1].risk_register_id,
										registerName: selected[selected.length - 1].name,
									})
								}
							/>
						</Box>
					</Box>
				)}
			</div>
			<Box sx={{ flexGrow: 1 }}>
				{selectedRegister !== undefined && selectedRegisterParents !== undefined && riskRegisters && (
					<RiskRegisterDetails
						list={riskRegisters}
						register={selectedRegister}
						parents={selectedRegisterParents}
						onChange={() => {
							loadData();
						}}
					/>
				)}
			</Box>
			{addRegisterDialogOpen && (
				<RiskRegisterEditDialog
					parent={selectedRegister || null}
					onClose={(result) => {
						setAddRegisterDialogOpen(false);

						if (result) {
							loadData();
						}
					}}
				/>
			)}
		</div>
	);
}
